import React, { useState, useEffect, Component } from "react";
import classnames from "classnames";
import axios from "axios";
import apiUrl from "../../config/config";
import $ from "jquery";
import { connect } from "react-redux";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import Select from "react-select";
import PropTypes from "prop-types";
import TopNav from "../../components/common/TopNav";
import socketIOClient from "socket.io-client";
import BeatsTabVerifier from "../verifier/BeatsTabVerifier";
import VerifierSummary from "../verifier/VerifierSummary";
import SettingsTab from "../../components/graph/SettingsTab";
import SideBarGraph from "../verifier/SideBarVerifier";
import AddFindingsTab from "../../components/graph/AddFindingsTab";
import DiaryNotes from "../../components/graph/DiaryNotes";
import VerifierSelectedBeats from "../verifier/VerifierSelectedBeats";
import AddModal from "../verifier/AddModal";
import AddModalComplete from "../verifier/AddModalComplete";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./vc_backup2.css";
import Loader from "react-loader-spinner";
import ReactModal from "react-modal";
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from "worker-loader!./worker.js";
import AddModalReport from "./AddModalReport";
import Addmodaldisclosure from "./Addmodaldisclosure";
import AddModalInside from "./AddModalInside";
import { now } from "moment";
const deviceFrequency = 15000;
let worker,
  worker2,
  worker3,
  worker4,
  worker5,
  worker7,
  worker13,
  worker14,
  worker15,
  worker6,
  workersum2,
  worker11,
  worker12,
  worker8,
  worker9,
  worker10;
let graphColor = "";
let gainMin = -0.0005;
let gainMax = 0.0015;
let clickContainerIndex = 0;
let chartCount = 0;
let pageIndex = 0;
window.tab = "Beats";
window.prev_tab = "Events";
require("highcharts-multicolor-series")(Highcharts);
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
//const socket = socketIOClient('http://edit.mybiocalculus.com:5000');
class VerifierChart extends Component {
  constructor() {
    super();
    /********* State Variables*************/
    worker = new Worker();
    worker2 = new Worker();
    worker3 = new Worker();
    worker4 = new Worker();
    worker5 = new Worker();
    worker6 = new Worker();
    worker7 = new Worker();
    worker8 = new Worker();
    worker9 = new Worker();
    worker13 = new Worker();
    worker10 = new Worker();
    worker11 = new Worker();
    worker12 = new Worker();
    worker14 = new Worker();
    worker15 = new Worker();
    workersum2 = new Worker();
   const menus = [
         { title: "Verifier Home", url: "/verifier-home" },
         { title: "Patient list", url: "/patient-list" },
       
       ];
       if (localStorage.getItem("verifiertype") === "8") {
         menus.splice(2, 0, { title: "Patient-hold", url: "/patient-hold" });
       }
       if (localStorage.getItem("verifierid") === "5d6df589c3d92110fccfde7c") {
         menus.splice(3, 0, { title: "Device Deatils", url: "/device-details" });
       }
       if (localStorage.getItem("verifiertype") === "8") {
         menus.splice(4, 0, { title: "Support Home", url: "support-home" });
       }
       if (localStorage.getItem("verifiertype") === "8") {
         menus.splice(5, 0, { title: "QC Home", url: "qc_home" });
       }
       if (localStorage.getItem("verifiertype") === "8") {
         menus.splice(6, 0, { title: "Support Web", url: "support-homeweb" });
       }
    this.state = {
      menus,
      checkArry: [
        { value: "current", label: "Current" },
        { value: "nsr", label: "Normal Sinus Rhythm" },
        { value: "st", label: "Sinus Tachycardia" },
        { value: "sb", label: "Sinus Bradycardia" },
        { value: "pac", label: "PAC" },
        { value: "pvc", label: "PVC" },
        { value: "sp", label: "Sinus pause" },
        { value: "vt", label: "Ventricular Tachycardia" },
        { value: "af", label: "Atrial Fibrillation" },
        { value: "flutter", label: "Atrial Flutter" },
        { value: "jbeat", label: "J Beat" },
        { value: "un", label: "Undiagnosed" },
        { value: "Short QT", label: "SVT" },
        { value: "Irregular PR", label: "2AVB" },
        { value: "Irregular RR", label: "CHB" },
      ],

      arrythymia_array: [],
      profileLink: "/verifier-profile",
      clickdb: "",
      treemenu: [],
      clickedPI: "", //used in updateState()
      rpeaks: [], //For Summar Tab
      arrythymia_type: [],
      databaseid: "",
      reoprtLink: "",
      reportLinkStatus: 0,
      reportStatus: "",
      x: 0,
      y: 0,
      images: {},
      imagess: {},
      endpoint: "localhost:5000",
      datasets: {},
      dblink: "", // check whether it is same as the previous databaseid
      nextDbId: "",
      prevDbId: "",
      fullVerifyStatus: 100,
      chart: "",
      isModalOpen: false,
      isModalReportOpen: false,
      ismodaldisOpen: false,
      isModalInsideOpen: false,
      rpeak_index: "",

      result3: [],
      charts: [],
      valueList: [],
      stripcount: 1,
      labelinfo: "Yes",
      sel_arr: "CURRENT",
      arr_list: "CURRENT",
      newName: "CURRENT",
      starr: [],
      errors: {},
      defarr: [],
      selectedChart: 0, //Check Whether it is needed
      mainEcgArray: [],
      mainActivityArray: [],
      datasetMnt: 0, //Check whether we need a state variable for this
      currentTab: "chart_tab_2",
      start: 0,
      end: 9,
      starttime: [],
      endtime: [],
      limitScrollStart: 1,
      limitScrollEnd: 1,
      peakStart: 0,
      graphMarker: {},
      patientMenuTree: {},
      verifyCheck: "",
      patientReportStatus: "",
      scrollStatus: "", //Remove this variable's usages then delete this
      summaryData: "",
      minMaxHrDataCurr: "",
      minMaxHrDataFull: "",
      heartRatesData: "",
      patientwrkdetails: "",
      patientwrktotaldetails: "",
      patientwrkdailydetails: "",
      arrCount: "",
      keyIntegrationStatus: 0,
      responseMessage: "",
      showModalSpinner: false,
      beat_order: 0,
      window_size: 0,
      threshold: 0,
      hrgt: 0,
      hrlt: 0,
      beatgt: 0,
      beatlt: 0,
      checkArr: "equal",
      qRecatArr: "Current",
      fullCurrent: "Current",
      newArr: "Current",
      newArrhy: "PVC",

      lowr: 0,
      highr: 0,
      response: "",
      showResponseModal: false,
      popupX: 0,
      popupY: 0,
      full_current: "Current",
      prev_arh: "Current",
      next_arh: "Current",
      low_2_high: 0,
      high_2_high: 0,
      verifiedStatus: "",
      //minmaxhr state initialize
      afibMinHr: null,
      afibMaxHr: null,
      iprMinHr: null,
      iprMaxHr: null,
      irrMinHr: null,
      irrMaxHr: null,
      pacMinHr: null,
      pacMaxHr: null,
      pvcMinHr: null,
      pvcMaxHr: null,
      stMinHr: null,
      stMaxHr: null,
      spMinHr: null,
      spMaxHr: null,
      nsrMinHr: null,
      nsrMaxHr: null,
      pqtMinHr: null,
      pqtMaxHr: null,
      pabsentMinHr: null,
      pabsentMaxHr: null,
      sqtMinHr: null,
      sqtMaxHr: null,
      vtMinHr: null,
      vtMaxHr: null,
      sbMinHr: null,
      sbMaxHr: null,
      selectedstarttime: null,
      selectedendtime: null,
      verifierNameData: [],
      isCommentModalOpen: false,
      comments:"",
    };

    /********* State Variables*************/
    this.fetchminmaxHr = this.fetchminmaxHr.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.openChartPopup = this.openChartPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleModalInside = this.handleModalInside.bind(this);
    this.resetModal = this.resetModal.bind(this);
    this.resetModalReport = this.resetModalReport.bind(this);
    this.resetmodaldis = this.resetmodaldis.bind(this);
    this.handleDiary = this.handleDiary.bind(this);
    this.updateState = this.updateState.bind(this);
    this.selectImage = this.selectImage.bind(this);
    this.updateRpeaks = this.updateRpeaks.bind(this); //saves peakvalues to state variable
    this.generateReport = this.generateReport.bind(this); //Executes on Generate Report Button
    this.fulldisclosure = this.fulldisclosure.bind(this);
    this.mailReport = this.mailReport.bind(this); //Mails Report
    this.newDataClick = this.newDataClick.bind(this); //For navigation to Next/Previous database.Currently displayed None
    this.updateMainChartODC = this.updateMainChartODC.bind(this); //Exeutes On Double click on Event Image within updateState()
    this.recategorizeAfterImage = this.recategorizeAfterImage.bind(this);
    this.captureImage = this.captureImage.bind(this);
    this.quickRecat = this.quickRecat.bind(this);
    this.enableKey = this.enableKey.bind(this);
    this.onStarttimeChange = this.onStarttimeChange.bind(this);
    this.onEndtimeChange = this.onEndtimeChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getverifierName = this.getverifierName.bind(this);
    // this.closeModal = this.closeModal.bind(this);
    this.submitcommentinfo = this.submitcommentinfo.bind(this);
    this.getInfo = this.getInfo.bind(this);
  }

  setTab(e) {
    var currentTab = e.currentTarget.id;
    this.setState({
      currentTab: currentTab,
    });
    if (
      e.currentTarget.id == "chart_tab_2" &&
      this.props.match.params.start == undefined
    ) {
      document.getElementById("chart_tab_2").innerHTML =
        '<img id="eventsimg" src="../../dist/img/Events.png"/>';
      document.getElementById("chart_tab_1").innerHTML =
        '<img id="beatsimg" src="../../dist/img/BeatsSelect.png"/>';
      // document.getElementById("chart_tab_3").innerHTML =
      //   '<img id="summaryimg" src="../../dist/img/Summary.png"/>';
      // document.getElementById("chart_tab_4").innerHTML="<img id=\"heartrateimg\" src=\"../../dist/img/HeartRate.png\"/>";
      document.getElementById("chart_tab_5").innerHTML =
        '<img id="selectimg" src="../../dist/img/SelectedEvents.png"/>';
      //    if(document.getElementById("chart_tab_email")!=null){document.getElementById("chart_tab_email").innerHTML="<img id=\"emailImg\" src=\"../../dist/img/Email.png\"/>";}
      //  document.getElementById("chart_tab_settings").innerHTML="<img id=\"settingsImg\" src=\"../../dist/img/Settings.png\"/>";
      document.getElementById("chart_tab_findings").innerHTML =
        '<img id="addFindImg" src="../../dist/img/AddFindings.png"/>';
      this.fetchminmaxHr();
    }
    if (
      e.currentTarget.id == "chart_tab_2" &&
      this.props.match.params.start != undefined
    ) {
      document.getElementById("chart_tab_2").innerHTML =
        '<img id="eventsimg" src="../../../../../dist/img/Events.png"/>';
      document.getElementById("chart_tab_1").innerHTML =
        '<img id="beatsimg" src="../../../../../dist/img/BeatsSelect.png"/>';
      // document.getElementById("chart_tab_3").innerHTML =
      //   '<img id="summaryimg" src="../../../../../dist/img/Summary.png"/>';
      //document.getElementById("chart_tab_4").innerHTML="<img id=\"heartrateimg\" src=\"../../../../../dist/img/HeartRate.png\"/>";
      document.getElementById("chart_tab_5").innerHTML =
        '<img id="selectimg" src="../../../../../dist/img/SelectedEvents.png"/>';
      //   if(document.getElementById("chart_tab_email")!=null){document.getElementById("chart_tab_email").innerHTML="<img id=\"emailImg\" src=\"../../../../../dist/img/Email.png\"/>";}
      //  document.getElementById("chart_tab_settings").innerHTML="<img id=\"settingsImg\" src=\"../../../../../dist/img/Settings.png\"/>";
      document.getElementById("chart_tab_findings").innerHTML =
        '<img id="addFindImg" src="../../../../../dist/img/AddFindings.png"/>';
    } else if (
      e.currentTarget.id == "chart_tab_1" &&
      this.props.match.params.start == undefined
    ) {
      document.getElementById("chart_tab_1").innerHTML =
        '<img id="eventsimg" src="../../dist/img/EventsSelected.png"/>';
      document.getElementById("chart_tab_2").innerHTML =
        '<img id="beatsimg" src="../../dist/img/Beats.png"/>';
      // document.getElementById("chart_tab_3").innerHTML =
      //   '<img id="summaryimg" src="../../dist/img/Summary.png"/>';
      // document.getElementById("chart_tab_4").innerHTML="<img id=\"heartrateimg\" src=\"../../dist/img/HeartRate.png\"/>";
      document.getElementById("chart_tab_5").innerHTML =
        '<img id="selectimg" src="../../dist/img/SelectedEvents.png"/>';
      //   if(document.getElementById("chart_tab_email")!=null){document.getElementById("chart_tab_email").innerHTML="<img id=\"emailImg\" src=\"../../dist/img/Email.png\"/>";}
      //  document.getElementById("chart_tab_settings").innerHTML="<img id=\"settingsImg\" src=\"../../dist/img/Settings.png\"/>";
      document.getElementById("chart_tab_findings").innerHTML =
        '<img id="addFindImg" src="../../dist/img/AddFindings.png"/>';
    } else if (
      e.currentTarget.id == "chart_tab_1" &&
      this.props.match.params.start != undefined
    ) {
      document.getElementById("chart_tab_1").innerHTML =
        '<img id="eventsimg" src="../../../../../dist/img/EventsSelected.png"/>';
      document.getElementById("chart_tab_2").innerHTML =
        '<img id="beatsimg" src="../../../../../dist/img/Beats.png"/>';
      // document.getElementById("chart_tab_3").innerHTML =
      //   '<img id="summaryimg" src="../../../../../dist/img/Summary.png"/>';
      //document.getElementById("chart_tab_4").innerHTML="<img id=\"heartrateimg\" src=\"../../../../../dist/img/HeartRate.png\"/>";
      document.getElementById("chart_tab_5").innerHTML =
        '<img id="selectimg" src="../../../../../dist/img/SelectedEvents.png"/>';
      //  if(document.getElementById("chart_tab_email")!=null){document.getElementById("chart_tab_email").innerHTML="<img id=\"emailImg\" src=\"../../../../../dist/img/Email.png\"/>";}
      //  document.getElementById("chart_tab_settings").innerHTML="<img id=\"settingsImg\" src=\"../../../../../dist/img/Settings.png\"/>";
      document.getElementById("chart_tab_findings").innerHTML =
        '<img id="addFindImg" src="../../../../../dist/img/AddFindings.png"/>';
    } else if (
      e.currentTarget.id == "chart_tab_findings" &&
      this.props.match.params.start == undefined
    ) {
      document.getElementById("chart_tab_1").innerHTML =
        '<img id="eventsimg" src="../../dist/img/Events.png"/>';
      document.getElementById("chart_tab_2").innerHTML =
        '<img id="beatsimg" src="../../dist/img/Beats.png"/>';
      // document.getElementById("chart_tab_3").innerHTML =
      //   '<img id="summaryimg" src="../../dist/img/Summary.png"/>';
      //document.getElementById("chart_tab_4").innerHTML="<img id=\"heartrateimg\" src=\"../../dist/img/HeartRate.png\"/>";
      document.getElementById("chart_tab_5").innerHTML =
        '<img id="selectimg" src="../../dist/img/SelectedEvents.png"/>';
      //    if(document.getElementById("chart_tab_email")!=null){document.getElementById("chart_tab_email").innerHTML="<img id=\"emailImg\" src=\"../../dist/img/Email.png\"/>";}
      //  document.getElementById("chart_tab_settings").innerHTML="<img id=\"settingsImg\" src=\"../../dist/img/Settings.png\"/>";
      document.getElementById("chart_tab_findings").innerHTML =
        '<img id="addFindImg" src="../../dist/img/AddFindings_white.png"/>';
    } else if (
      e.currentTarget.id == "chart_tab_findings" &&
      this.props.match.params.start != undefined
    ) {
      document.getElementById("chart_tab_1").innerHTML =
        '<img id="eventsimg" src="../../../../../dist/img/Events.png"/>';
      document.getElementById("chart_tab_2").innerHTML =
        '<img id="beatsimg" src="../../../../../dist/img/Beats.png"/>';
      // document.getElementById("chart_tab_3").innerHTML =
      //   '<img id="summaryimg" src="../../../../../dist/img/Summary.png"/>';
      //document.getElementById("chart_tab_4").innerHTML="<img id=\"heartrateimg\" src=\"../../../../../dist/img/HeartRate.png\"/>";
      document.getElementById("chart_tab_5").innerHTML =
        '<img id="selectimg" src="../../../../../dist/img/SelectedEvents.png"/>';
      //       if(document.getElementById("chart_tab_email")!=null){document.getElementById("chart_tab_email").innerHTML="<img id=\"emailImg\" src=\"../../../../../dist/img/Email.png\"/>";}
      //  document.getElementById("chart_tab_settings").innerHTML="<img id=\"settingsImg\" src=\"../../../../../dist/img/Settings.png\"/>";
      document.getElementById("chart_tab_findings").innerHTML =
        '<img id="addFindImg" src="../../../../../dist/img/AddFindings_white.png"/>';
    }

    //  else if(e.currentTarget.id=="chart_tab_settings" && this.props.match.params.start==undefined)
    // {
    //     document.getElementById("chart_tab_1").innerHTML="<img id=\"eventsimg\" src=\"../../dist/img/Events.png\"/>";
    //     document.getElementById("chart_tab_2").innerHTML="<img id=\"beatsimg\" src=\"../../dist/img/Beats.png\"/>";
    //     document.getElementById("chart_tab_3").innerHTML="<img id=\"summaryimg\" src=\"../../dist/img/Summary.png\"/>";
    //      //document.getElementById("chart_tab_4").innerHTML="<img id=\"heartrateimg\" src=\"../../dist/img/HeartRate.png\"/>";
    //     document.getElementById("chart_tab_5").innerHTML="<img id=\"selectimg\" src=\"../../dist/img/SelectedEvents.png\"/>";
    //     if(document.getElementById("chart_tab_email")!=null){document.getElementById("chart_tab_email").innerHTML="<img id=\"emailImg\" src=\"../../dist/img/Email.png\"/>";}
    //     //  document.getElementById("chart_tab_settings").innerHTML="<img id=\"settingsImg\" src=\"../../dist/img/Settings_white.png\"/>";
    //       document.getElementById("chart_tab_findings").innerHTML="<img id=\"addFindImg\" src=\"../../dist/img/AddFindings.png\"/>";

    // }
    // else if(e.currentTarget.id=="chart_tab_settings" && this.props.match.params.start!=undefined)
    // {
    //     document.getElementById("chart_tab_1").innerHTML="<img id=\"eventsimg\" src=\"../../../../../dist/img/Events.png\"/>";
    //     document.getElementById("chart_tab_2").innerHTML="<img id=\"beatsimg\" src=\"../../../../../dist/img/Beats.png\"/>";
    //     document.getElementById("chart_tab_3").innerHTML="<img id=\"summaryimg\" src=\"../../../../../dist/img/Summary.png\"/>";
    //      //document.getElementById("chart_tab_4").innerHTML="<img id=\"heartrateimg\" src=\"../../../../../dist/img/HeartRate.png\"/>";
    //     document.getElementById("chart_tab_5").innerHTML="<img id=\"selectimg\" src=\"../../../../../dist/img/SelectedEvents.png\"/>";
    //     if(document.getElementById("chart_tab_email")!=null){document.getElementById("chart_tab_email").innerHTML="<img id=\"emailImg\" src=\"../../../../../dist/img/Email.png\"/>";}
    //     //  document.getElementById("chart_tab_settings").innerHTML="<img id=\"settingsImg\" src=\"../../../../../dist/img/Settings_white.png\"/>";
    //       document.getElementById("chart_tab_findings").innerHTML="<img id=\"addFindImg\" src=\"../../../../../dist/img/AddFindings.png\"/>";

    // }
    else if (
      e.currentTarget.id == "chart_tab_8" &&
      this.props.match.params.start == undefined
    ) {
      document.getElementById("chart_tab_1").innerHTML =
        '<img id="eventsimg" src="../../dist/img/Events.png"/>';
      document.getElementById("chart_tab_2").innerHTML =
        '<img id="beatsimg" src="../../dist/img/Beats.png"/>';
      // document.getElementById("chart_tab_3").innerHTML =
      //   '<img id="summaryimg" src="../../dist/img/Summary.png"/>';
      //document.getElementById("chart_tab_4").innerHTML="<img id=\"heartrateimg\" src=\"../../dist/img/HeartRate.png\"/>";
      document.getElementById("chart_tab_5").innerHTML =
        '<img id="selectimg" src="../../dist/img/SelectedEvents.png"/>';
      //    if(document.getElementById("chart_tab_email")!=null){document.getElementById("chart_tab_email").innerHTML="<img id=\"emailImg\" src=\"../../dist/img/Email.png\"/>";}

      //  document.getElementById("chart_tab_settings").innerHTML="<img id=\"settingsImg\" src=\"../../dist/img/Settings.png\"/>";
      document.getElementById("chart_tab_findings").innerHTML =
        '<img id="addFindImg" src="../../dist/img/AddFindings.png"/>';
    } else if (
      e.currentTarget.id == "chart_tab_8" &&
      this.props.match.params.start != undefined
    ) {
      document.getElementById("chart_tab_1").innerHTML =
        '<img id="eventsimg" src="../../../../../dist/img/Events.png"/>';
      document.getElementById("chart_tab_2").innerHTML =
        '<img id="beatsimg" src="../../../../../dist/img/Beats.png"/>';
      // document.getElementById("chart_tab_3").innerHTML =
      //   '<img id="summaryimg" src="../../../../../dist/img/Summary.png"/>';
      //document.getElementById("chart_tab_4").innerHTML="<img id=\"heartrateimg\" src=\"../../../../../dist/img/HeartRate.png\"/>";
      document.getElementById("chart_tab_5").innerHTML =
        '<img id="selectimg" src="../../../../../dist/img/SelectedEvents.png"/>';
      //  if(document.getElementById("chart_tab_email")!=null){document.getElementById("chart_tab_email").innerHTML="<img id=\"emailImg\" src=\"../../../../../dist/img/Email.png\"/>";}
      //  document.getElementById("chart_tab_settings").innerHTML="<img id=\"settingsImg\" src=\"../../../../../dist/img/Settings.png\"/>";
      document.getElementById("chart_tab_findings").innerHTML =
        '<img id="addFindImg" src="../../../../../dist/img/AddFindings.png"/>';
    }

    window.prev_tab = window.tab;
    window.tab = e.currentTarget.innerHTML;
    //console.log('window.tab Inside', window.prev_tab);
  }

  selectImage(e) {
    var folder = window.eventclass;
    /*console.log(folder);
        console.log(e.currentTarget.className.split(" ")[3]);*/
    if (e.currentTarget.className.split(" ")[3] == folder) {
      if (e.currentTarget.childNodes[1].checked == true) {
        e.currentTarget.childNodes[1].checked = false;
      } else {
        e.currentTarget.childNodes[1].checked = true;
      }
      console.log(e.currentTarget.childNodes[1].checked);
    } else {
      e.currentTarget.childNodes[1].checked = false;
    }
  }
  handlearrythymiaChange = (selectedOptions) => {
    const valueList = selectedOptions.map((option) => option.value);
    console.log("Selected Values:", valueList);
    this.setState({ valueList: valueList });
    this.setState({
      arrythymia_array: selectedOptions || [], // Update selected verifiers or set empty array if none
    });
    // console.log('selected options',selectedOptions)
    // console.log('selected verifier state',this.state.selectedVerifiers,this.state.selectedVerifierIds)
  };
  updateState(e) {
    window.prev_tab = window.tab;
    window.tab = "Events";
    var clickedPI = e.currentTarget.id.toString();
    var clickedPI_ =
      clickedPI.substring(0, 2) +
      ":" +
      clickedPI.substring(2, 4) +
      ":" +
      clickedPI.substring(4, 6);
    var userid = this.props.match.params.userid;
    var databaseid = this.props.match.params.databaseid;
    var usSelf = this;

    if (typeof databaseid === "undefined") {
      databaseid = "0";
    }
    const data = {
      userid: userid,
      databaseid: databaseid,
      clickedPI_: clickedPI_,
    };
    // console.log('clickedPI_', clickedPI_);
    axios
      .post(apiUrl + "api/common/getRpeakIndex", data)
      .then((response) => {
        var clickedPI = response.data.rpeakindex;
        //console.log("clickedPI",clickedPI);
        // console.log('clickedPI', clickedPI);
        document.getElementById("chart_tab_2").click();
        if (clickedPI > 15000) {
          var stripIndex = Math.floor(clickedPI / 15000);
        } else {
          var stripIndex = 0;
        }
        window.chartid = 0;

        window.stripXValue = parseInt(clickedPI) - stripIndex * 15000;
        var start = stripIndex,
          end = stripIndex + 9;
        console.log("starts", start);
        console.log("prevStart", window.prevStart);
        window.container = start;
        usSelf.setState({
          start: start,
          end: end,
        });
        setTimeout(function () {
          if (window.prevStart <= start) {
            if (
              $("#selWrap").position().top -
                $("#container" + start).position().top !=
              0
            ) {
              $("#selWrap").scrollTop(0);
              $("#selWrap").scrollTop(
                $("#container" + start).position().top -
                  $("#selWrap").position().top
              );
              window.prevStart = start;
            }
          } else if (window.prevStart > start) {
            $("#selWrap").scrollTop(0);
            $("#selWrap").scrollTop(
              $("#container" + start).position().top -
                $("#selWrap").position().top
            );
            window.prevStart = start;
          }
        }, 1000);
      })
      .catch((err) => console.log(err));
  }

  updateMainChartODC(starts, ends) {
    var userid = userid;
    var mainEcgArray,
      mainActivityArray = [];
    var result,
      result2,
      result3,
      colorArray = [];
    var chart;

    var tonarr = [],
      rarr = [];

    // var parr = [],
    //     tarr = [],
    //     qarr = [],
    //     rarr = [],
    //     sarr = [],
    //     ponarr = [],
    //     poffarr = [],
    //     tonarr = [],
    //     toffarr = [],
    //     jarr = [];
    var peakColorArray = [];
    peakColorArray["Normal Sinus Rhythm"] = "#3D761D";
    peakColorArray["Sinus Tachycardia"] = "#00FFFF";
    peakColorArray["Sinus Bradycardia"] = "#FF9900";
    peakColorArray["PAC"] = "#0000FF";
    peakColorArray["PVC"] = "#FF00FF";
    peakColorArray["Ventricular Tachycardia"] = "#A64D79";
    peakColorArray["AFib"] = "#9900FF";
    peakColorArray["Sinus Pause"] = "#FF0000";
    peakColorArray["P Absent"] = "#bfbebb";
    peakColorArray["Irregular RR"] = "#7F6000";
    peakColorArray["Irregular PR"] = "#351C75";
    peakColorArray["Short QT"] = "#980000";
    peakColorArray["Prolonged QT"] = "#46BDC6";
    peakColorArray["Undiagnosed"] = "#FFD966";

    function chunkPeakArray(peakArray, start = 0) {
      //console.log(peakArray);
      let presults = [];
      var minIndex = 0;
      var breakVal = deviceFrequency * (window.pageIndex + 1);
      var z;
      var temRpeakindex;

      presults.push([]);
      for (var i = 0; i < peakArray.length; i++) {
        if (window.pageIndex * deviceFrequency < peakArray[i].rpeakindex) {
          if (peakArray[i].rpeakindex > breakVal) {
            presults.push([]);
            minIndex = minIndex + 1;
            breakVal = breakVal + deviceFrequency;
          }
          /*console.log(peakArray[i].rpeakindex);
                    console.log(minIndex);
                    console.log(peakArray[i].rpeakindex);
                    console.log(deviceFrequency);*/
          temRpeakindex =
            parseInt(peakArray[i].rpeakindex) -
            (minIndex + window.pageIndex) * deviceFrequency;
          presults[minIndex].push({
            x: temRpeakindex,
            y: parseFloat(0.8),
            color: peakColorArray[peakArray[i].arrhythmia],
            beat: parseInt(peakArray[i].indexcounter),
            hr: parseInt(peakArray[i].heartrate),
            rr: parseInt(peakArray[i].rr_interval),
            arrhythmia: peakArray[i].arrhythmia,
            peaktime: peakArray[i].peaktime,
          });
        }
      }
      return presults;
    }
    var sliceStart = 0;
    var sliceEnd = sliceStart + 15001;
    var sliceActStart = 0;
    var sliceActEnd = sliceActStart + 61;
    var currentEcgArray = this.ecgValues.slice(sliceStart, sliceEnd);
    var currentActArray = this.activityValues.slice(sliceActStart, sliceActEnd);

    //console.log(sliceStart+" "+sliceEnd);
    var tempChart;
    var colorArray = ["#98FB98", "#f1e4e4", "#f7e0a3", "#f09c67"];

    /* if (val - 500 <= 0) {
               this.state.chart.xAxis[0].setExtremes(val, val + 1000, true, false);
             } else {
               this.state.chart.xAxis[0].setExtremes(val - 500, val + 500);
             }*/

    var acti = 125;
    var actarr = [];
    var tttarr1 = [];

    for (var i = 0; i < currentActArray.length; i++) {
      actarr.push({
        x: acti,
        y: 0.5,
        color: colorArray[currentActArray[i]],
      });
      acti = acti + 150;
    }

    var prevChart = window.prevChart;
    if (window.beatArr.length > 0 && window.container != window.prevChart) {
      for (var i = 0; i < window.beatArr.length; i++) {
        var j = window.container * deviceFrequency;
        var xBeat = parseInt(window.beatArr[i]) - j;
        console.log("xBeat", xBeat);
        chart.xAxis[0].removePlotBand("plotband-" + xBeat);
      }
      //document.getElementById("beatSlected").value='[]';
      // beatArr=[];
    }

    var currentDataSet = [];
    var self = this;

    this.state.chart.series[0].setData(currentEcgArray, false);
    this.state.chart.series[1].setData(actarr, false);
    this.state.chart.series[2].setData(this.state.result3[0], true);
    //console.log(this.state.parr);
    // this.state.chart.series[3].update({
    //     data: this.state.parr[starts],
    // }, false);
    // this.state.chart.series[4].update({
    //     data: this.state.qarr[starts],
    // }, false);
    this.state.chart.series[5].update(
      {
        data: this.state.rarr[starts],
      },
      false
    );
    // this.state.chart.series[6].update({
    //     data: this.state.sarr[starts],
    // }, false);
    // this.state.chart.series[7].update({
    //     data: this.state.tarr[starts],
    // }, false);
    // this.state.chart.series[8].update({
    //     data: this.state.ponarr[starts],
    // }, false);
    // this.state.chart.series[9].update({
    //     data: this.state.poffarr[starts],
    // }, false);
    // this.state.chart.series[10].update({
    //     data: this.state.tonarr[starts],
    // }, false);
    // this.state.chart.series[11].update({
    //     data: this.state.toffarr[starts],
    // }, false);
    // this.state.chart.series[12].update({
    //     data: this.state.jarr[starts],
    // }, true);
    // this.state.chart.series[12].update({
    //     data: [],
    // }, true);
    var chart1 = this.state.charts[0];
    var tempChart = this.state.charts[window.prevChart];

    var lineWidth = 2;

    if (this.state.charts[window.prevChart] != undefined) {
      if (this.state.charts[window.prevChart].draggablePlotLine != undefined) {
        this.state.charts[window.prevChart].draggablePlotLine.destroy();
      }
    }
    if (this.props.match.params.start != undefined && window.diaryFlag == 1) {
      window.diaryFlag = 2;
      window.stripXValue = this.props.match.params.xValue;
    }
    this.state.charts[0].draggablePlotLine = this.state.charts[0].renderer
      .rect(
        this.state.charts[0].xAxis[0].toPixels(window.stripXValue) -
          this.state.charts[0].xAxis[0].toPixels(25),
        10,
        50,
        40,
        5
      )
      .attr({
        "stroke-width": 2,
        opacity: 0.5,
        fill: "grey",
        zIndex: 3,
      })
      .add();
    window.prevChart = 0;
    if (window.stripXValue + 500 > 15000) {
      this.state.chart.xAxis[0].setExtremes(
        window.stripXValue - 2250,
        window.stripXValue,
        true,
        false
      );
    } else {
      this.state.chart.xAxis[0].setExtremes(
        window.stripXValue - 1125,
        window.stripXValue + 1125,
        true,
        false
      );
    }
    console.log("A I umcoc");

    if (this.state.charts[0].container.onmousemove != undefined) {
      this.state.charts[0].container.onmousemove = function (e) {
        return false;
      };
    }
    var mainCChart = this.state.chart;
    this.state.charts[0].container.onmousemove = function (e) {
      if (chart1.draggablePlotLine.drag) {
        let normalizedEvent = chart1.pointer.normalize(e),
          extremes = {
            left: chart1.plotLeft,
            right: chart1.plotLeft + chart1.plotWidth,
          };
        if (e.chartX >= extremes.left && e.chartX <= extremes.right) {
          var pointValue = chart1.xAxis[0].toValue(e.chartX);
          if (pointValue - 500 <= 0) {
            mainCChart.xAxis[0].setExtremes(
              pointValue,
              pointValue + 2250,
              true,
              false
            );
          } else {
            mainCChart.xAxis[0].setExtremes(
              pointValue - 1125,
              pointValue + 1125,
              true,
              false
            );
          }
          chart1.draggablePlotLine.attr({
            x: e.chartX,
          });
        }
      }
    };

    this.state.charts[0].draggablePlotLine.element.onmousedown = function () {
      chart1.draggablePlotLine.drag = true;
    };

    this.state.charts[0].draggablePlotLine.element.onmouseup = function () {
      chart1.draggablePlotLine.drag = false;
    };
  }

  updateRpeaks(data) {
    this.setState({
      rpeaks: data,
    });
  }
  getInfo() {
    const userid = this.props.match.params.userid;

    axios
      .get(`${apiUrl}api/common/GetVerifierComments?userid=${userid}`)
      .then((res) => {
        console.log(res.data.comments)
        this.setState({comments:res.data.comments})
      })

      .catch((error) => {
        console.error("Error fetching ECG data:", error);
      });
  }

  generateReport(e) {
    const userid = this.props.match.params.userid;
    e.currentTarget.innerHTML = "PLease Wait...";

    this.setState({ isModalReportOpen: true });
    //
    /*axios
            .get('https://web.mybiocalculus.com/html/welcome/generateReportTest/' + userid)
            .then(res => {
                alert('Report Generated Successfully');
            })
            .catch(err => {

            });*/
  }

  fulldisclosure(e) {
    const userid = this.props.match.params.userid;
    e.currentTarget.innerHTML = "PLease Wait...";

    this.setState({ ismodaldisOpen: true });
  }
  mailReport(e) {
    // document.getElementById("chart_tab_email").innerHTML="<img id=\"emailImg\" src=\"../../dist/img/Email_white.png\"/>";
    const userid = this.props.match.params.userid;
    axios
      .get(apiUrl + "api/users/mailReport?userid=" + userid)
      .then((res) => {
        alert("Mail send successfully");
        //if(document.getElementById("chart_tab_email")!=null){document.getElementById("chart_tab_email").innerHTML="<img id=\"emailImg\" src=\"../../dist/img/Email.png\"/>";}
      })
      .catch((err) => {
        console.log(err);
        alert("Something went wrong");
        //if(document.getElementById("chart_tab_email")!=null){document.getElementById("chart_tab_email").innerHTML="<img id=\"emailImg\" src=\"../../dist/img/Email.png\"/>";}
      });
  }

  splitFn(value) {
    var res = value.split("_");
    var name =
      res[0].substring(0, 4) +
      "-" +
      res[0].substring(4, 6) +
      "-" +
      res[0].substring(6, 8) +
      " :: " +
      res[1].substring(0, 2) +
      ":" +
      res[1].substring(2, 4) +
      ":" +
      res[1].substring(4, 6);
    res[0] = name;
    return res;
  }

  resetGraph(e) {
    var it = 0;
    var currentChart = this.state.charts;
    var currentNsrarr = this.state.defarr;
    var myRGVar = setInterval(function () {
      currentChart[it].series[0].setData(currentNsrarr[it], true);
      it = it + 1;
      if (it > currentNsrarr.length - 1) {
        clearInterval(myRGVar);
      }
    }, 10);
  }
async  submitcommentinfo (e) {
     const userid = this.props.match.params.userid;
        const verifiername = localStorage.getItem("verifiername");
        const comments = `${this.state.comments} @${this.state.databaseid} -BY ${verifiername}`; // Replace with the actual comment input
        // console.log('comments',comments)
        try {
          const response = await axios.post(
            `${apiUrl}api/patients/addComment/${userid}`,
            { comments }
          );
        
          this.setState({ comments: "" });
          // this.fetchData() // Clear the input field
          // console.log(response.data); // Log the response from the server
        } catch (error) {
          console.error("Error submitting comment:", error);
        }
    
  }

  colorGraph(e) {
    var selector = e.currentTarget.value;
    window.sel = selector;
    if (selector == "Sinus Tachycardia") {
      var it = 0;
      var currentChart = this.state.charts;
      var currentStarr = this.state.starr;
      graphColor = "starr";
      var myCGVar = setInterval(function () {
        currentChart[it].series[0].setData(currentStarr[it], true);
        it = it + 1;
        if (it > currentStarr.length - 1) {
          clearInterval(myCGVar);
        }
      }, 1);
    }
  }

  addBeatToReport(e) {
    var beats = window.beatArr;
    var databaseid = this.state.databaseid;
    var userid = this.props.match.params.userid;
    if (beats.length > 0) {
      const data = {
        databaseid: databaseid,
        beats: beats,
        userid: userid,
      };
      axios
        .post(apiUrl + "api/common/addBeatToReport", data)
        .then((res) => {
          var currentChart = window.prevChart;
          var minus_val =
            (parseInt(window.pageIndex) + parseInt(window.prevChart)) *
            deviceFrequency;
          for (var i = 0; i < beats.length; i++) {
            this.state.chart.xAxis[0].removePlotBand(
              "plotband-" + (beats[i] - minus_val)
            );
          }
          window.beatArr = [];
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  generateEvents(e) {
    var databaseid = this.state.databaseid;
    var userid = this.props.match.params.userid;
    e.currentTarget.innerHTML = "PLease Wait...";
    var element = e.currentTarget;
    axios
      .get(
        "https://web.mybiocalculus.com/html/welcome/generateEventTest/?userid=" +
          userid +
          "&&databaseid=" +
          databaseid
      )
      .then((res) => {
        console.log("generated res", res);
        element.innerHTML = "Generate Events";
        var newCon = this;
        worker4.postMessage({
          name: "getfolderfiles",
          url:
            "https://web.mybiocalculus.com:5000/api/common/getfolderfiles?userid=" +
            userid +
            "&databaseid=" +
            databaseid,
        });
        worker4.onmessage = function (event) {};
        worker4.addEventListener("message", function (res) {
          newCon.setState({
            images: res.data[0],
          });
          newCon.setState({
            dblink: res.data[1],
          });
          newCon.filter1(window.filterId);
        });
        //    axios
        //     .get('https://web.mybiocalculus.com:5000/api/common/getfolderfiles?userid=' + userid + '&databaseid=' + databaseid)
        //     .then(res => {

        //         this.setState({
        //             images: res.data[0]
        //         });
        //         this.setState({
        //             dblink: res.data[1]
        //         });
        //         this.filter1(window.filterId);

        //     })
        //     .catch(err => {
        //         console.log(err);
        //     });
        //alert("events generated");
      })
      .catch((err) => {
        console.log(err);
      });
  }
  listenScrollEvent(e) {
    var databaseid = this.state.databaseid;
    var userid = this.props.match.params.userid;
    var dis = this;
    var ee = e.target;

    var element_height = 53.6833333;
    var positive = Math.abs(ee.scrollTop);
    var divided = positive / element_height;

    var round = divided != 0 ? Math.floor(divided) : 0;

    if (window.timer !== null) {
      clearTimeout(window.timer);
    }

    window.timer = setTimeout(function () {
      var height = document.getElementById("selWrap").scrollHeight;

      round = Math.floor(positive / (height / window.sc));
      var start = round;
      var end = round + 9;

      // Calculate available space
      var totalScrollableHeight = height;
      var currentScrollPosition = ee.scrollTop + ee.clientHeight;
      var availableSpace = totalScrollableHeight - currentScrollPosition;

      dis.setState({
        start: start,
        end: end,
        availableSpace: availableSpace, // Update state with available space
      });
      //console.log("start!!!!!!!!!!!!",start)

      //console.log("end!!!!!!!!!!", end);
      // Log available space
      dis.renderNewSmallCharts();
    }, 500);
  }

  recategorize(e) {
    var beats = window.beatArr;
    var databaseid = this.state.databaseid;
    var userid = this.props.match.params.userid;
    var arrhythmia = e.currentTarget.value;
    if (beats.length > 0) {
      const data = {
        databaseid: databaseid,
        beats: beats,
        arrhythmia: arrhythmia,
        userid: userid,
      };
      console.log("beats", beats);
      axios
        .post(apiUrl + "api/common/updateVerifierArrhythmia", data)
        .then((res) => {
          console.log("beats", beats);
          for (var i = 0; i < window.beatArr.length; i++) {
            /* var j  = (parseInt(window.pageIndex)+parseInt(window.prevChart))*deviceFrequency; */
            /*if (window.realcId != 0) {
                            var j = window.realcId * deviceFrequency;
                        } else {*/
            var j = parseInt(window.container) * deviceFrequency;
            //}
            var xBeat = parseInt(window.beatArr[i]) - j;

            //console.log('window.realcId', window.realcId);
            this.state.chart.xAxis[0].removePlotBand("plotband-" + xBeat);
          }
          var stripvalue_resume = parseInt(window.container);
          var xvalue_resume = beats[beats.length - 1];
          axios
            .get(
              apiUrl +
                "api/common/updateResumeInfo?databaseid=" +
                databaseid +
                "&userid=" +
                userid +
                "&stripvalue_resume=" +
                stripvalue_resume +
                "&xvalue_resume=" +
                xvalue_resume
            )
            .then((res) => {})
            .catch((err) => {});
          window.beatArr = [];
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  recategorizeAfterImage(arr) {
    var beats = window.beatArr;
    var databaseid = this.state.databaseid;
    var userid = this.props.match.params.userid;

    if (beats.length > 0) {
      const data = {
        databaseid: databaseid,
        beats: beats,
        arrhythmia: arr,
        userid: userid,
      };
      console.log("beats data", data);
      axios
        .post(apiUrl + "api/common/updateVerifierArrhythmia", data)
        .then((res) => {
          console.log("beats res", res.data);
          window.beatArr = [];
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  verify(e) {
    var databaseid = this.state.databaseid;
    var userid = this.props.match.params.userid;
    const verifiedtime = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Kolkata",
      hour12: false,
    });
    const verifytime = verifiedtime.replace("PM", "").replace(",", "").trim();
    const verifierid = localStorage.getItem("verifierid");
    const verifierName = localStorage.getItem("verifiername");
    console.log("verifierid !!!!!!!!!!!!!!!!!!!!!!!!!!!!!:", verifierName);
    const data = {
      databaseid: databaseid,
      userid: userid,
      verifyTime: verifytime,
      verifierId: verifierid,
      verifierName: localStorage.getItem("verifiername"),
    };
    axios
      .post(apiUrl + "api/common/verifyArrhythmia_nameNew", data)
      .then((res) => {
        $("#verfiyBtn").css("display", "none");
        $("#verifyBtn").css("display", "none");

        this.checkFullVerify();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  gainBtn(e) {
    var buttonId = e.currentTarget.id;
    var gainValue = document.getElementById("gainValue").value;
    var it = 0;
    var charts = this.state.charts;
    var arCount = this.state.datasetMnt - 1;
    if (gainValue == 1) {
      gainMin = -0.0005;
      gainMax = 0.0015;
    } else if (gainValue == 2) {
      gainMin = -0.0005;
      gainMax = 0.0008;
    } else if (gainValue == 3) {
      gainMin = -0.0005;
      gainMax = 0.0005;
    }

    var scaleUp = setInterval(function () {
      charts[it].update({
        yAxis: [
          {
            min: gainMin,
            max: gainMax,
          },
        ],
      });
      it = it + 1;
      if (it > arCount) {
        clearInterval(scaleUp);
      }
    }, 1);
  }

  checkFullVerify() {
    var fullVerify = this;
    worker15.postMessage({
      name: "checkFullVerify",
      url:
        apiUrl +
        "api/common/checkFullVerify?userid=" +
        this.props.match.params.userid,
    });
    worker15.onmessage = function (event) {};
    worker15.addEventListener("message", function (res) {
      fullVerify.setState({
        fullVerifyStatus: res.data,
      });
    });
    // axios
    //     .get(apiUrl + 'api/common/checkFullVerify?userid=' + this.props.match.params.userid)
    //     .then(res => {
    //         this.setState({
    //             fullVerifyStatus: res.data
    //         })
    //     })
    //     .catch(err => {
    //         console.log(err)
    //     });
  }
  renderResumeChart(userid, dbId, cont, strip_val) {
    var newThis = this,
      val,
      colorArray = [];
    window.chartid = 0;
    window.container = cont; //container id stored in global variable
    //    console.log('window.container', window.container);
    window.stripXValue = strip_val - cont * 15000; //got the location in the xaxis
    console.log("images:", this.state.images);
    /*************Remove Plotband***********************/

    if (window.beatArr.length > 0 && window.chartid != window.prevChart) {
      for (var i = 0; i < window.beatArr.length; i++) {
        var j = window.container * deviceFrequency;
        var xBeat = parseInt(window.beatArr[i]) - j;

        console.log("xBeat", xBeat);
        window.chart.xAxis[0].removePlotBand("plotband-" + xBeat);
      }
      //document.getElementById("beatSlected").value='[]';
      // beatArr=[];
    }

    /*************Remove Plotband***********************/
    if (newThis.state.charts[window.container]) {
      val = newThis.state.charts[window.container].xAxis[0].toValue(
        window.stripXValue
      );
    }

    //console.log(selfNew.state.mainEcgArray);
    var sliceStart = cont * deviceFrequency;
    var sliceEnd = sliceStart + deviceFrequency + 1;
    var sliceActStart = window.container * 60;
    var sliceActEnd = sliceActStart + 61;
    //console.log('window.stripXValue', strip_val);
    /*console.log('sliceStart',sliceEnd);
        console.log('mainEcgArray Two',newThis.state.mainEcgArray);*/
    var ecgArray = newThis.state.mainEcgArray.slice(0, 15001);
    var curActArray = newThis.state.mainActivityArray.slice(0, 61);

    if (val > ecgArray.length) {
      val = ecgArray.length - 500;
    }

    var acti = 125;
    var actarr = [];
    var tttarr1 = [];
    /*console.log('container',window.container);
        console.log(newThis.state.rarr[window.dataArrInd]);*/

    for (var i = 0; i < curActArray.length; i++) {
      actarr.push({
        x: acti,
        y: 0.5,
        color: colorArray[curActArray[i]],
      });
      acti = acti + 250;
    }

    console.log("testResult3", newThis.state.result3);
    //console.log(result3[window.container]);
    newThis.state.chart.series[0].setData(ecgArray, false);
    newThis.state.chart.series[1].setData(actarr, false);
    newThis.state.chart.series[2].setData(newThis.state.result3[0], false);
    // newThis.state.chart.series[3].update({
    //     data: newThis.state.parr[window.container],
    // }, false);
    // newThis.state.chart.series[4].update({
    //     data: newThis.state.qarr[window.container],
    // }, false);
    newThis.state.chart.series[5].update(
      {
        data: newThis.state.rarr[window.container],
      },
      false
    );
    // newThis.state.chart.series[6].update({
    //     data: newThis.state.sarr[window.container],
    // }, false);
    // newThis.state.chart.series[7].update({
    //     data: newThis.state.tarr[window.container],
    // }, false);
    // newThis.state.chart.series[8].update({
    //     data: newThis.state.ponarr[window.container],
    // }, false);
    // newThis.state.chart.series[9].update({
    //     data: newThis.state.poffarr[window.container],
    // }, false);
    // newThis.state.chart.series[10].update({
    //     data: newThis.state.tonarr[window.container],
    // }, false);
    // newThis.state.chart.series[11].update({
    //     data: newThis.state.toffarr[window.container],
    // }, false);
    // newThis.state.chart.series[12].update({
    //     data: newThis.state.jarr[window.container],
    // }, true);

    if (newThis.state.charts[window.prevChart] != undefined) {
      if (
        newThis.state.charts[window.prevChart].draggablePlotLine != undefined
      ) {
        newThis.state.charts[window.prevChart].draggablePlotLine.destroy();
      }
    }
    var chart1 = newThis.state.charts[0];

    window.prevChart = 0;

    var lineWidth = 2;

    chart1.draggablePlotLine = chart1.renderer
      .rect(
        newThis.state.charts[0].xAxis[0].toPixels(strip_val) -
          newThis.state.charts[0].xAxis[0].toPixels(25),
        10,
        50,
        40,
        5
      )
      .attr({
        "stroke-width": 2,
        opacity: 0.5,
        fill: "grey",
        zIndex: 3,
      })
      .add();
    newThis.state.chart.xAxis[0].setExtremes(
      strip_val - 500,
      strip_val + 500,
      true,
      false
    );

    if (newThis.state.charts[0].container.onmousemove != undefined) {
      newThis.state.charts[0].container.onmousemove = function (e) {
        return false;
      };
    }

    newThis.state.charts[0].container.onmousemove = function (e) {
      if (chart1.draggablePlotLine != undefined) {
        if (chart1.draggablePlotLine.drag) {
          let normalizedEvent = chart1.pointer.normalize(e),
            extremes = {
              left: chart1.plotLeft,
              right: chart1.plotLeft + chart1.plotWidth,
            };

          if (e.chartX >= extremes.left && e.chartX <= extremes.right) {
            var pointValue = chart1.xAxis[0].toValue(e.chartX);
            if (pointValue - 500 <= 0) {
              newThis.state.chart.xAxis[0].setExtremes(
                0,
                pointValue + 2250,
                true,
                false
              );
            } else {
              newThis.state.chart.xAxis[0].setExtremes(
                pointValue - 1125,
                pointValue + 1125,
                true,
                false
              );
            }
            chart1.draggablePlotLine.attr({
              x: e.chartX,
            });
          }
        }
      }
    };

    newThis.state.charts[0].draggablePlotLine.element.onmousedown =
      function () {
        chart1.draggablePlotLine.drag = true;
      };
    newThis.state.charts[0].draggablePlotLine.element.onmouseup = function () {
      chart1.draggablePlotLine.drag = false;
    };
  }

  renderNewChart(userid, dbId, step, start, end) {
    var arr = [],
      charts = [],
      arr2 = [],
      peakarr = [],
      barClick = 0,
      pointValue,
      tempChart,
      tempCont = 0;
    var starr = [],
      beatArr = [],
      defarr = [],
      plotband = [],
      piec;
    var maximumval;
    var b;
    var js_array = [];
    var chart;
    var minVal, maxVal, val, val2;
    var rarr = [];
    // var parr = [],
    //     tarr = [],
    //     qarr = [],
    //     rarr = [],
    //     sarr = [],
    //     ponarr = [],
    //     poffarr = [],
    //     tonarr = [],
    //     toffarr = [],
    //     jarr = [];
    // console.log("chart:", rarr);
    var result3,
      colorArray = [];
    var rpeakArray = [];
    /*  var markerAarray = [],*/
    var result4 = [];
    var peakColorArray = [];

    peakColorArray["Normal Sinus Rhythm"] = "#3D761D";
    peakColorArray["Sinus Tachycardia"] = "#00FFFF";
    peakColorArray["Sinus Bradycardia"] = "#FF9900";
    peakColorArray["PAC"] = "#0000FF";
    peakColorArray["PVC"] = "#FF00FF";
    peakColorArray["Ventricular Tachycardia"] = "#A64D79";
    peakColorArray["AFib"] = "#9900FF";
    peakColorArray["Sinus Pause"] = "#FF0000";
    peakColorArray["P Absent"] = "#bfbebb";
    peakColorArray["Irregular RR"] = "#7F6000";
    peakColorArray["Irregular PR"] = "#351C75";
    peakColorArray["Short QT"] = "#980000";
    peakColorArray["Prolonged QT"] = "#46BDC6";
    peakColorArray["Undiagnosed"] = "#FFD966";
    var userid = userid;
    var databaseid = dbId;
    var selfNew = this;
    var mainEcgArray,
      mainActivityArray = [];
    var datasetMnt = 0;

    //Split the array in chunks with given size
    function chunkArray(myArray, chunk_size) {
      let results = [];

      while (myArray.length) {
        results.push(myArray.splice(0, chunk_size));
      }
      return results;
    }

    function chunkPeakArray(peakArray, start = 0) {
      let presults = [];
      var minIndex = 0;
      var breakVal = (start + 1) * deviceFrequency;
      var z;
      var temRpeakindex;
      presults.push([]);
      for (var i = 0; i < peakArray.length; i++) {
        if (start * deviceFrequency < peakArray[i].rpeakindex) {
          if (peakArray[i].rpeakindex > breakVal) {
            presults.push([]);
            minIndex = minIndex + 1;
            breakVal = breakVal + deviceFrequency;
          }
          temRpeakindex =
            parseInt(peakArray[i].rpeakindex) -
            (minIndex + start) * deviceFrequency;
          presults[minIndex].push({
            x: temRpeakindex,
            y: parseFloat(0.8),
            color: peakColorArray[peakArray[i].arrhythmia],
            beat: parseInt(peakArray[i].indexcounter),
            hr: parseInt(peakArray[i].heartrate),
            rr: parseInt(peakArray[i].rr_interval),
            arrhythmia: peakArray[i].arrhythmia,
            peaktime: peakArray[i].peaktime,
          });
        }
      }
      return presults;
    }

    function chunkMarkerArray(markArrayX, markArrayY, start = 0) {
      var ppeakIndex = markArrayX.split(",");
      var ppeakAmp = markArrayY.split(",");
      let mresults = [];
      var minIndex = 0;
      var breakVal = (start + 1) * deviceFrequency;
      var z;
      var temindex;
      mresults.push([]);

      for (var i = 0; i < ppeakIndex.length; i++) {
        if (ppeakIndex[i] > start * deviceFrequency) {
          if (ppeakIndex[i] > breakVal) {
            mresults.push([]);
            minIndex = minIndex + 1;
            breakVal = breakVal + deviceFrequency;
          }
          temindex =
            parseInt(ppeakIndex[i]) - (minIndex + start) * deviceFrequency;
          mresults[minIndex].push([temindex, parseFloat(ppeakAmp[i])]);
        }
      }
      return mresults;
    }

    function clickFunction(event, that) {
      var containerId = that.renderTo.id; // Get the Container ID
      window.container = parseInt(containerId.replace("container", ""));
      window.chartid = window.container - selfNew.state.start;
      window.stripXValue = event.chartX; // Get the location on the x-axis

      /*************Remove Plotband***********************/
      if (window.beatArr.length > 0 && window.chartid != window.prevChart) {
        for (var i = 0; i < window.beatArr.length; i++) {
          var j = parseInt(window.container) * deviceFrequency;
          var xBeat = parseInt(window.beatArr[i]) - j;
          chart.xAxis[0].removePlotBand("plotband-" + xBeat);
        }
      }
      /*************Remove Plotband***********************/

      // Convert click position to x-axis value
      var val = charts[window.container].xAxis[0].toValue(event.chartX);

      // Get the start and end indices for slicing the ECG array
      var sliceStart = window.container * deviceFrequency;
      var sliceEnd = sliceStart + deviceFrequency;
      var ecgArray = selfNew.state.mainEcgArray.slice(sliceStart, sliceEnd);

      // Get the activity array
      var sliceActStart = window.container * 60;
      var sliceActEnd = sliceActStart + 60;
      var curActArray = selfNew.state.mainActivityArray.slice(
        sliceActStart,
        sliceActEnd
      );

      // Prepare activity data for plotting
      var actarr = [];
      var acti = 125;
      for (var i = 0; i < curActArray.length; i++) {
        actarr.push({
          x: acti,
          y: 0.5,
          color: colorArray[curActArray[i]],
        });
        acti += 250;
      }

      // Update chart series with new data
      chart.series[0].setData(ecgArray, false);
      chart.series[1].setData(actarr, false);
      chart.series[2].setData(selfNew.state.result3[window.chartid], false);
      chart.series[5].update(
        {
          data: selfNew.state.rarr[window.container],
        },
        false
      );

      // Handle draggable plot line
      if (
        charts[window.prevChart] != undefined &&
        charts[window.prevChart].draggablePlotLine != undefined
      ) {
        charts[window.prevChart].draggablePlotLine.destroy();
      }
      window.prevChart = window.chartid;

      // Add draggable plot line at the clicked position
      var chart1 = that;
      chart1.draggablePlotLine = chart1.renderer
        .rect(
          event.chartX - charts[window.chartid].xAxis[0].toPixels(25),
          10,
          50,
          40,
          5
        )
        .attr({
          "stroke-width": 2,
          opacity: 0.5,
          fill: "grey",
          zIndex: 3,
        })
        .add();

      charts[window.chartid].container.onmousemove = function (e) {
        if (chart1.draggablePlotLine && chart1.draggablePlotLine.drag) {
          let normalizedEvent = chart1.pointer.normalize(e);
          let extremes = {
            left: chart1.plotLeft,
            right: chart1.plotLeft + chart1.plotWidth,
          };
          if (e.chartX >= extremes.left && e.chartX <= extremes.right) {
            pointValue = chart1.xAxis[0].toValue(e.chartX);
            chart1.draggablePlotLine.attr({ x: e.chartX });
            chart.xAxis[0].setExtremes(
              Math.max(0, pointValue - 1125),
              Math.min(ecgArray.length, pointValue + 1125),
              true,
              false
            );
          }
        }
      };

      charts[window.chartid].draggablePlotLine.element.onmousedown =
        function () {
          chart1.draggablePlotLine.drag = true;
        };

      charts[window.chartid].draggablePlotLine.element.onmouseup = function () {
        chart1.draggablePlotLine.drag = false;
      };

      chart.xAxis[0].setExtremes(
        Math.max(0, val - 1125),
        Math.min(ecgArray.length, val + 1125),
        true,
        false
      );

      /************* Accurate Beat Selection Code ******************/
      const closestArray = window.result3[window.chartid].map(
        (point) => point.x
      );
      const jBeat = parseInt(window.container) * deviceFrequency;

      // Find the closest beat
      const closest = closestArray.reduce((prev, curr) =>
        Math.abs(curr - val) < Math.abs(prev - val) ? curr : prev
      );

      const plotbandExists = window.beatArr.includes(closest + jBeat);

      if (!event.shiftKey) {
        if (!plotbandExists) {
          plotband.push({
            color: "#ff9999",
            from: closest - 100,
            to: closest + 100,
            id: "plotband-" + closest,
          });
          chart.update({ xAxis: { plotBands: plotband } });
          window.beatArr.push(closest + jBeat);
        } else {
          const index = window.beatArr.indexOf(closest + jBeat);
          if (index > -1) {
            window.beatArr.splice(index, 1);
          }
          chart.xAxis[0].removePlotBand("plotband-" + closest);
        }
      } else {
        handleShiftKeyPlotbands(closest, jBeat, closestArray, chart);
      }

      /************* Accurate Beat Selection Code ******************/
    }

    function handleShiftKeyPlotbands(closest, jBeat, closestArray, chart) {
      let plotband = [];

      if (window.beatArr.length === 1) {
        let comStart, comEnd;
        if (closest > window.beatArr[0] - jBeat) {
          comEnd = closest;
          comStart = window.beatArr[0] - jBeat;
        } else {
          comStart = closest;
          comEnd = window.beatArr[0] - jBeat;
        }
        window.beatArr = [];
        for (let i = 0; i < closestArray.length; i++) {
          if (closestArray[i] >= comStart && closestArray[i] <= comEnd) {
            plotband.push({
              color: "#ff9999",
              from: closestArray[i] - 100,
              to: closestArray[i] + 100,
              id: "plotband-" + closestArray[i],
            });
            window.beatArr.push(closestArray[i] + jBeat);
          }
        }
        chart.update({ xAxis: { plotBands: plotband } });
      } else {
        resetPlotbands(closest, jBeat, chart);
      }

      document.addEventListener("keydown", function (event) {
        if (event.key === "z" || event.key === "Z") {
          const index = window.beatArr.indexOf(closest + jBeat);
          if (index > -1) {
            window.beatArr.splice(index, 1);
          }
          chart.xAxis[0].removePlotBand("plotband-" + closest);
        }
      });
    }

    function resetPlotbands(closest, jBeat, chart) {
      chart.update({ xAxis: { plotBands: [] } });
      window.beatArr = [];
      let plotband = [
        {
          color: "#ff9999",
          from: closest - 100,
          to: closest + 100,
          id: "plotband-" + closest,
        },
      ];
      chart.update({ xAxis: { plotBands: plotband } });
      window.beatArr.push(closest + jBeat);
    }

    function renderSmallCharts(clickPointIndex = 0) {
      var i = 0;
      var t = 0;
      var limit = 9;
      var stcolor = "#09ff08",
        defcolour = "#09ff08";
      //console.log("small chart");
      if (window.sc < 9) {
        limit = window.sc - 1;
      }

      var currentDataSet = [];

      for (var j = 0; j < window.sc; j++) {
        var newDiv = document.createElement("div");
        newDiv.id = "container" + j;
        newDiv.style = "min-width: 200px; height: 60px; margin: 0 auto";
        var newSpan = document.createElement("div");
        newSpan.id = "span" + j;
        newSpan.style = "color:#000; height: 21px;";
        document.getElementById("selWrap").appendChild(newSpan);
        document.getElementById("selWrap").appendChild(newDiv);
      }

      var myVar = setInterval(function () {
        var sliceStartI = i * deviceFrequency;
        var sliceEndI = sliceStartI + deviceFrequency + 1;
        // console.log("devicefrequency!!!!!!!", deviceFrequency);
        currentDataSet = selfNew.state.mainEcgArray.slice(
          sliceStartI,
          sliceEndI
        );

        var arrayIndex = 0;
        var k = 0;

        starr.push([]);

        defarr.push([]);

        if (selfNew.state.result3[i] != undefined) {
          if (selfNew.state.result3[i][0] != undefined) {
            var time1 = document.createTextNode(
              selfNew.state.result3[i][0].peaktime
            );
            document.getElementById("span" + i).appendChild(time1);

            for (var j = 0; j < currentDataSet.length; j++) {
              if (
                selfNew.state.result3[i][k] != null &&
                (j + 70 == selfNew.state.result3[i][k].x ||
                  selfNew.state.result3[i][k].x < 70)
              ) {
                stcolor = "09ff08";
                defcolour = "09ff08";

                if (
                  selfNew.state.result3[i][k].arrhythmia ==
                  "Normal Sinus Rhythm"
                ) {
                  stcolor = selfNew.state.result3[i][k].color;
                } else if (
                  selfNew.state.result3[i][k].arrhythmia == "Sinus Tachycardia"
                ) {
                  stcolor = selfNew.state.result3[i][k].color;
                } else if (
                  selfNew.state.result3[i][k].arrhythmia == "Sinus Bradycardia"
                ) {
                  stcolor = selfNew.state.result3[i][k].color;
                } else if (
                  selfNew.state.result3[i][k].arrhythmia == "Sinus Pause"
                ) {
                  stcolor = selfNew.state.result3[i][k].color;
                } else if (selfNew.state.result3[i][k].arrhythmia == "PAC") {
                  stcolor = selfNew.state.result3[i][k].color;
                } else if (selfNew.state.result3[i][k].arrhythmia == "PVC") {
                  stcolor = selfNew.state.result3[i][k].color;
                } else if (
                  selfNew.state.result3[i][k].arrhythmia ==
                  "Ventricular Tachycardia"
                ) {
                  stcolor = selfNew.state.result3[i][k].color;
                } else if (selfNew.state.result3[i][k].arrhythmia == "AFib") {
                  stcolor = selfNew.state.result3[i][k].color;
                } else if (
                  selfNew.state.result3[i][k].arrhythmia == "P Absent"
                ) {
                  stcolor = selfNew.state.result3[i][k].color;
                } else if (
                  selfNew.state.result3[i][k].arrhythmia == "Irregular RR"
                ) {
                  stcolor = selfNew.state.result3[i][k].color;
                } else if (
                  selfNew.state.result3[i][k].arrhythmia == "Irregular PR"
                ) {
                  stcolor = selfNew.state.result3[i][k].color;
                } else if (
                  selfNew.state.result3[i][k].arrhythmia == "Short QT"
                ) {
                  stcolor = selfNew.state.result3[i][k].color;
                } else if (
                  selfNew.state.result3[i][k].arrhythmia == "Prolonged QT"
                ) {
                  stcolor = selfNew.state.result3[i][k].color;
                } else if (
                  selfNew.state.result3[i][k].arrhythmia == "Undiagnosed"
                ) {
                  stcolor = selfNew.state.result3[i][k].color;
                }

                if (k < selfNew.state.result3[i].length - 1) {
                  k++;
                }
              }

              defarr[i].push({
                x: j,
                y: parseFloat(currentDataSet[j]),
                segmentColor: stcolor,
                //segmentColor: 'black'
              });

              starr[i].push({
                x: j,
                y: parseFloat(currentDataSet[j]),
                segmentColor: stcolor,
              });
            }
          }
        }

        charts[i] = new Highcharts.Chart("container" + i, {
          credits: {
            enabled: false,
          },
          chart: {
            height: 4 + "%",
            resetZoomButton: {
              theme: {
                display: "none",
              },
            },
            events: {
              click: function (e) {
                /* Do something with the event */

                clickFunction(e, this); // ...which means you can access it from inside the click handler function
              },
            },
            zoomType: "",
          },

          navigator: {
            enabled: false,
          },

          xAxis: [
            {
              min: 0,
              max: deviceFrequency,
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              minorTicks: false,
              labels: {
                enabled: false,
              },
              title: {
                enabled: false,
              },
              visible: false,
            },
          ],
          yAxis: [
            {
              min: gainMin,
              max: gainMax,
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              minorTicks: false,
              labels: {
                enabled: false,
              },
              title: {
                enabled: false,
              },
            },
          ],
          rangeSelector: {
            enabled: false,
          },
          plotOptions: {
            series: {
              turboThreshold: 15500,
            },
          },
          title: {
            text: "",
          },

          series: [
            {
              type: "coloredline",
              data: [],
              color: "#09ff08",
              allowPointSelect: false,
              animation: false,
              enableMouseTracking: false,
              lineWidth: 2,
              marker: {
                enabled: false,
              },
              shadow: false,
              stickyTracking: false,
              showInLegend: false,
            },
          ],
        });
        charts[i].series[0].setData(starr[i], true);

        if (i == 0) {
          clickContainerIndex = clickPointIndex;
          window.stripXValue = 100;
          window.container = 0;
          window.chartid = 0;
          window.pageIndex = 0;
        }

        window.prevChart = clickContainerIndex;
        if (i == clickContainerIndex) {
          charts[i].draggablePlotLine = charts[i].renderer
            .rect(
              charts[i].xAxis[0].toPixels(50) - charts[i].xAxis[0].toPixels(25),
              10,
              50,
              40,
              5
            )
            .attr({
              "stroke-width": 2,
              opacity: 0.5,
              fill: "grey",
              zIndex: 3,
            })
            .add();
          tempChart = charts[i];

          if (charts[i].container.onmousemove != undefined) {
            charts[i].container.onmousemove = function (e) {
              return false;
            };
          }
          var stripIndex = i;
          charts[stripIndex].container.onmousemove = function (e) {
            if (charts[stripIndex].draggablePlotLine.drag) {
              let normalizedEvent = charts[stripIndex].pointer.normalize(e),
                extremes = {
                  left: charts[stripIndex].plotLeft,
                  right:
                    charts[stripIndex].plotLeft + charts[stripIndex].plotWidth,
                };

              if (e.chartX >= extremes.left && e.chartX <= extremes.right) {
                var pointValue = charts[stripIndex].xAxis[0].toValue(e.chartX);
                if (pointValue - 500 <= 0) {
                  chart.xAxis[0].setExtremes(0, pointValue + 2250, true, false);
                } else {
                  chart.xAxis[0].setExtremes(
                    pointValue - 1125,
                    pointValue + 1125,
                    true,
                    false
                  );
                }
                charts[stripIndex].draggablePlotLine.attr({
                  x: e.chartX,
                });
              }
            }
          };
          charts[stripIndex].draggablePlotLine.element.onmousedown =
            function () {
              charts[stripIndex].draggablePlotLine.drag = true;
            };

          charts[stripIndex].draggablePlotLine.element.onmouseup = function () {
            charts[stripIndex].draggablePlotLine.drag = false;
          };
        }
        i = i + 1;

        if (i > limit) {
          clearInterval(myVar);
          if (selfNew.props.match.params.start != undefined) {
            setTimeout(function () {
              console.log($("#container5").position());
              $("#selWrap").scrollTop(
                $("#container" + selfNew.props.match.params.start).position()
                  .top - $("#selWrap").position().top
              );
              console.log($("#container5").position());

              /*var chart1=selfNew.state.charts[0];
                            selfNew.state.charts[0].draggablePlotLine = selfNew.state.charts[0].renderer.rect(selfNew.state.charts[0].xAxis[0].toPixels(selfNew.props.match.params.xValue) - selfNew.state.charts[0].xAxis[0].toPixels(25), 10, 50, 40, 5)
                                .attr({
                                    'stroke-width': 2,
                                    opacity: 0.5,
                                    fill: 'grey',
                                    zIndex: 3
                                })
                                .add();
                                window.prevChart=0;
                            selfNew.state.chart.xAxis[0].setExtremes(selfNew.props.match.params.xValue - 500, selfNew.props.match.params.xValue + 500, true, false);
                            console.log("A I umcoc");

                            if (selfNew.state.charts[0].container.onmousemove != undefined) {
                                selfNew.state.charts[0].container.onmousemove = function(e) {
                                    return false;
                                }
                            }
                            var mainCChart = selfNew.state.chart;
                            selfNew.state.charts[0].container.onmousemove = function(e) {
                                if (chart1.draggablePlotLine.drag) {

                                    let normalizedEvent = chart1.pointer.normalize(e),
                                        extremes = {
                                            left: chart1.plotLeft,
                                            right: chart1.plotLeft + chart1.plotWidth
                                        };

                                    if (e.chartX >= extremes.left && e.chartX <= extremes.right) {
                                        var pointValue = chart1.xAxis[0].toValue(e.chartX);
                                        if (pointValue - 500 <= 0) {
                                            mainCChart.xAxis[0].setExtremes(pointValue, pointValue + 1000, true, false);
                                        } else {
                                            mainCChart.xAxis[0].setExtremes(pointValue - 500, pointValue + 500, true, false);
                                        }
                                        chart1.draggablePlotLine.attr({
                                            x: e.chartX
                                        })
                                    }
                                }
                            }

                            selfNew.state.charts[0].draggablePlotLine.element.onmousedown = function() {
                                chart1.draggablePlotLine.drag = true;
                            }

                            selfNew.state.charts[0].draggablePlotLine.element.onmouseup = function() {
                                chart1.draggablePlotLine.drag = false;
                            }
*/
            }, 1000);
          }
        }
      }, 5);
    }

    // Function to plot the background graph
    function renderMainChart(self) {
      maximumval = 1000;
      b = 0;
      // var self = this;
      // Ensure correct ID for the popup
      const popup = document.getElementById("popup");
      const ttContainer = document.getElementById("tt_container");

      if (popup && ttContainer) {
        // Update tt_container content
        ttContainer.style.width = "70vw";

        ttContainer.innerHTML = ""; // Clear previous content

        // Create a new Highcharts chart inside tt_container
        chart = new Highcharts.StockChart("tt_container", {
          exporting: {
            enabled: false,
          },
          scrollbar: {
            enabled: false,
          },
          credits: {
            enabled: false,
          },
          chart: {
            type: "coloredline",
            height: 20 + "%",

            resetZoomButton: {
              theme: {
                display: "none",
              },
            },
            events: {
              click: function (event) {
                if (event.shiftKey) {
                  var k = 0,
                    plotbi = 0;
                  var dis = this;
                  var t = Math.floor(event.xAxis[0]["value"]);
                  var closestArray = [];
                  var multiArray = [];
                  var comStart, comEnd;

                  var j = parseInt(window.container) * deviceFrequency;

                  for (
                    var i = 0;
                    i < window.result3[parseInt(window.chartid)].length;
                    i++
                  ) {
                    closestArray[i] =
                      window.result3[parseInt(window.chartid)][i].x;
                  }
                  var closest = closestArray.reduce(function (prev, curr) {
                    return Math.abs(curr - t) < Math.abs(prev - t)
                      ? curr
                      : prev;
                  });
                  if (window.beatArr.length == 1) {
                    if (closest > window.beatArr[0] - j) {
                      comEnd = closest;
                      comStart = window.beatArr[0] - j;
                    } else {
                      comStart = closest;
                      comEnd = window.beatArr[0] - j;
                    }
                    window.beatArr = [];
                    for (var i = 1; i < closestArray.length; i++) {
                      if (
                        closestArray[i] >= comStart &&
                        closestArray[i] <= comEnd
                      ) {
                        plotband.push({
                          color: "#ff9999",
                          from: closestArray[i] - 100,
                          to: closestArray[i] + 100,
                          id: "plotband-" + closestArray[i],
                        });
                        window.beatArr.push(closestArray[i] + j);
                        /* window.captureArr=window.result3*/
                      }
                    }
                    chart.update({
                      xAxis: {
                        plotBands: plotband,
                      },
                    });

                    //console.log('beatArr',window.beatArr);
                  } else {
                    chart.update({
                      xAxis: {
                        plotBands: [],
                      },
                    });

                    window.beatArr = [];
                    plotband = [];
                    var check = $.inArray(closest + j, window.beatArr);

                    if (check == -1) {
                      plotband.push({
                        color: "#ff9999",
                        from: closest - 100,
                        to: closest + 100,
                        id: "plotband-" + closest,
                      });
                      chart.update({
                        xAxis: {
                          plotBands: plotband,
                        },
                      });
                      window.beatArr.push(closest + j);
                    } else {
                      var index = window.beatArr.indexOf(closest + j);
                      if (index > -1) {
                        window.beatArr.splice(index, 1);
                      }
                      chart.xAxis[0].removePlotBand("plotband-" + closest);
                    }
                  }
                } else {
                  var k = 0;
                  var closestArray = [];
                  var t = Math.floor(event.xAxis[0]["value"]);

                  var j = parseInt(window.container) * deviceFrequency;

                  //beatArr = JSON.parse($('#beatSlected').val());

                  //console.log('index',parseInt(window.pageIndex)+parseInt(window.container));

                  for (
                    var i = 0;
                    i < window.result3[parseInt(window.chartid)].length;
                    i++
                  ) {
                    closestArray[i] =
                      window.result3[parseInt(window.chartid)][i].x;
                  }
                  var closest = closestArray.reduce(function (prev, curr) {
                    return Math.abs(curr - t) < Math.abs(prev - t)
                      ? curr
                      : prev;
                  });
                  var captureIndex = closestArray.indexOf(closest);
                  var beatVal = JSON.parse($("#beatSlected").val());
                  var check = $.inArray(closest + j, window.beatArr);
                  if (check == -1) {
                    plotband.push({
                      color: "#ff9999",
                      from: closest - 100,
                      to: closest + 100,
                      id: "plotband-" + closest,
                    });
                    chart.update({
                      xAxis: {
                        plotBands: plotband,
                      },
                    });
                    console.log("closest", "plotband-" + closest);

                    window.beatArr.push(closest + j);
                    //       console.log(self);
                    /*self.setState({
                                       'sel_arr':window.result3[parseInt(window.chartid)][captureIndex].arrhythmia
                                   });*/
                  } else {
                    var index = window.beatArr.indexOf(closest + j);
                    if (index > -1) {
                      window.beatArr.splice(index, 1);
                    }
                    chart.xAxis[0].removePlotBand("plotband-" + closest);
                  }
                }
                // Add a keydown event listener to the document
                document.addEventListener("keydown", function (event) {
                  if (event.key === "z" || event.key === "Z") {
                    if (window.beatArr.length > 0) {
                      const lastBeat = window.beatArr.pop(); // Remove the last selected beat
                      const closest =
                        lastBeat - parseInt(window.container) * deviceFrequency;

                      // Remove the specific plot band
                      chart.xAxis[0].removePlotBand("plotband-" + closest);
                      console.log("plotband removed");
                    }
                  }
                });
              },
            },
            zoomType: "",
          },
          navigator: {
            enabled: false,
          },
          title: {
            text: "",
          },

          xAxis: [
            {
              min: 0, //setting min to 0
              max: 2250,
              events: {
                setExtremes: function (e) {
                  //val = e.min + 500;
                  if (e.min <= 500) {
                    val = e.min;
                  } else {
                    val = e.min + 500;
                  }
                  tempChart = charts[window.prevChart];
                  if (tempChart != undefined) {
                    if (tempChart.draggablePlotLine != undefined) {
                      val2 = tempChart.xAxis[0].toPixels(val);
                      tempChart.draggablePlotLine.attr({
                        x: val2,
                      });
                    }
                  }
                },
              },
              scrollbar: {
                enabled: true,
              },
              labels: {
                enabled: false,
              },

              tickInterval: 50,
              minorTicks: true,
              minorTickInterval: 10,
              gridLineWidth: 1,
              gridLineColor: "#D3D3D3",
            },
          ],
          yAxis: [
            {
              min: 0,
              max: 10,
              reversed: true,
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              minorTicks: false,

              labels: {
                enabled: true,
              },
              title: {
                enabled: false,
              },
              visible: false,
            },
            {
              min: -0.002,
              max: 0.002,
              tickPositions: [
                -0.002, -0.0015, -0.001, -0.0005, 0.0, 0.0005, 0.001, 0.0015,
                0.002,
              ],
              tickInterval: 0.0005,
              gridLineWidth: 3,
              minorGridLineWidth: 1,
              minorTicks: true,
              minorTickInterval: 0.0001,

              gridLineWidth: 2,
              gridLineColor: "#D3D3D3",
              labels: {
                enabled: false,
              },
              title: {
                enabled: false,
              },
            },
            {
              min: 0,
              max: 10,
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              minorTicks: false,

              labels: {
                enabled: true,
              },
              title: {
                enabled: false,
              },
              visible: false,
            },
          ],
          series: [
            {
              data: [],
              yAxis: 1,
              xAxis: 0,
              color: "#09ff08",
              states: {
                inactive: {
                  opacity: 1,
                },
              },

              allowPointSelect: false,
              animation: false,
              enableMouseTracking: false,
              lineWidth: 2,
              marker: {
                enabled: false,
              },
              shadow: false,
              stickyTracking: false,
              showInLegend: false,
            },
          ],
          rangeSelector: {
            enabled: false,
          },
          plotOptions: {
            series: {
              turboThreshold: 15500,
              colorByPoint: false,
            },
          },
        });

        // Display the popup
        popup.classList.remove("popup-hidden");
        chart.addSeries(
          {
            yAxis: 0,

            type: "column",

            data: [],
            pointWidth: 400,
            inverted: true,
            tooltip: {
              enabled: false,
            },
            states: {
              inactive: {
                opacity: 1,
              },
            },

            allowPointSelect: false,
            animation: false,
            enableMouseTracking: false,
            lineWidth: 1,

            shadow: false,
            stickyTracking: false,
            showInLegend: false,
          },
          true
        );
        chart.addSeries(
          {
            dataLabels: [
              {
                enabled: true,
                inside: true,
                align: "center",
                format: "B:{point.beat}; HR:{point.hr}", // RR:{point.rr}'
                //format: 'HR:{point.hr}; RR:{point.rr}'
              },
            ],
            yAxis: 2,
            type: "column",
            turboThreshold: 10000,
            data: [],
            pointWidth: 130,
            tooltip: {
              enabled: false,
            },
            point: {
              events: {
                click: function () {},
              },
            },
            states: {
              inactive: {
                opacity: 1,
              },
            },
            allowPointSelect: false,
            animation: false,
            enableMouseTracking: false,
            marker: {
              enabled: false,
            },
            showInLegend: false,
          },
          true
        );
        chart.addSeries(
          {
            name: "R Peak",
            yAxis: 1,
            data: [],

            lineWidth: 0,
            marker: {
              enabled: true,
              radius: 4,
              symbol: "circle",
            },

            tooltip: {
              enabled: false,
            },
            states: {
              hover: {
                lineWidthPlus: 0,
              },
              inactive: {
                opacity: 1,
              },
            },
            enableMouseTracking: false,
          },
          true
        );
        chart.addSeries(
          {
            name: "T Peak",
            yAxis: 1,
            data: [],

            lineWidth: 0,
            marker: {
              enabled: true,
              radius: 4,

              symbol: "circle",
            },

            tooltip: {
              enabled: false,
            },
            states: {
              hover: {
                lineWidthPlus: 0,
              },
              inactive: {
                opacity: 1,
              },
            },
            enableMouseTracking: false,
          },
          true
        );
        chart.addSeries(
          {
            name: "Place Holder",
            yAxis: 1,
            data: [],

            lineWidth: 0,
            marker: {
              enabled: true,
              radius: 4,

              symbol: "circle",
            },
            tooltip: {
              enabled: false,
            },
            states: {
              hover: {
                lineWidthPlus: 0,
              },
              inactive: {
                opacity: 1,
              },
            },
            enableMouseTracking: false,
          },
          true
        );
        return chart;
      } else {
        console.error("Popup or tt_container element not found.");
      }
    }
    if (step == 2) {
      this.state.chart.destroy();
      this.setState({
        databaseid: databaseid,
      });
    }

    document.getElementById("selWrap").innerHTML = "";
    var newCon = this;
    worker4.postMessage({
      name: "getfolderfiles",
      url:
        "https://web.mybiocalculus.com:5000/api/common/getfolderfiles?userid=" +
        userid +
        "&databaseid=" +
        databaseid,
    });
    worker4.onmessage = function (event) {};
    worker4.addEventListener("message", function (res) {
      newCon.setState({
        images: res.data[0],
      });
      newCon.setState({
        dblink: res.data[1],
      });
      newCon.filter1(window.filterId);
    });

    // axios
    //     .get('https://web.mybiocalculus.com:5000/api/common/getfolderfiles?userid=' + userid + '&databaseid=' + databaseid)
    //     .then(res => {

    //         this.setState({
    //             images: res.data[0]
    //         });
    //         this.setState({
    //             dblink: res.data[1]
    //         });
    //         this.filter1(window.filterId);

    //     })
    //     .catch(err => {
    //         console.log(err);
    //     });
    /*this.setState({
            minutes_no: "0"
        });*/

    // renderMainChart(this);

    this.setState({
      result3: [],
    });
    this.setState({
      defarr: [],
    });

    this.setState({
      starr: [],
    });

    var setEcgData = (res) => {
      for (var i = this.state.start; i < res.data.length; i++) {
        window.mainArray[i].push(res.data[i]);
      }
      if (!this.preEcgValues) {
        this.preEcgValues = res.data;
        this.ecgValues = res.data
          .map((e) => e[0])
          .reduce((a, b) => a.concat(b));
        this.activityValues = res.data
          .map((e) => e[1])
          .reduce((a, b) => a.concat(b));
        this.peakValues = res.data
          .map((e) => e[2])
          .reduce((a, b) => a.concat(b));
      } else {
        if (this.state.scrollStatus === "next") {
          this.preEcgValues = this.preEcgValues.filter(
            (e, i) => i > this.state.limitScrollStart - 1
          );

          this.preEcgValues = this.preEcgValues.concat(res.data);
        } else if (this.state.scrollStatus === "prev") {
          this.preEcgValues = this.preEcgValues.filter(
            (e, i) => i < this.state.limitScrollStart - 1
          );
          this.preEcgValues = res.data.concat(this.preEcgValues);
        }
        this.ecgValues = this.preEcgValues
          .map((e) => e[0])
          .reduce((a, b) => a.concat(b));
        this.activityValues = this.preEcgValues
          .map((e) => e[1])
          .reduce((a, b) => a.concat(b));
        this.peakValues = this.preEcgValues
          .map((e) => e[2])
          .reduce((a, b) => a.concat(b));
      }

      /*console.log("preEcgValues",this.preEcgValues);
            console.log("this.ecgValues.length",this.ecgValues.length);*/
      datasetMnt = Math.floor(this.ecgValues.length / deviceFrequency);
      //console.log("datasetMnt",datasetMnt);
      if (this.ecgValues.length % deviceFrequency > 0) {
        datasetMnt += 1;
      }

      /*mainEcgArray = this.ecgValues;
            mainActivityArray = this.activityValues;*/

      this.updateRpeaks(this.peakValues);
      var graphPointIndex = 0;
      if (this.state.scrollStatus === "next") {
        if (clickContainerIndex > 2) {
          graphPointIndex = parseInt(clickContainerIndex) - 2;
        }
      } else if (this.state.scrollStatus === "prev") {
        graphPointIndex = 4;
        if (clickContainerIndex < 2) {
          graphPointIndex = parseInt(clickContainerIndex) + 2;
        }
      }

      result3 = chunkPeakArray(this.peakValues, this.state.peakStart);
      window.result3 = result3;
      this.setState({
        result3: result3,
      });

      var acti = 125;
      var counteri = 249;
      var counter = 0;
      var t = 0;
      var actarr = [];
      colorArray = ["#98FB98", "#f1e4e4", "#f7e0a3", "#f09c67"];

      var sliceActStart = graphPointIndex * window.sc;
      var sliceActEnd = sliceActStart + window.sc + 1;
      var curDataArray = this.activityValues.slice(sliceActStart, sliceActEnd);
      // console.log(this.ecgValues.slice(sliceStart, sliceEnd));

      for (var i = 0; i < curDataArray.length; i++) {
        actarr.push({
          x: acti,
          y: 0.5,
          color: colorArray[curDataArray[i]],
        });
        acti = acti + 250;
      }

      chart.hideLoading();
      var sliceStart = graphPointIndex * deviceFrequency;
      var sliceEnd = sliceStart + deviceFrequency + 1;
      // console.log('ECG Valuess',this.ecgValues.slice(sliceStart, sliceEnd));
      chart.series[0].setData(this.ecgValues.slice(sliceStart, sliceEnd), true);

      chart.series[1].setData(actarr, true);
      chart.series[2].setData(result3[graphPointIndex], true);

      renderSmallCharts(graphPointIndex);

      this.setState({
        datasetMnt: datasetMnt,
      });
      this.setState({
        mainEcgArray: this.ecgValues,
      });
      //console.log('mainOne', this.state.mainEcgArray);
      this.setState({
        mainActivityArray: this.activityValues,
      });
      this.setState({
        result3: result3,
      });

      this.setState({
        charts: charts,
      });
      this.setState({
        chart: chart,
      });
      this.setState({
        defarr: defarr,
      });

      this.setState({
        starr: starr,
      });
    };

    //this.state.charts[0].destroy();

    for (var k = 0; k < this.state.charts.length; k++) {
      if (this.state.charts[k] != undefined) {
        //console.log('chart length',this.state.charts);
        this.state.charts[k].destroy();
      }
    }
    /*this.setState({
            minutes_no: start.toString()

        });*/
    //console.log('charts New',this.props.auth.user.minutes_no);
    /* start=parseInt(this.props.auth.user.minutes_no);
         end=parseInt(this.props.auth.user.minutes_no)+4;*/

    // axios
    //     .get(apiUrl + 'api/common/getEcgNewData?start=' + start + '&end=9&userid=' + userid + '&databaseid=' + databaseid)
    //     .then(

    //         setEcgData
    //     )
    //     .catch(err => {});
    var selfOne = this;

    worker2.postMessage({
      name: "getEcgNewData",
      url:
        apiUrl +
        "api/common/getEcgNewData?start=" +
        start +
        "&end=9&userid=" +
        userid +
        "&databaseid=" +
        databaseid,
    });
    worker2.onmessage = function (event) {};
    worker2.addEventListener("message", function (res) {
      for (var i = selfOne.state.start; i < res.data.length; i++) {
        window.mainArray[i].push(res.data[i]);
      }
      if (!selfOne.preEcgValues) {
        selfOne.preEcgValues = res.data;

        selfOne.ecgValues = res.data
          .map((e) => e[0])
          .reduce((a, b) => a.concat(b));
        selfOne.activityValues = res.data
          .map((e) => e[1])
          .reduce((a, b) => a.concat(b));
        selfOne.peakValues = res.data
          .map((e) => e[2])
          .reduce((a, b) => a.concat(b));
      } else {
        if (selfOne.state.scrollStatus === "next") {
          selfOne.preEcgValues = selfOne.preEcgValues.filter(
            (e, i) => i > selfOne.state.limitScrollStart - 1
          );

          selfOne.preEcgValues = selfOne.preEcgValues.concat(res.data);
        } else if (selfOne.state.scrollStatus === "prev") {
          selfOne.preEcgValues = selfOne.preEcgValues.filter(
            (e, i) => i < selfOne.state.limitScrollStart - 1
          );
          selfOne.preEcgValues = res.data.concat(selfOne.preEcgValues);
        }
        selfOne.ecgValues = selfOne.preEcgValues
          .map((e) => e[0])
          .reduce((a, b) => a.concat(b));
        selfOne.activityValues = selfOne.preEcgValues
          .map((e) => e[1])
          .reduce((a, b) => a.concat(b));
        selfOne.peakValues = selfOne.preEcgValues
          .map((e) => e[2])
          .reduce((a, b) => a.concat(b));
      }

      // console.log("preEcgValues", selfOne.preEcgValues);
      // console.log("selfOne.ecgValues.length", selfOne.ecgValues.length);
      datasetMnt = Math.floor(selfOne.ecgValues.length / deviceFrequency);
      //console.log("datasetMnt",datasetMnt);
      if (selfOne.ecgValues.length % deviceFrequency > 0) {
        datasetMnt += 1;
      }

      /*mainEcgArray = selfOne.ecgValues;
                mainActivityArray = selfOne.activityValues;*/
      selfOne.updateRpeaks(selfOne.peakValues);
      var graphPointIndex = 0;
      if (selfOne.state.scrollStatus === "next") {
        if (clickContainerIndex > 2) {
          graphPointIndex = parseInt(clickContainerIndex) - 2;
        }
      } else if (selfOne.state.scrollStatus === "prev") {
        graphPointIndex = 4;
        if (clickContainerIndex < 2) {
          graphPointIndex = parseInt(clickContainerIndex) + 2;
        }
      }

      result3 = chunkPeakArray(selfOne.peakValues, selfOne.state.peakStart);
      window.result3 = result3;
      selfOne.setState({
        result3: result3,
      });

      var acti = 125;
      var counteri = 249;
      var counter = 0;
      var t = 0;
      var actarr = [];
      colorArray = ["#98FB98", "#f1e4e4", "#f7e0a3", "#f09c67"];

      var sliceActStart = graphPointIndex * window.sc;
      var sliceActEnd = sliceActStart + window.sc + 1;
      var curDataArray = selfOne.activityValues.slice(
        sliceActStart,
        sliceActEnd
      );
      // console.log(selfOne.ecgValues.slice(sliceStart, sliceEnd));

      for (var i = 0; i < curDataArray.length; i++) {
        actarr.push({
          x: acti,
          y: 0.5,
          color: colorArray[curDataArray[i]],
        });
        acti = acti + 250;
      }

      chart.hideLoading();
      var sliceStart = graphPointIndex * deviceFrequency;
      var sliceEnd = sliceStart + deviceFrequency + 1;
      // console.log('ECG Valuess',selfOne.ecgValues.slice(sliceStart, sliceEnd));
      chart.series[0].setData(
        selfOne.ecgValues.slice(sliceStart, sliceEnd),
        true
      );
      chart.series[1].setData(actarr, true);
      chart.series[2].setData(result3[graphPointIndex], true);

      renderSmallCharts(graphPointIndex);

      selfOne.setState({
        datasetMnt: datasetMnt,
      });
      selfOne.setState({
        mainEcgArray: selfOne.ecgValues,
      });
      //console.log('mainOne', selfOne.state.mainEcgArray);
      selfOne.setState({
        mainActivityArray: selfOne.activityValues,
      });
      selfOne.setState({
        result3: result3,
      });

      selfOne.setState({
        charts: charts,
      });
      selfOne.setState({
        chart: chart,
      });
      selfOne.setState({
        defarr: defarr,
      });

      selfOne.setState({
        starr: starr,
      });
    });

    var newArrAxiosRes2 = [];

    this.getData(userid, databaseid).then((value) => {
      // console.log("value", value);
      for (var ij = 0; ij < window.sc - 10; ij++) {
        newArrAxiosRes2[ij] = value[ij].data;
      }
      if (newArrAxiosRes2 != undefined) {
        var ind = 0;
        for (var i = 10; i < window.sc; i++) {
          if (window.mainArray[i].length == 0) {
            window.mainArray[i].push(newArrAxiosRes2[ind][0]);
            ind = ind + 1;
          }
        }
      }
    });

    var setGraphMarker = (res) => {
      this.state.graphMarker = res;

      var clickPointIndex = 0;
      if (this.state.scrollStatus === "next") {
        if (clickContainerIndex > 2) {
          clickPointIndex = parseInt(clickContainerIndex) - 2;
        }
      } else if (this.state.scrollStatus === "prev") {
        clickPointIndex = 4;
        if (clickContainerIndex < 2) {
          clickPointIndex = parseInt(clickContainerIndex) + 2;
        }
      }
      var graphPointIndex = pageIndex * 3;
      // parr = chunkMarkerArray(res.data.ppeak_index, res.data.ppeak_amp, graphPointIndex);

      // chart.series[3].update({
      //     data: parr[clickPointIndex],
      // }, true);
      // this.setState({
      //     parr: parr
      // });
      // tarr = chunkMarkerArray(res.data.tpeak_index, res.data.tpeak_amp, graphPointIndex);
      // chart.series[7].update({
      //     data: tarr[clickPointIndex],
      // }, true);
      // this.setState({
      //     tarr: tarr
      // });
      // ponarr = chunkMarkerArray(res.data.pon_index, res.data.pon_amp, graphPointIndex);
      // chart.series[8].update({
      //     data: ponarr[clickPointIndex],
      // }, true);
      // this.setState({
      //     ponarr: ponarr
      // });
      // qarr = chunkMarkerArray(res.data.q_index, res.data.q_amp, graphPointIndex);
      // chart.series[4].update({
      //     data: qarr[clickPointIndex],
      // }, true);
      // this.setState({
      //     qarr: qarr
      // });
      rarr = chunkMarkerArray(
        res.data.rpeak_index,
        res.data.rpeak_amp,
        graphPointIndex
      );
      chart.series[5].update(
        {
          data: rarr[clickPointIndex],
        },
        true
      );
      this.setState({
        rarr: rarr,
      });
      // sarr = chunkMarkerArray(res.data.s_index, res.data.s_amp, graphPointIndex);
      // chart.series[6].update({
      //     data: sarr[clickPointIndex],
      // }, true);
      // this.setState({
      //     sarr: sarr
      // });
      // poffarr = chunkMarkerArray(res.data.poff_index, res.data.poff_amp, graphPointIndex);
      // chart.series[9].update({
      //     data: poffarr[clickPointIndex],
      // }, true);
      // this.setState({
      //     poffarr: poffarr
      // });
      // tonarr = chunkMarkerArray(res.data.ton_index, res.data.ton_amp, graphPointIndex);
      // chart.series[10].update({
      //     data: tonarr[clickPointIndex],
      // }, true);
      // this.setState({
      //     tonarr: tonarr
      // });
      // toffarr = chunkMarkerArray(res.data.toff_index, res.data.toff_amp, graphPointIndex);
      // chart.series[11].update({
      //     data: toffarr[clickPointIndex],
      // }, true);
      // this.setState({
      //     toffarr: toffarr
      // });
      // jarr = chunkMarkerArray(res.data.j_index, res.data.j_amp, graphPointIndex);
      // chart.series[12].update({
      //     data: jarr[clickPointIndex],
      // }, true);
      // this.setState({
      //     jarr: jarr
      // });
      // chart.series[13].update({
      //     data: [],
      // }, true);
      // console.log(this.state.rarr);
    };
    if (Object.keys(this.state.graphMarker).length > 0) {
      setGraphMarker(this.state.graphMarker);
    } else {
      // axios
      //     .get(apiUrl + 'api/common/getGraphMarker?userid=' + userid + '&databaseid=' + databaseid)
      //     .then(
      //         setGraphMarker
      //     )
      var markerThis = this;
      worker7.postMessage({
        name: "getGraphMarker",
        url:
          apiUrl +
          "api/common/getGraphMarker?userid=" +
          userid +
          "&databaseid=" +
          databaseid,
      });
      worker7.onmessage = function (event) {};
      worker7.addEventListener("message", function (res) {
        if (res.data != null) {
          markerThis.state.graphMarker = res;

          var clickPointIndex = 0;
          if (markerThis.state.scrollStatus === "next") {
            if (clickContainerIndex > 2) {
              clickPointIndex = parseInt(clickContainerIndex) - 2;
            }
          } else if (markerThis.state.scrollStatus === "prev") {
            clickPointIndex = 4;
            if (clickContainerIndex < 2) {
              clickPointIndex = parseInt(clickContainerIndex) + 2;
            }
          }
          var graphPointIndex = pageIndex * 3;
          // parr = chunkMarkerArray(res.data.ppeak_index, res.data.ppeak_amp, graphPointIndex);

          // chart.series[3].update({
          //     data: parr[clickPointIndex],
          // }, true);
          // markerThis.setState({
          //     parr: parr
          // });
          // tarr = chunkMarkerArray(res.data.tpeak_index, res.data.tpeak_amp, graphPointIndex);
          // chart.series[7].update({
          //     data: tarr[clickPointIndex],
          // }, true);
          // markerThis.setState({
          //     tarr: tarr
          // });
          // ponarr = chunkMarkerArray(res.data.pon_index, res.data.pon_amp, graphPointIndex);
          // chart.series[8].update({
          //     data: ponarr[clickPointIndex],
          // }, true);
          // markerThis.setState({
          //     ponarr: ponarr
          // });
          // qarr = chunkMarkerArray(res.data.q_index, res.data.q_amp, graphPointIndex);
          // chart.series[4].update({
          //     data: qarr[clickPointIndex],
          // }, true);
          // markerThis.setState({
          //     qarr: qarr
          // });
          rarr = chunkMarkerArray(
            res.data.rpeak_index,
            res.data.rpeak_amp,
            graphPointIndex
          );
          chart.series[5].update(
            {
              data: rarr[clickPointIndex],
            },
            true
          );
          markerThis.setState({
            rarr: rarr,
          });
          // sarr = chunkMarkerArray(res.data.s_index, res.data.s_amp, graphPointIndex);
          // chart.series[6].update({
          //     data: sarr[clickPointIndex],
          // }, true);
          // markerThis.setState({
          //     sarr: sarr
          // });
          // poffarr = chunkMarkerArray(res.data.poff_index, res.data.poff_amp, graphPointIndex);
          // chart.series[9].update({
          //     data: poffarr[clickPointIndex],
          // }, true);
          // markerThis.setState({
          //     poffarr: poffarr
          // });
          // tonarr = chunkMarkerArray(res.data.ton_index, res.data.ton_amp, graphPointIndex);
          // chart.series[10].update({
          //     data: tonarr[clickPointIndex],
          // }, true);
          // markerThis.setState({
          //     tonarr: tonarr
          // });
          // toffarr = chunkMarkerArray(res.data.toff_index, res.data.toff_amp, graphPointIndex);
          // chart.series[11].update({
          //     data: toffarr[clickPointIndex],
          // }, true);
          // markerThis.setState({
          //     toffarr: toffarr
          // });
          // jarr = chunkMarkerArray(res.data.j_index, res.data.j_amp, graphPointIndex);
          // chart.series[12].update({
          //     data: jarr[clickPointIndex],
          // }, true);
          // markerThis.setState({
          //     jarr: jarr
          // });
          // chart.series[13].update({
          //     data: [],
          // }, true);
        }
      });
    }

    const data = {
      databaseid: dbId,
      userid: userid,
    };
    var setVerifyCheck = (res) => {
      this.state.verifyCheck = res;
      if (res.data == 0) {
        $("#verfiyBtn").css("display", "inline-block");
      } else if (res.data == 1) {
        $("#verfiyBtn").css("display", "none");
      }
    };
    if (Object.keys(this.state.verifyCheck).length > 0) {
      setVerifyCheck(this.state.verifyCheck);
    } else {
      var verifycon = this;
      worker3.postMessage({
        name: "checkVerify",
        url: apiUrl + "api/common/checkVerify",
        dat: data,
      });
      worker3.onmessage = function (event) {};
      worker3.addEventListener("message", function (res) {
        verifycon.state.verifyCheck = res;
        if (res.data == 0) {
          $("#verfiyBtn").css("display", "inline-block");
        } else if (res.data == 1) {
          $("#verfiyBtn").css("display", "none");
        }
      });
      axios
        .post(`${apiUrl}api/common/checkVerify`, data)
        .then((response) => {
          this.setState({ verifiedStatus: response.data });
          // You can add any other logic to handle the response if needed
          // console.log("reposne:", this.state.verifiedStatus);
        })
        .catch((error) => {
          console.error("Error:", error); // Handle the error with a detailed message
          // Optionally, add further error handling logic here
        });
    }
    renderMainChart(this);
  }
  nextBtnLink(userid, databaseid) {
    console.log("databaseid!!!!!!!!!!!!", databaseid);
    const treemenudb = this.state.treemenu;

    // Array to store the extracted date and time
    let dateTimeArray = [];

    // Iterate through the treemenu and extract the date and time part
    Object.keys(treemenudb).forEach((dateKey) => {
      treemenudb[dateKey].forEach((item) => {
        const dateTime = item.date.split("+")[0].replace("T", " "); // Extract date and time
        dateTimeArray.push(dateTime);
      });
    });

    // Sort the extracted dateTimeArray (lexicographically works for date strings)
    dateTimeArray.sort();

    // console.log("Sorted date and time:", dateTimeArray);

    // Check if the sorted date and time matches the databaseid and get the index
    const matchedIndex = dateTimeArray.findIndex(
      (dateTime) => dateTime === databaseid
    );

    if (matchedIndex !== -1) {
      // console.log("Matched databaseid:", databaseid);
      // console.log("Matched date and time:", dateTimeArray[matchedIndex]);
      // console.log("Matched index:", matchedIndex);

      // Check if the next index (matchedIndex + 1) exists and update state with nextDateTime
      if (matchedIndex + 1 < dateTimeArray.length) {
        const nextDateTime = dateTimeArray[matchedIndex + 1];
        console.log("Next Date and Time (index + 1):", nextDateTime);

        // Set the state with the nextDateTime
        this.setState({ databaseid: nextDateTime, dblink: nextDateTime });
        //  this.renderNewChart(userid,nextDateTime,2)
        window.location.href = "/verifier-chart/" + userid + "/" + nextDateTime;
      } else {
        console.log("No next date and time. You are at the last entry.");
      }
    } else {
      console.log("Database ID not found in sorted date and time.");
    }
  }

  prevBtnLink(userid, databaseid) {
    // console.log("databaseid!!!!!!!!!!!!", databaseid);
    const treemenudb = this.state.treemenu;

    // Array to store the extracted date and time
    let dateTimeArray = [];

    // Iterate through the treemenu and extract the date and time part
    Object.keys(treemenudb).forEach((dateKey) => {
      treemenudb[dateKey].forEach((item) => {
        const dateTime = item.date.split("+")[0].replace("T", " "); // Extract date and time
        dateTimeArray.push(dateTime);
      });
    });

    // Sort the extracted dateTimeArray (lexicographically works for date strings)
    dateTimeArray.sort();

    // console.log("Sorted date and time:", dateTimeArray);

    // Check if the sorted date and time matches the databaseid and get the index
    const matchedIndex = dateTimeArray.findIndex(
      (dateTime) => dateTime === databaseid
    );

    if (matchedIndex !== -1) {
      // console.log("Matched databaseid:", databaseid);
      // console.log("Matched date and time:", dateTimeArray[matchedIndex]);
      // console.log("Matched index:", matchedIndex);

      // Check if the previous index (matchedIndex - 1) exists and update state with prevDateTime
      if (matchedIndex - 1 >= 0) {
        const prevDateTime = dateTimeArray[matchedIndex - 1];
        // console.log("Previous Date and Time (index - 1):", prevDateTime);

        // Set the state with the prevDateTime
        this.setState({ databaseid: prevDateTime, dblink: prevDateTime });
        this.state.chart.destroy();
        this.setState({
          chart: null,
        });
        //  this.renderNewChart(userid,prevDateTime,2)
        window.location.href = "/verifier-chart/" + userid + "/" + prevDateTime;
      } else {
        console.log("No previous date and time. You are at the first entry.");
      }
    } else {
      console.log("Database ID not found in sorted date and time.");
    }
  }

  newDataClick(e) {
    const buttonId = e.currentTarget.id;
    const databaseid = this.state.databaseid;
    const userid = this.props.match.params.userid;

    //  this.clearCurrentChart()

    // Call the appropriate link functions and render the new chart based on button clicked
    if (buttonId === "nextBtn") {
      this.nextBtnLink(userid, databaseid);
    } else if (buttonId === "prevBtn") {
      this.prevBtnLink(userid, databaseid);
    }
  }

  componentDidMount() {
    if (
      (localStorage.getItem("verifiertype") !== "8") &
      (localStorage.getItem("verifiertype") !== "2")
    ) {
      this.props.history.push("/error");
    }
    if (localStorage.getItem("verifiertype") !== "8") {
      const { userid } = this.props.match.params;
      const verifierid = localStorage.getItem("verifierid");
      // console.log("verifier id :",verifierid)

      axios
        .get("https://web.mybiocalculus.com:3002/api/users/allverifiers")
        .then((response) => {
          console.log("Verifier ID to match:", verifierid);
          console.log("All verifiers:", response.data);

          // Find the verifier that matches the verifierid
          const match = response.data.find(
            (verifier) => String(verifierid) === String(verifier._id)
          );

          if (match) {
            console.log("Matched Verifier:", match);

            // Check if userid exists in the patients array of the matched verifier
            const hasUserId = match.patients.includes(userid);

            if (hasUserId) {
              console.log(
                "User ID found in verifier's patients array:",
                userid
              );
              // Proceed with normal flow
              // this.props.history.push("/success"); // Example of normal flow navigation
            } else {
              console.log(
                "User ID not found in verifier's patients array:",
                userid
              );
              // Navigate to error page
              this.props.history.push("/error");
            }
          } else {
            console.log("No match found for verifier ID:", verifierid);
            // Navigate to error page
            this.props.history.push("/error");
          }
        })
        .catch((error) => {
          console.error("Error fetching verifiers:", error);
        });
    }

    window.container = 0;
    window.resumeFlag = 0;
    window.eventclass = "all";
    window.selectAll = 0;
    window.beatArr = [];
    window.timer = null;
    window.chartid = 0;
    window.mainArray = [];
    window.prevStart = 0;
    window.titleFlag = 0;
    window.diaryFlag = 0;
    this.addDragListeners();

    /* console.log(socket.connected);
    socket.on('connect', function(){console.log('connected')});
    socket.on('connect_error', function(err){console.log(err)});
    socket.on('data', function(data){console.log(data)});
    socket.on('disconnect', function(){console.log('disconnected')});*/
    //window.addEventListener('beforeunload', this.keepOnPage);

    this.callDataAPIs();
    if (!this.state.keyIntegrationStatus) {
      this.keyIntegration();
      this.state.keyIntegrationStatus = 1;
    }
    document.addEventListener("keydown", this.handleKeyPress);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
  }

  addDragListeners() {
    const popup = document.querySelector("#popup");
    let isDragging = false;
    let startX, startY, initialX, initialY;

    const dragMouseDown = (e) => {
      e.preventDefault();
      isDragging = true;
      startX = e.clientX;
      startY = e.clientY;
      initialX = popup.offsetLeft;
      initialY = popup.offsetTop;
      document.addEventListener("mousemove", elementDrag);
      document.addEventListener("mouseup", closeDragElement);
    };

    const elementDrag = (e) => {
      if (!isDragging) return;
      e.preventDefault();
      const deltaX = e.clientX - startX;
      const deltaY = e.clientY - startY;
      popup.style.left = initialX + deltaX + "px";
      popup.style.top = initialY + deltaY + "px";
    };

    const closeDragElement = () => {
      isDragging = false;
      document.removeEventListener("mousemove", elementDrag);
      document.removeEventListener("mouseup", closeDragElement);
    };

    if (popup) {
      const draggableArea = popup.querySelector(".draggable-area");
      draggableArea.addEventListener("mousedown", dragMouseDown);
    }
  }
  keyIntegration() {
    $(document).keydown((e) => {
      //console.log('key down');

      if (
        this.state.currentTab == "chart_tab_2" &&
        this.state.keyIntegrationStatus != 0
      ) {
        let chart = this.state.chart.xAxis[0];
        let extremes = chart.getExtremes();
        let zoomLevel = extremes.max - extremes.min;
        switch (e.which) {
          case 65:
          case 37: // lefty
            var minL = extremes.min - zoomLevel;
            var maxL = extremes.min;
            if (minL <= 0) {
              maxL = 2250;
              minL = 0;
            }
            if (extremes.min == 0) {
              var e = $.Event("keydown");

              // e.which is used to set the keycode
              e.which = 40; // it is down
              $("body").trigger(e);
            } else {
              chart.setExtremes(minL, maxL);
            }
            break;

          case 87:
          case 38: // up
            // this.handleTopScroll();

            break;

          case 68:
          case 39: // right
            var min = extremes.max;
            var max = extremes.max + zoomLevel;
            var stripIndex = window.prevChart;
            var sliceStart = stripIndex * deviceFrequency;
            var sliceEnd = sliceStart + deviceFrequency + 1;
            var currentEcgArray = this.state.mainEcgArray.slice(
              sliceStart,
              sliceEnd
            );
            if (max >= deviceFrequency) {
              max = deviceFrequency - 1;
              min = deviceFrequency - 2250;
            } else if (max > currentEcgArray.length) {
              max = currentEcgArray.length;
              min = currentEcgArray.length - zoomLevel;
            }
            if (extremes.max == deviceFrequency - 1) {
              var e = $.Event("keydown");

              // e.which is used to set the keycode
              e.which = 40; // it is down
              $("body").trigger(e);
            } else {
              chart.setExtremes(min, max);
            }
            break;

          case 83:
          case 40: // down
            //  this.handleBottomScroll();

            break;

          default:
            return; // exit this handler for other keys
        }
        e.preventDefault(); // prevent the default action (scroll / move caret)
      }
    });
  }

  recategorizeImg(e) {
    var checkedArray = [];
    var userid = this.props.match.params.userid;
    var databaseid = this.state.dblink;
    var folder = e.currentTarget.value;
    console.log(folder);
    var self = this;
    var clickedPI_;
    var value1;
    const data = [];
    var i = 0;
    $("input:checkbox[name=test]:checked").each(function () {
      checkedArray.push($(this).val());
      this.checked = false;
      var clickedPI = this.id.toString();
      clickedPI = clickedPI.split("_");
      clickedPI_ =
        clickedPI[1].substring(0, 2) +
        ":" +
        clickedPI[1].substring(2, 4) +
        ":" +
        clickedPI[1].substring(4, 6);
      data[i] = {
        userid: userid,
        databaseid: databaseid,
        clickedPI_: clickedPI_,
      };
      i = i + 1;

      window.beatArr.push(clickedPI[2].split(".")[0]);
      value1 = this.value.toString();
    });

    if (folder == "un") {
      value1 = "Undiagnosed";
    } else if (folder == "nsr") {
      value1 = "Normal Sinus Rhythm";
    } else if (folder == "sb") {
      value1 = "Sinus Bradycardia";
    } else if (folder == "st") {
      value1 = "Sinus Tachycardia";
    } else if (folder == "pac") {
      value1 = "PAC";
    } else if (folder == "pvc") {
      value1 = "PVC";
    } else if (folder == "vt") {
      value1 = "Ventricular Tachycardia";
    } else if (folder == "af") {
      value1 = "AFib";
    } else if (folder == "sp") {
      value1 = "Sinus Pause";
    } else if (folder == "pa") {
      value1 = "P Absent";
    } else if (folder == "irr") {
      value1 = "Irregular RR";
    } else if (folder == "ipr") {
      value1 = "Irregular PR";
    } else if (folder == "sqt") {
      value1 = "Short QT";
    } else if (folder == "lqt") {
      value1 = "Prolonged QT";
    }

    self.recategorizeAfterImage(value1);

    if (checkedArray.length > 0) {
      const data = {
        userid: userid,
        databaseid: databaseid,
        checkedArray: checkedArray,
        folder: folder,
      };
      //console.log("reCatData",data);
      axios
        .post(
          "https://web.mybiocalculus.com:5000/api/common/reCategorize",
          data
        )
        .then((res) => {
          axios
            .get(
              "https://web.mybiocalculus.com:5000/api/common/getfolderfiles?userid=" +
                userid +
                "&databaseid=" +
                databaseid
            )
            .then((res) => {
              this.setState({
                images: res.data[0],
              });
              this.setState({
                dblink: res.data[1],
              });
              console.log("checkData", res.data[0]);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
      // axios
      //     .post('https://web.mybiocalculus.com:5000/api/common/reCategorize', data)
      //     .then(res => {
      //         var newCon=this;
      //         worker4.postMessage({ name:'getfolderfiles',url: 'https://web.mybiocalculus.com:5000/api/common/getfolderfiles?userid=' + userid + '&databaseid=' + databaseid});
      //         worker4.onmessage = function (event) {};
      //         worker4.addEventListener("message", function (res) {
      //             newCon.setState({
      //                     images: res.data[0]
      //                 });
      //                 newCon.setState({
      //                     dblink: res.data[1]
      //                 });
      //                 newCon.filter1(window.filterId);

      //             });

      //     })
      //     .catch(err => {
      //         console.log(err);
      //     });
    }
  }

  removeFiles(e) {
    var checkedArray = [];
    var userid = this.props.match.params.userid;
    var databaseid = this.state.dblink;
    var folder = window.eventclass;
    console.log(folder);
    $("input:checkbox[name=test]:checked").each(function () {
      checkedArray.push($(this).val());
      this.checked = false;
    });
    if (checkedArray.length > 0) {
      const data = {
        userid: userid,
        databaseid: databaseid,
        checkedArray: checkedArray,
        folder: folder,
      };
      console.log(data);
      axios
        .post("https://web.mybiocalculus.com:5000/api/common/removeFiles", data)
        .then((res) => {
          alert("Files Removed");
          var newCon = this;
          worker4.postMessage({
            name: "getfolderfiles",
            url:
              "https://web.mybiocalculus.com:5000/api/common/getfolderfiles?userid=" +
              userid +
              "&databaseid=" +
              databaseid,
          });
          worker4.onmessage = function (event) {};
          worker4.addEventListener("message", function (res) {
            newCon.setState({
              images: res.data[0],
            });
            newCon.setState({
              dblink: res.data[1],
            });
            newCon.filter1(window.filterId);
          });
          // axios
          //     .get('https://web.mybiocalculus.com:5000/api/common/getfolderfiles?userid=' + userid + '&databaseid=' + databaseid)
          //     .then(res => {
          //         //  console.log(res.data[0]);
          //         this.setState({
          //             images: res.data[0]
          //         });
          //         this.setState({
          //             dblink: res.data[1]
          //         });

          //     })
          //     .catch(err => {
          //         console.log(err);
          //     });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  removeAll(e) {
    if (window.confirm("Do you want to delete all the ECG Event Strips?")) {
      console.log("remove all");

      var userid = this.props.match.params.userid;
      const data = {
        userid: userid,
      };

      axios
        .post("https://web.mybiocalculus.com:5000/api/common/removeAll", data)
        .then((res) => {
          console.log("Removed");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  /* getDiaryInfo(e) {
           var userid = this.props.match.params.userid;
          axios
                        .get(apiUrl+'api/patients/getDiaryInfo?userid=' + userid )
                        .then(res => {
                           console.log(res.data);

                        })
                        .catch(err => {
                            console.log(err);
                        });

    } */

  selectAll(e) {
    console.log("select all files");
    var searchDiv = document.getElementById("portfolio");
    var selBtn = document.getElementById("SA");
    //console.log(searchDiv);
    /* var cds= document.getElementById('currentDataSet');
         var fds = document.getElementById('fullDataSet');*/
    //console.log(fds);

    if (window.selectAll == 0) {
      selBtn.childNodes[0].text = "Deselect All";
      for (var i = 0; i < searchDiv.childNodes.length; i++) {
        if (
          searchDiv.childNodes[i].childNodes[1].className == window.eventclass
        ) {
          searchDiv.childNodes[i].childNodes[1].checked = "checked";
        }
      }
      window.selectAll = 1;
    } else if (window.selectAll == 1) {
      selBtn.childNodes[0].text = "Select All";
      for (var i = 0; i < searchDiv.childNodes.length; i++) {
        if (
          searchDiv.childNodes[i].childNodes[1].className == window.eventclass
        ) {
          searchDiv.childNodes[i].childNodes[1].checked = "";
        }
      }
      window.selectAll = 0;
    }
  }

  addImageToReport(e) {
    console.log("addImageToReport");
    var checkedArray = [];
    var userid = this.props.match.params.userid;
    var databaseid = this.state.dblink;
    var folder = window.eventclass;
    console.log(folder);
    $("input:checkbox[name=test]:checked").each(function () {
      checkedArray.push($(this).val());
      this.checked = false;
    });
    if (checkedArray.length > 0) {
      const data = {
        userid: userid,
        databaseid: databaseid,
        checkedArray: checkedArray,
        folder: folder,
      };
      console.log(data);
      axios
        .post(
          "https://web.mybiocalculus.com:5000/api/common/reCategorizeImg",
          data
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  filter(e) {
    var selected = e.currentTarget.id;
    //console.log('Main filter',selected);
    if (selected == "all") {
      $("#portfolio").children().show(1000);
      $(".filterMain a").removeClass("active");
      $("#" + e.currentTarget.id).addClass("active");
      window.eventclass = e.currentTarget.id;
    } else {
      $("#portfolio").children().hide(1000);
      var thisclass = "#portfolio ." + selected;
      $("#portfolio ." + selected).show(1000);
      $(".filterMain a").removeClass("active");
      $("#" + e.currentTarget.id + " a").addClass("active");
      window.eventclass = e.currentTarget.id;
    }
  }
  filter1(selected) {
    //console.log('Main filter1',selected);
    if (selected == "all") {
      $("#portfolio").children().show(1000);
      $(".filterMain a").removeClass("active");
      $("#" + selected).addClass("active");
      window.eventclass = selected;
    } else {
      $("#portfolio").children().hide(1000);
      var thisclass = "#portfolio ." + selected;
      $("#portfolio ." + selected).show(1000);
      $(".filterMain a").removeClass("active");
      $("#" + selected + " a").addClass("active");
      window.eventclass = selected;
    }
  }

  filter2(selected) {
    //console.log('Main filter2',selected);
    if (selected == "all") {
      $("#portfolio").children().show(1000);
      $(".filtersNewMain a").removeClass("active");
      $("." + selected).addClass("active");
      window.eventclass = selected;
    } else {
      $("#portfolio1").children().hide(1000);
      var thisclass = "#portfolio ." + selected;
      $("#portfolio1 ." + selected).show(1000);
      //$(".filtersNewMain a").removeClass('active');
      $("." + selected + " a").addClass("active");
      $("#" + "allImage" + " a").addClass("active");
      window.eventclass = selected;
    }
  }

  resumeVerify() {
    var userid = this.props.match.params.userid;
    window.resumeFlag = 1;
    /* console.log('top',$('#selWrap').position().top);
         console.log('top',$('#container20').position().top-($('#selWrap').position().top+53.6833333));*/

    axios
      .get(
        apiUrl +
          "api/patients/getResumeDetails?userid=" +
          this.props.match.params.userid
      )
      .then((res) => {
        console.log(res.data.stripvalue_resume);
        this.setState({
          databaseid: res.data.db_resume,
        });
        /* this.setState({
                     start: res.data.stripvalue_resume
                 });
                 this.setState({
                     end: res.data.stripvalue_resume + 4
                 });*/
        window.stripXValue =
          res.data.xvalue_resume - res.data.stripvalue_resume * 15000;
        window.container = res.data.stripvalue_resume;

        $("#selWrap").scrollTop(
          $("#container" + res.data.stripvalue_resume).position().top -
            $("#selWrap").position().top
        );
        //  $('#selWrap').scrollTop($('#span').offset().top);
        // this.renderResumeDataOnly(userid,res.data,1);
        //this.nextBtnLink(userid,res.data);
        //this.prevBtnLink(userid,res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  saveVerify() {
    var userid = this.props.match.params.userid;
    axios
      .get(
        apiUrl +
          "api/patients/saveChartDetails?userid=" +
          this.props.match.params.userid
      )
      .then((res) => {
        console.log(res.data.db_resume);
        this.setState({
          databaseid: res.data.db_resume,
        });
        this.setState({
          start: res.data.stripvalue_resume,
        });
        this.setState({
          databaseid: res.data.stripvalue_resume + 9,
        });
        this.renderNewChart(
          userid,
          res.data.db_resume,
          1,
          this.state.start,
          this.state.end
        );
        // this.renderResumeDataOnly(userid,res.data,1);
        //this.nextBtnLink(userid,res.data);
        //this.prevBtnLink(userid,res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  renderNewSmallCharts() {
    var j = this.state.start;
    var datasetMnt = j + 10;
    var start = this.state.start;
    var end = this.state.end;
    var userid = this.props.match.params.userid;

    //   console.log("Rendersmall chart");
    for (var i = 0; i < this.state.charts.length; i++) {
      this.state.charts[i].destroy();
    }

    var i = 0;
    this.state.charts = [];
    var dis = this;
    var charts = [];

    var myVar = setInterval(function () {
      charts[i] = new Highcharts.Chart("container" + j, {
        credits: {
          enabled: false,
        },
        chart: {
          height: 4 + "%",
          resetZoomButton: {
            theme: {
              display: "none",
            },
          },
          events: {
            click: function (e) {
              /* Do something with the event */
              clickFunction(e, this); // ...which means you can access it from inside the click handler function
            },
          },
          zoomType: "",
        },

        navigator: {
          enabled: false,
        },

        xAxis: [
          {
            min: 0,
            max: deviceFrequency,
            labels: {
              enabled: false,
            },
            visible: false,
          },
        ],
        yAxis: [
          {
            min: gainMin,
            max: gainMax,
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            minorTicks: false,
            labels: {
              enabled: false,
            },
            title: {
              enabled: false,
            },
          },
        ],
        rangeSelector: {
          enabled: false,
        },
        plotOptions: {
          series: {
            turboThreshold: 0,
          },
        },
        title: {
          text: "",
        },

        series: [
          {
            type: "coloredline",
            data: [],
            color: "#09ff08",
            allowPointSelect: false,
            animation: false,
            enableMouseTracking: false,
            lineWidth: 2,
            marker: {
              enabled: false,
            },
            shadow: false,
            stickyTracking: false,
            showInLegend: false,
          },
        ],
      });

      j = j + 1;
      i = i + 1;

      if (j > datasetMnt - 1) {
        clearInterval(myVar);
      }
    }, 1);

    this.setState({
      charts: charts,
    });

    var newThis = this,
      val;

    var databaseid = this.state.databaseid;
    var mainEcgArray,
      mainActivityArray = [];
    var result3,
      colorArray = [];
    var chart;

    var tonarr = [],
      rarr = [];

    // var parr = [],
    //     tarr = [],
    //     qarr = [],
    //     rarr = [],
    //     sarr = [],
    //     ponarr = [],
    //     poffarr = [],
    //     tonarr = [],
    //     toffarr = [],
    //     jarr = [];
    var peakColorArray = [];
    peakColorArray["Normal Sinus Rhythm"] = "#3D761D";
    peakColorArray["Sinus Tachycardia"] = "#00FFFF";
    peakColorArray["Sinus Bradycardia"] = "#FF9900";
    peakColorArray["PAC"] = "#0000FF";
    peakColorArray["PVC"] = "#FF00FF";
    peakColorArray["Ventricular Tachycardia"] = "#A64D79";
    peakColorArray["AFib"] = "#9900FF";
    peakColorArray["Sinus Pause"] = "#FF0000";
    peakColorArray["P Absent"] = "#bfbebb";
    peakColorArray["Irregular RR"] = "#7F6000";
    peakColorArray["Irregular PR"] = "#351C75";
    peakColorArray["Short QT"] = "#980000";
    peakColorArray["Prolonged QT"] = "#46BDC6";
    peakColorArray["Undiagnosed"] = "#FFD966";

    function chunkPeakArray(peakArray, start) {
      let presults = [];
      var minIndex = 0;
      /*if(window.tab=='event'){

               start=window.pageIndex;
            }
            else
            {
                start=this.state.start;
            }*/
      var breakVal = (start + 1) * deviceFrequency; //165000
      var z;
      var temRpeakindex;
      presults.push([]);

      for (var i = 0; i < peakArray.length; i++) {
        /*console.log('start',start);
                console.log(peakArray[i].rpeakindex);*/
        if (start * deviceFrequency < peakArray[i].rpeakindex) {
          if (peakArray[i].rpeakindex > breakVal) {
            presults.push([]);
            minIndex = minIndex + 1;
            breakVal = breakVal + deviceFrequency;
          }

          temRpeakindex =
            parseInt(peakArray[i].rpeakindex) -
            (minIndex + start) * deviceFrequency;
          presults[minIndex].push({
            x: temRpeakindex,
            y: parseFloat(0.8),
            color: peakColorArray[peakArray[i].arrhythmia],
            beat: parseInt(peakArray[i].indexcounter),
            hr: parseInt(peakArray[i].heartrate),
            rr: parseInt(peakArray[i].rr_interval),
            arrhythmia: peakArray[i].arrhythmia,
            peaktime: peakArray[i].peaktime,
          });
        }
      }
      return presults;
    }

    function chunkMarkerArray(markArrayX, markArrayY, start = 0) {
      var ppeakIndex = markArrayX.split(",");

      var ppeakAmp = markArrayY.split(",");
      let mresults = [];

      var minIndex = 0;
      var breakVal = (start + 1) * deviceFrequency;
      var z;
      var temindex;
      mresults.push([]);
      for (var i = 0; i < ppeakIndex.length; i++) {
        if (ppeakIndex[i] > start * deviceFrequency) {
          if (ppeakIndex[i] > breakVal) {
            mresults.push([]);
            minIndex = minIndex + 1;
            breakVal = breakVal + deviceFrequency;
          }
          temindex =
            parseInt(ppeakIndex[i]) - (minIndex + start) * deviceFrequency;
          mresults[minIndex].push([temindex, parseFloat(ppeakAmp[i])]);
        }
      }
      return mresults;
    }

    function clickFunction(event, that) {
      var containerId = that.renderTo.id; // got the Container ID
      window.container = containerId.replace("container", "");
      window.chartid =
        parseInt(containerId.replace("container", "")) - newThis.state.start; // container id stored in global variable
      window.stripXValue = event.chartX; // got the location in the x-axis

      /*************Remove Plotband***********************/
      if (window.beatArr.length > 0 && window.chartid != window.prevChart) {
        for (var i = 0; i < window.beatArr.length; i++) {
          var j = parseInt(window.container) * deviceFrequency;
          var xBeat = parseInt(window.beatArr[i]) - j;
          newThis.state.chart.xAxis[0].removePlotBand("plotband-" + xBeat);
        }
      }
      /*************Remove Plotband***********************/

      if (newThis.state.charts[window.chartid]) {
        val = newThis.state.charts[window.chartid].xAxis[0].toValue(
          event.chartX
        );
      }

      var sliceStart = window.chartid * deviceFrequency;
      var sliceEnd = sliceStart + deviceFrequency + 1;
      var sliceActStart = window.chartid * 60;
      var sliceActEnd = sliceActStart + 61;

      var ecgArray = newThis.state.mainEcgArray.slice(sliceStart, sliceEnd);
      var curActArray = newThis.state.mainActivityArray.slice(
        sliceActStart,
        sliceActEnd
      );

      if (val > ecgArray.length) {
        val = ecgArray.length - 500;
      }

      var acti = 125;
      var actarr = [];
      var colorArray = ["#98FB98", "#f1e4e4", "#f7e0a3", "#f09c67"];

      for (var i = 0; i < curActArray.length; i++) {
        actarr.push({
          x: acti,
          y: 0.5,
          color: colorArray[curActArray[i]],
        });
        acti = acti + 250;
      }

      newThis.state.chart.series[0].setData(ecgArray, false);
      newThis.state.chart.series[1].setData(actarr, false);
      newThis.state.chart.series[2].setData(
        newThis.state.result3[window.chartid],
        false
      );

      newThis.state.chart.series[5].update(
        {
          data: newThis.state.rarr[window.container],
        },
        false
      );

      if (newThis.state.charts[window.prevChart] != undefined) {
        if (
          newThis.state.charts[window.prevChart].draggablePlotLine != undefined
        ) {
          newThis.state.charts[window.prevChart].draggablePlotLine.destroy();
        }
      }
      var chart1 = that;

      window.prevChart = window.chartid;
      var lineWidth = 2;

      chart1.draggablePlotLine = chart1.renderer
        .rect(
          event.chartX -
            newThis.state.charts[window.chartid].xAxis[0].toPixels(25),
          10,
          50,
          40,
          5
        )
        .attr({
          "stroke-width": 2,
          opacity: 0.5,
          fill: "grey",
          zIndex: 3,
        })
        .add();

      if (newThis.state.charts[0].container.onmousemove != undefined) {
        newThis.state.charts[0].container.onmousemove = function (e) {
          return false;
        };
      }

      newThis.state.charts[window.chartid].container.onmousemove = function (
        e
      ) {
        if (chart1.draggablePlotLine != undefined) {
          if (chart1.draggablePlotLine.drag) {
            let normalizedEvent = chart1.pointer.normalize(e),
              extremes = {
                left: chart1.plotLeft,
                right: chart1.plotLeft + chart1.plotWidth,
              };

            if (e.chartX >= extremes.left && e.chartX <= extremes.right) {
              var pointValue = chart1.xAxis[0].toValue(e.chartX);
              if (pointValue - 500 <= 0) {
                newThis.state.chart.xAxis[0].setExtremes(
                  0,
                  pointValue + 2250,
                  true,
                  false
                );
              } else {
                newThis.state.chart.xAxis[0].setExtremes(
                  pointValue - 1125,
                  pointValue + 1125,
                  true,
                  false
                );
              }
              chart1.draggablePlotLine.attr({
                x: e.chartX,
              });
            }
          }
        }
      };

      newThis.state.charts[
        window.chartid
      ].draggablePlotLine.element.onmousedown = function () {
        chart1.draggablePlotLine.drag = true;
      };

      newThis.state.charts[window.chartid].draggablePlotLine.element.onmouseup =
        function () {
          chart1.draggablePlotLine.drag = false;
        };

      if (val - 500 <= 0) {
        newThis.state.chart.xAxis[0].setExtremes(0, val + 2250, true, false);
      } else {
        newThis.state.chart.xAxis[0].setExtremes(val - 1125, val + 1125);
      }

      /*************Add Plotband***********************/
      const closestArray = newThis.state.result3[window.chartid].map(
        (point) => point.x
      );
      const jBeat = parseInt(window.container) * deviceFrequency;

      // Find the closest beat
      const closest = closestArray.reduce((prev, curr) =>
        Math.abs(curr - val) < Math.abs(prev - val) ? curr : prev
      );

      const plotbandExists = window.beatArr.includes(closest + jBeat);

      if (!event.shiftKey) {
        if (!plotbandExists) {
          let plotband = {
            color: "#ff9999",
            from: closest - 100,
            to: closest + 100,
            id: "plotband-" + closest,
          };
          newThis.state.chart.xAxis[0].addPlotBand(plotband);
          window.beatArr.push(closest + jBeat);
        } else {
          const index = window.beatArr.indexOf(closest + jBeat);
          if (index > -1) {
            window.beatArr.splice(index, 1);
          }
          newThis.state.chart.xAxis[0].removePlotBand("plotband-" + closest);
        }
      } else {
        handleShiftKeyPlotbands(
          closest,
          jBeat,
          closestArray,
          newThis.state.chart
        );
      }

      /*************Add Plotband***********************/
    }

    function handleShiftKeyPlotbands(closest, jBeat, closestArray, chart) {
      let plotband = [];

      if (window.beatArr.length === 1) {
        let comStart, comEnd;
        if (closest > window.beatArr[0] - jBeat) {
          comEnd = closest;
          comStart = window.beatArr[0] - jBeat;
        } else {
          comStart = closest;
          comEnd = window.beatArr[0] - jBeat;
        }
        window.beatArr = [];
        for (let i = 0; i < closestArray.length; i++) {
          if (closestArray[i] >= comStart && closestArray[i] <= comEnd) {
            plotband.push({
              color: "#ff9999",
              from: closestArray[i] - 100,
              to: closestArray[i] + 100,
              id: "plotband-" + closestArray[i],
            });
            window.beatArr.push(closestArray[i] + jBeat);
          }
        }
        chart.update({ xAxis: { plotBands: plotband } });
      } else {
        resetPlotbands(closest, jBeat, chart);
      }
    }

    function resetPlotbands(closest, jBeat, chart) {
      chart.update({ xAxis: { plotBands: [] } });
      window.beatArr = [];
      let plotband = [
        {
          color: "#ff9999",
          from: closest - 100,
          to: closest + 100,
          id: "plotband-" + closest,
        },
      ];
      chart.update({ xAxis: { plotBands: plotband } });
      window.beatArr.push(closest + jBeat);
    }

    function setDataOnly(res, self) {
      /* console.log('Initial res',window.mainArray);
              console.log('start',self.state.start);
              console.log('end',self.state.end);*/

      var timeCounter = self.state.start;
      if (res != undefined) {
        var ind = 0;
        for (var i = 0; i < window.arrAxios.length; i++) {
          if (window.mainArray[window.arrAxios[i]].length == 0) {
            window.mainArray[window.arrAxios[i]].push(res[ind][0]);
            ind = ind + 1;
          }
        }
        res = [];
        for (var i = self.state.start; i <= self.state.end; i++) {
          res.push(window.mainArray[i][0]);
          kk = kk + 1;
        }
      } else {
        var kk = 0;
        var res = [];
        for (var i = self.state.start; i <= self.state.end; i++) {
          //console.log('i value',i);
          res.push(window.mainArray[i][0]);
          kk = kk + 1;
        }
      }

      //console.log('FInal res',window.mainArray);

      if (!self.preEcgValues) {
        self.preEcgValues = res;
        self.ecgValues = res.map((e) => e[0]).reduce((a, b) => a.concat(b));
        self.activityValues = res
          .map((e) => e[1])
          .reduce((a, b) => a.concat(b));
        self.peakValues = res.map((e) => e[2]).reduce((a, b) => a.concat(b));
      } else {
        if (self.state.scrollStatus === "next") {
          self.preEcgValues = self.preEcgValues.filter(
            (e, i) => i > self.state.limitScrollStart - 1
          );
          //console.log("preEcgValues",self.preEcgValues.length);

          self.preEcgValues = self.preEcgValues.concat(res);
        } else if (self.state.scrollStatus === "prev") {
          self.preEcgValues = self.preEcgValues.filter(
            (e, i) => i < self.state.limitScrollStart + 3
          );
          // console.log("preValues",self.preEcgValues.length);
          self.preEcgValues = res.concat(self.preEcgValues);
        } else {
          self.preEcgValues = window.mainArray[timeCounter].filter(
            (e, i) => i < 0
          );

          // console.log("preEcgValues",self.preEcgValues.length);
          self.preEcgValues = self.preEcgValues.concat(res);
          //console.log("preEcgValues",self.preEcgValues.length);
          //console.log("preEcgValues",self.preEcgValues);
        }

        //console.log("preEcgValues",self.preEcgValues.length);
        self.ecgValues = self.preEcgValues
          .map((e) => e[0])
          .reduce((a, b) => a.concat(b));
        self.activityValues = self.preEcgValues
          .map((e) => e[1])
          .reduce((a, b) => a.concat(b));
        self.peakValues = self.preEcgValues
          .map((e) => e[2])
          .reduce((a, b) => a.concat(b));
      }

      mainEcgArray = self.ecgValues;
      mainActivityArray = self.activityValues;

      self.setState({
        mainEcgArray: mainEcgArray,
      });
      self.setState({
        mainActivityArray: mainActivityArray,
      });
      result3 = chunkPeakArray(self.peakValues, self.state.start);

      self.setState({
        result3: result3,
      });
      //  console.log("state result",self.state.result3);
      window.result3 = result3;

      var starr = [],
        defarr = [];

      var i = 0;
      var t = 0;
      var j = 1;

      var stcolor = "09ff08",
        defcolour = "09ff08";

      var currentDataSet = [];
      var self = self;

      var beatInd = self.state.start;
      var myVar2 = setInterval(function () {
        var sliceStartI = i * deviceFrequency;
        var sliceEndI = sliceStartI + deviceFrequency + 1;

        currentDataSet = self.state.mainEcgArray.slice(sliceStartI, sliceEndI);

        if (self.state.result3[i]) {
          var time1 = document.createTextNode(
            self.state.result3[i][0].peaktime
          );

          document.getElementById("span" + timeCounter).innerHTML = "";
          document.getElementById("span" + timeCounter).appendChild(time1);
          timeCounter = timeCounter + 1;
        }
        var k = 0;
        /***********color***********/

        starr.push([]);
        defarr.push([]);
        for (var j = 0; j < currentDataSet.length; j++) {
          if (
            self.state.result3[i][k] != null &&
            (j + 70 == self.state.result3[i][k].x ||
              self.state.result3[i][k].x < 70)
          ) {
            stcolor = "09ff08";
            defcolour = "09ff08";

            if (self.state.result3[i][k].arrhythmia == "Normal Sinus Rhythm") {
              stcolor = self.state.result3[i][k].color;
            } else if (
              self.state.result3[i][k].arrhythmia == "Sinus Tachycardia"
            ) {
              stcolor = self.state.result3[i][k].color;
            } else if (
              self.state.result3[i][k].arrhythmia == "Sinus Bradycardia"
            ) {
              stcolor = self.state.result3[i][k].color;
            } else if (self.state.result3[i][k].arrhythmia == "Sinus Pause") {
              stcolor = self.state.result3[i][k].color;
            } else if (self.state.result3[i][k].arrhythmia == "PAC") {
              stcolor = self.state.result3[i][k].color;
            } else if (self.state.result3[i][k].arrhythmia == "PVC") {
              stcolor = self.state.result3[i][k].color;
            } else if (
              self.state.result3[i][k].arrhythmia == "Ventricular Tachycardia"
            ) {
              stcolor = self.state.result3[i][k].color;
            } else if (self.state.result3[i][k].arrhythmia == "AFib") {
              stcolor = self.state.result3[i][k].color;
            } else if (self.state.result3[i][k].arrhythmia == "P Absent") {
              stcolor = self.state.result3[i][k].color;
            } else if (self.state.result3[i][k].arrhythmia == "Irregular RR") {
              stcolor = self.state.result3[i][k].color;
            } else if (self.state.result3[i][k].arrhythmia == "Irregular PR") {
              stcolor = self.state.result3[i][k].color;
            } else if (self.state.result3[i][k].arrhythmia == "Short QT") {
              stcolor = self.state.result3[i][k].color;
            } else if (self.state.result3[i][k].arrhythmia == "Prolonged QT") {
              stcolor = self.state.result3[i][k].color;
            } else if (self.state.result3[i][k].arrhythmia == "Undiagnosed") {
              stcolor = self.state.result3[i][k].color;
            }

            if (k < self.state.result3[i].length - 1) {
              k++;
            }
          }

          defarr[i].push({
            x: j,
            y: parseFloat(currentDataSet[j]),
            segmentColor: "#09ff08",
          });
          starr[i].push({
            x: j,
            y: parseFloat(currentDataSet[j]),
            segmentColor: stcolor,
          });
        }

        /***********color**********/

        /**********setStateColor*********/

        self.setState({
          starr: starr,
        });

        self.setState({
          defarr: defarr,
        });

        /**********setStateColor*********/
        if (self.state.charts[i] != undefined) {
          if (window.tab != "Events") {
            if (window.sel == "Sinus Tachycardia") {
              self.state.charts[i].series[0].setData(starr[i], true);
            } else {
              self.state.charts[i].series[0].setData(starr[i], true);
            }
          } else {
            if (window.response == "Sinus Tachycardia") {
              self.state.charts[i].series[0].setData(starr[i], true);
            } else {
              self.state.charts[i].series[0].setData(starr[i], true);
            }
          }
        }
        if (window.mainArray[beatInd].length == 0) {
          window.mainArray.splice(beatInd, 1, defarr[i]);
        }
        //console.log('MainArray',window.mainArray);
        i = i + 1;
        j = j + 1;
        beatInd = beatInd + 1;
        if (i > 9) {
          if (window.prev_tab == "Events") {
            self.updateMainChartODC(self.state.start, self.state.end);
            // window.prev_tab=window.tab;
            window.tab = "Beats";
            window.prev_tab = "Beats";
          }
          if (
            self.props.match.params.start != undefined &&
            window.diaryFlag == 0
          ) {
            window.diaryFlag = 1;
            self.updateMainChartODC(
              self.props.match.params.start,
              self.props.match.params.end
            );
          }
          clearInterval(myVar2);
        }
      }, 1);
    }
    window.arrAxios = [];
    var count = 0;
    //  console.log('MainArray',window.mainArray);
    for (var ii = start; ii <= end; ii++) {
      if (window.mainArray[ii].length == 0) {
        window.arrAxios.push(ii);
      }
    }
    var newArrAxios = [];

    if (window.arrAxios.length > 0) {
      var newArrAxiosRes = [];
      this.getRepos(userid, databaseid).then((value) => {
        for (var ij = 0; ij < window.arrAxios.length; ij++) {
          newArrAxiosRes[ij] = value[ij].data;
        }
        setDataOnly(newArrAxiosRes, this);
        if (window.resumeFlag == 0) {
          this.renderResumeChart(
            userid,
            databaseid,
            window.container,
            window.stripXValue
          );
          window.resumeFlag = 0;
        }
      });
    } else {
      setDataOnly(newArrAxios, this);
    }
  }

  async getRepos(userid, databaseid) {
    var ops = [];
    for (var ii = 0; ii < window.arrAxios.length; ii++) {
      let op = axios.get(
        apiUrl +
          "api/common/getEcgNewData?start=" +
          window.arrAxios[ii] +
          "&end=" +
          window.arrAxios[ii] +
          "&userid=" +
          userid +
          "&databaseid=" +
          databaseid
      );
      ops.push(op);
    }
    let res = await axios.all(ops);

    return res;
  }
  generateApiUrl(userid, databaseid, index) {
    return (
      apiUrl +
      "api/common/getEcgNewData?start=" +
      index +
      "&end=" +
      index +
      "&userid=" +
      userid +
      "&databaseid=" +
      databaseid
    );
  }

  async executeBatchCalls(userid, databaseid, start, end) {
    var ops = [];
    for (let ii = start; ii < end; ii++) {
      let url = this.generateApiUrl(userid, databaseid, ii);
      let op = axios.get(url);
      // console.log("API call for index: " + ii);
      ops.push(op);
    }
    return await axios.all(ops);
  }
  async getData(userid, databaseid) {
    let batchSize = Math.ceil((window.sc - 10) / 4); // Divide into 4 batches
    let results = [];

    for (let batch = 0; batch < 4; batch++) {
      let start = 10 + batch * batchSize;
      let end = Math.min(start + batchSize, window.sc);
      let batchResults = await this.executeBatchCalls(
        userid,
        databaseid,
        start,
        end
      );
      results.push(...batchResults);
    }
    this.fetchminmaxHr();
    this.getverifierName();

    return results;
  }

  callDiaryDataAPIs(db, userid, start, end, stripX) {
    var userid = userid;
    var databaseid = db;

    this.setState({ start: start, end: end });
    var stripThis = this;

    worker13.postMessage({
      name: "getStripCount",
      url:
        apiUrl +
        "api/common/getStripCount?userid=" +
        userid +
        "&databaseid=" +
        databaseid,
    });
    worker13.onmessage = function (event) {};
    worker13.addEventListener("message", function (res) {
      window.sc = parseInt(res.data);
      if (window.sc != 0) {
        for (var i = 0; i < window.sc; i++) {
          window.mainArray.push([]);
        }

        if (typeof databaseid === "undefined") {
          databaseid = "0";
          worker14.postMessage({
            name: "getLatestDatabaseId",
            url: apiUrl + "api/common/getLatestDatabaseId?userid=" + userid,
          });
          worker14.onmessage = function (event) {};
          worker14.addEventListener("message", function (res) {
            stripThis.setState({
              databaseid: res.data,
            });
            stripThis.renderNewChart(userid, res.data, 1);
          });
          // axios
          //     .get(apiUrl + 'api/common/getLatestDatabaseId?userid=' + userid)
          //     .then(res => {
          //         stripThis.setState({
          //             databaseid: res.data
          //         });
          //         stripThis.renderNewChart(userid, res.data, 1);
          //         //this.nextBtnLink(userid,res.data);
          //         //this.prevBtnLink(userid,res.data);
          //     })
          //     .catch(err => {
          //         console.log(err);
          //     });
        } else {
          stripThis.setState({
            databaseid: databaseid,
          });

          stripThis.renderNewChart(
            userid,
            databaseid,
            1,
            stripThis.state.start,
            stripThis.state.end
          );
          //this.nextBtnLink(userid,databaseid);
          //this.prevBtnLink(userid,databaseid);
        }
      }
    });
    //     axios
    //         .get(apiUrl+'api/common/getStripCount?userid=' + userid + '&databaseid=' + databaseid)
    //         .then(res => {
    //              window.sc = parseInt(res.data);
    //             if(window.sc!=0)
    //             {

    //             for (var i = 0; i < window.sc; i++) {
    //                 window.mainArray.push([]);
    //             }

    //     if (typeof databaseid === 'undefined') {
    //         databaseid = "0";
    //         axios
    //             .get(apiUrl + 'api/common/getLatestDatabaseId?userid=' + userid)
    //             .then(res => {
    //                 this.setState({
    //                     databaseid: res.data
    //                 });
    //                 this.renderNewChart(userid, res.data, 1);
    //                 //this.nextBtnLink(userid,res.data);
    //                 //this.prevBtnLink(userid,res.data);
    //             })
    //             .catch(err => {
    //                 console.log(err);
    //             });
    //     } else {
    //         this.setState({
    //             databaseid: databaseid
    //         });

    //         this.renderNewChart(userid, databaseid, 1, this.state.start, this.state.end);
    //         //this.nextBtnLink(userid,databaseid);
    //         //this.prevBtnLink(userid,databaseid);
    //     }
    // }
    //         })
    //         .catch(err => {
    //             console.log(err)
    //         });

    var setPatientReportStatus = (res) => {
      this.state.patientReportStatus = res;
      this.setState({
        reportStatus: res.data,
      });
    };
    if (Object.keys(this.state.patientReportStatus).length > 0) {
      setPatientReportStatus(this.state.patientReportStatus);
    } else {
      axios
        .get(
          apiUrl +
            "api/patients/patientReportStatus?userid=" +
            this.props.match.params.userid
        )
        .then(setPatientReportStatus)
        .catch((err) => {
          console.log(err);
        });
    }
    var setPatientMenuTree = (res) => {
      this.state.patientMenuTree = res;
      this.setState({
        treemenu: res.data,
      });
    };

    if (Object.keys(this.state.patientMenuTree).length > 0) {
      setPatientMenuTree(this.state.patientMenuTree);
    } else {
      var pmenu = this;
      worker.postMessage({
        name: "patinentmenutree",
        url:
          apiUrl +
          "api/common/patinentmenutreeNew?userid=" +
          this.props.match.params.userid,
      });
      worker.onmessage = function (event) {};
      worker.addEventListener("message", function (res) {
        pmenu.state.patientMenuTree = res;
        pmenu.setState({
          treemenu: res.data,
        });
      });
      // axios
      //     .get(apiUrl + 'api/common/patinentmenutreeNew?userid=' + this.props.match.params.userid)
      //     .then(
      //         setPatientMenuTree
      //     )
      //     .catch(err => {
      //         console.log(err);
      //     });
    }

    if (this.state.reportLinkStatus == 0) {
      axios
        .get(
          "https://web.mybiocalculus.com:5000/api/common/getReport?userid=" +
            this.props.match.params.userid
        )
        .then((res) => {
          this.state.reportLinkStatus = 1;
          if (res.data != "") {
            this.setState({
              reoprtLink: res.data,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    this.checkFullVerify();
  }
  callDataAPIs() {
    var userid = this.props.match.params.userid;
    var databaseid = this.props.match.params.databaseid;
    var stripThis = this;
    worker13.postMessage({
      name: "getStripCount",
      url:
        apiUrl +
        "api/common/getStripCount?userid=" +
        userid +
        "&databaseid=" +
        databaseid,
    });
    worker13.onmessage = function (event) {};
    worker13.addEventListener("message", function (res) {
      window.sc = parseInt(res.data);
      if (window.sc != 0) {
        for (var i = 0; i < window.sc; i++) {
          window.mainArray.push([]);
        }

        if (typeof databaseid === "undefined") {
          databaseid = "0";
          worker14.postMessage({
            name: "getLatestDatabaseId",
            url: apiUrl + "api/common/getLatestDatabaseId?userid=" + userid,
          });
          worker14.onmessage = function (event) {};
          worker14.addEventListener("message", function (res) {
            stripThis.setState({
              databaseid: res.data,
            });
            stripThis.renderNewChart(userid, res.data, 1);
          });
          // axios
          //     .get(apiUrl + 'api/common/getLatestDatabaseId?userid=' + userid)
          //     .then(res => {
          //         stripThis.setState({
          //             databaseid: res.data
          //         });
          //         stripThis.renderNewChart(userid, res.data, 1);
          //         //this.nextBtnLink(userid,res.data);
          //         //this.prevBtnLink(userid,res.data);
          //     })
          //     .catch(err => {
          //         console.log(err);
          //     });
        } else {
          stripThis.setState({
            databaseid: databaseid,
          });

          stripThis.renderNewChart(
            userid,
            databaseid,
            1,
            stripThis.state.start,
            stripThis.state.end
          );
          //this.nextBtnLink(userid,databaseid);
          //this.prevBtnLink(userid,databaseid);
        }
      }
    });
    //     axios
    //         .get(apiUrl+'api/common/getStripCount?userid=' + userid + '&databaseid=' + databaseid)
    //         .then(res => {
    //              window.sc = parseInt(res.data);
    //             if(window.sc!=0)
    //             {

    //             for (var i = 0; i < window.sc; i++) {
    //                 window.mainArray.push([]);
    //             }

    //     if (typeof databaseid === 'undefined') {
    //         databaseid = "0";
    //         axios
    //             .get(apiUrl + 'api/common/getLatestDatabaseId?userid=' + this.props.match.params.userid)
    //             .then(res => {
    //                 this.setState({
    //                     databaseid: res.data
    //                 });
    //                 this.renderNewChart(userid, res.data, 1);
    //                 //this.nextBtnLink(userid,res.data);
    //                 //this.prevBtnLink(userid,res.data);
    //             })
    //             .catch(err => {
    //                 console.log(err);
    //             });
    //     } else {
    //         this.setState({
    //             databaseid: databaseid
    //         });

    //         this.renderNewChart(userid, databaseid, 1, this.state.start, this.state.end);
    //           if(this.props.match.params.start!=undefined)
    //           {
    //           document.getElementById('chart_tab_2').click();
    //         }
    //         //this.nextBtnLink(userid,databaseid);
    //         //this.prevBtnLink(userid,databaseid);

    //     }
    // }
    // else{
    //     //alert('Sorry!!!The data is Backed Up')

    // }

    //         })
    //         .catch(err => {
    //             console.log(err)
    //         });

    var setPatientReportStatus = (res) => {
      this.state.patientReportStatus = res;
      this.setState({
        reportStatus: res.data,
      });
    };
    if (Object.keys(this.state.patientReportStatus).length > 0) {
      setPatientReportStatus(this.state.patientReportStatus);
    } else {
      axios
        .get(
          apiUrl +
            "api/patients/patientReportStatus?userid=" +
            this.props.match.params.userid
        )
        .then(setPatientReportStatus)
        .catch((err) => {
          console.log(err);
        });
    }
    var setPatientMenuTree = (res) => {
      // console.log("ressss", res);
      this.state.patientMenuTree = res;
      this.setState({
        treemenu: res.data,
      });
      // console.log("treemenu", this.state.treemenu);
    };
    if (Object.keys(this.state.patientMenuTree).length > 0) {
      setPatientMenuTree(this.state.patientMenuTree);
    } else {
      var pmenu = this;
      worker6.postMessage({
        name: "patinentmenutree",
        url:
          apiUrl +
          "api/common/patinentmenutreeNew?userid=" +
          this.props.match.params.userid,
      });
      worker6.onmessage = function (event) {};
      worker6.addEventListener("message", function (res) {
        pmenu.state.patientMenuTree = res;
        pmenu.setState({
          treemenu: res.data,
        });
      });
      // axios
      //     .get(apiUrl + 'api/common/patinentmenutreeNew?userid=' + this.props.match.params.userid)
      //     .then(
      //         setPatientMenuTree
      //     )
      //     .catch(err => {
      //         console.log(err);
      //     });
    }

    if (this.state.reportLinkStatus == 0) {
      axios
        .get(
          "https://web.mybiocalculus.com:5000/api/common/getReport?userid=" +
            this.props.match.params.userid
        )
        .then((res) => {
          this.state.reportLinkStatus = 1;
          if (res.data != "") {
            this.setState({
              reoprtLink: res.data,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    this.checkFullVerify();
  }

  fetchSummary(e) {
    window.prev_tab = window.tab;
    window.tab = "Summary";
    var mmhrf = this;
    if (this.props.match.params.start == undefined) {
      document.getElementById("chart_tab_1").innerHTML =
        '<img id="eventsimg" src="../../dist/img/Events.png"/>';
      document.getElementById("chart_tab_2").innerHTML =
        '<img id="beatsimg" src="../../dist/img/Beats.png"/>';
      // document.getElementById("chart_tab_3").innerHTML =
      //   '<img id="summaryimg" src="../../dist/img/Summary_White.png"/>';
      //document.getElementById("chart_tab_4").innerHTML="<img id=\"heartrateimg\" src=\"../../dist/img/HeartRate.png\"/>";
      document.getElementById("chart_tab_5").innerHTML =
        '<img id="selectimg" src="../../dist/img/SelectedEvents.png"/>';
    } else if (this.props.match.params.start != undefined) {
      document.getElementById("chart_tab_1").innerHTML =
        '<img id="eventsimg" src="../../../../../dist/img/Events.png"/>';
      document.getElementById("chart_tab_2").innerHTML =
        '<img id="beatsimg" src="../../../../../dist/img/Beats.png"/>';
      // document.getElementById("chart_tab_3").innerHTML =
      //   '<img id="summaryimg" src="../../../../../dist/img/Summary_White.png"/>';
      //document.getElementById("chart_tab_4").innerHTML="<img id=\"heartrateimg\" src=\"../../../../../dist/img/HeartRate.png\"/>";
      document.getElementById("chart_tab_5").innerHTML =
        '<img id="selectimg" src="../../../../../dist/img/SelectedEvents.png"/>';
    }

    var databaseid = this.props.match.params.databaseid;
    axios
      .get(
        apiUrl +
          "api/common/getAllPeakValues?userid=" +
          this.props.match.params.userid +
          "&databaseid=" +
          databaseid
      )
      .then((res) => {
        if (res.data != null) {
          this.setState({
            summaryData: res.data,
          });
          console.log("summary data", this.state.summaryData);
          console.log("min max data", this.state.minMaxHrDataCurr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    var wrk5this = this;
    worker5.postMessage({
      name: "getMinMaxHeartrateCurrent",
      url:
        apiUrl +
        "api/common/getMinMaxHeartrateCurrent?userid=" +
        this.props.match.params.userid +
        "&databaseid=" +
        databaseid,
    });
    worker5.onmessage = function (event) {};
    worker5.addEventListener("message", function (res) {
      if (res.data != null) {
        wrk5this.setState({
          minMaxHrDataCurr: res.data,
        });
      }
    });
    // workersum2.postMessage({ name:'getMinMaxHeartrateFull',url: apiUrl+'api/common/getMinMaxHeartrateFull?userid='+this.props.match.params.userid});
    // workersum2.onmessage = function (event) {};
    // workersum2.addEventListener("message", function (res) {
    //     if (res.data != null) {
    //         wrk5this.setState({
    //             minMaxHrDataFull: res.data
    //         });
    //     }
    // });
    worker11.postMessage({
      name: "getHeartRates",
      url:
        apiUrl +
        "api/common/getHeartRates?userid=" +
        this.props.match.params.userid +
        "&databaseid=" +
        databaseid,
    });
    worker11.onmessage = function (event) {};
    worker11.addEventListener("message", function (res) {
      wrk5this.setState({
        heartRatesData: res.data,
      });
    });
    worker8.postMessage({
      name: "details",
      url:
        apiUrl +
        "api/patients/details?userid=" +
        this.props.match.params.userid,
    });
    worker8.onmessage = function (event) {};
    worker8.addEventListener("message", function (res) {
      wrk5this.setState({
        patientwrkdetails: res.data,
      });
    });
    worker9.postMessage({
      name: "getPatientTotalDetails",
      url:
        apiUrl +
        "api/common/getPatientTotalDetails?userid=" +
        this.props.match.params.userid,
    });
    worker9.onmessage = function (event) {};
    worker9.addEventListener("message", function (res) {
      wrk5this.setState({
        patientwrktotaldetails: res.data,
      });
    });
    worker10.postMessage({
      name: "getdailydeatils",
      url:
        apiUrl +
        "api/common/getdailydeatils?userid=" +
        this.props.match.params.userid,
    });
    worker10.onmessage = function (event) {};
    worker10.addEventListener("message", function (res) {
      wrk5this.setState({
        patientwrkdailydetails: res.data,
      });
    });
    worker12.postMessage({
      name: "getArrhythmiaCount",
      url:
        apiUrl +
        "api/common/getArrhythmiaCount?userid=" +
        this.props.match.params.userid,
    });
    worker12.onmessage = function (event) {};
    worker12.addEventListener("message", function (res) {
      wrk5this.setState({
        arrCount: res.data,
      });
    });
  }
  getverifierName(e) {
    const databaseid = this.props.match.params.databaseid; // Get databaseid from route params
    const userid = this.props.match.params.userid; // Get userid from route params

    if (!userid || !databaseid) {
      console.error("Both userid and databaseid are required");
      return;
    }

    axios
      .get(`${apiUrl}api/common/verifierName`, {
        params: {
          userid: userid,
          databaseid: databaseid,
        },
      })
      .then((response) => {
        // console.log("Verifier Name Data:", response.data);
        // Update the component state or handle the data as needed
        this.setState({ verifierNameData: response.data });
        // console.log("verifiername", this.state.verifierNameData);
      })
      .catch((error) => {
        console.error("Error fetching verifier name:", error);
      });
  }

  fetchminmaxHr(e) {
    const databaseid = this.props.match.params.databaseid;
    const userid = this.props.match.params.userid;
    const apiBase = apiUrl + "api/common/";

    // Fetch summary data
    // axios.get(`${apiBase}getAllPeakValues?userid=${userid}&databaseid=${databaseid}`)
    //   .then((res) => {
    //     if (res.data != null) {
    //       this.setState({ summaryData: res.data });
    //       console.log('summary data', this.state.summaryData);
    //       console.log('min max data', this.state.minMaxHrDataCurr);
    //     }
    //   })
    //   .catch((err) => console.log(err));

    // Fetch min/max heart rate current data
    axios
      .get(
        `${apiBase}getMinMaxHeartrateCurrent?userid=${userid}&databaseid=${databaseid}`
      )
      .then((res) => {
        if (res.data != null) {
          this.setState({ minMaxHrDataCurr: res.data }, () => {
            const {
              afib,
              ipr,
              irr,
              pac,
              pvc,
              st,
              sp,
              nsr,
              pqt,
              pabsent,
              sqt,
              vt,
              sb,
            } = this.state.minMaxHrDataCurr;

            const setMinMaxHeartRates = (category, name) => {
              if (category && category.length > 0) {
                const heartRates = category.map((item) => item.heartrate);
                const maxHeartRate = Math.max(...heartRates);
                const minHeartRate = Math.min(...heartRates);

                // console.log(`${name} heart rates:`, heartRates);
                // console.log(`Max ${name} heart rate:`, maxHeartRate);
                // console.log(`Min ${name} heart rate:`, minHeartRate);

                // Dynamically set state for each category’s min and max
                this.setState({
                  [`${name}MinHr`]: minHeartRate,
                  [`${name}MaxHr`]: maxHeartRate,
                });
              } else {
                // console.log(`${name} has no heart rate data.`);
                this.setState({
                  [`${name}MinHr`]: null,
                  [`${name}MaxHr`]: null,
                });
              }
            };

            setMinMaxHeartRates(afib, "afib");
            setMinMaxHeartRates(ipr, "ipr");
            setMinMaxHeartRates(irr, "irr");
            setMinMaxHeartRates(pac, "pac");
            setMinMaxHeartRates(pvc, "pvc");
            setMinMaxHeartRates(st, "st");
            setMinMaxHeartRates(sp, "sp");
            setMinMaxHeartRates(nsr, "nsr");
            setMinMaxHeartRates(pqt, "pqt");
            setMinMaxHeartRates(pabsent, "pabsent");
            setMinMaxHeartRates(sqt, "sqt");
            setMinMaxHeartRates(vt, "vt");
            setMinMaxHeartRates(sb, "sb");
          });
        }
      })
      .catch((err) => console.log(err));

    // Fetch heart rates data
    // axios.get(`${apiBase}getHeartRates?userid=${userid}&databaseid=${databaseid}`)
    //   .then((res) => {
    //     if (res.data != null) {
    //       this.setState({ heartRatesData: res.data });
    //       console.log('heartreate:',this.state.heartRatesData)
    //     }
    //   })
    //   .catch((err) => console.log(err));

    // Fetch patient details
    // axios.get(apiUrl + `api/patients/details?userid=${userid}`)
    //   .then((res) => {
    //     if (res.data != null) {
    //       console.log('patientdetails:', res.data)
    //       // this.setState({ patientwrkdetails: res.data });
    //     }
    //   })
    //   .catch((err) => console.log(err));

    // Fetch patient total details
    // axios.get(`${apiBase}getPatientTotalDetails?userid=${userid}`)
    //   .then((res) => {
    //     if (res.data != null) {
    //       this.setState({ patientwrktotaldetails: res.data });
    //     }
    //   })
    //   .catch((err) => console.log(err));

    // Fetch daily patient details
    // axios.get(`${apiBase}getdailydeatils?userid=${userid}`)
    //   .then((res) => {
    //     if (res.data != null) {
    //       this.setState({ patientwrkdailydetails: res.data });
    //     }
    //   })
    //   .catch((err) => console.log(err));

    // Fetch arrhythmia count
    // axios.get(`${apiBase}getArrhythmiaCount?userid=${userid}`)
    //   .then((res) => {
    //     if (res.data != null) {
    //       this.setState({ arrCount: res.data });
    //       // console.log('arr count :!!!!!!',this.state.arrCount)
    //     }
    //   })
    //   .catch((err) => console.log(err));
  }

  selectedEvents(e) {
    window.prev_tab = window.tab;
    window.tab = "Selected Events";
    if (this.props.match.params.start == undefined) {
      document.getElementById("chart_tab_1").innerHTML =
        '<img id="eventsimg" src="../../dist/img/Events.png"/>';
      document.getElementById("chart_tab_2").innerHTML =
        '<img id="beatsimg" src="../../dist/img/Beats.png"/>';
      // document.getElementById("chart_tab_3").innerHTML =
      //   '<img id="summaryimg" src="../../dist/img/Summary.png"/>';
      //document.getElementById("chart_tab_4").innerHTML="<img id=\"heartrateimg\" src=\"../../dist/img/HeartRate.png\"/>";
      document.getElementById("chart_tab_5").innerHTML =
        '<img id="selectimg" src="../../dist/img/SelectedEventsSelected.png"/>';
    } else if (this.props.match.params.start != undefined) {
      document.getElementById("chart_tab_1").innerHTML =
        '<img id="eventsimg" src="../../../../../dist/img/Events.png"/>';
      document.getElementById("chart_tab_2").innerHTML =
        '<img id="beatsimg" src="../../../../../dist/img/Beats.png"/>';
      // document.getElementById("chart_tab_3").innerHTML =
      //   '<img id="summaryimg" src="../../../../../dist/img/Summary.png"/>';
      //document.getElementById("chart_tab_4").innerHTML="<img id=\"heartrateimg\" src=\"../../../../../dist/img/HeartRate.png\"/>";
      document.getElementById("chart_tab_5").innerHTML =
        '<img id="selectimg" src="../../../../../dist/img/SelectedEventsSelected.png"/>';
    }

    var datasets = [];
    var self = this;
    var testArr = {};

    var testArrNew = {};
    worker.postMessage({
      name: "patinentmenutree",
      url:
        apiUrl +
        "api/common/patinentmenutree?userid=" +
        this.props.match.params.userid,
    });
    worker.onmessage = function (event) {};
    worker.addEventListener("message", function (res) {
      datasets = res.data;

      self.getRepos1(self.props.match.params.userid, datasets).then((value) => {
        for (var ij = 0; ij < datasets.length; ij++) {
          if (value[ij].data[0].length != 0) {
            var strdat = "";

            //console.log('testDatasets',value[ij].data);
            var keys = Object.keys(value[ij].data[0]);

            for (var ik = 0; ik < keys.length; ik++) {
              if (testArr[keys[ik]] != undefined) {
                var strapp =
                  testArr[keys[ik]] + "," + value[ij].data[0][keys[ik]];
                testArr[keys[ik]] = strapp.split(",");
              } else {
                //console.log('testDatasets',value[ij].data[1]);
                testArr[keys[ik]] = value[ij].data[0][keys[ik]];
              }
            }
          }
        }
        self.setState({
          imagess: testArr,
        });
        window.filterId2 = Object.keys(testArr)[0];

        self.filter2(window.filterId2);
      });
    });
  }
  async getRepos1(userid, datasets) {
    var ops = [];

    for (var ii = 0; ii < datasets.length; ii++) {
      let op = axios.get(
        "https://web.mybiocalculus.com:3002/api/common/getselectedfolderfiles?userid=" +
          this.props.match.params.userid +
          "&databaseid=" +
          datasets[ii]
      );
      ops.push(op);
    }
    let res = await axios.all(ops);
    return res;
  }
  async getReposNew(userid, datasets) {
    var ops = [];
    let op = axios.get(
      "https://web.mybiocalculus.com:3002/api/common/getselectedfolderfilesMax?userid=" +
        this.props.match.params.userid
    );
    ops.push(op);
    let res = await axios.all(ops);
    return res;
  }

  superImposeData(e) {
    if (window.confirm("Do you want to continue?")) {
      e.preventDefault();

      var userid = this.props.match.params.userid;
      var databaseid = this.props.match.params.databaseid;

      const config = {
        beat_order: this.state.beat_order,
        window_size: this.state.window_size,
        threshold: this.state.threshold,
        newArrhy: this.state.newArrhy,
        full_current: this.state.full_current,

        // valueList:this.state.valueList,
      };
      const valueList = this.state.valueList;
      const val_in = this.state.arrythymia_array;
      const data_input = JSON.stringify(val_in);
      const serializedValueList = JSON.stringify(valueList);
      const encodedValueList = encodeURIComponent(serializedValueList);

      // console.log("User ID:", userid);
      // console.log("Database ID:", databaseid);
      // console.log("Beat Order:", config.beat_order);
      // console.log("Window Size:", config.window_size);

      axios
        .get(
          `https://web.mybiocalculus.com/html/welcome/superimpose/?userid=${userid}&databaseid=${databaseid}&beat_order=${config.beat_order}&window_size=${config.window_size}&threshold=${config.threshold}&valueList=${data_input}&newArrhy=${config.newArrhy}&full_current=${config.full_current}`
        )
        .then((res) => {
          this.setState({ responseMessage: res.data });
          alert("Done");
          // window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            responseMessage: "An error occurred while processing your request.",
          });
        });
    }
  }

  captureImage(e) {
    e.preventDefault();

    if (window.confirm("Do you want to capture?")) {
      $(".closePopup").click();
      this.enableKey();
      var beats = window.beatArr;
      var databaseid = this.props.match.params.databaseid;
      var userid = this.props.match.params.userid;
      var last_beat = beats[beats.length - 1];
      const config = {
        stripcount: this.state.stripcount,
        labelinfo: this.state.labelinfo,
        newName: this.state.newName,
        sel_arr: this.state.sel_arr,
        arr_list: this.state.arr_list,
      };
      // console.log('https://web.mybiocalculus.com/html/Welcome/captureImage/' + userid + '/' + databaseid + '/' + last_beat+'/'+ config.stripcount+'/'+config.labelinfo+'/'+config.sel_arr+'/'+config.arr_list+'/'+config.newName);

      if (!last_beat) {
        return;
      } else {
        axios
          .get(
            "https://web.mybiocalculus.com/html/Welcome/captureImage/" +
              userid +
              "/" +
              databaseid +
              "/" +
              last_beat +
              "/" +
              config.stripcount +
              "/" +
              config.labelinfo +
              "/" +
              config.sel_arr +
              "/" +
              config.arr_list +
              "/" +
              config.newName
          )
          .then((res) => {
            if (res.data != null) {
              console.log(res.data);
              alert("Captured");
            } else {
              console.log("nothing");
              alert("Error in capture Image");
            }
          })
          .catch((err) => {
            console.log(err);
            alert("Error in capture Image");
          });

        var minus_val = window.container * deviceFrequency;
        for (var i = 0; i < beats.length; i++) {
          this.state.chart.xAxis[0].removePlotBand(
            "plotband-" + (beats[i] - minus_val)
          );
        }
        window.beatArr = [];
      }
    }
  }

  quickRecat(e) {
    e.preventDefault();
    // this.setState({showModalSpinner:true})

    if (
      window.confirm(`Do you want to Proceed With ${this.state.fullCurrent}`)
    ) {
      $(".closePopup").click();
      this.enableKey();
      var databaseid = this.props.match.params.databaseid;
      var userid = this.props.match.params.userid;
      const config = {
        hrgt: this.state.hrgt,
        hrlt: this.state.hrlt,
        beatgt: this.state.beatgt,
        beatlt: this.state.beatlt,
        checkArr: this.state.checkArr,
        qRecatArr: this.state.qRecatArr,
        newArr: this.state.newArr,
        fullCurrent: this.state.fullCurrent,
        lowr: this.state.lowr,
        highr: this.state.highr,

        prev_arh: this.state.prev_arh,
        next_arh: this.state.next_arh,
        low_2_high: this.state.low_2_high,
        high_2_high: this.state.high_2_high,
      };
      console.log("finding.................", this.state.selectedstarttime);
      // console.log('https://web.mybiocalculus.com/html/Welcome/captureImage/' + userid + '/' + databaseid + '/' + last_beat+'/'+ config.stripcount+'/'+config.labelinfo+'/'+config.sel_arr+'/'+config.arr_list+'/'+config.newName);
      axios

        .get(
          "https://web.mybiocalculus.com/html/Welcome/quick_recatnew_test/" +
            userid +
            "/" +
            databaseid +
            "/" +
            config.hrgt +
            "/" +
            config.hrlt +
            "/" +
            config.beatgt +
            "/" +
            config.beatlt +
            "/" +
            config.checkArr +
            "/" +
            config.qRecatArr +
            "/" +
            config.fullCurrent +
            "/" +
            config.newArr +
            "/" +
            config.lowr +
            "/" +
            config.highr +
            "/" +
            config.prev_arh +
            "/" +
            config.next_arh +
            "/" +
            config.low_2_high +
            "/" +
            config.high_2_high +
            "/" +
            this.state.selectedstarttime +
            "/" +
            this.state.selectedendtime
        )
        .then((res) => {
          alert("Done");
          // window.location.reload();
          // this.setState({showModalSpinner:false})
        })
        .catch((err) => {
          console.log(err);

          alert("Error in Quick Recategorize");
        });
    }
  }

  disableKey(e) {
    this.setState({ keyIntegrationStatus: 0 });
    console.log("disableKey", this.state.keyIntegrationStatus);
    const treemenu = this.state.treemenu;
    let optionnewon = []; // For ascending order
    let optionnewendon = []; // For descending order

    // Iterate over each date group in treemenu
    Object.keys(treemenu).forEach((dateKey) => {
      treemenu[dateKey].forEach((entry) => {
        const MainTimeon = entry.date.split("T");
        const timeon = MainTimeon[1].split("+")[0]; // Extract time portion, ignoring timezone

        // Create the formatted time string: "date time"
        const timeStr = `${dateKey} ${timeon}`;

        // Push to both arrays
        optionnewon.push(timeStr);
        optionnewendon.push(timeStr);
      });
    });

    // Sort optionnewon (ascending order) by full date-time
    optionnewon.sort((a, b) => {
      const fullDateA = new Date(a); // Convert full date-time string to Date object
      const fullDateB = new Date(b); // Convert full date-time string to Date object
      return fullDateA - fullDateB; // Ascending order: compare full date-time
    });

    // Sort optionnewendon (descending order) by full date-time
    optionnewendon.sort((a, b) => {
      const fullDateA = new Date(a); // Convert full date-time string to Date object
      const fullDateB = new Date(b); // Convert full date-time string to Date object
      return fullDateB - fullDateA; // Descending order: compare full date-time
    });

    // Output the results
    // console.log('Sorted Ascending:', optionnewon);
    this.setState({ starttime: optionnewendon });
    this.setState({ endtime: optionnewon });

    // console.log(".....................", optionnewon);
  }
  enableKey(e) {
    this.setState({ keyIntegrationStatus: 1 });
  }
  //     analyzeDataset(e) {
  //         var userid = this.props.match.params.userid;
  //     var databaseid = this.props.match.params.databaseid;
  //     e.currentTarget.innerHTML="Analyzing...";
  //     //const userid = this.props.match.params.userid;
  //     axios
  //         .get('https://web.mybiocalculus.com/html/welcome/analyzeDataset/?userid=' + userid + '&&databaseid=' + databaseid+ '&&url=' + window.analyzeLink)
  //         .then(res => {
  //           console.log(res.data);
  //           window.location.href = '/';
  //         })
  //         .catch(err => {
  //             console.log(err);
  //         });
  //   }

  /*  _onSelect(e)
  {
    
    if(e.value=='RPeaks')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/rpeaks_codes/analyze_last_correct.py';
    }
    else if(e.value=='Classic')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/classic_peak/analyze_last_correct.py';
    }
    else if(e.value=='Classical')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/classical_code/analyze_last_correct.py';
    }
    else if(e.value=='Classic Negative')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/classic_negpeak/analyze_last_correct.py';
    }
    else if(e.value=='AI')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/ai_codes/analyze_last_correct.py';
    }
    else if(e.value=='AIcodes')
    {
        console.log('Select',e.value);handleModal
        window.analyzeLink='/home/biocalculusssh/web_analysis/saroja_aicodes/analyze_last_correct.py';
    }
   
  }*/

  handleModal(db) {
    this.setState({ clickdb: db });
    this.setState({ isModalOpen: true });
  }
  resetModal() {
    this.setState({ isModalOpen: false });
  }
  resetmodaldis() {
    this.setState({ ismodaldisOpen: false });
  }

  handlemodaldis(db) {
    this.setState({ ismodaldisOpen: true });
  }

  resetModalReport() {
    this.setState({ isModalReportOpen: false });
  }
  handleModalInside(db) {
    this.setState({ clickdb: db });
    this.setState({ isModalInsideOpen: true });
  }

  handleModalReport(db) {
    this.setState({ isModalReportOpen: true });
  }
  handleDiary(db, maindt) {
    //  console.log("db",db);
    //   console.log("userid",this.props.match.params.userid);
    //  console.log("maindt",maindt);
    var MainTime = db.split("T");
    var time = MainTime[1].split("+");
    db = MainTime[0] + " " + time[0];
    //   console.log(MainTime[0]+""+time[0]);
    //this.setState({"showModalSpinner":true});
    var searchArr = [];

    axios
      .get(
        apiUrl +
          "api/common/getAllRPeakValues?userid=" +
          this.props.match.params.userid +
          "&databaseid=" +
          db
      )
      .then((res) => {
        this.setState({ showModalSpinner: false });

        searchArr.push(res.data);
        console.log("searchArr", searchArr);
        var queryTime = maindt.split(" ")[1];
        console.log("queryTime", queryTime);

        var closest = "23:59:59";
        var rpeakI;
        Object.keys(searchArr).map((val, key) => {
          searchArr[val].map((value, key) => {
            const time1 = value.peaktime;

            if (time1 >= queryTime && (time1 < closest || time1 < closest)) {
              closest = value.peaktime;

              rpeakI = value.rpeakindex;
              //  console.log('key',key);
              //  console.log("closest",closest);
              //  console.log("rpeakI",rpeakI);
              //this.callDiaryDataAPIs(db,this.props.match.params.userid,start,end,window.stripXValue);
            }
          });
        });

        if (rpeakI > 15000) {
          var stripIndex = Math.floor(rpeakI / 15000);
        } else {
          var stripIndex = 0;
        }
        window.stripXValue = parseInt(rpeakI) - stripIndex * 15000;
        var start = stripIndex,
          end = stripIndex + 9;
        //console.log("rpeakindex",rpeakI);

        if (Number.isNaN(window.stripXValue)) {
          console.log("window.stripXValue", window.stripXValue);
          alert("dataset not available");
        } else {
          window.location.href =
            "/verifier-chart/" +
            this.props.match.params.userid +
            "/" +
            db +
            "/" +
            start +
            "/" +
            end +
            "/" +
            window.stripXValue;
        }
      })
      .catch((err) => {
        this.setState({ showModalSpinner: false });
        alert(err);
        console.log(err);
      });
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(e.target.name);
    if (e.target.name == "arr_list") {
      this.setState({ ["newName"]: e.target.value });
    }
  };
  onStarttimeChange = (e) => {
    var value = e.currentTarget.value;
    console.log(value);
    this.setState({ selectedstarttime: value });
  };

  onEndtimeChange = (e) => {
    var value = e.currentTarget.value;
    console.log(value);
    this.setState({ selectedendtime: value });
  };

  openChartPopup(source) {
    const popup = document.querySelector("#popup");
    if (popup) {
      popup.className = "popup-open";
    }
  }

  closePopup(source) {
    const popup = document.querySelector("#popup");
    if (popup) {
      popup.className = "popup-close";
      console.log(`Popup closed by ${source}`);
    }
  }

  handleKeyPress(event) {
    if (event.key === "F2") {
      event.preventDefault();
      this.prevBtnLink(
        this.props.match.params.userid,
        this.props.match.params.databaseid
      );
      console.log("f2 clicked");
    }
    if (event.key === "F3") {
      event.preventDefault();
      this.nextBtnLink(
        this.props.match.params.userid,
        this.props.match.params.databaseid
      );
      console.log("f3 clicked");
    }
    if (
      (event.ctrlKey && event.key === "g") ||
      (event.ctrlKey && event.key === "G")
    ) {
      event.preventDefault(); // Prevent default browser action if necessary

      // Find the link element
      const chartTab = document.querySelector("#chart_tab_6");
      if (chartTab) {
        // Trigger the click programmatically
        chartTab.click();
      }
      this.fetchminmaxHr();
    }
    if (event.key === "Escape") {
      // 40 is the key code for the down arrow key
      this.closePopup("keyboard");
    }
    if (
      (event.ctrlKey && event.key === "v") ||
      (event.ctrlKey && event.key === "V")
    ) {
      // 40 is the key code for the down arrow key
      this.verify();
    }
    if (
      (event.ctrlKey && event.key === "o") ||
      (event.ctrlKey && event.key === "O")
    ) {
      this.openChartPopup("keyboard");
    }
    if (
      (event.ctrlKey && event.key === "q") ||
      (event.ctrlKey && event.key === "Q")
    ) {
      document.getElementById("quickRecat").click();
    }
    if (
      (event.ctrlKey && event.key === "c") ||
      (event.ctrlKey && event.key === "C")
    ) {
      const captureButton = document.querySelector(
        '[data-target="#captureModal"]'
      );
      if (captureButton) {
        captureButton.click();
      }
    }
    if (
      (event.ctrlKey && event.key === "s") ||
      (event.ctrlKey && event.key === "S")
    ) {
      const stButton = document.querySelector(
        '.arythmia-btn[value="Sinus Tachycardia"]'
      );
      if (stButton) {
        stButton.click();
      }
    }
    if (
      (event.ctrlKey && event.key === "b") ||
      (event.ctrlKey && event.key === "B")
    ) {
      const sbButton = document.querySelector(
        '.arythmia-btn[value="Sinus Bradycardia"]'
      );
      if (sbButton) {
        sbButton.click();
      }
    }
    if (
      (event.ctrlKey && event.key === "a") ||
      (event.ctrlKey && event.key === "A")
    ) {
      const pacButton = document.querySelector('.arythmia-btn[value="PAC"]');
      if (pacButton) {
        pacButton.click();
      }
    }
    if (
      (event.ctrlKey && event.key === "v") ||
      (event.ctrlKey && event.key === "V")
    ) {
      const pvcButton = document.querySelector('.arythmia-btn[value="PVC"]');
      if (pvcButton) {
        pvcButton.click();
      }
    }
    if (
      (event.ctrlKey && event.key === "t") ||
      (event.ctrlKey && event.key === "T")
    ) {
      const vtButton = document.querySelector(
        '.arythmia-btn[value="Ventricular Tachycardia"]'
      );
      if (vtButton) {
        vtButton.click();
      }
    }
    if (
      (event.ctrlKey && event.key === "f") ||
      (event.ctrlKey && event.key === "F")
    ) {
      const unButton = document.querySelector('.arythmia-btn[value="UN"]');
      if (unButton) {
        unButton.click();
      }
    }
    if (
      (event.ctrlKey && event.key === "u") ||
      (event.ctrlKey && event.key === "U")
    ) {
      const vtButton = document.querySelector('.arythmia-btn[value="AFib"]');
      if (vtButton) {
        vtButton.click();
      }
    }
  }

  render() {
    //selWrap
    let titleFlag = 0;
    const options = [
      "RPeaks",
      "Classic",
      "Classical",
      "Classic Negative",
      "AI",
      "AIcodes",
    ];
    const { errors } = this.state;
    const customStyles = {
      content: {
        background: "transparent",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };
    const defaultOption = options[0];
    if (localStorage.jwtToken == undefined) {
      window.location.href = "/";
    }
    const userid = this.props.match.params.userid;
    var databaseid = this.props.match.params.databaseid;
    const text = "Recategorizing......";
    if (typeof databaseid === "undefined") {
      databaseid = "0";
    }
    return (
      <div className="wrapper theme-6-active box-layout pimary-color-green">
        <input type="hidden" value={this.state.nextDbId} id="nextDbId" />
        <input type="hidden" value={this.state.prevDbId} id="prevDbId" />
        {/* <TopNav
          profileLink={this.state.profileLink}
          userid={userid}
          databaseid={databaseid}
        /> */}

        <SideBarGraph
          userid={userid}
          onSelectModal={this.handleModal}
          menus={this.state.menus}
          treemenu={this.state.treemenu}
          databaseid={databaseid}
        />
        <AddModal
          userid={userid}
          isOpen={this.state.isModalOpen}
          onResetModal={this.resetModal}
          clickdb={this.state.clickdb}
          start={this.props.match.params.start}
        />
        <AddModalInside
          clickdb={this.state.clickdb}
          userid={this.state.userid}
          isOpen={this.state.isModalInsideOpen}
          onResetModal={this.resetModal}
          start={this.props.match.params.start}
        />
        <AddModalReport
          userid={userid}
          onSelectModalReport={this.resetModalReport}
          isOpen={this.state.isModalReportOpen}
          treemenu={this.state.treemenu}
        />

        <Addmodaldisclosure
          userid={userid}
          onSelectmodaldis={this.resetmodaldis}
          isOpen={this.state.ismodaldisOpen}
          treemenu={this.state.treemenu}
        />
        <ReactModal isOpen={this.state.showModalSpinner} style={customStyles}>
          <Loader type="Puff" color="#00BFFF" height={100} width={100} />
          <div className="bounce-container">
            {text.split("").map((char, index) => (
              <span
                key={index}
                className="bounce-letter"
                style={{ animationDelay: `${index * 0.1}s` }}
              >
                {char}
              </span>
            ))}
          </div>
        </ReactModal>
        <div className="right-sidebar-backdrop"></div>
        <div className="page-wrapper" style={{ marginTop: "-1%" }}>
          <div className="container-fluid" style={{ marginTop: "3%" }}>
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="panel panel-default card-view graphMainCont">
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body">
                      <div className="pills-struct">
                        <ul
                          role="tablist"
                          className="nav nav-pills"
                          id="myTabs_6"
                        >
                          <li
                            role="presentation"
                            className="active"
                            style={{ height: "30px" }}
                          >
                            <a
                              data-toggle="tab"
                              id="chart_tab_2"
                              onClick={this.setTab.bind(this)}
                              role="tab"
                              href="#chart_beats"
                              aria-expanded="true"
                              style={{ height: "30px" }}
                            >
                              {this.props.match.params.start == undefined && (
                                <img
                                  id="beatsimg"
                                  src="../../dist/img/Beats.png"
                                />
                              )}
                              {this.props.match.params.start != undefined && (
                                <img
                                  id="beatsimg"
                                  src="../../../../dist/img/Beats.png"
                                />
                              )}
                            </a>
                          </li>
                          <li role="presentation" style={{ height: "30px" }}>
                            <a
                              aria-expanded="false"
                              data-toggle="tab"
                              onClick={this.setTab.bind(this)}
                              role="tab"
                              id="chart_tab_1"
                              href="#chart_events"
                              style={{ height: "30px" }}
                            >
                              {this.props.match.params.start == undefined && (
                                <img
                                  id="eventsimg"
                                  src="../../dist/img/EventsSelected.png"
                                />
                              )}
                              {this.props.match.params.start != undefined && (
                                <img
                                  id="eventsimg"
                                  src="../../../../dist/img/EventsSelected.png"
                                />
                              )}
                            </a>
                          </li>

                          {/* <li
                            role="presentation"
                            className=""
                            style={{ height: "30px" }}
                          >
                            <a
                              data-toggle="tab"
                              id="chart_tab_3"
                              onClick={this.fetchSummary.bind(this)}
                              role="tab"
                              href="#chart_summary"
                              aria-expanded="false"
                              style={{ height: "30px" }}
                            >
                              <img
                                id="summaryimg"
                                src="../../dist/img/Summary.png"
                              />
                            </a>
                          </li> */}

                          <li
                            className=""
                            role="presentation"
                            style={{ height: "30px" }}
                          >
                            <a
                              data-toggle="tab"
                              role="tab"
                              id="chart_tab_5"
                              onClick={this.selectedEvents.bind(this)}
                              href="#chart_sel_events"
                              aria-expanded="false"
                              style={{ height: "30px" }}
                            >
                              <img
                                id="selectimg"
                                src="../../dist/img/SelectedEvents.png"
                              />
                            </a>
                          </li>
                          <li role="presentation" style={{ height: "30px" }}>
                            <a
                              data-toggle="tab"
                              id="chart_tab_findings"
                              onClick={this.setTab.bind(this)}
                              role="tab"
                              href="#chart_findings"
                              aria-expanded="false"
                              style={{ height: "30px" }}
                            >
                              <img
                                id="addFindImg"
                                src="../../dist/img/AddFindings.png"
                              />
                            </a>
                          </li>
                          {/* <li role="presentation"  style={{"height":"30px"}}>
                            <a  data-toggle="tab" id="chart_tab_settings" onClick={this.setTab.bind(this)} role="tab" href="#chart_settings" aria-expanded="false" style={{"height":"30px"}}><img id="settingsImg" src="../../dist/img/Settings.png"/></a>
                          </li>  */}
                          <li
                            role="presentation"
                            id="chart_tab_report"
                            className="pull-right"
                          >
                            <a
                              onClick={this.fulldisclosure.bind(this)}
                              href="#"
                            >
                              Full Disclosure
                            </a>
                          </li>
                          <li
                            role="presentation"
                            id="chart_tab_report"
                            className="pull-right"
                          >
                            <a
                              onClick={this.generateReport.bind(this)}
                              href="#"
                            >
                              Generate Report
                            </a>
                          </li>
                          <li
                            role="presentation"
                            id="chart_tab_report"
                            className="pull-right"
                          >
                            <a onClick={this.handleModalInside} href="#">
                              Analayze
                            </a>
                          </li>

                          {this.state.reportStatus == 3 && (
                            <li
                              role="presentation"
                              id="chart_tab_report"
                              className="pull-right"
                            >
                              <a href="#">Please Wait..</a>
                            </li>
                          )}
                          {this.state.reportStatus >= 4 && (
                            <a
                              className="pull-right"
                              onClick={this.mailReport.bind()}
                              id="chart_tab_email"
                              href="#"
                              style={{ height: "30px" }}
                            >
                              {this.props.match.params.start == undefined && (
                                <img
                                  id="emailImg"
                                  src="../../dist/img/Email.png"
                                />
                              )}
                              {this.props.match.params.start != undefined && (
                                <img
                                  id="emailImg"
                                  src="../../../../../dist/img/Email.png"
                                />
                              )}
                            </a>
                          )}
                          {this.state.reportStatus >= 4 && (
                            <a
                              className="pull-right"
                              target="_blank"
                              href={this.state.reoprtLink}
                              id="chart_tab_reportView"
                              style={{ height: "30px" }}
                            >
                              {this.props.match.params.start == undefined && (
                                <img
                                  id="reportImg"
                                  src="../../dist/img/Report.png"
                                />
                              )}
                              {this.props.match.params.start != undefined && (
                                <img
                                  id="reportImg"
                                  src="../../../../../dist/img/Report.png"
                                />
                              )}
                            </a>
                          )}

                          <a
                            className="pull-right"
                            data-toggle="tab"
                            id="chart_tab_8"
                            onClick={this.setTab.bind(this)}
                            role="tab"
                            href="#diary_findings"
                            aria-expanded="false"
                            style={{ height: "30px" }}
                          >
                            {this.props.match.params.start == undefined && (
                              <img
                                id="addDiaryImg"
                                src="../../dist/img/diaryNotes.png"
                              />
                            )}
                            {this.props.match.params.start != undefined && (
                              <img
                                id="addDiaryImg"
                                src="../../../../../dist/img/diaryNotes.png"
                              />
                            )}
                          </a>
                        </ul>
                        <div className="clearfix"></div>
                        <div className="tab-content" id="myTabContent_6">
                          <div
                            id="chart_beats"
                            className="tab-pane fade active in"
                            role="tabpanel"
                          >
                            <div className="beat" id="beat">
                              <div className="panel-body">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div
                                      className="display"
                                      id="graphPrev"
                                    ></div>
                                  </div>
                                  <div
                                    className="col-md-4 text-center txt-light"
                                    style={{ marginTop: "-30px" }}
                                  >
                                    Database Id :: {this.state.databaseid}
                                  </div>
                                  <div className="col-md-4 text-right">
                                    <div
                                      className="display"
                                      id="graphNext"
                                    ></div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div
                                    className="col-sm-6"
                                    style={{ width: "100%" }}
                                  >
                                    <h2
                                      className="panel-title txt-light rechead"
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{ flex: "1", textAlign: "left" }}
                                      >
                                        {/* <button
        className="arythmia-btn"
        id="quickRecat"
        data-toggle="modal"
        onClick={this.disableKey.bind(this)}
        data-target="#quickModal"
      >
        Quick Recategorize
      </button> */}
                                      </div>

                                      <div
                                        style={{
                                          flex: "1",
                                          textAlign: "center",
                                        }}
                                      >
                                        <button
                                          id="prevBtn"
                                          className="arythmia-btn"
                                          onClick={this.newDataClick.bind(this)}
                                        >
                                          Previous
                                        </button>
                                        <button
                                          className="arythmia-btn"
                                          data-toggle="modal"
                                          onClick={this.disableKey.bind(this)}
                                          style={{ marginLeft: "10px" }}
                                          data-target="#captureModal"
                                        >
                                          Capture Image
                                        </button>
                                        <button
                                          className="arythmia-btn"
                                          id="verfiyBtn"
                                          style={{ marginLeft: "10px" }}
                                          onClick={this.verify.bind(this)}
                                        >
                                          Verify
                                        </button>
                                        <button
                                          id="nextBtn"
                                          className="arythmia-btn"
                                          onClick={this.newDataClick.bind(this)}
                                          style={{ marginLeft: "10px" }}
                                        >
                                          Next
                                        </button>
                                      </div>
                                      {localStorage.getItem("verifiertype") ===
                                        "8" && (
                                        <div
                                          style={{
                                            display: "flex",
                                            position: "fixed",
                                            top: "5.5%",
                                            left: "61%",
                                            height: "20px",
                                          }}
                                        >
                                          <p style={{ color: "white" }}>
                                            verified by -{" "}
                                            {this.state.verifierNameData
                                              .verifierName || "N/A"}{" "}
                                            /{" "}
                                            {this.state.verifierNameData
                                              .verifyTime || "N/A"}
                                          </p>
                                        </div>
                                      )}
                                      <div
                                        style={{
                                          // backgroundColor: "blue",
                                          display: "flex",
                                          justifyContent: "flex-end", // Aligns content to the right
                                          padding: "10px", // Optional padding
                                        }}
                                      >
                                        <button
                                          className="btn btn-warning"
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            this.getInfo();
                                            console.log("clicked");
                                            this.setState({keyIntegrationStatus:0})
                                            this.setState({
                                              isCommentModalOpen: true,
                                            });
                                          }}
                                        >
                                          Comments
                                        </button>
                                      </div>

                                      <div
                                        style={{
                                          flex: "1",
                                          textAlign: "right",
                                        }}
                                      >
                                        <select
                                          id="gainValue"
                                          style={{ color: "Black" }}
                                          onChange={this.gainBtn.bind(this)}
                                        >
                                          <option
                                            value="1"
                                            style={{ color: "Black" }}
                                          >
                                            1X
                                          </option>
                                          <option
                                            value="2"
                                            style={{ color: "Black" }}
                                          >
                                            2X
                                          </option>
                                          <option
                                            value="3"
                                            style={{ color: "Black" }}
                                          >
                                            3X
                                          </option>
                                        </select>
                                      </div>
                                    </h2>
                                    <div
                                      className="beats-container"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {/* {this.state.minMaxHrDataCurr && this.state.minMaxHrDataCurr.nsr && this.state.minMaxHrDataCurr.nsr.length !== 0 && (<p style={{ margin: "0 10px",fontSize:"15px" ,border:"1px solid white"}}>NSR:  <span className="beats-value-container">beats:{this.state.minMaxHrDataCurr.nsr.length}  </span> <span className="beats-value-container">min:{this.state.nsrMinHr}</span>  <span className="beats-value-container"></span>  max:{this.state.nsrMaxHr} </p>
                                      )} */}
                                      {this.state.minMaxHrDataCurr &&
                                        this.state.minMaxHrDataCurr.pvc &&
                                        this.state.minMaxHrDataCurr.pvc
                                          .length !== 0 && (
                                          <p
                                            style={{
                                              marginRight: "30px",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <span className="beats-value-container">
                                              PVC
                                            </span>
                                            <span className="beats-value-container">
                                              Beats:{" "}
                                              {
                                                this.state.minMaxHrDataCurr.pvc
                                                  .length
                                              }
                                            </span>
                                            <span className="beats-value-container">
                                              Min: {this.state.pvcMinHr}
                                            </span>
                                            <span className="beats-value-container">
                                              Max: {this.state.pvcMaxHr}
                                            </span>
                                          </p>
                                        )}
                                      {this.state.minMaxHrDataCurr &&
                                        this.state.minMaxHrDataCurr.pac &&
                                        this.state.minMaxHrDataCurr.pac
                                          .length !== 0 && (
                                          <p
                                            style={{
                                              marginRight: "30px",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <span className="beats-value-container">
                                              PAC
                                            </span>
                                            <span className="beats-value-container">
                                              Beats:{" "}
                                              {
                                                this.state.minMaxHrDataCurr.pac
                                                  .length
                                              }
                                            </span>
                                            <span className="beats-value-container">
                                              Min: {this.state.pacMinHr}
                                            </span>
                                            <span className="beats-value-container">
                                              Max: {this.state.pacMaxHr}
                                            </span>
                                          </p>
                                        )}
                                      {this.state.minMaxHrDataCurr &&
                                        this.state.minMaxHrDataCurr.st &&
                                        this.state.minMaxHrDataCurr.st
                                          .length !== 0 && (
                                          <p
                                            style={{
                                              marginRight: "30px",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <span className="beats-value-container">
                                              ST
                                            </span>
                                            <span className="beats-value-container">
                                              Beats:{" "}
                                              {
                                                this.state.minMaxHrDataCurr.st
                                                  .length
                                              }
                                            </span>
                                            <span className="beats-value-container">
                                              Min: {this.state.stMinHr}
                                            </span>
                                            <span className="beats-value-container">
                                              Max: {this.state.stMaxHr}
                                            </span>
                                          </p>
                                        )}
                                      {this.state.minMaxHrDataCurr &&
                                        this.state.minMaxHrDataCurr.vt &&
                                        this.state.minMaxHrDataCurr.vt
                                          .length !== 0 && (
                                          <p
                                            style={{
                                              marginRight: "30px",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <span className="beats-value-container">
                                              VT
                                            </span>
                                            <span className="beats-value-container">
                                              Beats:{" "}
                                              {
                                                this.state.minMaxHrDataCurr.vt
                                                  .length
                                              }
                                            </span>
                                            <span className="beats-value-container">
                                              Min: {this.state.vtMinHr}
                                            </span>
                                            <span className="beats-value-container">
                                              Max: {this.state.vtMaxHr}
                                            </span>
                                          </p>
                                        )}
                                      {this.state.minMaxHrDataCurr &&
                                        this.state.minMaxHrDataCurr.afib &&
                                        this.state.minMaxHrDataCurr.afib
                                          .length !== 0 && (
                                          <p
                                            style={{
                                              marginRight: "30px",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <span className="beats-value-container">
                                              AFIB
                                            </span>
                                            <span className="beats-value-container">
                                              Beats:{" "}
                                              {
                                                this.state.minMaxHrDataCurr.afib
                                                  .length
                                              }
                                            </span>
                                            <span className="beats-value-container">
                                              Min: {this.state.afibMinHr}
                                            </span>
                                            <span className="beats-value-container">
                                              Max: {this.state.afibMaxHr}
                                            </span>
                                          </p>
                                        )}
                                      {this.state.minMaxHrDataCurr &&
                                        this.state.minMaxHrDataCurr.sb &&
                                        this.state.minMaxHrDataCurr.sb
                                          .length !== 0 && (
                                          <p
                                            style={{
                                              marginRight: "30px",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <span className="beats-value-container">
                                              SB
                                            </span>
                                            <span className="beats-value-container">
                                              Beats:{" "}
                                              {
                                                this.state.minMaxHrDataCurr.sb
                                                  .length
                                              }
                                            </span>
                                            <span className="beats-value-container">
                                              Min: {this.state.sbMinHr}
                                            </span>
                                            <span className="beats-value-container">
                                              Max: {this.state.sbMaxHr}
                                            </span>
                                          </p>
                                        )}
                                      {this.state.minMaxHrDataCurr &&
                                        this.state.minMaxHrDataCurr.sp &&
                                        this.state.minMaxHrDataCurr.sp
                                          .length !== 0 && (
                                          <p
                                            style={{
                                              marginRight: "30px",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <span className="beats-value-container">
                                              SP
                                            </span>
                                            <span className="beats-value-container">
                                              Beats:{" "}
                                              {
                                                this.state.minMaxHrDataCurr.sp
                                                  .length
                                              }
                                            </span>
                                            <span className="beats-value-container">
                                              Min: {this.state.spMinHr}
                                            </span>
                                            <span className="beats-value-container">
                                              Max: {this.state.spMaxHr}
                                            </span>
                                          </p>
                                        )}
                                      {this.state.minMaxHrDataCurr &&
                                        this.state.minMaxHrDataCurr.ipr &&
                                        this.state.minMaxHrDataCurr.ipr
                                          .length !== 0 && (
                                          <p
                                            style={{
                                              marginRight: "30px",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <span className="beats-value-container">
                                              2AVB
                                            </span>
                                            <span className="beats-value-container">
                                              Beats:{" "}
                                              {
                                                this.state.minMaxHrDataCurr.ipr
                                                  .length
                                              }
                                            </span>
                                            <span className="beats-value-container">
                                              Min: {this.state.iprMinHr}
                                            </span>
                                            <span className="beats-value-container">
                                              Max: {this.state.iprMaxHr}
                                            </span>
                                          </p>
                                        )}
                                      {this.state.minMaxHrDataCurr &&
                                        this.state.minMaxHrDataCurr.irr &&
                                        this.state.minMaxHrDataCurr.irr
                                          .length !== 0 && (
                                          <p
                                            style={{
                                              marginRight: "30px",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <span className="beats-value-container">
                                              CHB
                                            </span>
                                            <span className="beats-value-container">
                                              Beats:{" "}
                                              {
                                                this.state.minMaxHrDataCurr.irr
                                                  .length
                                              }
                                            </span>
                                            <span className="beats-value-container">
                                              Min: {this.state.irrMinHr}
                                            </span>
                                            <span className="beats-value-container">
                                              Max: {this.state.irrMaxHr}
                                            </span>
                                          </p>
                                        )}
                                      {this.state.minMaxHrDataCurr &&
                                        this.state.minMaxHrDataCurr.pqt &&
                                        this.state.minMaxHrDataCurr.pqt
                                          .length !== 0 && (
                                          <p
                                            style={{
                                              marginRight: "30px",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <span className="beats-value-container">
                                              JB
                                            </span>
                                            <span className="beats-value-container">
                                              Beats:{" "}
                                              {
                                                this.state.minMaxHrDataCurr.pqt
                                                  .length
                                              }
                                            </span>
                                            <span className="beats-value-container">
                                              Min: {this.state.pqtMinHr}
                                            </span>
                                            <span className="beats-value-container">
                                              Max: {this.state.pqtMaxHr}
                                            </span>
                                          </p>
                                        )}
                                      {this.state.minMaxHrDataCurr &&
                                        this.state.minMaxHrDataCurr.pabsent &&
                                        this.state.minMaxHrDataCurr.pabsent
                                          .length !== 0 && (
                                          <p
                                            style={{
                                              marginRight: "30px",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <span className="beats-value-container">
                                              3AVB
                                            </span>
                                            <span className="beats-value-container">
                                              Beats:{" "}
                                              {
                                                this.state.minMaxHrDataCurr
                                                  .pabsent.length
                                              }
                                            </span>
                                            <span className="beats-value-container">
                                              Min: {this.state.pabsentMinHr}
                                            </span>
                                            <span className="beats-value-container">
                                              Max: {this.state.pabsentMaxHr}
                                            </span>
                                          </p>
                                        )}
                                      {this.state.minMaxHrDataCurr &&
                                        this.state.minMaxHrDataCurr.sqt &&
                                        this.state.minMaxHrDataCurr.sqt
                                          .length !== 0 && (
                                          <p
                                            style={{
                                              marginRight: "30px",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <span className="beats-value-container">
                                              SVT
                                            </span>
                                            <span className="beats-value-container">
                                              Beats:{" "}
                                              {
                                                this.state.minMaxHrDataCurr.sqt
                                                  .length
                                              }
                                            </span>
                                            <span className="beats-value-container">
                                              Min: {this.state.sqtMinHr}
                                            </span>
                                            <span className="beats-value-container">
                                              Max: {this.state.sqtMaxHr}
                                            </span>
                                          </p>
                                        )}
                                    </div>
                                  </div>

                                  <div className="values-container"></div>
                                  <div className="col-sm-1"></div>
                                  <div className="col-sm-5 text-right">
                                    {/* <h2 className="panel-title txt-light rechead"> */}
                                    {/* <ul role="tablist" className="nav nav-pills" id="myTabs_6">
                                     <li role="presentation" className="pull-left">
                                    <Dropdown options={options} onChange={this._onSelect.bind(this)} value={defaultOption} placeholder="Select an option" /></li>
                                          <li role="presentation" className="pull-right">
                                          <a  data-toggle="tab" id="chart_tab_6" onClick={this.analyzeDataset.bind(this)} role="tab" href="#chart_g_events" aria-expanded="false">Analyze</a>
                                        </li>
                                        </ul> */}
                                    &nbsp;
                                    {/* <span className="pull-right">
                                        Recategorize To!
                                      </span>
                                    </h2> */}
                                    {/* <button
                                      className="arythmia-btn"
                                      value="Normal Sinus Rhythm"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      NSR
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="Sinus Tachycardia"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      ST
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="Sinus Bradycardia"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      SB
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="PAC"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      PAC
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="PVC"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      PVC
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="Ventricular Tachycardia"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      VT
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="AFib"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      AF
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="Sinus Pause"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      SP
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="P Absent"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      3AVB
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="Irregular RR"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      CHB
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="Irregular PR"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      2AVB
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="Short QT"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      SVT
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="Prolonged QT"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      JB
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="Undiagnosed"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      UN
                                    </button> */}
                                  </div>
                                </div>

                                {/*  */}
                                <div
                                  id="popup"
                                  className="popup-close"
                                  style={{ position: "absolute" }}
                                >
                                  <div
                                    className="draggable-area"
                                    style={{ cursor: "move", padding: "10px" }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <button
                                        className="close-button"
                                        onClick={() =>
                                          this.closePopup("button")
                                        }
                                        style={{
                                          marginLeft: "auto",
                                        }}
                                      >
                                        ❌
                                      </button>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div
                                      className="col-sm-6"
                                      style={{ top: "10px" }}
                                    >
                                      <h2 className="panel-title txt-light rechead">
                                        <button
                                          className="arythmia-btn"
                                          id="superImpose"
                                          data-toggle="modal"
                                          data-target="#SuperModal"
                                        >
                                          Superimpose
                                        </button>
                                        <button
                                          className="arythmia-btn"
                                          id="quickRecat"
                                          data-toggle="modal"
                                          onClick={this.disableKey.bind(this)}
                                          data-target="#quickModal"
                                          style={{ marginLeft: "-50px" }}
                                        >
                                          Quick Recategorize
                                        </button>
                                      
                                        {/* <button
                                          className="arythmia-btn"
                                          id="quickRecat"
                                          data-toggle="modal"
                                          onClick={this.disableKey.bind(this)}
                                          data-target="#quickModal"
                                          style={{marginLeft:"10px"}}
                                        >
                                          PAC Run
                                        </button> */}
                                        {/* <button
                                          className="arythmia-btn"
                                          id="quickRecat"
                                          data-toggle="modal"
                                          onClick={this.disableKey.bind(this)}
                                          data-target="#quickModal"
                                          style={{marginLeft:"10px"}}
                                        >
                                          J Beat Run
                                        </button> */}
                                      </h2>
                                      {/* <button className="arythmia-btn nsr-btn">NSR</button>
                                  <button className="arythmia-btn st-btn">ST</button>
                                  <button className="arythmia-btn sb-btn" >SB</button>
                                  <button className="arythmia-btn pac-btn">PAC</button>
                                  <button className="arythmia-btn pvc-btn">PVC</button>
                                  <button className="arythmia-btn vt-btn">VT</button>
                                  <button className="arythmia-btn af-btn">AF</button>
                                  <button className="arythmia-btn sp-btn">SP</button>
                                  <button className="arythmia-btn pa-btn">3AVB</button>
                                  <button className="arythmia-btn irr-btn">CHB</button>
                                  <button className="arythmia-btn ipr-btn">2AVB</button>
                                  <button className="arythmia-btn sqt-btn">SVT</button>
                                  <button className="arythmia-btn lqt-btn">JB</button>
                                  <button className="arythmia-btn un-btn">UN</button>
                                  <button className="arythmia-btn st-btn" value='Sinus Tachycardia' onClick={this.colorGraph.bind(this)}>Color Chart</button> */}
                                    </div>

                                    <div
                                      className="col-sm-5 text-right"
                                      style={{ width: "100%" }}
                                    >
                                      <h2 className="panel-title txt-light rechead">
                                        {/* <ul role="tablist" className="nav nav-pills" id="myTabs_6">
                                 <li role="presentation" className="pull-left">
                                <Dropdown options={options} onChange={this._onSelect.bind(this)} value={defaultOption} placeholder="Select an option" /></li>
                                      <li role="presentation" className="pull-right">
                                      <a  data-toggle="tab" id="chart_tab_6" onClick={this.analyzeDataset.bind(this)} role="tab" href="#chart_g_events" aria-expanded="false">Analyze</a>
                                    </li>
                                    </ul> */}
                                        &nbsp;
                                        <span className="pull-right">
                                          Recategorize To
                                        </span>
                                      </h2>
                                      <button
                                        className="arythmia-btn"
                                        value="Normal Sinus Rhythm"
                                        onClick={this.recategorize.bind(this)}
                                        style={{ backgroundColor: "#3D761D" }}
                                      >
                                        NSR
                                      </button>
                                      <button
                                        className="arythmia-btn"
                                        value="Sinus Tachycardia"
                                        onClick={this.recategorize.bind(this)}
                                        style={{ backgroundColor: "#00FFFF" }}
                                      >
                                        ST
                                      </button>
                                      <button
                                        className="arythmia-btn"
                                        value="Sinus Bradycardia"
                                        onClick={this.recategorize.bind(this)}
                                        style={{ backgroundColor: "#FF9900" }}
                                      >
                                        SB
                                      </button>
                                      <button
                                        className="arythmia-btn"
                                        value="PAC"
                                        onClick={this.recategorize.bind(this)}
                                        style={{ backgroundColor: "#0000FF" }}
                                      >
                                        PAC
                                      </button>
                                      <button
                                        className="arythmia-btn"
                                        value="PVC"
                                        onClick={this.recategorize.bind(this)}
                                        style={{ backgroundColor: "#FF00FF" }}
                                      >
                                        PVC
                                      </button>
                                      <button
                                        className="arythmia-btn"
                                        value="Ventricular Tachycardia"
                                        onClick={this.recategorize.bind(this)}
                                        style={{ backgroundColor: "#A64D79" }}
                                      >
                                        VT
                                      </button>
                                      <button
                                        className="arythmia-btn"
                                        value="AFib"
                                        onClick={this.recategorize.bind(this)}
                                        style={{ backgroundColor: "#9900FF" }}
                                      >
                                        AF
                                      </button>
                                      <button
                                        className="arythmia-btn"
                                        value="Sinus Pause"
                                        onClick={this.recategorize.bind(this)}
                                        style={{ backgroundColor: "#FF0000" }}
                                      >
                                        SP
                                      </button>
                                      <button
                                        className="arythmia-btn"
                                        value="P Absent"
                                        onClick={this.recategorize.bind(this)}
                                        style={{ backgroundColor: "#bfbebb" }}
                                      >
                                        3AVB
                                      </button>
                                      <button
                                        className="arythmia-btn"
                                        value="Irregular RR"
                                        onClick={this.recategorize.bind(this)}
                                        style={{ backgroundColor: "#7F6000" }}
                                      >
                                        CHB
                                      </button>
                                      <button
                                        className="arythmia-btn"
                                        value="Irregular PR"
                                        onClick={this.recategorize.bind(this)}
                                        style={{ backgroundColor: "#351C75" }}
                                      >
                                        2AVB
                                      </button>
                                      <button
                                        className="arythmia-btn"
                                        value="Short QT"
                                        onClick={this.recategorize.bind(this)}
                                        style={{ backgroundColor: "#980000" }}
                                      >
                                        SVT
                                      </button>
                                      <button
                                        className="arythmia-btn"
                                        value="Prolonged QT"
                                        onClick={this.recategorize.bind(this)}
                                        style={{ backgroundColor: "#46BDC6" }}
                                      >
                                        JB
                                      </button>
                                      <button
                                        className="arythmia-btn"
                                        value="Undiagnosed"
                                        onClick={this.recategorize.bind(this)}
                                        style={{ backgroundColor: "#FFD966" }}
                                      >
                                        UN
                                      </button>
                                    </div>
                                  </div>
                                  <div id="tt_container"></div>
                                </div>
                                {/* <div className="text-right">
                                 
                                </div> */}
                                <input
                                  type="hidden"
                                  name="beatSlected"
                                  id="beatSlected"
                                  value="[]"
                                />
                                <br />

                                <div
                                  id="selWrap"
                                  className="sel selwrap-container"
                                  onScroll={this.listenScrollEvent.bind(this)}
                                  onClick={this.openChartPopup.bind(this)}
                                ></div>

                                <input
                                  type="hidden"
                                  name="selectedChart"
                                  id="selectedChart"
                                  value={this.state.selectedChart}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            id="chart_events"
                            className="tab-pane fade "
                            role="tabpanel"
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <div className="">
                                  <div className="panel-wrapper collapse in">
                                    <div className="panel-body">
                                      <div className="row">
                                        <div
                                          className="col-sm-6"
                                          style={{ width: "100%" }}
                                        >
                                          <h2
                                            className="panel-title txt-light rechead"
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                flex: "1",
                                                textAlign: "left",
                                              }}
                                            >
                                              {/* <button
        className="arythmia-btn"
        id="quickRecat"
        data-toggle="modal"
        onClick={this.disableKey.bind(this)}
        data-target="#quickModal"
      >
        Quick Recategorize
      </button> */}
                                            </div>

                                            <div
                                              style={{
                                                flex: "1",
                                                textAlign: "center",
                                              }}
                                            >
                                              <button
                                                id="prevBtn"
                                                className="arythmia-btn"
                                                onClick={this.newDataClick.bind(
                                                  this
                                                )}
                                              >
                                                Previous
                                              </button>
                                              {/* <button
                                          className="arythmia-btn"
                                          data-toggle="modal"
                                          onClick={this.disableKey.bind(this)}
                                          style={{ marginLeft: "10px" }}
                                          data-target="#captureModal"
                                        >
                                          Capture Image
                                        </button> */}
                                              {this.state.verifiedStatus ===
                                                0 && (
                                                <button
                                                  id="verifyBtn"
                                                  className="arythmia-btn"
                                                  style={{ marginLeft: "10px" }}
                                                  onClick={(e) =>
                                                    this.verify(e)
                                                  }
                                                >
                                                  Verify
                                                </button>
                                              )}
                                              {this.state.verifiedStatus ===
                                                1 && (
                                                <button
                                                  id="verifyBtn"
                                                  className="arythmia-btn"
                                                  style={{ display: "none" }}
                                                >
                                                  Verified
                                                </button>
                                              )}
                                              <button
                                                id="nextBtn"
                                                className="arythmia-btn"
                                                onClick={this.newDataClick.bind(
                                                  this
                                                )}
                                                style={{ marginLeft: "10px" }}
                                              >
                                                Next
                                              </button>
                                            </div>

                                            <div
                                              style={{
                                                flex: "1",
                                                textAlign: "right",
                                              }}
                                            >
                                              <select
                                                id="gainValue"
                                                style={{ color: "Black" }}
                                                onChange={this.gainBtn.bind(
                                                  this
                                                )}
                                              >
                                                <option
                                                  value="1"
                                                  style={{ color: "Black" }}
                                                >
                                                  1X
                                                </option>
                                                <option
                                                  value="2"
                                                  style={{ color: "Black" }}
                                                >
                                                  2X
                                                </option>
                                                <option
                                                  value="3"
                                                  style={{ color: "Black" }}
                                                >
                                                  3X
                                                </option>
                                              </select>
                                            </div>
                                          </h2>
                                        </div>

                                        <div className="col-sm-1"></div>
                                        <div className="col-sm-5 text-right">
                                          {/* <h2 className="panel-title txt-light rechead"> */}
                                          {/* <ul role="tablist" className="nav nav-pills" id="myTabs_6">
                                     <li role="presentation" className="pull-left">
                                    <Dropdown options={options} onChange={this._onSelect.bind(this)} value={defaultOption} placeholder="Select an option" /></li>
                                          <li role="presentation" className="pull-right">
                                          <a  data-toggle="tab" id="chart_tab_6" onClick={this.analyzeDataset.bind(this)} role="tab" href="#chart_g_events" aria-expanded="false">Analyze</a>
                                        </li>
                                        </ul> */}
                                          &nbsp;
                                          {/* <span className="pull-right">
                                        Recategorize To!
                                      </span>
                                    </h2> */}
                                          {/* <button
                                      className="arythmia-btn"
                                      value="Normal Sinus Rhythm"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      NSR
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="Sinus Tachycardia"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      ST
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="Sinus Bradycardia"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      SB
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="PAC"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      PAC
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="PVC"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      PVC
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="Ventricular Tachycardia"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      VT
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="AFib"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      AF
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="Sinus Pause"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      SP
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="P Absent"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      3AVB
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="Irregular RR"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      CHB
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="Irregular PR"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      2AVB
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="Short QT"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      SVT
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="Prolonged QT"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      JB
                                    </button>
                                    <button
                                      className="arythmia-btn"
                                      value="Undiagnosed"
                                      onClick={this.recategorize.bind(this)}
                                    >
                                      UN
                                    </button> */}
                                        </div>
                                      </div>
                                      <h2 className="panel-title txt-light rechead">
                                        Cardiac Event Detected
                                      </h2>
                                      <div className="filter-wrap mb-10">
                                        <ul id="filters" className="filterMain">
                                          {Object.keys(this.state.images).map(
                                            (val, key) => {
                                              if (titleFlag == 0) {
                                                titleFlag = 1;
                                                window.filterId = val;
                                                return (
                                                  <li
                                                    key={key}
                                                    id={val}
                                                    value={val}
                                                    onClick={this.filter.bind(
                                                      this
                                                    )}
                                                  >
                                                    <a
                                                      href="#"
                                                      data-filter={"." + val}
                                                      className="active"
                                                    >
                                                      {window.filterId}
                                                    </a>
                                                  </li>
                                                );
                                              } else {
                                                return (
                                                  <li
                                                    key={key}
                                                    id={val}
                                                    value={val}
                                                    onClick={this.filter.bind(
                                                      this
                                                    )}
                                                  >
                                                    <a
                                                      href="#"
                                                      data-filter={"." + val}
                                                    >
                                                      {val}
                                                    </a>
                                                  </li>
                                                );
                                              }
                                            }
                                          )}
                                        </ul>
                                        <hr />
                                        <div className="clearfix"></div>
                                      </div>
                                      <h5 className="panel-title txt-light rechead">
                                        Recategorize To
                                      </h5>
                                      <div className="row mt-5 mt-5hed">
                                        <div className="col-sm-12">
                                          <button
                                            className="arythmia-btn nsr-btn"
                                            value="nsr"
                                            onClick={this.recategorizeImg.bind(
                                              this
                                            )}
                                          >
                                            NSR
                                          </button>
                                          <button
                                            className="arythmia-btn st-btn"
                                            value="st"
                                            onClick={this.recategorizeImg.bind(
                                              this
                                            )}
                                          >
                                            ST
                                          </button>
                                          <button
                                            className="arythmia-btn sb-btn"
                                            value="sb"
                                            onClick={this.recategorizeImg.bind(
                                              this
                                            )}
                                          >
                                            SB
                                          </button>
                                          <button
                                            className="arythmia-btn pac-btn"
                                            value="pac"
                                            onClick={this.recategorizeImg.bind(
                                              this
                                            )}
                                          >
                                            PAC
                                          </button>
                                          <button
                                            className="arythmia-btn pvc-btn"
                                            value="pvc"
                                            onClick={this.recategorizeImg.bind(
                                              this
                                            )}
                                          >
                                            PVC
                                          </button>
                                          <button
                                            className="arythmia-btn vt-btn"
                                            value="vt"
                                            onClick={this.recategorizeImg.bind(
                                              this
                                            )}
                                          >
                                            VT
                                          </button>
                                          <button
                                            className="arythmia-btn af-btn"
                                            value="af"
                                            onClick={this.recategorizeImg.bind(
                                              this
                                            )}
                                          >
                                            AF
                                          </button>
                                          <button
                                            className="arythmia-btn sp-btn"
                                            value="sp"
                                            onClick={this.recategorizeImg.bind(
                                              this
                                            )}
                                          >
                                            SP
                                          </button>
                                          <button
                                            className="arythmia-btn pa-btn"
                                            value="pa"
                                            onClick={this.recategorizeImg.bind(
                                              this
                                            )}
                                            style={{
                                              backgroundColor: "#bfbebb",
                                            }}
                                          >
                                            3AVB
                                          </button>
                                          <button
                                            className="arythmia-btn irr-btn"
                                            value="irr"
                                            onClick={this.recategorizeImg.bind(
                                              this
                                            )}
                                          >
                                            CHB
                                          </button>
                                          <button
                                            className="arythmia-btn ipr-btn"
                                            value="ipr"
                                            onClick={this.recategorizeImg.bind(
                                              this
                                            )}
                                          >
                                            2AVB
                                          </button>
                                          <button
                                            className="arythmia-btn sqt-btn"
                                            value="sqt"
                                            onClick={this.recategorizeImg.bind(
                                              this
                                            )}
                                          >
                                            SVT
                                          </button>
                                          <button
                                            className="arythmia-btn lqt-btn"
                                            value="lqt"
                                            onClick={this.recategorizeImg.bind(
                                              this
                                            )}
                                          >
                                            JB
                                          </button>
                                          <button
                                            className="arythmia-btn un-btn"
                                            value="un"
                                            onClick={this.recategorizeImg.bind(
                                              this
                                            )}
                                          >
                                            UN
                                          </button>
                                          <ul
                                            role="tablist"
                                            className="nav nav-pills"
                                            id="myTabs_6"
                                          >
                                            <li
                                              role="presentation"
                                              className="pull-right"
                                            >
                                              <a
                                                data-toggle="tab"
                                                id="chart_tab_6"
                                                onClick={this.generateEvents.bind(
                                                  this
                                                )}
                                                role="tab"
                                                href="#chart_g_events"
                                                aria-expanded="false"
                                              >
                                                Generate Events
                                              </a>
                                            </li>
                                            {window.eventclass != "all" && (
                                              <li
                                                role="presentation"
                                                className="pull-right"
                                              >
                                                <a
                                                  onClick={this.addImageToReport.bind(
                                                    this
                                                  )}
                                                >
                                                  Add beat to report
                                                </a>
                                              </li>
                                            )}
                                            {window.eventclass != "all" && (
                                              <li
                                                role="presentation"
                                                className="pull-right"
                                              >
                                                <a
                                                  onClick={this.removeFiles.bind(
                                                    this
                                                  )}
                                                >
                                                  Remove File
                                                </a>
                                              </li>
                                            )}

                                            {window.eventclass != "all" && (
                                              <li
                                                role="presentation"
                                                className="pull-right"
                                                id="SA"
                                              >
                                                <a
                                                  onClick={this.selectAll.bind(
                                                    this
                                                  )}
                                                >
                                                  Select All
                                                </a>
                                              </li>
                                            )}
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="gallery-wrap">
                                        <div className="portfolio-wrap project-gallery">
                                          <div
                                            id="portfolio"
                                            className="row construct project-gallery"
                                          >
                                            {Object.keys(this.state.images).map(
                                              (val, key) => {
                                                return (
                                                  <React.Fragment key={key}>
                                                    {this.state.images[val].map(
                                                      (value, key) => {
                                                        var tempvalue =
                                                          this.splitFn(value);

                                                        return (
                                                          <div
                                                            key={key}
                                                            onDoubleClick={
                                                              this.updateState
                                                            }
                                                            id={tempvalue[1]}
                                                            onClick={
                                                              this.selectImage
                                                            }
                                                            className={
                                                              "col-sm-3 item tall " +
                                                              val
                                                            }
                                                            data-src="dist/img/gallery/mock1.jpg"
                                                            data-sub-html=""
                                                          >
                                                            <p>
                                                              {tempvalue[0]}
                                                            </p>
                                                            <input
                                                              type="checkbox"
                                                              id={value}
                                                              value={
                                                                val +
                                                                "|" +
                                                                value
                                                              }
                                                              className={val}
                                                              name="test"
                                                            />
                                                            <label
                                                              htmlFor={value}
                                                            >
                                                              <img
                                                                className="img-responsive zoom"
                                                                src={
                                                                  "https://web.mybiocalculus.com/assets/dist/user_records/" +
                                                                  userid +
                                                                  "/events_list/" +
                                                                  val +
                                                                  "/" +
                                                                  this.state
                                                                    .dblink +
                                                                  "/" +
                                                                  value
                                                                }
                                                                alt="Image description"
                                                              />
                                                            </label>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </React.Fragment>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            id="chart_summary"
                            className="tab-pane fade"
                            role="tabpanel"
                          >
                            <VerifierSummary
                              userid={userid}
                              databaseid={databaseid}
                              rpeaks={this.state.summaryData}
                              minMaxHrCurr={this.state.minMaxHrDataCurr}
                              minMaxHrFull={this.state.minMaxHrDataFull}
                              patientwrktotaldetails={
                                this.state.patientwrktotaldetails
                              }
                              patientwrkdailydetails={
                                this.state.patientwrkdailydetails
                              }
                              patientwrkdetails={this.state.patientwrkdetails}
                              arrCount={this.state.arrCount}
                              heartRatesData={this.state.heartRatesData}
                            />
                          </div>

                          <div
                            id="chart_sel_events"
                            className="tab-pane fade"
                            role="tabpanel"
                          >
                            <VerifierSelectedBeats
                              userid={userid}
                              databaseid={databaseid}
                              datasets={this.state.datasets}
                              imagess={this.state.imagess}
                              select={window.eventclass}
                            />
                          </div>

                          {/* <div  id="chart_settings" className="tab-pane fade" role="tabpanel">
                            <SettingsTab userid={userid} reportStatus={this.state.reportStatus}/>
                          </div> */}

                          <div
                            id="chart_findings"
                            className="tab-pane fade"
                            role="tabpanel"
                          >
                            <AddFindingsTab
                              userid={userid}
                              databaseid={databaseid}
                              reportStatus={this.state.reportStatus}
                            />
                          </div>
                          <div
                            id="diary_findings"
                            className="tab-pane fade"
                            role="tabpanel"
                          >
                            <DiaryNotes
                              userid={userid}
                              databaseid={databaseid}
                              treemenu={this.state.treemenu}
                              onSelectDiary={this.handleDiary}
                            />
                          </div>

                          <div
                            id="chart_regenerate"
                            className="tab-pane fade"
                            role="tabpanel"
                          >
                            <p>Regenerate</p>
                          </div>
                          <div
                            id="chart_report"
                            className="tab-pane fade"
                            role="tabpanel"
                          >
                            <p>Report</p>
                          </div>
                          <div
                            id="SuperModal"
                            className="modal fade"
                            role="dialog"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <button
                                    type="button"
                                    className="close"
                                    onClick={this.enableKey}
                                    data-dismiss="modal"
                                  >
                                    &times;
                                  </button>
                                  <h4 className="modal-title text-center">
                                    Superimpose
                                  </h4>
                                </div>
                                <form
                                  method="post"
                                  onSubmit={this.superImposeData.bind(this)}
                                >
                                  <div className="modal-body">
                                    <div className="row row-sm-offset">
                                      <br />
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label style={{ color: "Black" }}>
                                            Beat order{" "}
                                          </label>
                                          <input
                                            type="text"
                                            className={classnames(
                                              "form-control",
                                              {
                                                errors: errors.hrgt,
                                              }
                                            )}
                                            name="beat_order"
                                            required=""
                                            id="beat_order"
                                            onChange={this.onChange}
                                            value={this.state.beat_order}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label style={{ color: "Black" }}>
                                            Window Size{" "}
                                          </label>
                                          <input
                                            type="text"
                                            className={classnames(
                                              "form-control",
                                              {
                                                errors: errors.hrlt,
                                              }
                                            )}
                                            name="window_size"
                                            required=""
                                            id="window_size"
                                            onChange={this.onChange}
                                            value={this.state.window_size}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row row-sm-offset">
                                      <br />
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label style={{ color: "Black" }}>
                                            Check Arrhythmia{" "}
                                          </label>
                                          <Select
                                            isMulti
                                            name="checkArry"
                                            className="basic-multi-select"
                                            options={this.state.checkArry}
                                            value={this.state.arrythymia_array}
                                            onChange={
                                              this.handlearrythymiaChange
                                            }
                                            classNamePrefix="select"
                                            placeholder="Select Arrhythmias"
                                            styles={{
                                              option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor:
                                                  state.isSelected
                                                    ? "blue"
                                                    : state.isFocused
                                                    ? "#cedbf5"
                                                    : null,
                                                color: "",
                                              }),
                                              multiValue: (provided) => ({
                                                ...provided,
                                                backgroundColor: "white",
                                                color: "black",
                                              }),
                                              multiValueLabel: (provided) => ({
                                                ...provided,
                                                color: "black",
                                              }),
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label style={{ color: "Black" }}>
                                            Threshold{" "}
                                          </label>

                                          <input
                                            type="text"
                                            className={classnames(
                                              "form-control",
                                              {
                                                errors: errors.hrgt,
                                              }
                                            )}
                                            name="threshold"
                                            required=""
                                            id="threshold"
                                            onChange={this.onChange}
                                            value={this.state.threshold}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 multi-horizontal">
                                    <div className="form-group">
                                      <label style={{ color: "Black" }}>
                                        New Arrhythmia
                                      </label>
                                      <select
                                        name="newArrhy"
                                        className="form-control"
                                        value={this.state.newArrhy}
                                        onChange={this.onChange}
                                      >
                                        <option value="Normal Sinus Rhythm">
                                          Normal Sinus Rhythm
                                        </option>
                                        <option value="Sinus Tachycardia">
                                          Sinus Tachycardia{" "}
                                        </option>
                                        <option value="Sinus Bradycardia">
                                          Sinus Bradycardia
                                        </option>
                                        <option value="PAC">PAC</option>
                                        <option value="PVC">PVC</option>
                                        <option value="Sinus pause">
                                          Sinus pause
                                        </option>
                                        <option value="Ventricular Tachycardia">
                                          Ventricular Tachycardia
                                        </option>
                                        <option value="af">Afib </option>
                                        <option value="flutter">
                                          Atrial Flutter
                                        </option>
                                        <option value="jbeat">J Beat</option>
                                        <option value="Undiagnosed">
                                          Undiagnosed
                                        </option>
                                        <option value="Short QT">SVT</option>
                                        <option value="Irregular PR">
                                          2AVB
                                        </option>
                                        <option value="Irregular RR">
                                          CHB
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-6 multi-horizontal">
                                    <div className="form-group">
                                      <label style={{ color: "Black" }}>
                                        Data{" "}
                                      </label>
                                      <select
                                        name="full_current"
                                        className="form-control"
                                        value={this.state.full_current}
                                        onChange={this.onChange}
                                      >
                                        <option value="Current">Current</option>
                                        <option value="verify">verify</option>
                                        <option value="unverify">
                                          unverify
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <div className="row row-sm-offset">
                                      <div className="col-md-6 multi-horizontal">
                                        <button
                                          type="submit"
                                          className="btn btn-success"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                      <div className="col-md-6 multi-horizontal text-left">
                                        <button
                                          id=""
                                          type="button"
                                          className="btn btn-default closePopup"
                                          onClick={this.enableKey}
                                          data-dismiss="modal"
                                        >
                                          Close
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  {this.state.responseMessage && (
                                    <div
                                      className="alert alert-info mt-3"
                                      role="alert"
                                    >
                                      <pre>{this.state.responseMessage}</pre>
                                    </div>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                          <div
                            id="quickModal"
                            className="modal fade"
                            role="dialog"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <button
                                    type="button"
                                    className="close"
                                    onClick={this.enableKey}
                                    data-dismiss="modal"
                                  >
                                    &times;
                                  </button>
                                  <h4 className="modal-title text-center">
                                    Quick Recategorization
                                  </h4>
                                </div>
                                <form method="post" onSubmit={this.quickRecat}>
                                  <div className="modal-body">
                                    <div className="row row-sm-offset">
                                      <br />
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label style={{ color: "Black" }}>
                                            HR gt{" "}
                                          </label>
                                          <input
                                            type="text"
                                            className={classnames(
                                              "form-control",
                                              {
                                                errors: errors.hrgt,
                                              }
                                            )}
                                            name="hrgt"
                                            required=""
                                            id="hrgt"
                                            onChange={this.onChange}
                                            value={this.state.hrgt}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label style={{ color: "Black" }}>
                                            HR lt{" "}
                                          </label>
                                          <input
                                            type="text"
                                            className={classnames(
                                              "form-control",
                                              {
                                                errors: errors.hrlt,
                                              }
                                            )}
                                            name="hrlt"
                                            required=""
                                            id="hrlt"
                                            onChange={this.onChange}
                                            value={this.state.hrlt}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row row-sm-offset">
                                      <br />
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label style={{ color: "Black" }}>
                                            Beat gt{" "}
                                          </label>
                                          <input
                                            type="text"
                                            className={classnames(
                                              "form-control",
                                              {
                                                errors: errors.beatgt,
                                              }
                                            )}
                                            name="beatgt"
                                            required=""
                                            id="beatgt"
                                            onChange={this.onChange}
                                            value={this.state.beatgt}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label style={{ color: "Black" }}>
                                            Beat lt{" "}
                                          </label>
                                          <input
                                            type="text"
                                            className={classnames(
                                              "form-control",
                                              {
                                                errors: errors.beatlt,
                                              }
                                            )}
                                            name="beatlt"
                                            required=""
                                            id="beatlt"
                                            onChange={this.onChange}
                                            value={this.state.beatlt}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row row-sm-offset">
                                      <br />
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label style={{ color: "Black" }}>
                                            Previous beat HR gt{" "}
                                          </label>
                                          <input
                                            type="text"
                                            className={classnames(
                                              "form-control",
                                              {
                                                errors: errors.lowr,
                                              }
                                            )}
                                            name="lowr"
                                            required=""
                                            id="lowr"
                                            onChange={this.onChange}
                                            value={this.state.lowr}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label style={{ color: "Black" }}>
                                            Previous beat HR lt{" "}
                                          </label>
                                          <input
                                            type="text"
                                            className={classnames(
                                              "form-control",
                                              {
                                                errors: errors.highr,
                                              }
                                            )}
                                            name="highr"
                                            required=""
                                            id="highr"
                                            onChange={this.onChange}
                                            value={this.state.highr}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label style={{ color: "Black" }}>
                                            Next beat HR gt{" "}
                                          </label>
                                          <input
                                            type="text"
                                            className={classnames(
                                              "form-control",
                                              {
                                                errors: errors.low_2_high,
                                              }
                                            )}
                                            name="low_2_high"
                                            required=""
                                            id="low_2_high"
                                            onChange={this.onChange}
                                            value={this.state.low_2_high}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label style={{ color: "Black" }}>
                                            Next beat HR lt{" "}
                                          </label>
                                          <input
                                            type="text"
                                            className={classnames(
                                              "form-control",
                                              {
                                                errors: errors.high_2_high,
                                              }
                                            )}
                                            name="high_2_high"
                                            required=""
                                            id="high_2_high"
                                            onChange={this.onChange}
                                            value={this.state.high_2_high}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label style={{ color: "Black" }}>
                                            Previous Arrhythmia
                                          </label>
                                          <select
                                            name="prev_arh"
                                            className="form-control"
                                            value={this.state.prev_arh}
                                            onChange={this.onChange}
                                          >
                                            <option value="Current">
                                              Current{" "}
                                            </option>
                                            <option value="nsr">
                                              Normal Sinus Rhythm
                                            </option>
                                            <option value="st">
                                              Sinus Tachycardia{" "}
                                            </option>
                                            <option value="sb">
                                              Sinus Bradycardia
                                            </option>
                                            <option value="pac">PAC</option>
                                            <option value="pvc">PVC</option>
                                            <option value="sp">
                                              Sinus pause
                                            </option>
                                            <option value="vt">
                                              Ventricular Tachycardia
                                            </option>
                                            <option value="af">
                                              Atrial Fibrillation{" "}
                                            </option>
                                            <option value="flutter">
                                              Atrial Flutter
                                            </option>
                                            <option value="jbeat">
                                              J Beat
                                            </option>
                                            <option value="un">
                                              Undiagnosed
                                            </option>
                                            <option value="Short QT">
                                              SVT
                                            </option>
                                            <option value="Irregular PR">
                                              2AVB
                                            </option>
                                            <option value="P Absent">
                                              3AVB
                                            </option>
                                            <option value="Irregular RR">
                                              CHB
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label style={{ color: "Black" }}>
                                            Next Arrhythmia
                                          </label>
                                          <select
                                            name="next_arh"
                                            className="form-control"
                                            value={this.state.next_arh}
                                            onChange={this.onChange}
                                          >
                                            <option value="Current">
                                              Current{" "}
                                            </option>
                                            <option value="nsr">
                                              Normal Sinus Rhythm
                                            </option>
                                            <option value="st">
                                              Sinus Tachycardia{" "}
                                            </option>
                                            <option value="sb">
                                              Sinus Bradycardia
                                            </option>
                                            <option value="pac">PAC</option>
                                            <option value="pvc">PVC</option>
                                            <option value="sp">
                                              Sinus pause
                                            </option>
                                            <option value="vt">
                                              Ventricular Tachycardia
                                            </option>
                                            <option value="af">
                                              Atrial Fibrillation{" "}
                                            </option>
                                            <option value="flutter">
                                              Atrial Flutter
                                            </option>
                                            <option value="jbeat">
                                              J Beat
                                            </option>
                                            <option value="un">
                                              Undiagnosed
                                            </option>
                                            <option value="Short QT">
                                              SVT
                                            </option>
                                            <option value="Irregular PR">
                                              2AVB
                                            </option>
                                            <option value="P Absent">
                                              3AVB
                                            </option>
                                            <option value="Irregular RR">
                                              CHB
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label style={{ color: "Black" }}>
                                            Start time{" "}
                                          </label>
                                          <select
                                            name="starttime"
                                            className="form-control"
                                            value={this.state.selectedstarttime} // Bind value to selectedstarttime, default to empty
                                            onChange={this.onStarttimeChange}
                                          >
                                            <option value="">
                                              Select Start Time
                                            </option>
                                            {this.state.starttime.map(
                                              (time, index) => (
                                                <option
                                                  key={index}
                                                  value={time}
                                                >
                                                  {time}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label style={{ color: "Black" }}>
                                            End time
                                          </label>
                                          <select
                                            name="endtime"
                                            className="form-control"
                                            value={this.state.selectedendtime} // Bind value to selectedendtime, default to empty
                                            onChange={this.onEndtimeChange}
                                          >
                                            <option value="">
                                              Select End Time
                                            </option>
                                            {this.state.endtime.map(
                                              (time, index) => (
                                                <option
                                                  key={index}
                                                  value={time}
                                                >
                                                  {time}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row row-sm-offset">
                                      <br />
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label style={{ color: "Black" }}>
                                            Check Arrhythmia{" "}
                                          </label>
                                          <select
                                            name="checkArr"
                                            className="form-control"
                                            value={this.state.checkArr}
                                            onChange={this.onChange}
                                          >
                                            <option value="equal">Equal</option>
                                            <option value="notEqual">
                                              Not Equal
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label style={{ color: "Black" }}>
                                            Data{" "}
                                          </label>
                                          <select
                                            name="fullCurrent"
                                            className="form-control"
                                            value={this.state.fullCurrent}
                                            onChange={this.onChange}
                                          >
                                            <option value="Current">
                                              Current
                                            </option>
                                            <option value="unverify">
                                              Unverify
                                            </option>

                                            <option value="verify">
                                              Verify
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label style={{ color: "Black" }}>
                                            Arrhythmia
                                          </label>
                                          <select
                                            name="qRecatArr"
                                            className="form-control"
                                            value={this.state.qRecatArr}
                                            onChange={this.onChange}
                                          >
                                            <option value="Current">
                                              Current{" "}
                                            </option>
                                            <option value="nsr">
                                              Normal Sinus Rhythm
                                            </option>
                                            <option value="st">
                                              Sinus Tachycardia{" "}
                                            </option>
                                            <option value="sb">
                                              Sinus Bradycardia
                                            </option>
                                            <option value="pac">PAC</option>
                                            <option value="pvc">PVC</option>
                                            <option value="sp">
                                              Sinus pause
                                            </option>
                                            <option value="vt">
                                              Ventricular Tachycardia
                                            </option>
                                            <option value="af">
                                              Atrial Fibrillation{" "}
                                            </option>
                                            <option value="flutter">
                                              Atrial Flutter
                                            </option>
                                            <option value="jbeat">
                                              J Beat
                                            </option>
                                            <option value="un">
                                              Undiagnosed
                                            </option>
                                            <option value="Short QT">
                                              SVT
                                            </option>
                                            <option value="Irregular PR">
                                              2AVB
                                            </option>
                                            <option value="P Absent">
                                              3AVB
                                            </option>
                                            <option value="Irregular RR">
                                              CHB
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label style={{ color: "Black" }}>
                                            New Arrhythmia
                                          </label>
                                          <select
                                            name="newArr"
                                            className="form-control"
                                            value={this.state.newArr}
                                            onChange={this.onChange}
                                          >
                                            <option value="Current">
                                              Current{" "}
                                            </option>
                                            <option value="nsr">
                                              Normal Sinus Rhythm
                                            </option>
                                            <option value="st">
                                              Sinus Tachycardia{" "}
                                            </option>
                                            <option value="sb">
                                              Sinus Bradycardia
                                            </option>
                                            <option value="pac">PAC</option>
                                            <option value="pvc">PVC</option>
                                            <option value="sp">
                                              Sinus pause
                                            </option>
                                            <option value="vt">
                                              Ventricular Tachycardia
                                            </option>
                                            <option value="af">
                                              Atrial Fibrillation{" "}
                                            </option>
                                            <option value="flutter">
                                              Atrial Flutter
                                            </option>
                                            <option value="jbeat">
                                              J Beat
                                            </option>
                                            <option value="un">
                                              Undiagnosed
                                            </option>
                                            <option value="Short QT">
                                              SVT
                                            </option>
                                            <option value="Irregular PR">
                                              2AVB
                                            </option>
                                            <option value="P Absent">
                                              3AVB
                                            </option>
                                            <option value="Irregular RR">
                                              CHB
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <div className="row row-sm-offset">
                                      <div className="col-md-6 multi-horizontal">
                                        <button
                                          type="submit"
                                          className="btn btn-success"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                      <div className="col-md-6 multi-horizontal text-left">
                                        <button
                                          id=""
                                          type="button"
                                          className="btn btn-default closePopup"
                                          onClick={this.enableKey}
                                          data-dismiss="modal"
                                        >
                                          Close
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                          {this.state.isCommentModalOpen && (
                            <div
                              className="modal fade in"
                              role="dialog"
                              style={{ display: "block" }}
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <button
                                      type="button"
                                      className="close"
                                      onClick={this.handleCommentModalClose}
                                      data-dismiss="modal"
                                    >
                                      &times;
                                    </button>
                                    <h4 className="modal-title text-center">
                                      Comments
                                    </h4>
                                  </div>
                                  <form
                                   
                                  >
                                    <div className="modal-body">
                                      <div className="form-group">
                                        <label style={{ color: "Black" }}>
                                          Comments
                                        </label>
                                        <textarea
                                          className="form-control"
                                          value={this.state.comments}
                                          onChange={(e)=>{this.setState({comments:e.target.value})}}
                                          style={{height:'150px'}}
                                        />
                                      </div>
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="submit"
                                        className="btn btn-success"
                                        data-dismiss="modal"
                                        onClick={this.submitcommentinfo}
                                      >
                                        Submit
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-default"
                                        onClick={(e) => {
                                          this.setState({
                                            isCommentModalOpen: false,
                                          });
                                        }}
                                        data-dismiss="modal"
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          )}

                          <div
                            id="captureModal"
                            className="modal fade"
                            role="dialog"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <button
                                    type="button"
                                    className="close"
                                    onClick={this.enableKey}
                                    data-dismiss="modal"
                                  >
                                    &times;
                                  </button>
                                  <h4 className="modal-title text-center">
                                    Capture Image Configuration
                                  </h4>
                                </div>
                                <form
                                  method="post"
                                  onSubmit={this.captureImage}
                                >
                                  <div className="modal-body">
                                    <div className="row row-sm-offset">
                                      <br />
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label
                                            className="form-control-label mbr-fonts-style display-7"
                                            style={{ color: "Black" }}
                                          >
                                            Strip Count
                                          </label>
                                          <select
                                            name="stripcount"
                                            className="form-control"
                                            value={this.state.stripcount}
                                            onChange={this.onChange}
                                          >
                                            <option value="">Choose </option>
                                            <option value="1">1 Min</option>
                                            <option value="2">2 Min</option>
                                            <option value="5">5 Min</option>
                                            <option value="10">10 Min</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label
                                            className="form-control-label mbr-fonts-style display-7"
                                            style={{ color: "Black" }}
                                          >
                                            Label Info
                                          </label>
                                          <select
                                            name="labelinfo"
                                            className="form-control"
                                            value={this.state.labelinfo}
                                            onChange={this.onChange}
                                          >
                                            <option value="">Choose </option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row row-sm-offset">
                                      <br />
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label
                                            className="form-control-label mbr-fonts-style display-7"
                                            style={{ color: "Black" }}
                                          >
                                            Add Symptoms And Comments
                                          </label>
                                          <input
                                            type="text"
                                            className={classnames(
                                              "form-control",
                                              {
                                                errors: errors.sel_arr,
                                              }
                                            )}
                                            name="sel_arr"
                                            required=""
                                            id="sel_arr"
                                            onChange={this.onChange}
                                            value={this.state.sel_arr}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label
                                            className="form-control-label mbr-fonts-style display-7"
                                            style={{ color: "Black" }}
                                          >
                                            New{" "}
                                          </label>
                                          <input
                                            type="text"
                                            className={classnames(
                                              "form-control",
                                              {
                                                errors: errors.newName,
                                              }
                                            )}
                                            name="newName"
                                            required=""
                                            id="newName"
                                            onChange={this.onChange}
                                            value={this.state.newName}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row row-sm-offset">
                                      <br />
                                      <div className="col-md-6 multi-horizontal">
                                        <div className="form-group">
                                          <label
                                            className="form-control-label mbr-fonts-style display-7"
                                            style={{ color: "Black" }}
                                          >
                                            List of Arrhythmia
                                          </label>
                                          <select
                                            name="arr_list"
                                            className="form-control"
                                            value={this.state.arr_list}
                                            onChange={this.onChange}
                                          >
                                            <option value="">Choose </option>
                                            <option
                                              value="CURRENT"
                                              style={{ color: "Black" }}
                                            >
                                              CURRENT
                                            </option>

                                            <option
                                              label="SINUS RHYTHMS"
                                              style={{
                                                fontWeight: "bold",
                                                color: "black",
                                              }}
                                              disabled
                                            />

                                            <option value="min">
                                              Minimum HR
                                            </option>
                                            <option value="max">
                                              Maximum HR
                                            </option>
                                            <option value="nsr">
                                              Normal Sinus Rhythm
                                            </option>
                                            <option value="st">
                                              Sinus Tachycardia{" "}
                                            </option>
                                            <option value="sb">
                                              Sinus Bradycardia
                                            </option>
                                            <option value="sa">
                                              Sinus Arrhythmia
                                            </option>

                                            <option
                                              label="ADDITIONAL"
                                              style={{
                                                fontWeight: "bold",
                                                color: "black",
                                              }}
                                              disabled
                                            />
                                            <option value="sym">
                                              Diary Notes
                                            </option>

                                            <option
                                              label="ATRIAL ECTOPICS"
                                              style={{
                                                fontWeight: "bold",
                                                color: "black",
                                              }}
                                              disabled
                                            />
                                            <option value="pac">PAC</option>
                                            <option value="couplet_pac">
                                              PAC couplet
                                            </option>
                                            <option value="Triplet_pac">
                                              PAC Triplet
                                            </option>
                                            <option value="run_pac">
                                              PAC Run
                                            </option>
                                            <option value="bi_pac">
                                              PAC Bigeminy{" "}
                                            </option>
                                            <option value="tri_pac">
                                              PAC Trigeminy
                                            </option>
                                            <option value="at">
                                              Atrial Tachycardia
                                            </option>
                                            <option value="svt">
                                              Supraventricular Tachycardia
                                            </option>
                                            <option value="flutter">
                                              Atrial Flutter
                                            </option>
                                            <option value="af">
                                              Atrial Fibrillation{" "}
                                            </option>
                                            <option value="ncar">
                                              Non Conducted Atrial Response
                                            </option>

                                            <option
                                              label="VENTRICULAR ECTOPICS"
                                              style={{
                                                fontWeight: "bold",
                                                color: "black",
                                              }}
                                              disabled
                                            />
                                            <option value="pvc">PVC</option>
                                            <option value="couplet_pvc">
                                              PVC couplet
                                            </option>
                                            <option value="Triplet_pvc">
                                              PVC Triplet
                                            </option>
                                            <option value="run_pvc">
                                              PVC Run{" "}
                                            </option>
                                            <option value="bi_pvc">
                                              PVC Bigeminy
                                            </option>
                                            <option value="tri_pvc">
                                              PVC Trigeminy
                                            </option>
                                            <option value="vt">
                                              Ventricular Tachycardia
                                            </option>
                                            <option value="ivr">
                                              IdioVentricular Rhythm
                                            </option>
                                            <option value="aivr">
                                              Accelerated IdioVentricular Rhythm{" "}
                                            </option>

                                            <option
                                              label="BLOCKS"
                                              style={{
                                                fontWeight: "bold",
                                                color: "black",
                                              }}
                                              disabled
                                            />
                                            <option value="block">
                                              Sinus Block
                                            </option>
                                            <option value="arrest">
                                              Sinus Arrest
                                            </option>
                                            <option value="sp">
                                              Sinus pause
                                            </option>
                                            <option value="1avb">
                                              First Degree AV Block
                                            </option>
                                            <option value="2avb">
                                              Second Degree AV Block
                                            </option>
                                            <option value="2avb_type1">
                                              Second Degree AV Block Type 1
                                            </option>
                                            <option value="2avb_type2">
                                              Second Degree AV Block Type 2
                                            </option>
                                            <option value="2avb2:1">
                                              Second Degree AV Block 2:1
                                            </option>
                                            <option value="2avb3:1">
                                              Second Degree AV Block 3:1
                                            </option>
                                            <option value="chb">
                                              Complete Heart Block
                                            </option>

                                            <option
                                              label="JUNCTIONAL ECTOPICS"
                                              style={{
                                                fontWeight: "bold",
                                                color: "black",
                                              }}
                                              disabled
                                            />
                                            <option value="pjc">PJC</option>
                                            <option value="jc">
                                              Junctional Couplet
                                            </option>
                                            <option value="j">
                                              Junctional Beats
                                            </option>
                                            <option value="jt">
                                              Junctional Tachycardia
                                            </option>
                                            <option value="jer">
                                              Junctional Escape Rhythm
                                            </option>
                                            <option value="ajr">
                                              Accelerated Junctional Rhythm
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <div className="row row-sm-offset">
                                      <div className="col-md-6 multi-horizontal">
                                        <button
                                          type="submit"
                                          className="btn btn-success"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                      <div className="col-md-6 multi-horizontal text-left">
                                        <button
                                          id=""
                                          type="button"
                                          className="btn btn-default closePopup"
                                          onClick={this.enableKey}
                                          data-dismiss="modal"
                                        >
                                          Close
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

VerifierChart.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(VerifierChart);
