import React, { useState, useEffect } from "react";
import axios from "axios";
import apiUrl from "../../../config/config";
import "./ReportsCompleted.css"; // Import CSS for dark theme
import Loading from "./Loading"; // Import Loading component

const ReportsCompleted = () => {
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Add loading state
  const patientsPerPage = 8;
  const maxPageNumbersToShow = 8;

  const fetchPatients = async () => {
    try {
      const response = await axios.post(`${apiUrl}api/users/getReportPatients`);
      const filteredPatients = response.data.patients.filter(
        (patient) => patient.ReportPublished !== "NA"
      );
      const sortedPatients = filteredPatients.sort(
        (a, b) => new Date(b.ReportPublished) - new Date(a.ReportPublished) // Descending order
      );
      setPatients(sortedPatients);
      setFilteredPatients(sortedPatients);
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error("Error fetching patients:", error);
      setLoading(false); // Set loading to false in case of error
    }
  };

  useEffect(() => {
    fetchPatients();
  }, []);

  // Get current patients
  const indexOfLastPatient = currentPage * patientsPerPage;
  const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
  const currentPatients = filteredPatients.slice(
    indexOfFirstPatient,
    indexOfLastPatient
  );

  // Get pagination numbers
  const totalPages = Math.ceil(filteredPatients.length / patientsPerPage);
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxPageNumbersToShow / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);
  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Get total patient count and date range
  const totalPatients = patients.length;
  const startDate =
    totalPatients > 0
      ? new Date(
          patients[totalPatients - 1].ReportPublished
        ).toLocaleDateString()
      : "N/A";
  const endDate =
    totalPatients > 0
      ? new Date(patients[0].ReportPublished).toLocaleDateString()
      : "N/A";

  // Get current month date range and count
  const currentMonthStart = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  const currentMonthEnd = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  );
  const currentMonthPatients = patients.filter(
    (patient) =>
      new Date(patient.ReportPublished) >= currentMonthStart &&
      new Date(patient.ReportPublished) <= currentMonthEnd
  );
  const currentMonthCount = currentMonthPatients.length;

  // Get last 7 days date range and count
  const last7DaysStart = new Date();
  last7DaysStart.setDate(last7DaysStart.getDate() - 7);
  const last7DaysPatients = patients.filter(
    (patient) => new Date(patient.ReportPublished) >= last7DaysStart
  );
  const last7DaysCount = last7DaysPatients.length;

  // Get today's date and count
  const todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0);
  const todayEnd = new Date();
  todayEnd.setHours(23, 59, 59, 999);
  const todayPatients = patients.filter(
    (patient) =>
      new Date(patient.ReportPublished) >= todayStart &&
      new Date(patient.ReportPublished) <= todayEnd
  );
  const todayCount = todayPatients.length;

  // Filter functions
  const filterTotalReports = () => {
    setFilteredPatients(patients);
    setCurrentPage(1);
  };

  const filterCurrentMonthReports = () => {
    setFilteredPatients(currentMonthPatients);
    setCurrentPage(1);
  };

  const filterLast7DaysReports = () => {
    setFilteredPatients(last7DaysPatients);
    setCurrentPage(1);
  };

  const filterTodayReports = () => {
    setFilteredPatients(todayPatients);
    setCurrentPage(1);
  };

  return (
    <div className="reports-completed">
      {loading ? (
        <Loading /> // Show Loading component while data is being fetched
      ) : (
        <>
          <table className="reports-table">
            <thead>
              <tr>
                <th>Sl No</th>
                <th>Name</th>
                <th>Report Completed Time</th>
                <th>Report Shared</th>
              </tr>
            </thead>
            <tbody>
              {currentPatients.map((patient, index) => (
                <tr key={index}>
                  <td>{indexOfFirstPatient + index + 1}</td>
                  <td>{patient.name}</td>
                  <td>{patient.ReportPublished}</td>
                  <td>
                    {patient.mailsentime && patient.whatsappsenttime
                      ? `Mail: ${patient.mailsentime}, WhatsApp: ${patient.whatsappsenttime}`
                      : patient.mailsentime
                      ? `Mail: ${patient.mailsentime}`
                      : patient.whatsappsenttime
                      ? `WhatsApp: ${patient.whatsappsenttime}`
                      : "Not Shared"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="reports-pagination">
            {pageNumbers.map((number) => (
              <button
                key={number}
                onClick={() => paginate(number)}
                className={currentPage === number ? "active" : ""}
              >
                {number}
              </button>
            ))}
          </div>
          <div className="report-summary-container">
            <div className="report-summary-box" onClick={filterTotalReports}>
              <h3 style={{ color: "white" }}>Total report completed</h3>
              <p>Start time: {startDate}</p>
              <p>End time: {endDate}</p>
              <p>Total patients: {totalPatients}</p>
            </div>
            <div
              className="report-summary-box"
              onClick={filterCurrentMonthReports}
            >
              <h3 style={{ color: "white" }}>Current month report completed</h3>
              <p>Start time: {currentMonthStart.toLocaleDateString()}</p>
              <p>End time: {currentMonthEnd.toLocaleDateString()}</p>
              <p>Total patients: {currentMonthCount}</p>
            </div>
            <div
              className="report-summary-box"
              onClick={filterLast7DaysReports}
            >
              <h3 style={{ color: "white" }}>Weekly report completed</h3>
              <p>Start time: {last7DaysStart.toLocaleDateString()}</p>
              <p>End time: {new Date().toLocaleDateString()}</p>
              <p>Total patients: {last7DaysCount}</p>
            </div>
            <div className="report-summary-box" onClick={filterTodayReports}>
              <h3 style={{ color: "white" }}>Today's report completed</h3>
              <p>Date: {new Date().toLocaleDateString()}</p>
              <p>Total patients: {todayCount}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ReportsCompleted;
