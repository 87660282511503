import React, { Component } from "react";
import axios from "axios";
import apiUrl from "../../config/config";
import classnames from "classnames";
import $ from "jquery";
import socketIOClient from "socket.io-client";
import { MDBDataTable } from "mdbreact";
import ProgressBtn from "../../components/common/ProgressButton";
import TopNav from "../../components/common/TopNav";
import SideBar from "../../components/common/SideBar";
import AddModalComplete from "../verifier/AddModalComplete";
import "react-dropdown/style.css";
import { Toaster, toast } from "react-hot-toast";

import "./verifierhome.css";

import Select from "react-select";
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from "worker-loader!./worker.js";
import { ar } from "date-fns/locale";
import url from "socket.io-client/lib/url";

const socket = socketIOClient("https://web.mybiocalculus.com:5000");

const customStyles = {
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: "10px",
    position: "relative",
  },
  linearProgress: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    opacity: 0.4,
    borderRadius: 4,
  },
};
const menus = [
      { title: "Verifier Home", url: "/verifier-home" },
      { title: "Patient list", url: "/patient-list" },
    
    ];
    if (localStorage.getItem("verifiertype") === "8") {
      menus.splice(2, 0, { title: "Patient-hold", url: "/patient-hold" });
    }
    if (localStorage.getItem("verifierid") === "5d6df589c3d92110fccfde7c") {
      menus.splice(3, 0, { title: "Device Deatils", url: "/device-details" });
    }
    if (localStorage.getItem("verifiertype") === "8") {
      menus.splice(4, 0, { title: "Support Home", url: "support-home" });
    }
    if (localStorage.getItem("verifiertype") === "8") {
      menus.splice(5, 0, { title: "QC Home", url: "qc_home" });
    }
    if (localStorage.getItem("verifiertype") === "8") {
      menus.splice(6, 0, { title: "Support Web", url: "support-homeweb" });
    }
class PatientHold extends Component {
  constructor() {
    super();
    this.state = {
      menus,
      isModalCompleteOpen: false,
      profileLink: "verifier-profile",
      patients: [],
      nor_minhr: "",
      nor_maxhr: "",
      qtcformula: "",
      qtcvalue_min: "",
      qtcvaluemax: "",
      qrswidth_min: "",
      qrswidth_max: "",
      qrsamp_min: "",
      qrsamp_max: "",
      stelevation: "",
      pwidth_min: "",
      pwidth_max: "",
      pamp_min: "",
      pamp_max: "",
      stdepression: "",
      twidth_min: "",
      twidth_max: "",
      tamp_min: "",
      tamp_max: "",
      printerval_min: "",
      printerval_max: "",
      tachyhr_lower: "",
      tachyhr_upper: "",
      bradyhr_lower: "",
      bradyhr_upper: "",
      pauselength: "",
      graph_gen: "",
      errors: {},
      userid: "",
      duration: "",
      smoking: "",
      diabetes: "",
      height: "",
      weight: "",
      hospital: "",
      phone: "",
      patient_name: "",
      email_id: "",
      dob: "",
      af: "",
      hyper_tension: "",
      hyper_lipidemia: "",
      indication: "",
      thyroid_function: "",
      cardiac_surgery: "",
      doctors: [],
      verifiers: [],
      selectedVerifierIds: [],
      selectedVerifiers: [],
      doctor_id: "",
      doctor_name: "Choose",
      selectedDoctor: null,
      data: {},
      prog_data: 0,
      loading: false,
      socketNew: socket,
      patientDetails: null,
      ipop: "",
      priority: "",
      reportstatus: "",
      percentageVerified: "",
      verifiertype: "",
      comments: "",
      customermailid: "",
      mailuserid: "",
      device_id: "",
      patientName: "",
      mailsubject: "",
      whatsappNumber: "",
      arrythymia: "",
      whatsappUserid: "",
      columns: [],
      rows: [],
      searchQuery: "",
      currentPage: 1,
      rowsPerPage: 50,
      pacemaker: false,
      issueReport: "",
      reportPhone: "",
      selectedType: "customer", // Add this line to set the default value
      patientmailid: "",
      patientmailsubject: "",
      patientwhatsappNumber: "",
      patientwhatsappMessage: "",
      setCompletionDate: "",
      isConfirmationModalOpen: "",
      selecetdConfirmation: "",
      selectedPatientId: null,
    };
    this.selectItem = this.selectItem.bind(this);
    this.getInfo = this.getInfo.bind(this);
    this.sentMailReport = this.sentMailReport.bind(this);
    this.sentWhatsappReport = this.sentWhatsappReport.bind(this);
    this.preventDefault = this.preventDefault.bind(this);
    this.filterRows = this.filterRows.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleConfirmationClick = this.handleConfirmationClick.bind(this);
    this.handleConfirmationChange = this.handleConfirmationChange.bind(this);
    this.handleConfirmationSubmit = this.handleConfirmationSubmit.bind(this);
    this.handleConfirmationCancel = this.handleConfirmationCancel.bind(this);
  }

  componentDidMount() {
    if (
      (localStorage.getItem("verifiertype") !== "8") &
      (localStorage.getItem("verifiertype") !== "2")
    ) {
      this.props.history.push("/error");
    } else {
      this.fetchData();
    }
  }
  fetchData = (e) => {
    const verifiertype = localStorage.getItem("verifiertype");
    // console.log('verifierid:',verifiertype)
    this.setState({ verifiertype: verifiertype });
    const worker = new Worker();

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    var newstat = this;
    /*socket.on('connect', function(){console.log('connected')});
    socket.on('connect_error', function(err){console.log(err)});
    socket.on('data', function(data){


      newstat.setState({"loading":true});
      
      console.log(newstat.state.loading)
    });
    socket.on('disconnect', function(){console.log('disconnected')});*/
    //   axios
    //        .get(apiUrl+'api/users/alldoctors')
    //        .then(res => {
    //          this.setState({ ['doctors']: res.data })
    //        })
    //        .catch(err => {
    //          this.setState({ ['doctors']: [] })
    //        });

    worker.postMessage({
      name: "alldoctors",
      url: apiUrl + "api/users/alldoctors",
    });
    worker.onmessage = function (event) {};
    worker.addEventListener("message", function (event) {
      newstat.setState({ ["doctors"]: event.data });
      //console.log(newstat.state.doctors);
    });
    axios
      .get(apiUrl + "api/users/getVerifierPatientsHold")
      .then((res) => {
        const sortedData = res.data.sort((a, b) => {
          const reportOrder = { 0: 5, 2: 4, 4: 3, 5: 2 }; // Order for report_status
          const aReportStatus = reportOrder[a.report_status] || 5;
          const bReportStatus = reportOrder[b.report_status] || 5;

          if (aReportStatus !== bReportStatus) {
            return aReportStatus - bReportStatus;
          }

          // Then, sort by backup_status (1 should come last)
          const aBackupStatus = a.backup_status === 1 ? 2 : 1;
          const bBackupStatus = b.backup_status === 1 ? 2 : 1;

          if (aBackupStatus !== bBackupStatus) {
            return aBackupStatus - bBackupStatus;
          }
        });

        // Set the sorted patients data in state
        this.setState({
          patients: sortedData,
        });
        // console.log('whatsapp sent time', this.state.patients.whatsappsenttime);

        // Loop through object keys if it's not an array
        if (
          typeof this.state.patients === "object" &&
          this.state.patients !== null
        ) {
          Object.keys(this.state.patients).forEach((key) => {
            console.log(
              "whatsapp sent time",
              this.state.patients[key].whatsappsenttime
            );
            console.log("mail sent time", this.state.patients[key].mailsentime);
          });
        }

        const data = {
          columns: [
            {
              label: "SL NO",
              field: "slno",
              sort: "asc",
              width: 5,
            },
            {
              label: "PATIENT ID",
              field: "patientId",
              sort: "asc",
              width: 70,
            },
            {
              label: "ADDED DATE",
              field: "added_date",
              sort: "asc",
              width: 70,
            },
            {
              label: "NAME",
              field: "name",
              sort: "asc",
              width: 130,
            },
            {
              label: "Action",
              field: "action",
              sort: "disabled",
              width: 160,
            },

            {
              label: "REPORT",
              field: "report",
              sort: "disabled",
              width: 150,
            },
          ],
          rows: [],
        };

        var onClick = this.selectItem.bind(this);
        var getInfo = this.getInfo.bind(this);

        var preventDefault = this.preventDefault.bind(this);
        var createdData = "";
        const options = [
          "RPeaks",
          "Classic",
          "Classical",
          "Classic Negative",
          "AI",
          "AIcodes",
        ];
        const defaultOption = options[0];
        sortedData.map(function (patient, i) {
          // console.log(patient);
          var url = " ";
          if (patient.LastDatasetId != undefined) {
            if (
              patient.backup_status == 0 ||
              patient.backup_status == undefined
            ) {
              url =
                "/verifier-unanalysed/" +
                patient._id +
                "/" +
                patient.LastDatasetId;
            } else {
              url = " ";
            }
          }

          var anl_btn_txt = "teststart";
          var anl_btn_class = "btn-danger";
          var gen_btn_txt = "Not Generated";
          var gen_btn_class = "btn-danger";
          var report_link = "#";
          var report_link_target = "";
          var report_status = patient.report_status;
          window.backup_status = patient.backup_status;
          if (patient.report_status == 1) {
            anl_btn_txt = "Analyzing...";
            anl_btn_class = "btn-success";
          }
          if (patient.report_status >= 2) {
            if (
              patient.backup_status == 0 ||
              patient.backup_status == undefined
            ) {
              url =
                "/verifier-chart/" + patient._id + "/" + patient.LastDatasetId;
              // console.log('percentage!!!!!!!!!!!!!!!!!', patient)
            } else {
              url = " ";
            }
            anl_btn_txt = "test";
            anl_btn_class = "btn-success";
          }
          if (patient.report_status >= 4) {
            gen_btn_txt = "In Preparation";
            gen_btn_class = "btn-yellow";
          }
          if (patient.report_status >= 5) {
            gen_btn_txt = "View Report";
            gen_btn_class = "btn-success";
            report_link_target = "_blank";
          }

          if (
            patient.backup_status == 0 ||
            patient.backup_status == undefined
          ) {
            anl_btn_txt = "TEST3";
          } else if (patient.backup_status == 1) {
            anl_btn_txt = "Retrieve";
          } else if (patient.backup_status == 2) {
            anl_btn_txt = "Retrieving";
          }
          createdData = patient.created_at.split("T");
          data.rows.push({
            slno: i + 1,
            patientId: patient.device_id,
            added_date: createdData[0],

            name: capitalizeFirstLetter(patient.name),
            action: (
              <React.Fragment>
                <div style={{ margin: "auto", width: "100%" }}>
                  <ProgressBtn
                    id={patient._id}
                    bc_stat={patient.backup_status}
                    onSelectModalComplete={this.handleModalComplete}
                    r_stat={patient.report_status}
                    socket={newstat.state.socketNew}
                  />
                  {this.state.verifiertype === "8" && (
                    <button
                      type="button"
                      id={patient._id}
                      style={{ float: "left", marginRight: "10px" }}
                      onClick={getInfo}
                      className="btn btn-warning"
                      data-toggle="modal"
                      data-target="#editInfoModal"
                    >
                      Edit
                    </button>
                  )}

                  <button
                    type="button"
                    id={patient._id}
                    onClick={onClick}
                    style={{ float: "left", marginRight: "10px" }}
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#myModalDHome"
                  >
                    Setting
                  </button>

                  <button
                    type="button"
                    id={patient._id}
                    style={{ float: "left", marginRight: "10px" }}
                    onClick={getInfo}
                    className="btn btn-warning"
                    data-toggle="modal"
                    data-target="#commentinfomodal"
                  >
                    Comments
                  </button>
                  <button
                    type="button"
                    id={patient._id}
                    style={{ float: "left", marginRight: "10px" }}
                    onClick={() =>
                      this.handleConfirmationClick(
                        patient._id,
                        patient.Confirmation
                      )
                    }
                    data-toggle="modal"
                    data-target="#ConfirmInfoModal"
                    className={`btn ${
                      patient.Confirmation === "Yes"
                        ? "btn-success"
                        : patient.Confirmation === "No"
                        ? "btn-danger"
                        : "btn-warning"
                    }`}
                  >
                    {patient.Confirmation || "NO"}
                  </button>
                </div>
              </React.Fragment>
            ),

            report: (
              <React.Fragment>
                {patient.report_status === 5 && (
                  <button
                    type="button"
                    className={"btn " + gen_btn_class}
                    style={
                      gen_btn_class === "btn-yellow"
                        ? { backgroundColor: "#2ecd99" }
                        : {}
                    }
                    onClick={async () => {
                      try {
                        const response = await axios.get(
                          `https://web.mybiocalculus.com:3002/api/users/generateSas/${patient._id}`
                        );

                        const reportUrl = response.data.url;
                        if (reportUrl) {
                          window.open(reportUrl, "_blank"); // Opens the URL in a new tab
                        } else {
                          console.error("No report URL found.");
                        }
                      } catch (error) {
                        console.error("Error fetching report link:", error);
                      }
                    }}
                  >
                    {gen_btn_txt}
                  </button>
                )}
                {patient.report_status !== 5 && (
                  <a href="#" target={report_link_target}>
                    <button
                      type="button"
                      className={"btn " + gen_btn_class}
                      style={
                        gen_btn_class === "btn-yellow"
                          ? { backgroundColor: "#2ecd99" }
                          : {}
                      }
                    >
                      {gen_btn_txt}
                    </button>
                  </a>
                )}

                {this.state.verifiertype == 8 &&
                  patient.report_status === 5 &&
                  patient.QC === true &&
                  patient.ReportPublished !== "NA" && (
                    <button
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "#34A853",
                        width: "100px",
                        height: "40px",
                        border: "none",
                      }}
                      type="button"
                      id={patient._id}
                      onClick={async () => {
                        const response = await axios(
                          `https://web.mybiocalculus.com:3002/api/users/getMailidfromDeviceinfo/${patient.device_id}000000000`
                        );

                        console.log("response", response.data.deviceInfo);
                        this.setState({
                          mailuserid: patient._id,
                          device_id: patient.device_id,
                          patientName: patient.name,
                          mailsubject: `${patient.name} Biocalculus: ${patient.device_id}`,
                          customermailid: response.data.deviceInfo.mail,
                          device_mac: response.data.deviceInfo.mac_id,
                          whatsappNumber: response.data.deviceInfo.whatsapp,
                          patientmailsubject: `${patient.name} Biocalculus: ${patient.device_id}`,
                        });
                        const formatDate = (date) => {
                          let dd = String(date.getDate()).padStart(2, "0");
                          let mm = String(date.getMonth() + 1).padStart(2, "0");
                          let yyyy = date.getFullYear();
                          let hrs = String(date.getHours()).padStart(2, "0");
                          let min = String(date.getMinutes()).padStart(2, "0");
                          return `${yyyy}-${mm}-${dd}T${hrs}:${min}`;
                        };
                        const Priority =
                          (response.data.deviceInfo &&
                            response.data.deviceInfo.Priority) ||
                          4;
                        let finalTime;

                        if (patient.ConfirmupdatedDate) {
                          let confirmButtonTime =
                            patient.ConfirmupdatedDate.split("T");
                          let confirmDate = confirmButtonTime[0];
                          let confirmTime = confirmButtonTime[1]
                            ? confirmButtonTime[1].split("Z")[0]
                            : "";
                          if (confirmTime) {
                            let time = confirmTime.split(".")[0];
                            finalTime = confirmDate + " " + time;
                          }
                        }

                        if (patient.updatedDate && !finalTime) {
                          let updatedDateTime = patient.updatedDate.split(" ");
                          let updatedDate = updatedDateTime[0];
                          let updatedTime = updatedDateTime[1];
                          finalTime = updatedDate + " " + updatedTime;
                        }

                        const now = new Date();
                        const finalDate = new Date(finalTime);
                        const timeDifference = now - finalDate;
                        const hoursDifference =
                          timeDifference / (1000 * 60 * 60);

                        let timeLimit;
                        switch (Priority) {
                          case 1:
                            timeLimit = 1;
                            break;
                          case 2:
                            timeLimit = 2;
                            break;
                          case 3:
                            timeLimit = 3;
                            break;
                          case 4:
                            timeLimit = 4;
                            break;
                          case 5:
                            timeLimit = 5;
                            break;
                          default:
                            timeLimit = 4;
                        }

                        const timeLimitHours =
                          timeLimit * patient.exp_device_use_days;
                        let timePercentage =
                          (hoursDifference / timeLimitHours) * 100;
                        if (timePercentage > 100) timePercentage = 100;

                        const completionRate = timePercentage / hoursDifference;
                        const totalCompletionTime = 100 / completionRate;
                        const remainingTimeInHours =
                          totalCompletionTime - hoursDifference;
                        const remainingTimeInMilliseconds =
                          remainingTimeInHours * 60 * 60 * 1000;
                        const completionDate = new Date(
                          now.getTime() + remainingTimeInMilliseconds
                        );
                        this.setState({
                          setCompletionDate: formatDate(completionDate),
                        });
                        console.log(
                          "getmailscheduletime!!!!!!!!!!!!!!!!!!!!!!!!!!!",
                          this.state.setCompletionDate
                        );
                      }}
                      data-toggle="modal"
                      data-target="#mailinfomodal"
                    >
                      Mail Report
                    </button>
                  )}

                {patient.mailsentime && (
                  <span
                    className="mail-icon"
                    onClick={() =>
                      toast(`Report shared via mail in: ${patient.mailsentime}`)
                    }
                  >
                    <img
                      className="gmail-icon"
                      src="../../../gmail.png"
                      alt="Mail Icon"
                    ></img>
                  </span>
                )}
                {patient.whatsappsenttime && (
                  <span
                    className="mail-icon"
                    onClick={() =>
                      toast(
                        `Report shared via whatspp in: ${patient.mailsentime}`
                      )
                    }
                  >
                    {patient.mailsentime ? "" : "      "}
                    <img
                      className="gmail-icon"
                      src="../../../whatsapp icon.png"
                      alt="WhatsApp Icon"
                    ></img>
                  </span>
                )}

                <input type="hidden" className="patientLink" value={url} />
              </React.Fragment>
            ),
          });
        }, this);
        this.setState({ data: data });
        this.setState({ columns: data.columns });
        this.setState({ rows: data.rows });
      })
      .catch((err) => {});

    $(document).ready(function () {
      $("#mbdTable").on("click", "tr td:nth-child(2)", function () {
        var url = $(this).parent().find(".patientLink").val();
        if (url != " ") {
          window.location.href = url;
          // console.log("url");
        }
      });
      $("#mbdTable").on("click", "tr td:nth-child(3)", function () {
        var url = $(this).parent().find(".patientLink").val();
        if (url != " ") {
          window.location.href = url;
          // console.log("url:", url);
        }
      });
      $("#mbdTable").on("click", "tr td:nth-child(4)", function () {
        var url = $(this).parent().find(".patientLink").val();
        if (url != " ") {
          window.location.href = url;
          // console.log("url");
        }
      });
    });
  };
  handleVerifierChange = (selectedOptions) => {
    this.setState({
      selectedVerifiers: selectedOptions || [], // Update selected verifiers or set empty array if none
    });
    // console.log('selected options',selectedOptions)
    // console.log('selected verifier state',this.state.selectedVerifiers,this.state.selectedVerifierIds)
  };
  handleConfirmationClick(patientId, confirmation) {
    console.log(this.state.patients);
    this.setState({
      isConfirmationModalOpen: true,
      selectedPatientId: patientId,
      selectedConfirmation: confirmation,
    });
  }

  handleConfirmationChange(event) {
    this.setState({ selectedConfirmation: event.target.value });
  }

  handleConfirmationSubmit() {
    const { selectedPatientId, selectedConfirmation } = this.state;
    // Handle the submit action, e.g., make an API call to update the confirmation status
    axios
      .post(`${apiUrl}api/patients/updateConfirmation`, {
        userid: selectedPatientId,
        Confirmation: selectedConfirmation,
      })
      .then((response) => {
        console.log("Confirmation updated:", response.data);
        this.setState({ isConfirmationModalOpen: false });

        toast.success(`Confirmation Updated to : ${selectedConfirmation}`);
        this.fetchData(); // Refresh the data after updating confirmation
      })
      .catch((error) => {
        console.error("Error updating confirmation:", error);
      });
  }

  handleConfirmationCancel() {
    this.setState({ isConfirmationModalOpen: false });
    // console.log('cancel')
  }

  submitComment = async (e) => {
    const userid = e.currentTarget.id;
    const verifiername = localStorage.getItem("verifiername");
    const comments = `${this.state.comments} -BY ${verifiername}`; // Replace with the actual comment input
    // console.log('comments',comments)
    try {
      const response = await axios.post(
        `${apiUrl}api/patients/addComment/${userid}`,
        { comments }
      );
      $(".closePopup").click();
      this.setState({ comments: "" });
      // this.fetchData() // Clear the input field
      // console.log(response.data); // Log the response from the server
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };
  sentMailReport = async (e) => {
    const mailid = this.state.customermailid;
    const userid = this.state.mailuserid;

    if (!userid || !mailid) {
      toast.error("Enter Email ID to proceed");
      return;
    }

    var apiUrl = `https://web.mybiocalculus.com:3002/api/users/sendmailReport_new_new_Read_Schedule`;

    var requestBody = {
      userid: userid,
      mailid: mailid,
      mailsubject:
        this.state.mailsubject ||
        `${this.state.patientName}: Biocalculus: ${this.state.device_id}`,
      scheduledTime: this.state.setCompletionDate, // Add scheduled time for customer
    };

    await axios.post(
      `https://web.mybiocalculus.com:3002/api/users/updateMailId`,
      {
        mailid: this.state.customermailid,
        mac_id: this.state.device_mac,
        phone: this.state.whatsappNumber,
        device_id: this.state.device_id,
      }
    );

    fetch(apiUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    })
      .then((response) =>
        response.json().then((data) => {
          if (!response.ok)
            throw new Error(data.message || "Failed to send email.");
          return data;
        })
      )
      .then((data) => {
        toast.success(data.message);
        $(".closePopup").click();
        this.setState({ customermailid: "", whatsappNumber: "", mailid: "" });
      })
      .catch((error) => {
        toast.error(error.message || "An error occurred while sending mail.");
      });
  };

  sentWhatsappReport = async (e) => {
    const number = this.state.whatsappNumber;
    const userid = this.state.mailuserid;
    const arrythymia = this.state.arrythymia || "NA";

    if (!arrythymia || !number) {
      toast.error("Enter WhatsApp number to proceed");
      return;
    }

    var apiUrl = `https://web.mybiocalculus.com:3002/api/common/schedule_whatsapp`;

    var requestBody = {
      user_id: userid,
      arrythymia: arrythymia,
      number: number,
      scheduledTime: this.state.setCompletionDate,
    };

    fetch(apiUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    })
      .then((response) =>
        response.json().then((data) => {
          if (!response.ok)
            throw new Error(data.message || "Failed to send WhatsApp message.");
          return data;
        })
      )
      .then((data) => {
        toast.success("WhatsApp message sent successfully!");
        this.setState({
          customermailid: "",
          whatsappNumber: "",
          mailid: "",
          arrythymia: "",
        });
        $(".closePopup").click();
      })
      .catch((error) => {
        toast.error(
          error.message || "An error occurred while sending WhatsApp message."
        );
      });
  };
  sentMailReportPatient = async (e) => {
    const mailid = this.state.patientmailid;
    const userid = this.state.mailuserid;

    if (!userid || !mailid) {
      toast.error("Enter Email ID to proceed");
      return;
    }

    var apiUrl = `https://web.mybiocalculus.com:3002/api/users/sendmailReport_new_new_Read_Schedule`;

    var requestBody = {
      userid: userid,
      mailid: mailid,
      mailsubject:
        this.state.patientmailsubject ||
        `${this.state.patientName}: Biocalculus: ${this.state.device_id}`,
      scheduledTime: this.state.setCompletionDate, // Add scheduled time for patient
    };

    fetch(apiUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    })
      .then((response) =>
        response.json().then((data) => {
          if (!response.ok)
            throw new Error(data.message || "Failed to send email.");
          return data;
        })
      )
      .then((data) => {
        toast.success("Mail sent successfully!");
        $(".closePopup").click();
      })
      .catch((error) => {
        toast.error(error.message || "An error occurred while sending mail.");
      });
  };

  sentWhatsappReportPatient = async (e) => {
    const number = this.state.patientwhatsappNumber;
    const userid = this.state.mailuserid;
    const arrythymia = this.state.arrythymia || "NA";

    if (!arrythymia || !number) {
      toast.error("Enter WhatsApp number to proceed");
      return;
    }

    var apiUrl = `https://web.mybiocalculus.com:3002/api/common/schedule_whatsapp`;

    var requestBody = {
      user_id: userid,
      arrythymia: arrythymia,
      number: number,
      scheduledTime: this.state.setCompletionDate, // Add scheduled time for patient
    };

    fetch(apiUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    })
      .then((response) =>
        response.json().then((data) => {
          if (!response.ok)
            throw new Error(data.message || "Failed to send WhatsApp message.");
          return data;
        })
      )
      .then((data) => {
        toast.success("WhatsApp message sent successfully!");
      })
      .catch((error) => {
        toast.error(
          error.message || "An error occurred while sending WhatsApp message."
        );
      });
  };

  handleModalComplete(id) {
    // console.log("id:", id);
    this.state.userid = id;
    this.setState({ isModalCompleteOpen: true });
  }
  onSubmit = (e) => {
    e.preventDefault();

    const settings = {
      userid: this.state.userid,
      nor_minhr: this.state.nor_minhr,
      nor_maxhr: this.state.nor_maxhr,
      qtcformula: this.state.qtcformula,
      qtcvalue_min: this.state.qtcvalue_min,
      qtcvaluemax: this.state.qtcvaluemax,
      qrswidth_min: this.state.qrswidth_min,
      qrswidth_max: this.state.qrswidth_max,
      qrsamp_min: this.state.qrsamp_min,
      qrsamp_max: this.state.qrsamp_max,
      stelevation: this.state.stelevation,
      pwidth_min: this.state.pwidth_min,
      pwidth_max: this.state.pwidth_max,
      pamp_min: this.state.pamp_min,
      pamp_max: this.state.pamp_max,
      stdepression: this.state.stdepression,
      twidth_min: this.state.twidth_min,
      twidth_max: this.state.twidth_max,
      tamp_min: this.state.tamp_min,
      tamp_max: this.state.tamp_max,
      printerval_min: this.state.printerval_min,
      printerval_max: this.state.printerval_max,
      tachyhr_lower: this.state.tachyhr_lower,
      tachyhr_upper: this.state.tachyhr_upper,
      bradyhr_lower: this.state.bradyhr_lower,
      bradyhr_upper: this.state.bradyhr_upper,
      pauselength: this.state.pauselength,
      graph_gen: this.state.graph_gen,
    };

    // console.log(settings);
    axios
      .post(apiUrl + "api/patients/updateSettings", settings)
      .then((res) => {
        $("#closePopup").click();
        // console.log("onclick");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onSubmitInfo = (e) => {
    e.preventDefault();
    this.state.dob = document.getElementsByName("dob")[0].value;

    //   this.state.selectedVerifiers.forEach(verifier => {
    //     console.log("Verifier ID:", verifier.value);
    // });

    const info = {
      name: this.state.patient_name,
      patientid: this.state.userid,
      userid: this.state.userid,
      dob: this.state.dob,
      weight: this.state.weight,
      height: this.state.height,
      hospital: this.state.hospital,
      phone: this.state.phone,
      email: this.state.email_id,
      doctorid: this.state.selectedDoctor.value,
      ipop: this.state.ipop,
      verifierid: this.state.selectedVerifiers.map(
        (verifier) => verifier.value
      ),
      priority: this.state.priority,
      reportstatus: this.state.reportstatus,
      // smoking: this.state.smoking,
      // diabetes: this.state.diabetes,
      af: this.state.af,
      // hyper_tension: this.state.hyper_tension,
      // hyper_lipidemia: this.state.hyper_lipidemia,
      indication: this.state.indication,
      cardiac_surgery: this.state.cardiac_surgery,
      thyroid: this.state.thyroid_function,
      exp_device_use_days: this.state.duration,
      hyper_lipidemia: this.state.pacemaker,
      issueReported: this.state.issueReport,
    };
    // console.log('info:',info);
    // console.log("info date!!!!!!!!!",this.state);
    axios
      .post(apiUrl + "api/patients/updateInfo_new_info_new", info)
      .then((res) => {
        // console.log('info',res.data);

        $(".closePopup").click();

        // window.location.href = "/verifier-home";
        this.resetInfoModal();
        this.fetchData();

        //   var data = res.data;
        //   console.log("Name",res.data.name);
        //   this.setState({ patient_name: res.data.name });
        //   this.state.patient_name=res.data.name;
        //   console.log("Name",this.state.patient_name);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onChange = (e) => {
    var err = this.state.errors;
    delete err[e.target.name];
    // console.log("onChange");
    // console.log(e.target.name);
    // console.log(e.target.value);
    // console.log(e.target)
    // this.setState({reportstatus:e.target.value})
    // console.log(e.target.value)

    this.setState({ [e.target.name]: e.target.value });
  };
  onDurationChange = (e) => {
    var err = this.state.errors;
    delete err[e.target.name];
    this.setState({ duration: e.target.value });
    console.log(this.state.duration);
  };
  onPriorityChange = (e) => {
    var err = this.state.errors;
    delete err[e.target.name];

    this.setState({ priority: e.target.value });
    console.log(this.state.priority);
  };
  onReportStatuschange = (e) => {
    // console.log("report stats change",e.target.value)
    this.setState({ reportstatus: e.target.value });
  };

  preventDefault(e) {
    e.preventDefault();
  }

  getInfo(e) {
    this.setState({
      smoking: "",
      diabetes: "",
      af: "",
      hyper_tension: "",
      hyper_lipidemia: "",
      indication: "",
      cardiac_surgery: "",
      thyroid_function: "",
    });
    this.setState({ isModalCompleteOpen: false });

    var userid = e.currentTarget.id || "NA";
    this.setState({ userid: userid });

    axios
      .get(`${apiUrl}api/patients/getPatinetAdditionalInfo?userid=${userid}`)
      .then((res) => {
        const data = res.data[0];

        if (Array.isArray(this.state.patients)) {
          this.state.patients.forEach((patient) => {
            if (patient._id === userid) {
              this.setState({ reportstatus: patient.report_status });
              // console.log("report status", patient.report_status);
            }
          });
        }
        data.customer_details.forEach((customer) => {
          if (customer.customer_details) {
            // console.log('Nested Customer Details:', customer);
            this.setState({ customer_details: customer.customer_details });
          }
        });
        // console.log(data)
        // Update state with patient info
        this.setState({
          af: data.af ? "yes" : "no",
          duration: data.exp_device_use_days,
          cardiac_surgery: data.cardiac_surgery ? "yes" : "no",
          thyroid_function: data.thyroid,
          diabetes: data.diabetes,
          indication: data.indication,
          height: data.height,
          weight: data.weight,
          hospital: data.hospital,
          phone: data.phone,
          patient_name: data.name,
          email_id: data.email,
          selectedDoctor: { value: data.doctor_id, label: data.doctor_name },
          pacemaker: data.hyper_lipidemia,
          issueReport: data.issueReported,

          duration: data.exp_device_use_days,
        });

        // Handle DOB formatting
        const dob = new Date(Date.parse(data.dob));
        const formattedDob = `${
          dob.getMonth() + 1
        }/${dob.getDate()}/${dob.getFullYear()}`;
        this.setState({ dob: formattedDob });

        // Second API call for verifiers
        return axios.get(`${apiUrl}api/users/allverifiers`);
      })
      .then((res) => {
        const verifiers = res.data;
        const formattedVerifiers = verifiers.map((verifier) => ({
          value: verifier._id,
          label: verifier.name,
          patients: verifier.patients, // Assuming patients is an array of user IDs
        }));

        const filteredVerifiers = formattedVerifiers.filter((verifier) =>
          verifier.patients.includes(userid)
        );

        // console.log('All formatted verifiers:', formattedVerifiers);
        // console.log('Filtered verifiers with matching user:', this.state.priority);

        this.setState({ selectedVerifiers: filteredVerifiers });

        this.setState({ verifiers: formattedVerifiers });
        // console.log('formatted verifiers:',verifiers)
        // Third API call for patient search (ipop)
        this.state.patients.forEach((patient) => {
          if (patient._id === userid) {
            this.setState({ comments: patient.comments });
            this.setState({ priority: patient.priority });
            this.setState({ ipop: patient.ip_op });
          }
        });

        // console.log("ipop", this.state.patients);

        // Fourth API call for verified percentage
        return axios.get(`${apiUrl}api/users/getVerifiedPercentage/${userid}`);
      })
      .then((res) => {
        const ecgValues = res.data.ecgValues; // Assuming `ecgValues` is an array
        const totalLength = ecgValues.length;
        let verifyCount = 0; // Initialize verify count

        ecgValues.forEach((value, index) => {
          if (value.verifyStatus === 1) {
            // console.log(`Link for ECG value at index ${index}:`, value.link); // Assuming there's a 'link' property
            verifyCount++; // Increment the verify count
          }
        });

        // console.log('Total verified ECG values:', verifyCount);
        // console.log('Total count:', totalLength);

        if (totalLength > 0) {
          // console.log('ECG data is available.');

          // Calculate and log the percentage of verified ECG values
          const percentageVerified = (verifyCount / totalLength) * 100;
          // console.log('Percentage of verified ECG values:', percentageVerified.toFixed(2) + '%');
          this.setState({ percentageVerified: percentageVerified.toFixed(2) });
          // console.log('verified percentage:',this.state.percentageVerified)
        } else {
          // console.log('No ECG data found.');
        }
      })
      .catch((error) => {
        console.error("Error fetching ECG data:", error);
      });
  }

  selectItem(e) {
    var userid = e.currentTarget.id;
    this.setState({ userid: userid });
    this.setState({
      nor_minhr: "",
      nor_maxhr: "",
      qtcformula: "",
      qtcvalue_min: "",
      qtcvaluemax: "",
      qrswidth_min: "",
      qrswidth_max: "",
      qrsamp_min: "",
      qrsamp_max: "",
      stelevation: "",
      pwidth_min: "",
      pwidth_max: "",
      pamp_min: "",
      pamp_max: "",
      stdepression: "",
      twidth_min: "",
      twidth_max: "",
      tamp_min: "",
      tamp_max: "",
      printerval_min: "",
      printerval_max: "",
      tachyhr_lower: "",
      tachyhr_upper: "",
      bradyhr_lower: "",
      bradyhr_upper: "",
      pauselength: "",
      graph_gen: "",
    });
    axios
      .get(apiUrl + "api/patients/settings?userid=" + userid)
      .then((res) => {
        var data = res.data;
        this.setState(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  resetInfoModal() {
    this.setState({
      smoking: "",
      diabetes: "",
      af: "",
      hyper_tension: "",
      hyper_lipidemia: "",
      indication: "",
      cardiac_surgery: "",
      thyroid_function: "",
      customer_details: "", // Reset customer details too
      reportstatus: "",
      dob: "",
      height: "",
      weight: "",
      hospital: "",
      phone: "",
      patient_name: "",
      email_id: "",
      doctor_name: "",
      doctor_id: "",
      duration: "",
      selectedVerifiers: [],
      verifiers: [],
      comments: "",
      priority: "",
      ipop: "",
      percentageVerified: "",
    });
    // console.log(this.state.verifiers);
  }
  filterRows = () => {
    const { rows, searchQuery } = this.state;

    if (!searchQuery) {
      return rows; // If no search query, return all rows
    }

    return rows.filter((row) => {
      // You can modify this to check specific fields for a match
      return Object.values(row).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  };
  handleSearchChange = (e) => {
    this.setState({ searchQuery: e.target.value });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleNextPage = () => {
    const totalPages = Math.ceil(
      this.filterRows().length / this.state.rowsPerPage
    );
    this.setState((prevState) => ({
      currentPage: Math.min(prevState.currentPage + 1, totalPages),
    }));
  };
  handlePrevPage = () => {
    this.setState((prevState) => ({
      currentPage: Math.max(prevState.currentPage - 1, 1),
    }));
  };
  handleRowClick(event) {
    const target = event.target;
    console.log("clicked!!!!!!!!!!!");
    // Check if the click happened on the specific columns (2, 3, or 4)
    if (target.closest("td:nth-child(2), td:nth-child(3), td:nth-child(4)")) {
      const row = target.closest("tr");
      const urlElement = row.querySelector(".patientLink");
      const url = urlElement ? urlElement.value : ""; // Avoid optional chaining

      // Check if a URL exists and navigate to it
      if (url && url.trim() !== "") {
        window.location.href = url;
      }
    }
  }
  handleToggle = (type) => {
    this.setState({ selectedType: type });
  };
  clearShareReportState = () => {
    this.setState({
      customermailid: "",
      whatsappNumber: "",
      mailid: "",
      arrythymia: "",
      patientmailid: "",
      patientwhatsappNumber: "",
      patientmailsubject: "",
      patientwhatsappMessage: "",
    });
  };

  render() {
    const { isConfirmationModalOpen, selectedConfirmation } = this.state;
    const { currentPage, rowsPerPage } = this.state;
    const { columns, rows } = this.state;

    const totalPages = Math.ceil(this.filterRows().length / rowsPerPage);
    const startIndex = (currentPage - 1) * rowsPerPage;
    const displayedRows = this.filterRows().slice(
      startIndex,
      startIndex + rowsPerPage
    );

    // Pagination range logic (show up to 8 page numbers)
    const paginationNumbers = [];
    const startPage = Math.max(1, currentPage - 4);
    const endPage = Math.min(totalPages, startPage + 7);

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }
    const filteredRows = this.filterRows();
    if (localStorage.jwtToken == undefined) {
      window.location.href = "/";
    }
    const { errors, doctors, selectedDoctor } = this.state;
    const options = doctors.map((doctor) => ({
      value: doctor._id,
      label: doctor.name,
    }));

    let doctorsList =
      this.state.doctors.length > 0 &&
      this.state.doctors.map((item, i) => {
        return (
          <option
            key={i}
            value={item._id}
            selected={this.state.doctor_name == item.name}
          >
            {item.name}
          </option>
        );
      }, this);

    return (
      <div className="wrapper theme-6-active box-layout pimary-color-green">
        <Toaster position="top-right" reverseOrder={false} />
        <TopNav profileLink={this.state.profileLink} />
        <AddModalComplete
          userid={this.state.userid}
          isOpen={this.state.isModalCompleteOpen}
        />

        <SideBar menus={this.state.menus} />
        <div className="right-sidebar-backdrop"></div>
        <div className="page-wrapper" style={{ marginTop: "30px" }}>
          <div className="container-fluid">
            <div id="myModalDHome" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                    <h4 className="modal-title text-center">
                      General Settings
                    </h4>
                  </div>
                  <form method="post" onSubmit={this.onSubmit}>
                    <div className="modal-body">
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Normal Heart Rate Min
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.nor_minhr,
                              })}
                              name="nor_minhr"
                              required=""
                              id="nor_minhr"
                              onChange={this.onChange}
                              value={this.state.nor_minhr}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Normal Heart Rate Max
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.nor_maxhr,
                              })}
                              name="nor_maxhr"
                              required=""
                              id="nor_maxhr"
                              onChange={this.onChange}
                              value={this.state.nor_maxhr}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QTC Formula
                            </label>
                            <select
                              name="qtcformula"
                              className={classnames("form-control", {
                                errors: errors.qtcformula,
                              })}
                              value={this.state.qtcformula}
                              onChange={this.onChange}
                            >
                              <option value="Bazett">Bazett </option>
                              <option value="Fridericia">Fridericia</option>
                              <option value="Framingham">Framingham</option>
                              <option value="Hodges">Hodges</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QTC Value Min
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qtcvalue_min,
                              })}
                              name="qtcvalue_min"
                              required=""
                              id="qtcvalue_min"
                              onChange={this.onChange}
                              value={this.state.qtcvalue_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QTC Value Max
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qtcvaluemax,
                              })}
                              name="qtcvaluemax"
                              required=""
                              id="qtcvaluemax"
                              onChange={this.onChange}
                              value={this.state.qtcvaluemax}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QRS Width Min(ms){" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qrswidth_min,
                              })}
                              name="qrswidth_min"
                              required=""
                              id="qrswidth_min"
                              onChange={this.onChange}
                              value={this.state.qrswidth_min}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QRS Width Max (ms){" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qrswidth_max,
                              })}
                              name="qrswidth_max"
                              required=""
                              id="qrswidth_max"
                              onChange={this.onChange}
                              value={this.state.qrswidth_max}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QRS Amplitude Min (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qrsamp_min,
                              })}
                              name="qrsamp_min"
                              required=""
                              id="qrsamp_min"
                              onChange={this.onChange}
                              value={this.state.qrsamp_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QRS Amplitude Max (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qrsamp_max,
                              })}
                              name="qrsamp_max"
                              required=""
                              id="qrsamp_max"
                              onChange={this.onChange}
                              value={this.state.qrsamp_max}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              ST Elevation (mm)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.stelevation,
                              })}
                              name="stelevation"
                              required=""
                              id="stelevation"
                              onChange={this.onChange}
                              value={this.state.stelevation}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              P Width Min (ms)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.pwidth_min,
                              })}
                              name="pwidth_min"
                              required=""
                              id="pwidth_min"
                              onChange={this.onChange}
                              value={this.state.pwidth_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              P Width Max (ms)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.pwidth_max,
                              })}
                              name="pwidth_max"
                              required=""
                              id="pwidth_max"
                              onChange={this.onChange}
                              value={this.state.pwidth_max}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              P Amplitude Min (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.pamp_min,
                              })}
                              name="pamp_min"
                              required=""
                              id="pamp_min"
                              onChange={this.onChange}
                              value={this.state.pamp_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              P Amplitude Max (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.pamp_max,
                              })}
                              name="pamp_max"
                              required=""
                              id="pamp_max"
                              onChange={this.onChange}
                              value={this.state.pamp_max}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              T Width Min (ms){" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.twidth_min,
                              })}
                              name="twidth_min"
                              required=""
                              id="twidth_min"
                              onChange={this.onChange}
                              value={this.state.twidth_min}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              T Width Max (ms)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.twidth_max,
                              })}
                              name="twidth_max"
                              required=""
                              id="twidth_max"
                              onChange={this.onChange}
                              value={this.state.twidth_max}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              T Amplitude Min (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.tamp_min,
                              })}
                              name="tamp_min"
                              required=""
                              id="tamp_min"
                              onChange={this.onChange}
                              value={this.state.tamp_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              T Amplitude Max (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.tamp_max,
                              })}
                              name="tamp_max"
                              required=""
                              id="tamp_max"
                              onChange={this.onChange}
                              value={this.state.tamp_max}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              PR Interval Min{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.printerval_min,
                              })}
                              name="printerval_min"
                              required=""
                              id="printerval_min"
                              onChange={this.onChange}
                              value={this.state.printerval_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              PR Interval Max{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.printerval_max,
                              })}
                              name="printerval_max"
                              required=""
                              id="printerval_max"
                              onChange={this.onChange}
                              value={this.state.printerval_max}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Tachy Lower Limit{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.tachyhr_lower,
                              })}
                              name="tachyhr_lower"
                              required=""
                              id="tachyhr_lower"
                              onChange={this.onChange}
                              value={this.state.tachyhr_lower}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Tachy Upper Limit{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.tachyhr_upper,
                              })}
                              name="tachyhr_upper"
                              required=""
                              id="tachyhr_upper"
                              onChange={this.onChange}
                              value={this.state.tachyhr_upper}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Brady Lower Limit{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.bradyhr_lower,
                              })}
                              name="bradyhr_lower"
                              required=""
                              id="bradyhr_lower"
                              onChange={this.onChange}
                              value={this.state.bradyhr_lower}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Brady Upper Limit{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.bradyhr_upper,
                              })}
                              name="bradyhr_upper"
                              required=""
                              id="bradyhr_upper"
                              onChange={this.onChange}
                              value={this.state.bradyhr_upper}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              ST Depression (mm)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.stdepression,
                              })}
                              name="stdepression"
                              required=""
                              id="stdepression"
                              onChange={this.onChange}
                              value={this.state.stdepression}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Pause Length (s){" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.pauselength,
                              })}
                              name="pauselength"
                              required=""
                              id="pauselength"
                              onChange={this.onChange}
                              value={this.state.pauselength}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Graphs To Be Generated(no:){" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.graph_gen,
                              })}
                              name="graph_gen"
                              required=""
                              id="graph_gen"
                              onChange={this.onChange}
                              value={this.state.graph_gen}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-success"
                        style={{ color: "Black" }}
                      >
                        Submit
                      </button>
                      {
                        <button
                          id="closePopup"
                          type="button"
                          className="btn btn-default closePopup"
                          data-dismiss="modal"
                          style={{ color: "Black" }}
                        >
                          Close
                        </button>
                      }
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              id="commentinfomodal"
              className="modal fade"
              role="dialog"
              aria-labelledby="commentModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4
                      className="modal-title text-center"
                      id="commentModalLabel"
                    >
                      Add comments
                    </h4>
                  </div>

                  <div className="modal-body">
                    <textarea
                      value={this.state.comments || ""} // Default to empty string if comments is undefined
                      style={{ width: "100%", height: "100px", color: "black" }}
                      placeholder="Enter your comments here"
                      onChange={(e) =>
                        this.setState({ comments: e.target.value })
                      } // Update comments directly in state
                    />
                  </div>
                  <div className="modal-footer">
                    <div className="row row-sm-offset">
                      <div className="col-md-6 multi-horizontal">
                        <button
                          id={this.state.userid}
                          type="submit"
                          className="btn btn-success"
                          style={{ color: "Black" }}
                          onClick={this.submitComment}
                        >
                          Submit
                        </button>
                      </div>
                      <div className="col-md-6 multi-horizontal text-left">
                        <button
                          type="button"
                          className="btn btn-default closePopup"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="mailinfomodal"
              className="modal fade"
              role="dialog"
              aria-labelledby="mailinfomodal"
              aria-hidden="true"
              onClick={(e) => {
                if (
                  e.target.className.includes("close") ||
                  e.target.className.includes("btn-default")
                ) {
                  this.clearShareReportState();
                }
              }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={this.clearShareReportState}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 className="modal-title text-center">Share Report</h4>
                  </div>

                  <div className="modal-body">
                    {/* Switch Selector for Customer/Patient */}
                    <div className="switch-container">
                      <div
                        className={`switch-option ${
                          this.state.selectedType === "customer" ? "active" : ""
                        }`}
                        onClick={() =>
                          this.setState({ selectedType: "customer" })
                        }
                      >
                        Customer
                      </div>
                      <div
                        className={`switch-option ${
                          this.state.selectedType === "patient" ? "active" : ""
                        }`}
                        onClick={() =>
                          this.setState({ selectedType: "patient" })
                        }
                      >
                        Patient
                      </div>
                    </div>

                    {/* Dynamic Form Section */}
                    {this.state.selectedType === "customer" ? (
                      <>
                        {/* Mail Report Section */}
                        <div className="mail-section">
                          <h5>Mail Report (Customer):</h5>
                          <input
                            value={this.state.customermailid}
                            style={{ width: "100%", marginBottom: "10px" }}
                            placeholder="Enter customer email..."
                            onChange={(e) =>
                              this.setState({ customermailid: e.target.value })
                            }
                          />
                          <textarea
                            onChange={(e) =>
                              this.setState({ mailsubject: e.target.value })
                            }
                            value={this.state.mailsubject}
                            placeholder="Enter email subject..."
                            style={{ width: "100%", height: "80px" }}
                          />
                        </div>

                        <hr />

                        {/* WhatsApp Report Section */}
                        <div className="whatsapp-section">
                          <h5>WhatsApp Report (Customer):</h5>
                          <input
                            value={this.state.whatsappNumber}
                            style={{ width: "100%", marginBottom: "10px" }}
                            placeholder="Enter customer WhatsApp number..."
                            onChange={(e) =>
                              this.setState({ whatsappNumber: e.target.value })
                            }
                          />
                          <textarea
                            onChange={(e) =>
                              this.setState({ arrythymia: e.target.value })
                            }
                            value={this.state.whatsappMessage}
                            placeholder="Enter WhatsApp message..."
                            style={{ width: "100%", height: "80px" }}
                          />
                        </div>

                        {/* New field for scheduling mail */}
                        <input
                          type="datetime-local"
                          value={this.state.setCompletionDate}
                          style={{ width: "100%", marginBottom: "10px" }}
                          onChange={(e) =>
                            this.setState({ setCompletionDate: e.target.value })
                          }
                        />
                      </>
                    ) : (
                      <>
                        {/* Mail Report Section for Patient */}
                        <div className="mail-section">
                          <h5>Mail Report (Patient):</h5>
                          <input
                            value={this.state.patientmailid}
                            style={{ width: "100%", marginBottom: "10px" }}
                            placeholder="Enter patient email..."
                            onChange={(e) =>
                              this.setState({ patientmailid: e.target.value })
                            }
                          />
                          <textarea
                            onChange={(e) =>
                              this.setState({
                                patientmailsubject: e.target.value,
                              })
                            }
                            value={this.state.patientmailsubject}
                            placeholder="Enter email subject..."
                            style={{ width: "100%", height: "80px" }}
                          />
                        </div>

                        <hr />

                        {/* WhatsApp Report Section for Patient */}
                        <div className="whatsapp-section">
                          <h5>WhatsApp Report (Patient):</h5>
                          <input
                            value={this.state.patientwhatsappNumber}
                            style={{ width: "100%", marginBottom: "10px" }}
                            placeholder="Enter patient WhatsApp number..."
                            onChange={(e) =>
                              this.setState({
                                patientwhatsappNumber: e.target.value,
                              })
                            }
                          />
                          <textarea
                            onChange={(e) =>
                              this.setState({ arrythymia: e.target.value })
                            }
                            value={this.state.arrythymia}
                            placeholder="Enter WhatsApp message..."
                            style={{ width: "100%", height: "80px" }}
                          />
                        </div>

                        {/* New field for scheduling mail */}
                        <input
                          type="datetime-local"
                          value={this.state.setCompletionDate}
                          style={{ width: "100%", marginBottom: "10px" }}
                          onChange={(e) =>
                            this.setState({ setCompletionDate: e.target.value })
                          }
                        />
                      </>
                    )}
                  </div>

                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-success"
                      data-dismiss="modal"
                      onClick={() => {
                        if (this.state.selectedType === "customer") {
                          this.sentMailReport();
                          this.sentWhatsappReport();
                        } else {
                          this.sentMailReportPatient();
                          this.sentWhatsappReportPatient();
                        }
                        this.clearShareReportState();
                      }}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                      onClick={this.clearShareReportState}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="whatsappinfomodal"
              className="modal fade"
              role="dialog"
              aria-labelledby="whatsappinfomodal"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4
                      className="modal-title text-center"
                      id="whatsappinfomodal"
                    >
                      Share Report Via Whatsapp
                    </h4>
                  </div>

                  <div className="modal-body">
                    <input
                      value={this.state.whatsappNumber || ""} // Default to empty string if comments is undefined
                      style={{ width: "100%", color: "black" }}
                      placeholder="Enter whatsapp to share report....."
                      onChange={(e) =>
                        this.setState({ whatsappNumber: e.target.value })
                      } // Update comments directly in state
                    />
                    <textarea
                      onChange={(e) => {
                        const value = e.target.value; // Get the current value
                        console.log("Typing value:", value); // Log the value to the console
                        this.setState({ arrythymia: value }); // Update state
                      }}
                      // value={this.state.mailsubject} // Bind to the dynamic state
                      placeholder="Enter Report Subject Here..."
                      className="subject-textarea"
                    />
                  </div>
                  <div className="modal-footer">
                    <div className="row row-sm-offset">
                      <div className="col-md-6 multi-horizontal">
                        <button
                          id={this.state.userid}
                          type="submit"
                          className="btn btn-success"
                          style={{ color: "Black" }}
                          onClick={this.sentWhatsappReport}
                        >
                          Submit
                        </button>
                      </div>
                      <div className="col-md-6 multi-horizontal text-left">
                        <button
                          type="button"
                          className="btn btn-default closePopup"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="editInfoModal" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      onClick={this.resetInfoModal.bind(this)}
                    >
                      &times;
                    </button>
                    <h4 className="modal-title text-center">
                      Edit Patient Information
                    </h4>
                  </div>
                  <form method="post" onSubmit={this.onSubmitInfo}>
                    <div className="modal-body">
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="patient_name"
                              onChange={this.onChange}
                              value={this.state.patient_name}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Email
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="email_id"
                              onChange={this.onChange}
                              value={this.state.email_id}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Doctor Name
                            </label>

                            <Select
                              options={options} // Provide the options
                              value={selectedDoctor} // Controlled component value
                              onChange={this.handleDoctorChange} // Handle selection
                              placeholder="Search Doctor"
                              classNamePrefix="react-select"
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  borderColor: errors.doctor_id
                                    ? "red"
                                    : base.borderColor,
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              DOB
                            </label>
                            <div
                              className="input-group date"
                              id="datetimepicker1"
                            >
                              <input
                                type="text"
                                className={classnames("form-control", {
                                  errors: errors.dob,
                                })}
                                onChange={this.onChange}
                                value={this.state.dob}
                                name="dob"
                              />
                              <span className="input-group-addon">
                                <span className="fa fa-calendar"></span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          {/* <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Height
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="height"
                              onChange={this.onChange}
                              value={this.state.height}
                            />
                          </div> */}
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          {/* <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Weight
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="weight"
                              onChange={this.onChange}
                              value={this.state.weight}
                            />
                          </div> */}
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Hospital Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="hospital"
                              onChange={this.onChange}
                              value={this.state.hospital}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Phone
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="phone"
                              onChange={this.onChange}
                              value={this.state.phone}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Assign Verifier
                            </label>

                            <Select
                              isMulti
                              name="Assign Verifier"
                              options={this.state.verifiers} // Pass the verifier options
                              value={this.state.selectedVerifiers} // Set the selected verifiers
                              onChange={this.handleVerifierChange}
                              // Update the selected verifiers on change
                              className="basic-multi-select"
                              classNamePrefix="select"
                              placeholder="Select verifiers..."
                              styles={{
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected
                                    ? "blue"
                                    : state.isFocused
                                    ? "#e5e5e5"
                                    : null,
                                  color: state.isSelected ? "white" : "black",
                                }),
                                multiValue: (provided) => ({
                                  ...provided,
                                  backgroundColor: "blue",
                                }),
                                multiValueLabel: (provided) => ({
                                  ...provided,
                                  color: "white",
                                }),
                                multiValue: (provided, state) => ({
                                  ...provided,
                                  key: state.data.value, // Use the unique value as the key
                                  backgroundColor: "blue",
                                }),
                              }}
                            />

                            {/* Display selected verifier names below the select element */}
                            {this.state.selectedVerifiers.length > 0 && (
                              <div style={{ marginTop: "10px" }}>
                                <strong>Selected Verifiers:</strong>{" "}
                                {this.state.selectedVerifiers
                                  .map((verifier) => verifier.label)
                                  .join(", ")}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Verified percentage
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="verified_percentage"
                              value={this.state.percentageVerified}
                              disabled="true"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Atrial Fibrillation
                            </label>
                            <select
                              name="smoking"
                              className="form-control"
                              value={this.state.af}
                              onChange={this.onChange}
                            >
                              <option value="">Choose </option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Report Status
                            </label>
                            <select
                              name="Report Status"
                              className="form-control"
                              value={this.state.reportstatus}
                              onChange={this.onReportStatuschange}
                            >
                              <option value="0">Not Analayzed </option>

                              <option value="2">Analyze Compeleted </option>
                              <option value="4">In Preparation </option>

                              <option value="5">Report Completed</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              IP/OP
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="ipop"
                              onChange={this.onChange}
                              value={this.state.ipop}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Indication
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="indication"
                              onChange={this.onChange}
                              value={this.state.indication}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              monitoring duration
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="patient_name"
                              onChange={this.onDurationChange}
                              value={this.state.duration}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Pacemaker
                            </label>
                            <select
                              name="Pacemaker"
                              value={this.state.pacemaker}
                              className="form-control"
                              onChange={(e) => {
                                if (e.target.value === "true") {
                                  this.setState({ pacemaker: true });
                                } else if (e.target.value === "false") {
                                  this.setState({ pacemaker: false });
                                }
                              }}
                            >
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Issue Noticed
                            </label>
                            <select
                              name="thyroid_function"
                              className="form-control"
                              value={this.state.issueReport}
                              onChange={(e) => {
                                console.log(e.target.value);
                                this.setState({ issueReport: e.target.value });
                              }}
                            >
                              <option value="NA">Not Selected</option>

                              <option value="repeat">Repeat</option>
                              <option value="low_data">Low data</option>
                              <option value="customer_reject">
                                Customer Reject
                              </option>
                              <option value="battery_issue">
                                Battery Issue
                              </option>
                              <option value="device_issue">Device Issue</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            {/* <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Verified percentage
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="verified_percentage"
                              onChange={this.onChange}
                              value={this.state.percentageVerified}
                              disabled='true'
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <div className="row row-sm-offset">
                        <div className="col-md-6 multi-horizontal">
                          <button
                            type="submit"
                            className="btn btn-success"
                            style={{ color: "Black" }}
                          >
                            Submit
                          </button>
                        </div>
                        {
                          <div className="col-md-6 multi-horizontal text-left">
                            <button
                              id=""
                              type="button"
                              className="btn btn-default closePopup"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        }
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div id="ConfirmInfoModal" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                    <h4 className="modal-title text-center">
                      Confirmation Status
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="form-group">
                      <label
                        className="form-control-label mbr-fonts-style display-7"
                        style={{ color: "Black" }}
                      >
                        Select Status
                      </label>
                      <select
                        className="form-control"
                        value={this.state.selectedConfirmation}
                        onChange={this.handleConfirmationChange}
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="Hold">Hold</option>
                      </select>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-success"
                      data-dismiss="modal"
                      onClick={this.handleConfirmationSubmit}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                      onClick={this.handleConfirmationCancel}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="panel panel-default card-view">
                  <div className="panel-heading">
                    <div className="pull-left">
                      <h6 className="panel-title txt-dark">Patient List</h6>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div className="panel-wrapper collapse in">
                    <div
                      className="search-bar"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        style={{ width: "10%" }}
                        value={this.state.searchQuery}
                        onChange={this.handleSearchChange}
                      />
                    </div>
                    <div className="panel-body">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            {columns.map((col, index) => (
                              <th key={index}>{col.label}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {displayedRows.map((row, rowIndex) => (
                            <tr
                              key={rowIndex}
                              style={{ backgroundColor: row.backgroundColor }}
                            >
                              <td>{startIndex + rowIndex + 1}</td>
                              <td
                                onClick={this.handleRowClick}
                                style={{ cursor: "pointer" }}
                              >
                                {row.patientId}
                              </td>
                              <td
                                onClick={this.handleRowClick}
                                style={{ cursor: "pointer" }}
                              >
                                {row.added_date}
                              </td>
                              <td
                                onClick={this.handleRowClick}
                                style={{ cursor: "pointer" }}
                              >
                                {row.name}
                              </td>
                              <td>{row.action}</td>
                              <td>{row.report}</td>
                              <td>{row.monitoring_duration}</td>{" "}
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      {/* Pagination Controls */}
                      <div
                        className="pagination-container"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "10px",
                        }}
                      >
                        <button
                          onClick={this.handlePrevPage}
                          disabled={currentPage === 1}
                          style={{
                            border: "1px solid #007bff",
                            margin: "0 1px",
                            padding: "5px 10px",
                            cursor: "pointer",
                            borderRadius: "5px",
                            color: "black",
                          }}
                        >
                          Prev
                        </button>
                        {paginationNumbers.map((num) => (
                          <button
                            key={num}
                            onClick={() => this.handlePageChange(num)}
                            style={{
                              fontWeight:
                                num === currentPage ? "bold" : "normal",
                              backgroundColor:
                                num === currentPage ? "#007bff" : "#fff",
                              color: num === currentPage ? "#fff" : "#000",
                              border: "1px solid #007bff",
                              margin: "0 1px",
                              padding: "5px 10px",
                              cursor: "pointer",
                              borderRadius: "5px",
                            }}
                          >
                            {num}
                          </button>
                        ))}
                        <button
                          onClick={this.handleNextPage}
                          disabled={currentPage === totalPages}
                          style={{
                            color: "black",
                            border: "1px solid #007bff",
                            margin: "0 1px",
                            padding: "5px 10px",
                            cursor: "pointer",
                            borderRadius: "5px",
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PatientHold;
