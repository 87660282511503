import React, { useState, useEffect } from "react";
import axios from "axios";
import apiUrl from "../../../config/config";
import moment from "moment";
import "./ReportsCompleted.css"; // Import CSS for dark theme
import Loading from "./Loading"; // Import Loading component

const DailyCount = () => {
  const [analysedData, setAnalysedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Add loading state
  const dataPerPage = 8;
  const maxPageNumbersToShow = 8;

  const fetchAnalysedStatus = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}api/users/getPatientsAnalysedStatus`
      );
      console.log("Analysed status response:", response.data);

      const verifierType = localStorage.getItem("verifiertype");
      const verifierId = localStorage.getItem("verifierid");

      const analysedPatients = response.data.users
        .filter((user) => {
          if (verifierType === "8") {
            return true; // Show all verifiers
          } else if (verifierType === "2") {
            return user._id === verifierId; // Show only the corresponding verifier
          }
          return false;
        })
        .map((user, index) => {
          const dailyECGValues = user.ecgValues.filter((ecg) =>
            moment(ecg.verifyTime, "M/D/YYYY HH:mm:ss").isSame(moment(), "day")
          );
          const weeklyECGValues = user.ecgValues.filter((ecg) =>
            moment(ecg.verifyTime, "M/D/YYYY HH:mm:ss").isSameOrAfter(
              moment().subtract(7, "days")
            )
          );
          const monthlyECGValues = user.ecgValues.filter((ecg) =>
            moment(ecg.verifyTime, "M/D/YYYY HH:mm:ss").isSame(
              moment(),
              "month"
            )
          );

          return {
            serialNo: index + 1,
            verifierName: user.name,
            totalHoursAnalysed: user.ecgValues.length, // Count of ecgValues
            dailyCount: dailyECGValues.length,
            weeklyCount: weeklyECGValues.length,
            monthlyCount: monthlyECGValues.length,
            verifyTime:
              user.ecgValues.length > 0 ? user.ecgValues[0].verifyTime : "N/A",
          };
        });

      setAnalysedData(analysedPatients);
      setFilteredData(analysedPatients);
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error("Error fetching analysed status:", error);
      setLoading(false); // Set loading to false in case of error
    }
  };

  useEffect(() => {
    fetchAnalysedStatus();
  }, []);

  // Get current data
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredData.slice(indexOfFirstData, indexOfLastData);

  // Get pagination numbers
  const totalPages = Math.ceil(filteredData.length / dataPerPage);
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxPageNumbersToShow / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);
  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="reports-completed">
      {loading ? (
        <Loading /> // Show Loading component while data is being fetched
      ) : (
        <>
          <table className="reports-table">
            <thead>
              <tr>
                <th>Serial No</th>
                <th>Verifier Name</th>
                <th>Total Hours Analysed</th>
                <th>Daily Count</th>
                <th>Weekly Count</th>
                <th>Monthly Count</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((data, index) => (
                <tr key={index}>
                  <td>{indexOfFirstData + index + 1}</td>
                  <td>{data.verifierName}</td>
                  <td>{data.totalHoursAnalysed}</td>
                  <td>{data.dailyCount}</td>
                  <td>{data.weeklyCount}</td>
                  <td>{data.monthlyCount}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="reports-pagination">
            {pageNumbers.map((number) => (
              <button
                key={number}
                onClick={() => paginate(number)}
                className={currentPage === number ? "active" : ""}
              >
                {number}
              </button>
            ))}
          </div>
          <div className="report-summary-container">
            <div className="report-summary-box">
              <h3 style={{ color: "white" }}>Total Count</h3>
              <p>
                {analysedData.reduce(
                  (acc, patient) => acc + patient.totalHoursAnalysed,
                  0
                )}
              </p>
              <p>
                Monthly Count:{" "}
                {analysedData.reduce(
                  (acc, patient) => acc + patient.monthlyCount,
                  0
                )}
              </p>
              <p>
                Weekly Count:{" "}
                {analysedData.reduce(
                  (acc, patient) => acc + patient.weeklyCount,
                  0
                )}
              </p>
              <p>
                Daily Count:{" "}
                {analysedData.reduce(
                  (acc, patient) => acc + patient.dailyCount,
                  0
                )}
              </p>
              <p>
                Start time:{" "}
                {analysedData.length > 0 ? analysedData[0].verifyTime : "N/A"}
              </p>
              <p>
                End time:{" "}
                {analysedData.length > 0
                  ? analysedData[analysedData.length - 1].verifyTime
                  : "N/A"}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DailyCount;
