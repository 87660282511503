import React, { useState, useEffect } from "react";
import "./verifierdasdhboard.css";
import ReportsCompleted from "./ReportsCompleted";
import DailyCount from "./DailyCount";
import ActivityLevel from "./ActivityLevel";
import Break from "./Break";
import Productivity from "./Productivity";

const VerifierDashboard = () => {
  const [activeComponent, setActiveComponent] = useState("reports");
  const [verifierType, setVerifierType] = useState(null);

  useEffect(() => {
    const verifierTypeFromStorage = localStorage.getItem("verifiertype");
    setVerifierType(verifierTypeFromStorage);
  }, []);

  const renderComponent = () => {
    switch (activeComponent) {
      case "reports":
        return verifierType === "8" ? <ReportsCompleted /> : null;
      case "daily-count":
        return <DailyCount />;
      case "activity-level":
        return <ActivityLevel />;
      case "break":
        return <Break />;
      case "productivity":
        return <Productivity />;
      default:
        return (
          <p>
            Welcome to the Verifier Dashboard. Select an option from the sidebar
            to view details.
          </p>
        );
    }
  };

  return (
    <div className="dashboard-container dark-theme">
      {/* Sidebar */}
      <div className="sidebar">
        <nav>
          <ul>
            {verifierType === "8" && (
              <li>
                <a
                  href="#"
                  onClick={() => setActiveComponent("reports")}
                  className={activeComponent === "reports" ? "active" : ""}
                >
                  Reports completed
                </a>
              </li>
            )}
            <li>
              <a
                href="#"
                onClick={() => setActiveComponent("daily-count")}
                className={activeComponent === "daily-count" ? "active" : ""}
              >
                Analysed datas
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={() => setActiveComponent("activity-level")}
                className={activeComponent === "activity-level" ? "active" : ""}
              >
                Activity level
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={() => setActiveComponent("break")}
                className={activeComponent === "break" ? "active" : ""}
              >
                Break
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={() => setActiveComponent("productivity")}
                className={activeComponent === "productivity" ? "active" : ""}
              >
                Productivity
              </a>
            </li>
          </ul>
        </nav>
      </div>

      {/* Main content area */}
      <div className="main-content">
        <header>
          <h1>Verifier Dashboard</h1>
        </header>
        <main>{renderComponent()}</main>
      </div>
    </div>
  );
};

export default VerifierDashboard;
