import React from "react";
import "./Loading.css"; // Import the CSS for styling

const Loading = () => {
  return (
    <div className="loading-container">
      <p className="loading-text">loading...</p>
      <div className="bouncing-bars">
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </div>
  );
};

export default Loading;
