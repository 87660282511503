import React, { Component } from "react";
import classnames from "classnames";
import apiUrl from "../../config/config";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import $ from "jquery";
import url from "socket.io-client/lib/url";
class SettingsTab extends Component {
  constructor() {
    super();
    this.state = {
      userid: "",
      diary_notes: [],
      data: {},
      errors: {},
      time: "",
      diary_note_id: "",
      note: "",
      action: "",
      addTime: "",
      addNote: "",
      duration: [],
      activity: [],
      datasets: [],
    };
    this.onChange = this.onChange.bind(this);
    this.onTimeUpdate = this.onTimeUpdate.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ datasets: nextProps.treemenu });
  }
  componentDidMount() {
    //8 10/08/2020

    var self = this;
    let closest = new Date().toISOString();
    $(document).ready(function () {
      $("#mbdTable1").on("", "tr td:nth-child(2)", function () {
        //diary date click functionalaity diabled - 'click'
        var str = $(this)[0].innerText;
        var dt = str.replace(/\//g, "-").split(" ");
        var dt1 = dt[0].split("-");
        var mainDT = dt1[2] + "-" + dt1[0] + "-" + dt1[1] + " " + dt[1];
        var closeFlag = 0;

        var newmainDT = new Date(mainDT);
        console.log("newmainDT", newmainDT);
        newmainDT.setHours(newmainDT.getHours() - 1);

        newmainDT = new Date(newmainDT).toISOString();
        console.log("newmainDT", newmainDT);
        Object.keys(self.state.datasets).map((val, key) => {
          self.state.datasets[val].map((value, key) => {
            const date = new Date(value.date).toISOString();
            if (newmainDT <= date) {
              closest = value.date;
            }
          });
        });
        // console.log('closest',closest);
        if (new Date(closest).toISOString() > new Date(mainDT).toISOString()) {
          Object.keys(self.state.datasets).map((val, key) => {
            self.state.datasets[val].map((value, key) => {
              const date = new Date(value.date).toISOString();
              if (new Date(closest).toISOString() == date && closeFlag == 0) {
                console.log("date1", value.date);
                closeFlag = 1;
              } else if (closeFlag == 1) {
                console.log("date2", value.date);
                closest = value.date;
                closeFlag = 2;
                return;
              }
            });
            if (closeFlag == 2) {
              return;
            }
          });
        }
        console.log("closest", closest);
        //document.getElementById('chart_tab_2').click();
        self.props.onSelectDiary(closest, mainDT);
        //const closest1 = closest.reduce((a, b) => a.Date - newmainDT < b.Date - newmainDT ? a : b);
        //console.log(closest1);
      });
    });
    // var userid = this.props.userid;
    // this.setState({ userid: userid });
    // var getInfo = this.getInfo.bind(this);
    // var deleteDiary = this.deleteDiary.bind(this);
    // var addDiary = this.addDiary.bind(this);
    this.getList();
  }

  search(obj, diary_id) {
    var results = [];
    var searchField = "_id";
    var searchVal = diary_id;
    for (var i = 0; i < obj.length; i++) {
      if (obj[i][searchField] == searchVal) {
        results.push(obj[i]);
      }
    }
    return results;
  }
  getList() {
    console.log("here");
    var getInfo = this.getInfo.bind(this);
    var deleteDiary = this.deleteDiary.bind(this);
    var userid = this.props.userid;
    this.setState({ userid: userid });
    axios
      .get(apiUrl + "api/patients/getDiaryInfo?userid=" + userid)
      .then((res) => {
        var search = this.search.bind(this);
        this.setState({ diary_notes: res.data });
        //  this.setState({act_duration:res.data});
        //  this.setState({event_activity:res.data});
        //  console.log('diary_notes',this.state.diary_notes);
        const data = {
          columns: [
            {
              label: "SL NO",
              field: "slno",
              sort: "asc",
              width: 30,
            },
            {
              label: "Date",
              field: "datetime",
              sort: "asc",
              width: 100,
            },
            {
              label: "Diary Note",
              field: "diary_note",
              sort: "asc",
              width: 100,
            },
            {
              label: "Duration",
              field: "act_duration",
              sort: "asc",
              width: 100,
            },
            {
              label: "activity",
              field: "event_activity",
              sort: "asc",
              width: 100,
            },
            {
              label: "Action",
              field: "action_data",
              sort: "asc",
              width: 100,
            },
            {
              label: "Action",
              field: "action",
              sort: "disabled",
              width: 100,
            },
          ],
          rows: [],
        };
        var arrData = [];
        res.data.map(function (patient, i) {
          if (res.data.length != 0) {
            data.rows.push({
              slno: i + 1,
              datetime: patient.datetime,
              diary_note: patient.diary_notes,
              act_duration: patient.act_duration,
              event_activity: patient.event_activity,
              action_data: patient.action_data,
              action: (
                <React.Fragment>
                  <button
                    type="button"
                    id={patient._id}
                    onClick={getInfo}
                    className="btn btn-warning mr-20"
                    data-toggle="modal"
                    data-target="#editInfoModal"
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    id={patient._id}
                    onClick={deleteDiary}
                    className="btn btn-danger mr-20"
                    style={{ maxWidth: "fit-content" }}
                  >
                    Delete
                  </button>
                </React.Fragment>
              ),
            });
          }
          // arrData.push({id:patient._id,datetime:patient.datetime,diary_note:patient.diary_notes });
        });
        if (res.data.length == 0) {
          data.rows.push({
            slno: "-",
            datetime: "-",
            diary_note: "No Events Noted",

            action: <React.Fragment></React.Fragment>,
          });
        }
        this.setState({ data: data });
        console.log("res.data", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getInfo(e) {
    var diary_id = e.currentTarget.id;
    this.state.diary_note_id = diary_id;
    var arr = this.search(this.state.diary_notes, diary_id);
    this.setState({
      time: arr[0].datetime,
      note: arr[0].diary_notes,
      duration: arr[0].act_duration,
      activity: arr[0].event_activity,
      action: arr[0].action_data,
    });
    // console.log("diary_id",this.state.diary_note_id);
  }

  deleteDiary(e) {
    var diary_id = e.currentTarget.id;
    var uid = this.props.userid;
    var did = this.props.databaseid;
    //console.log("diary_id",diary_id);
    const info = {
      diary_id: diary_id,
    };
    axios
      .post(apiUrl + "api/patients/deleteDiary", info)
      .then((res) => {
        console.log(res.data);
        $(".closePopup").click();
        if (url == "/doctor-home") {
          console.log("infourl", url);
          window.location.href = "/doctor-home/" + uid + "/" + did;
        }
        this.getList();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  addDiary(e) {
    var userid = e.currentTarget.id;
    console.log("diary_id", userid);
  }
  onChange = (e) => {
    var err = this.state.errors;
    delete err[e.target.name];
    this.setState({ [e.target.name]: e.target.value });

    console.log(e.target.name);
    console.log(e.target.value);
  };

  onTimeUpdate = (e) => {
    console.log(e);
    var err = this.state.errors;
    delete err[e.target.name];
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmitInfo = (e) => {
    e.preventDefault();
    var uid = this.props.userid;
    var did = this.props.databaseid;
    var finTime = this.state.time;

    const info = {
      id: this.state.diary_note_id,
      datetime: finTime,
      diary_notes: this.state.note,
      act_duration: this.state.duration,
      event_activity: this.state.activity,
      action_data: this.state.action,
    };
    console.log("info", info);
    axios
      .post(apiUrl + "api/patients/updateDiary", info)
      .then((res) => {
        console.log("response");
        console.log(res.data);
        $(".closePopup").click();
        if (url == "/doctor-home") {
          console.log("infourl", url);
          window.location.href = "/doctor-home/" + uid + "/" + did;
        }
        this.getList();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  onSubmitDiary = (e) => {
    e.preventDefault();
    var uid = this.props.userid;
    var did = this.props.databaseid;
    var finTime = this.state.addTime;

    const info = {
      id: this.props.userid,
      datetime: finTime,
      diary_notes: this.state.addNote,
      act_duration: this.state.duration,
      event_activity: this.state.activity,
      // action: this.state.action
      // activity:"0y"
    };

    // console.log("info", info);
    axios
      .post(apiUrl + "api/patients/addDiary", info)
      .then((res) => {
        console.log(res.data);
        $(".closePopup").click();
        if (url == "/doctor-home") {
          console.log("infourl", url);
          window.location.href = "/doctor-home/" + uid + "/" + did;
        }
        this.getList();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    const { errors } = this.state;

    return (
      <React.Fragment>
        <div className="row mt-5 mt-5hed">
          <div className="col-sm-12" style={{ marginTop: "1%" }}>
            <button
              type="button"
              onClick={this.addDiary}
              className="btn btn-success mr-20 pull-right"
              data-toggle="modal"
              data-target="#addInfoModal"
            >
              Add Diary Notes
            </button>
          </div>
        </div>
        <div className="panel-wrapper collapse in">
          <MDBDataTable id="mbdTable1" noBottomColumns data={this.state.data} />
        </div>
        <div id="editInfoModal" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
                <h4 className="modal-title text-center">Edit Diary Notes</h4>
                <form method="post" onSubmit={this.onSubmitInfo}>
                  <div className="modal-body">
                    <div className="row row-sm-offset">
                      <br />
                      <div className="col-md-6 multi-horizontal">
                        <div className="form-group">
                          <label
                            className="form-control-label mbr-fonts-style display-7"
                            style={{ color: "black" }}
                          >
                            {" "}
                            TIME
                          </label>
                          <input
                            type="datetime-local"
                            value={this.state.time}
                            style={{ width: "100%", marginBottom: "10px" }}
                            onChange={(e) =>
                              this.setState({ time: e.target.value })
                            }
                            name="time"
                            className={classnames("form-control", {
                              errors: errors.time,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row row-sm-offset">
                      <br />
                      <div className="col-md-12 multi-horizontal">
                        <div className="form-group">
                          <label
                            className="form-control-label mbr-fonts-style display-7"
                            style={{ color: "black" }}
                          >
                            Duration
                          </label>
                          <textarea
                            type="textarea"
                            className="form-control"
                            name="duration"
                            onChange={this.onChange}
                            value={this.state.duration}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row row-sm-offset">
                      <br />
                      <div className="col-md-12 multi-horizontal">
                        <div className="form-group">
                          <label
                            className="form-control-label mbr-fonts-style display-7"
                            style={{ color: "black" }}
                          >
                            Activity
                          </label>
                          <textarea
                            type="textarea"
                            className="form-control"
                            name="activity"
                            onChange={this.onChange}
                            value={this.state.activity}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row row-sm-offset">
                      <br />

                      <div className="col-md-12 multi-horizontal">
                        <div className="form-group">
                          <label
                            className="form-control-label mbr-fonts-style display-7"
                            style={{ color: "black" }}
                          >
                            Note
                          </label>
                          <textarea
                            type="textarea"
                            className="form-control"
                            name="note"
                            onChange={this.onChange}
                            value={this.state.note}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row row-sm-offset">
                      <br />

                      <div className="col-md-12 multi-horizontal">
                        <div className="form-group">
                          <label
                            className="form-control-label mbr-fonts-style display-7"
                            style={{ color: "black" }}
                          >
                            Action
                          </label>
                          <textarea
                            type="textarea"
                            className="form-control"
                            name="action"
                            onChange={this.onChange}
                            value={this.state.action}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="row row-sm-offset">
                      <div className="col-md-6 multi-horizontal">
                        <button type="submit" className="btn btn-success">
                          Submit
                        </button>
                      </div>
                      <div className="col-md-6 multi-horizontal text-left">
                        <button
                          id=""
                          type="button"
                          className="btn btn-default closePopup"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div id="addInfoModal" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &#10006;
                </button>
                <h4 className="modal-title text-center">Add Diary Notes</h4>
                <form method="post" onSubmit={this.onSubmitDiary}>
                  <div className="modal-body">
                    <div className="row row-sm-offset">
                      <br />
                      <div className="col-md-6 multi-horizontal">
                        <div className="form-group">
                          <label
                            className="form-control-label mbr-fonts-style display-7"
                            style={{ color: "black" }}
                          >
                            {" "}
                            TIME
                          </label>
                          <input
                            type="datetime-local"
                            value={this.state.addTime}
                            style={{ width: "100%", marginBottom: "10px" }}
                            onChange={(e) =>
                              this.setState({ addTime: e.target.value })
                            }
                            name="addTime"
                            className={classnames("form-control", {
                              errors: errors.time,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row row-sm-offset">
                      <br />

                      <div className="col-md-12 multi-horizontal">
                        <div className="form-group">
                          <label
                            className="form-control-label mbr-fonts-style display-7"
                            style={{ color: "black" }}
                          >
                            Duration
                          </label>
                          <textarea
                            type="textarea"
                            className="form-control"
                            name="duration"
                            onChange={this.onChange}
                            value={this.state.duration}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row row-sm-offset">
                      <br />
                      <div className="col-md-12 multi-horizontal">
                        <div className="form-group">
                          <label
                            className="form-control-label mbr-fonts-style display-7"
                            style={{ color: "black" }}
                          >
                            Activity
                          </label>
                          <textarea
                            type="textarea"
                            className="form-control"
                            name="activity"
                            onChange={this.onChange}
                            value={this.state.activity}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row row-sm-offset">
                      <br />

                      <div className="col-md-12 multi-horizontal">
                        <div className="form-group">
                          <label
                            className="form-control-label mbr-fonts-style display-7"
                            style={{ color: "black" }}
                          >
                            Note
                          </label>
                          <textarea
                            type="textarea"
                            className="form-control"
                            name="addNote"
                            onChange={this.onChange}
                            value={this.state.addNote}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="row row-sm-offset">
                      <div className="col-md-6 multi-horizontal">
                        <button type="submit" className="btn btn-success">
                          Submit
                        </button>
                      </div>
                      <div className="col-md-6 multi-horizontal text-left">
                        <button
                          id=""
                          type="button"
                          className="btn btn-default closePopup"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SettingsTab;
