import React, { useEffect, useState, version } from "react";
import axios from "axios";
import apiUrl from "../../config/config";
import { Toaster, toast } from "react-hot-toast";
import TopNav from "../common/TopNav";
import SideBar from "../common/SideBar";
import "./devicedeatils.css";
const DeviceDetailsDistributor = () => {
  const [devices, setDevices] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 20;
  const [menus, setMenus] = useState([
    { title: "Patient List", url: "/Partner" },
    { title: "Device Details", url: "/device-home" },
  ]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [newDevice, setNewDevice] = useState({
    serial_no: "",
    mac_id: "",
    whatsapp: "",
    mail: "",
    customer_details: "",
    contactPerson: "",
    VerisonDetails: "",
  });

  useEffect(() => {
    fetchDevices();
  }, []);

  const fetchDevices = async () => {
    try {
      const response = await axios.get(`${apiUrl}api/users/GetDevicedetails`);
      setDevices(response.data.reverse()); // Reverse the data here
    } catch (error) {
      console.error("Error fetching device details", error);
    }
  };

  const filterRows = () => {
    const verifierId = localStorage.getItem("verifierid"); // Get verifier ID from localStorage

    return devices.filter(
      (device) =>
        device.customer_id === verifierId && // Filter matching customer_id
        (!searchQuery || // If no search query, return all filtered devices
          Object.values(device).some(
            (value) =>
              value &&
              value.toString().toLowerCase().includes(searchQuery.toLowerCase())
          ))
    );
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(filterRows().length / rowsPerPage);
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedDevice((prevDevice) => ({
      ...prevDevice,
      [name]: value,
    }));
  };

  const handleEditDevice = async () => {
    try {
      await axios.post(`${apiUrl}api/users/UpdateDeviceInfo`, selectedDevice);
      fetchDevices();
      toast.success("Device updated successfully");
    } catch (error) {
      console.error("Error updating device", error);
      toast.error("Error updating device");
    }
  };

  const totalPages = Math.ceil(filterRows().length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const displayedRows = filterRows().slice(
    startIndex,
    startIndex + rowsPerPage
  );

  const paginationNumbers = [];
  const startPage = Math.max(1, currentPage - 4);
  const endPage = Math.min(totalPages, startPage + 7);

  for (let i = startPage; i <= endPage; i++) {
    paginationNumbers.push(i);
  }

  return (
    <div className="wrapper theme-6-active box-layout pimary-color-green">
      <Toaster position="top-right" reverseOrder={false} />
      <TopNav profileLink="verifier-profile" /> {/* Add TopNav component */}
      <SideBar menus={menus} /> {/* Add SideBar component */}
      <div className="right-sidebar-backdrop"></div>
      <div className="page-wrapper" style={{ marginTop: "30px" }}>
        <div className="container-fluid">
          <div className="panel panel-default card-view">
            <div className="panel-heading">
              <div className="pull-left">
                <h6 className="panel-title txt-dark">Device Details</h6>
              </div>
              <div className="clearfix"></div>
            </div>
            <div className="panel-wrapper collapse in">
              <div
                className="search-bar"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  style={{ width: "10%" }}
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
              <div className="panel-body">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th className="th">Sl No</th>
                        <th className="th">Serial No</th>
                        <th className="th">MAC ID</th>
                        <th className="th">WhatsApp Number</th>
                        <th className="th" style={{ textAlign: "start" }}>Mail Id</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayedRows.map((device, index) => (
                        <tr key={device._id}>
                          <td className="td">{startIndex + index + 1}</td>
                          <td className="td">{device.serial_no || "_"}</td>
                          <td className="td">{device.mac_id || "_"}</td>
                          <td className="td">{device.whatsapp || "_"}</td>
                          <td className="td" style={{ width: "150px" }}>
                            {device.mail || "_"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div
                  className="pagination-container"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                  }}
                >
                  <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    style={{
                      border: "1px solid #007bff",
                      margin: "0 1px",
                      padding: "5px 10px",
                      cursor: "pointer",
                      borderRadius: "5px",
                      color: "black",
                    }}
                  >
                    Prev
                  </button>
                  {paginationNumbers.map((num) => (
                    <button
                      key={num}
                      onClick={() => handlePageChange(num)}
                      style={{
                        fontWeight: num === currentPage ? "bold" : "normal",
                        backgroundColor:
                          num === currentPage ? "#007bff" : "#fff",
                        color: num === currentPage ? "#fff" : "#000",
                        border: "1px solid #007bff",
                        margin: "0 1px",
                        padding: "5px 10px",
                        cursor: "pointer",
                        borderRadius: "5px",
                      }}
                    >
                      {num}
                    </button>
                  ))}
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      color: "black",
                      border: "1px solid #007bff",
                      margin: "0 1px",
                      padding: "5px 10px",
                      cursor: "pointer",
                      borderRadius: "5px",
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Edit Device Modal */}
      {selectedDevice && (
        <div id="editDeviceModal" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
                <h4 className="modal-title">Edit Device Details</h4>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label style={{ color: "black" }}>Version Details</label>
                      <input
                        type="text"
                        className="form-control"
                        name="VerisonDetails"
                        value={selectedDevice.VerisonDetails || ""}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label style={{ color: "black" }}>Serial No</label>
                      <input
                        type="text"
                        className="form-control"
                        name="serial_no"
                        value={selectedDevice.serial_no || ""}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label style={{ color: "black" }}>WhatsApp Number</label>
                      <input
                        type="text"
                        className="form-control"
                        name="whatsapp"
                        value={selectedDevice.whatsapp || ""}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label style={{ color: "black" }}>Mail</label>
                      <input
                        type="text"
                        className="form-control"
                        name="mail"
                        value={selectedDevice.mail || ""}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label style={{ color: "black" }}>Customer Details</label>
                      <input
                        type="text"
                        className="form-control"
                        name="customer_details"
                        value={selectedDevice.customer_details || ""}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label style={{ color: "black" }}>Contact Person</label>
                      <input
                        type="text"
                        className="form-control"
                        name="contactPerson"
                        value={selectedDevice.contactPerson || ""}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={handleEditDevice}
                  data-dismiss="modal"
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceDetailsDistributor;
