import React, { Component } from "react";
import ReactModal from "react-modal";
import apiUrl from "../../config/config";
import axios from "axios";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Loader from "react-loader-spinner";

class AddModal extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      showModalNew: false,
      showModalSpinner: false,
      clickdata: "",
      startProp: "",
      userid: "",
      showInputPopup: false,
      showInvertorpopup: false,
      showAnlayseone: false,
      showAnalysetwo: false,
      showAnalysethree: false,
      showAnalyzefour: false,
      inputValue: "",
      treemenu: [],
      treemenurev: [],
      starttime: "",
      endtime: "",
      lastindex: "",
      analyzeModal: "",
      showDeletePopup: false,
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseModalNew = this.handleCloseModalNew.bind(this);
    this.handleCloseModalSpinnerNew =
      this.handleCloseModalSpinnerNew.bind(this);
    this.handleDeletePopupClose = this.handleDeletePopupClose.bind(this);
    this.handleDeletePopupOk = this.handleDeletePopupOk.bind(this);
  }
  componentDidMount() {
    ReactModal.setAppElement("body");
    window.analyzeLink = "";
  }

  componentWillReceiveProps(nextProps) {
    //console.log(nextProps);
    this.state.clickdata = nextProps.clickdb;
    this.state.showModal = nextProps.isOpen;
    this.state.userid = nextProps.userid;
    this.state.startProp = nextProps.start;
  }
  handleCloseModal() {
    this.setState({ showModal: false });
    this.props.onResetModal();
  }

  handleCloseModalNew() {
    this.setState({ showModalNew: false });
    this.props.onResetModal();
  }
  handleCloseModalSpinnerNew() {
    this.setState({ showModalSpinner: false });
  }
  handleOpenModalSpinnerNew() {
    this.setState({ showModalSpinner: true });
  }
  async openAnalyzemodal() {
    const response = await axios.get(
      `${apiUrl}api/common/patinentmenutreeNew?userid=${this.state.userid}`
    );
    // console.log('treemenu:!!!!!!!!!!',response.data)
    const treemenu = response.data;
    let optionnewon = []; // For ascending order
    let optionnewendon = []; // For descending order

    // Iterate over each date group in treemenu
    Object.keys(treemenu).forEach((dateKey) => {
      treemenu[dateKey].forEach((entry) => {
        const MainTimeon = entry.date.split("T");
        const timeon = MainTimeon[1].split("+")[0]; // Extract time portion, ignoring timezone

        // Create the formatted time string: "date time"
        const timeStr = `${dateKey} ${timeon}`;

        // Push to both arrays
        optionnewon.push(timeStr);
        optionnewendon.push(timeStr);
      });
    });

    // Sort optionnewon (ascending order) by full date-time
    optionnewon.sort((a, b) => {
      const fullDateA = new Date(a); // Convert full date-time string to Date object
      const fullDateB = new Date(b); // Convert full date-time string to Date object
      return fullDateA - fullDateB; // Ascending order: compare full date-time
    });

    // Sort optionnewendon (descending order) by full date-time
    optionnewendon.sort((a, b) => {
      const fullDateA = new Date(a); // Convert full date-time string to Date object
      const fullDateB = new Date(b); // Convert full date-time string to Date object
      return fullDateB - fullDateA; // Descending order: compare full date-time
    });

    // Output the results
    // console.log('Sorted Ascending:', optionnewon);
    const formattedOptionNewEndon = optionnewendon.map((value, index) => ({
      index,
      value,
    }));
    const formattedOptionNewStarton = optionnewon.map((value, index) => ({
      index,
      value,
    }));

    this.setState({ treemenu: formattedOptionNewEndon });
    this.setState({ treemenurev: formattedOptionNewStarton });
    const lenght = this.state.treemenu.length;
    // console.log("treemenu", lenght);
    this.setState({ lastindex: lenght });
    this.setState({ showModal: false });
    this.setState({ showModalNew: true });
    this.setState({ starttime: this.state.clickdata.replace("%20", " ") });
    this.setState({ endtime: this.state.clickdata.replace("%20", " ") });
    this.setState({ inputValue: "1.5" });
    // console.log("st time", this.state.clickdata.replace("%20",' '));
    // console.log("ed time", this.state.clickdata);
  }
  handleInputChange(e) {
    this.setState({ inputValue: e.target.value });
  }
  handleInputPopupClose() {
    this.setState({ showInputPopup: false });
    this.setState({ showInvertorpopup: false });
    this.setState({ showAnlayseone: false });
    this.setState({ showAnalysetwo: false });

    this.setState({ showAnalysethree: false });
    this.setState({ showAnalyzefour: false });
  }
  handleInputPopupOk() {
    // const inputvalue=this.state.inputValue
    // console.log(inputvalue)

    const userId = this.state.userid;
    const startTime = this.state.starttime.replace(/ /g, "%20");
    const endTime = this.state.endtime.replace(/ /g, "%20");
    if (this.state.inputValue) {
      this.setState({ showInputPopup: false });
      this.setState({ showInvertorpopup: false });
      this.setState({ showAnlayseone: false });
      this.setState({ showAnalysetwo: false });

      this.setState({ showAnalysethree: false });
      this.setState({ showAnalyzefour: false });
      this.setState({ showModalSpinner: true });
      this.setState({ showModalNew: false });
      // this.analyzeDataset.bind({ id: "temp2" });

      this.handleOpenModalSpinnerNew();

      const response = axios
        .get(
          `https://web.mybiocalculus.com/html/welcome/amplitude_increase/?userid=${userId}&&st_time=${startTime}&&ed_time=${endTime}&&val=${this.state.inputValue}`
        )
        .then((res) => {
          this.setState({ showModalSpinner: false });
          this.handleCloseModal();

          this.setState({ showModalNew: false });
        });
    }
  }

  handleSelectorOk() {
    const userId = this.state.userid;
    const startTime = this.state.starttime.replace(/ /g, "%20");
    const endTime = this.state.endtime.replace(/ /g, "%20");

    const selectedcode = this.state.analyzeModal;
    this.setState({ showInputPopup: false });
    this.setState({ showInvertorpopup: false });
    this.setState({ showAnlayseone: false });
    this.setState({ showAnalysetwo: false });

    this.setState({ showAnalysethree: false });
    this.setState({ showAnalyzefour: false });
    this.setState({ showModalSpinner: true });
    this.setState({ showModalNew: false });

    // console.log(selectedcode)
    if (selectedcode == "inversion") {
      axios
        .post(
          `https://web.mybiocalculus.com/html/welcome/explicit_invert/?userid=${userId}&&st_time=${startTime}&&ed_time=${endTime}`
        )
        .then((response) => {
          console.log("inversion");
          console.log("Response:", response);
          this.setState({ showModalSpinner: false });
          this.handleCloseModal();

          // Logic to handle modal completion
          // Example: Set state to show a modal
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle errors here, such as showing an error modal
          this.setState({ showModalSpinner: false });
          this.setState({ showModalNew: true });
        });
    } else if (
      selectedcode === "first-code" &&
      this.state.starttime === this.state.endtime
    ) {
      console.log("start time", startTime, "endtime:", endTime);
      axios
        .post(
          `https://web.mybiocalculus.com/html/welcome/endpoint_analyze/?userid=${userId}&&st_time=${startTime}&&ed_time=${endTime}`
        )
        .then((response) => {
          console.log(response);
          this.setState({ showModalSpinner: false });
          this.handleCloseModal();
        })
        .catch((error) => {
          console.log(error);
          this.setState({ showModalSpinner: false });
          this.setState({ showModalNew: true });
        });

      console.log("first code");
    } else if (
      selectedcode === "first-code" &&
      this.state.starttime !== this.state.endtime
    ) {
      axios
        .post(
          `https://web.mybiocalculus.com/html/welcome/endpoint_analyze/?userid=${userId}&&st_time=${startTime}&&ed_time=${endTime}`
        )
        .then((response) => {
          console.log(response);
          this.setState({ showModalSpinner: false });
          this.handleCloseModal();
        })
        .catch((error) => {
          console.log(error);
          this.setState({ showModalSpinner: false });
          this.setState({ showModalNew: true });
        });
      console.log("first code full analyse");
    } else if (
      selectedcode == "second-code" &&
      this.state.starttime === this.state.endtime
    ) {
      axios
        .post(
          `https://web.mybiocalculus.com/html/welcome/classic_analyze/?userid=${userId}&&st_time=${startTime}&&ed_time=${endTime}`
        )
        .then((response) => {
          console.log(response);
          this.setState({ showModalSpinner: false });
          this.handleCloseModal();
        })
        .catch((error) => {
          console.log(error);
          this.setState({ showModalSpinner: false });
          this.setState({ showModalNew: true });
        });
      console.log("second code");
    } else if (
      selectedcode === "second-code" &&
      this.state.starttime !== this.state.endtime
    ) {
      axios
        .post(
          `https://web.mybiocalculus.com/html/welcome/classic_analyze/?userid=${userId}&&st_time=${startTime}&&ed_time=${endTime}`
        )
        .then((response) => {
          console.log(response);
          this.setState({ showModalSpinner: false });
          this.handleCloseModal();
        })
        .catch((error) => {
          console.log(error);
          this.setState({ showModalSpinner: false });
          this.setState({ showModalNew: true });
        });
      console.log("second code full analyse");
    } else if (
      selectedcode == "third-code" &&
      this.state.starttime === this.state.endtime
    ) {
      axios
        .post(
          `https://web.mybiocalculus.com/html/welcome/emergency_analyze/?userid=${userId}&&st_time=${startTime}&&ed_time=${endTime}`
        )
        .then((response) => {
          console.log(response);
          this.setState({ showModalSpinner: false });
          this.handleCloseModal();
        })
        .catch((error) => {
          console.log(error);
          this.setState({ showModalSpinner: false });
          this.setState({ showModalNew: true });
        });
      console.log("third-code");
    } else if (
      selectedcode == "third-code" &&
      this.state.starttime !== this.state.endtime
    ) {
      axios
        .post(
          `https://web.mybiocalculus.com/html/welcome/emergency_analyze/?userid=${userId}&&st_time=${startTime}&&ed_time=${endTime}`
        )
        .then((response) => {
          console.log(response);
          this.setState({ showModalSpinner: false });
          this.handleCloseModal();
        })
        .catch((error) => {
          console.log(error);
          this.setState({ showModalSpinner: false });
          this.setState({ showModalNew: true });
        });
      console.log("third-code full analayse");
    } else if (selectedcode == "fourth-code") {
      axios
        .post(
          `https://web.mybiocalculus.com/html/welcome/neg_analyze/?userid=${userId}&&st_time=${startTime}&&ed_time=${endTime}`
        )
        .then((response) => {
          console.log(response);
          this.setState({ showModalSpinner: false });
          this.handleCloseModal();
        })
        .catch((error) => {
          console.log(error);
          this.setState({ showModalSpinner: false });
          this.setState({ showModalNew: true });
        });
      console.log("fourth-code" && this.state.starttime === this.state.endtime);
    } else if (
      selectedcode == "fourth-code" &&
      this.state.starttime !== this.state.endtime
    ) {
      axios
        .post(
          `https://web.mybiocalculus.com/html/welcome/neg_analyze/?userid=${userId}&&st_time=${startTime}&&ed_time=${endTime}`
        )
        .then((response) => {
          console.log(response);
          this.setState({ showModalSpinner: false });
          this.handleCloseModal();
        })
        .catch((error) => {
          console.log(error);
          this.setState({ showModalSpinner: false });
          this.setState({ showModalNew: true });
        });
      console.log("fourth-code full analyse");
    }
  }

  handleDeletePopupClose() {
    this.setState({ showDeletePopup: false });

  }

  async deleteVerify(e) {
    const response = await axios.get(
      `${apiUrl}api/common/patinentmenutreeNew?userid=${this.state.userid}`
    );
    const treemenu = response.data;
    let optionnewon = []; // For ascending order
    let optionnewendon = []; // For descending order

    // Iterate over each date group in treemenu
    Object.keys(treemenu).forEach((dateKey) => {
      treemenu[dateKey].forEach((entry) => {
        const MainTimeon = entry.date.split("T");
        const timeon = MainTimeon[1].split("+")[0]; // Extract time portion, ignoring timezone

        // Create the formatted time string: "date time"
        const timeStr = `${dateKey} ${timeon}`;

        // Push to both arrays
        optionnewon.push(timeStr);
        optionnewendon.push(timeStr);
      });
    });

    // Sort optionnewon (ascending order) by full date-time
    optionnewon.sort((a, b) => {
      const fullDateA = new Date(a); // Convert full date-time string to Date object
      const fullDateB = new Date(b); // Convert full date-time string to Date object
      return fullDateA - fullDateB; // Ascending order: compare full date-time
    });

    // Sort optionnewendon (descending order) by full date-time
    optionnewendon.sort((a, b) => {
      const fullDateA = new Date(a); // Convert full date-time string to Date object
      const fullDateB = new Date(b); // Convert full date-time string to Date object
      return fullDateB - fullDateA; // Descending order: compare full date-time
    });

    const formattedOptionNewEndon = optionnewendon.map((value, index) => ({
      index,
      value,
    }));
    const formattedOptionNewStarton = optionnewon.map((value, index) => ({
      index,
      value,
    }));

    this.setState({ treemenu: formattedOptionNewEndon });
    this.setState({ treemenurev: formattedOptionNewStarton });
    this.setState({starttime:this.state.clickdata.replace("%20",' ')});
    this.setState({endtime:this.state.clickdata.replace("%20",' ')});
    this.setState({ showDeletePopup: true });
  }

  handleDeletePopupOk() {
    if (window.confirm("Do you want to delete this Dataset?")) {
      console.log("remove all");

      var userid = this.state.userid;
      const starttime = this.state.starttime;
      const endtime = this.state.endtime;

      axios
        .get(
          `https://web.mybiocalculus.com/html/welcome/explicitDelete?userid=${userid}&starttime=${starttime}&endtime=${endtime}`
        )
        .then((res) => {
          axios
            .get(`${apiUrl}api/common/getLatestDatabaseId?userid=${userid}`)
            .then((res) => {
              var latestDatabaseid = res.data;
              axios
                .get(
                  `${apiUrl}api/patients/getLatestDatabaseId?userid=${userid}&latestDatabaseid=${latestDatabaseid}`
                )
                .then((res) => {
                  console.log("Removed");
                  this.handleCloseModal();
                  if (window.location.href.includes("verifier-chart")) {
                    // window.location.href =
                    //   "/verifier-chart/" + userid + "/" + latestDatabaseid;
                  } else if (
                    window.location.href.includes("verifier-unanalysed")
                  ) {
                    // window.location.href =
                    //   "/verifier-unanalysed/" + userid + "/" + latestDatabaseid;
                  } else {
                    console.log(
                      "The URL does not contain 'verifier-chart' or 'verifier-unanalysed'"
                    );
                  }
                })
                .catch((err) => {
                  //      console.log(err);
                });
            })
            .catch((err) => {
              //        console.log(err);
            });
        })
        .catch((err) => {
          //      console.log(err);
        });
    }
    this.setState({ showDeletePopup: false });
  }

  async analyzeDataset(e) {
    var userid = this.state.userid;
    var databaseid = this.state.clickdata;
    if (e.currentTarget.id == "ai") {
      //  console.log("Analyse last");
      //   console.log('Select',e.currentTarget.id);
      window.analyzeLink =
        "/home/ubuntu/analysis_codes/ai_codes/analyze_last_correct.py";
    } else if (e.currentTarget.id == "hybrid") {
      // console.log('Select',e.currentTarget.id);
      window.analyzeLink =
        "/home/ubuntu/analysis_codes/emergency_code/analyze_last_correct.py";
    } else if (e.currentTarget.id == "classical") {
      //   console.log('Select',e.currentTarget.id);
      window.analyzeLink =
        "/home/ubuntu/analysis_codes/classical_code/analyze_last_correct.py";
    } else if (e.currentTarget.id == "temp1") {
      //    console.log('Select',e.currentTarget.id);
      window.analyzeLink =
        "/home/ubuntu/analysis_codes/negpeak/analyze_last_correct.py";
    } else if (e.currentTarget.id == "temp2") {
      //    console.log('Select',e.currentTarget.id);
      window.analyzeLink =
        "/home/ubuntu/analysis_codes/pvc_model/analyze_last_correct.py";
    } else if (e.currentTarget.id == "inversion") {
      console.log("Inversion single data");
      console.log("databaseid !!!!!!!!!!!!!!!!", databaseid);
      window.analyzeLink = "/home/ubuntu/web_analysis/single_invert.py";
    }
    //  console.log('window.analyzeLink',window.analyzeLink);
    // console.log('uid',userid);
    //  console.log('uid',databaseid);
    // console.log('&&databaseid=');
    if (window.analyzeLink != "") {
      var userid = this.state.userid;
      var databaseid = this.state.clickdata;
      e.currentTarget.innerHTML = "Analyzing...";
      //const userid = this.props.match.params.userid;
      this.handleCloseModalNew();
      this.handleOpenModalSpinnerNew();
      axios
        .get(
          "https://web.mybiocalculus.com/html/welcome/analyzeDataset/?userid=" +
            userid +
            "&&databaseid=" +
            databaseid +
            "&&url=" +
            window.analyzeLink
        )
        .then((res) => {
          console.log("database analys");
          console.log(
            "https://web.mybiocalculus.com/html/welcome/analyzeDataset/?userid=" +
              userid +
              "&&databaseid=" +
              databaseid +
              "&&url=" +
              window.analyzeLink
          );
          console.log(res.data);
          console.log(window.analyzeLink);
          //window.location.href = '/';
          this.handleCloseModalSpinnerNew();
        })
        .catch((err) => {
          //     console.log(err);
          this.handleCloseModalSpinnerNew();
        });
    }
  }
  /* _onSelect(e)
  {
  
    if(e.value=='RPeaks')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/rpeaks_codes/analyze_last_correct.py';
    }
    else if(e.value=='Classic')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/classic_peak/analyze_last_correct.py';
    }
    else if(e.value=='Classical')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/classical_code/analyze_last_correct.py';
    }
    else if(e.value=='Classic Negative')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/classic_negpeak/analyze_last_correct.py';
    }
    else if(e.value=='AI')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/ai_codes/analyze_last_correct.py';
    }
    else if(e.value=='AIcodes')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/saroja_aicodes/analyze_last_correct.py';
    }
   
  }
*/
  render() {
    /* const imageList = [{
  src: '../../dist/img/AI.png',
  width: 152,
  height: 102
}, {
  src: '../../dist/img/AI.png',
  width: 152,
  height: 102
}, {
  src: '../../dist/img/AI.png',
  width: 152,
  height: 102
}, {
  src: '../../dist/img/AI.png',
  width: 152,
  height: 102
},{
  src: '../../dist/img/AI.png',
  width: 152,
  height: 102
}, {
  src: '../../dist/img/AI.png',
  width: 152,
  height: 102
}]; */
    const customStyles = {
      content: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)", // Ensures perfect centering
        background: "transparent",
        border: "none", // Removes borders
        padding: 0, // Ensures no extra space around content
        display: "flex", // Aligns spinner properly
        justifyContent: "center", // Centers spinner horizontally
        alignItems: "center", // Centers spinner vertically
      },
      overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Adds a semi-transparent overlay
      },
    };
    const customStylesNew = {
      content: {
        top: "38%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        border: "1px solid rgb(204, 204, 204)",
        background: "rgb(255, 255, 255)",
        overflow: "auto",
        borderRadius: "4px",
        outline: "none",
        padding: "20px",
        height: "45%",
        width: "35%",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };
    const text = "Analyzing....";
    //  const options = [
    //        'RPeaks', 'Classic', 'Classical','Classic Negative', 'AI','AIcodes'
    //       ];
    //        const defaultOption = options[0];
    // console.log(this.state.showModal)
    return (
      <React.Fragment>
        <ReactModal isOpen={this.state.showModalSpinner} style={customStyles}>
          <Loader type="Puff" color="#00BFFF" height={100} width={100} />
          <div className="bounce-container">
            {text.split("").map((char, index) => (
              <span
                key={index}
                className="bounce-letter"
                style={{ animationDelay: `${index * 0.1}s` }}
              >
                {char}
              </span>
            ))}
          </div>
        </ReactModal>
        <ReactModal
          isOpen={this.state.showModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <React.Fragment>
            <div className="blog-pop-bg"></div>
            <div className="blog-pop-box">
              <button
                className="pull-right closeBtn"
                onClick={this.handleCloseModal}
              >
                &#10006;
              </button>

              <div className="fea pull-left" style={{ marginRight: "50px" }}>
                {this.state.startProp == undefined && (
                  <button style={{ border: "none" }}>
                    <img
                      onClick={this.openAnalyzemodal.bind(this)}
                      src="../../dist/img/blog-pop1.png"
                      alt=""
                    />
                  </button>
                )}
                {this.state.startProp != undefined && (
                  <button style={{ border: "none" }}>
                    <img
                      onClick={this.openAnalyzemodal.bind(this)}
                      src="../../../../../dist/img/blog-pop1.png"
                      alt=""
                    />
                  </button>
                )}
                <p style={{ marginLeft: "30%", fontSize: "25px" }}>Analyze</p>
              </div>
              <div className="fea pull-right" style={{ marginLeft: "50px" }}>
                {this.state.startProp == undefined && (
                  <button style={{ border: "none" }}>
                    <img
                      onClick={this.deleteVerify.bind(this)}
                      src="../../dist/img/blog-pop2.png"
                      alt=""
                    />
                  </button>
                )}
                {this.state.startProp != undefined && (
                  <button style={{ border: "none" }}>
                    <img
                      onClick={this.deleteVerify.bind(this)}
                      src="../../../../../dist/img/blog-pop2.png"
                      alt=""
                    />
                  </button>
                )}
                <p style={{ marginLeft: "40%", fontSize: "25px" }}>DELETE</p>
              </div>
              <br />
            </div>
          </React.Fragment>
        </ReactModal>
        <ReactModal
          isOpen={this.state.showModalNew}
          style={customStylesNew}
          contentLabel="Analyze Modal"
        >
          <React.Fragment>
            <div className="blog-pop-box">
              <button
                className="pull-right closeBtn"
                onClick={this.handleCloseModalNew}
              >
                &#10006;
              </button>

              <div className="container col-md-12">
                <div className="row" style={{ marginTop: "15%" }}>
                  <div className="col-md-4">
                    <button style={{ border: "none" }}>
                      <img
                        id="ai"
                        onClick={(e) =>
                          this.setState({
                            showAnlayseone: true,
                            analyzeModal: "first-code",
                          })
                        }
                        src="../../dist/img/AI.png"
                        alt=""
                      />
                    </button>
                  </div>

                  <div className="col-md-4">
                    <button style={{ border: "none" }}>
                      <img
                        id="classical"
                        onClick={(e) =>
                          this.setState({
                            showAnalysetwo: true,
                            analyzeModal: "second-code",
                          })
                        }
                        src="../../dist/img/CLASSICAL.png"
                        alt=""
                      />
                    </button>
                  </div>

                  <div className="col-md-4">
                    <button style={{ border: "none" }}>
                      <img
                        id="hybrid"
                        onClick={(e) =>
                          this.setState({
                            showAnalysethree: true,
                            analyzeModal: "third-code",
                          })
                        }
                        src="../../dist/img/HYBRID.png"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <button style={{ border: "none" }}>
                      <img
                        id="temp1"
                        onClick={(e) =>
                          this.setState({
                            showAnalyzefour: true,
                            analyzeModal: "fourth-code",
                          })
                        }
                        src="../../dist/img/TEMPLATE1.png"
                        alt=""
                      />
                    </button>
                  </div>

                  <div className="col-md-4">
                    <button style={{ border: "none" }}>
                      <img
                        id="temp2"
                        onClick={(e) => this.setState({ showInputPopup: true })}
                        src="../../dist/img/TEMPLATE2.png"
                        alt=""
                      />
                    </button>
                  </div>
                  <div className="col-md-4">
                    <button style={{ border: "none" }}>
                      <img
                        id="inversion"
                        onClick={() =>
                          this.setState({
                            showInvertorpopup: true,
                            analyzeModal: "inversion",
                          })
                        }
                        src="../../dist/img/inversion.png"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        </ReactModal>
        //for Amplitude increase
        <ReactModal isOpen={this.state.showInputPopup} style={customStylesNew}>
          <div style={{ display: "block" }}>
            <label style={{ color: "black", padding: "5px" }}>
              Enter the point to increase Amp?
            </label>
            <input
              style={{
                color: "black",
                padding: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                marginTop: "10px",
                width: "100%",
                fontSize: "16px",
              }}
              type="text"
              value={this.state.inputValue}
              onChange={this.handleInputChange.bind(this)}
            />
          </div>

          {/* First Select Tag */}
          <div style={{ marginTop: "15px" }}>
            <label style={{ color: "black", padding: "5px" }}>
              Start Time:
            </label>
            <select
              style={{
                color: "black",
                padding: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                marginTop: "10px",
                width: "100%",
                fontSize: "16px",
              }}
              value={this.state.starttime}
              onChange={(e) => this.setState({ starttime: e.target.value })}
            >
              {Array.isArray(this.state.treemenu) &&
                this.state.treemenu.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.value || "No Value"}
                  </option>
                ))}
            </select>
          </div>

          {/* Second Select Tag */}
          <div style={{ marginTop: "15px" }}>
            <label style={{ color: "black", padding: "5px" }}>End Time:</label>
            <select
              style={{
                color: "black",
                padding: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                marginTop: "10px",
                width: "100%",
                fontSize: "16px",
              }}
              value={this.state.endtime}
              onChange={(e) => this.setState({ endtime: e.target.value })}
            >
              {Array.isArray(this.state.treemenurev) &&
                this.state.treemenurev.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label || item.value || "No Label"}
                  </option>
                ))}
            </select>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <button
              style={{
                padding: "10px 20px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                marginRight: "10px",
              }}
              onClick={this.handleInputPopupOk.bind(this)}
            >
              OK
            </button>
            <button
              className="primary"
              style={{
                padding: "10px 20px",
                backgroundColor: "#f44336",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              onClick={this.handleInputPopupClose.bind(this)}
            >
              Cancel
            </button>
          </div>
        </ReactModal>
        //end amplitude increase //start invert
        <ReactModal
          isOpen={this.state.showInvertorpopup}
          style={customStylesNew}
        >
          {/* First Select Tag */}
          <div style={{ marginTop: "15px" }}>
            Invert
            <label style={{ color: "black", padding: "5px" }}>
              Start Time:
            </label>
            <select
              style={{
                color: "black",
                padding: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                marginTop: "10px",
                width: "100%",
                fontSize: "16px",
              }}
              value={this.state.starttime}
              onChange={(e) => this.setState({ starttime: e.target.value })}
            >
              {Array.isArray(this.state.treemenu) &&
                this.state.treemenu.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.value || "No Value"}
                  </option>
                ))}
            </select>
          </div>

          {/* Second Select Tag */}
          <div style={{ marginTop: "15px" }}>
            <label style={{ color: "black", padding: "5px" }}>End Time:</label>
            <select
              style={{
                color: "black",
                padding: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                marginTop: "10px",
                width: "100%",
                fontSize: "16px",
              }}
              value={this.state.endtime}
              onChange={(e) => this.setState({ endtime: e.target.value })}
            >
              {Array.isArray(this.state.treemenurev) &&
                this.state.treemenurev.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label || item.value || "No Label"}
                  </option>
                ))}
            </select>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <button
              style={{
                padding: "10px 20px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                marginRight: "10px",
              }}
              onClick={this.handleSelectorOk.bind(this)}
            >
              OK
            </button>
            <button
              className="primary"
              style={{
                padding: "10px 20px",
                backgroundColor: "#f44336",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              onClick={this.handleInputPopupClose.bind(this)}
            >
              Cancel
            </button>
          </div>
        </ReactModal>
        //end invert //start analyze first code
        <ReactModal isOpen={this.state.showAnlayseone} style={customStylesNew}>
          {/* First Select Tag */}
          <div style={{ marginTop: "15px" }}>
            First Code
            <label style={{ color: "black", padding: "5px" }}>
              Start Time:
            </label>
            <select
              style={{
                color: "black",
                padding: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                marginTop: "10px",
                width: "100%",
                fontSize: "16px",
              }}
              value={this.state.starttime}
              onChange={(e) => this.setState({ starttime: e.target.value })}
            >
              {Array.isArray(this.state.treemenu) &&
                this.state.treemenu.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.value || "No Value"}
                  </option>
                ))}
            </select>
          </div>

          {/* Second Select Tag */}
          <div style={{ marginTop: "15px" }}>
            <label style={{ color: "black", padding: "5px" }}>End Time:</label>
            <select
              style={{
                color: "black",
                padding: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                marginTop: "10px",
                width: "100%",
                fontSize: "16px",
              }}
              value={this.state.endtime}
              onChange={(e) => this.setState({ endtime: e.target.value })}
            >
              {Array.isArray(this.state.treemenurev) &&
                this.state.treemenurev.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label || item.value || "No Label"}
                  </option>
                ))}
            </select>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <button
              style={{
                padding: "10px 20px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                marginRight: "10px",
              }}
              onClick={this.handleSelectorOk.bind(this)}
            >
              OK
            </button>
            <button
              className="primary"
              style={{
                padding: "10px 20px",
                backgroundColor: "#f44336",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              onClick={this.handleInputPopupClose.bind(this)}
            >
              Cancel
            </button>
          </div>
        </ReactModal>
        //end anayze first code //start analyze second code
        <ReactModal isOpen={this.state.showAnalysetwo} style={customStylesNew}>
          {/* First Select Tag */}
          <div style={{ marginTop: "15px" }}>
            Second Code
            <label style={{ color: "black", padding: "5px" }}>
              Start Time:
            </label>
            <select
              style={{
                color: "black",
                padding: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                marginTop: "10px",
                width: "100%",
                fontSize: "16px",
              }}
              value={this.state.starttime}
              onChange={(e) => this.setState({ starttime: e.target.value })}
            >
              {Array.isArray(this.state.treemenu) &&
                this.state.treemenu.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.value || "No Value"}
                  </option>
                ))}
            </select>
          </div>

          {/* Second Select Tag */}
          <div style={{ marginTop: "15px" }}>
            <label style={{ color: "black", padding: "5px" }}>End Time:</label>
            <select
              style={{
                color: "black",
                padding: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                marginTop: "10px",
                width: "100%",
                fontSize: "16px",
              }}
              value={this.state.endtime}
              onChange={(e) => this.setState({ endtime: e.target.value })}
            >
              {Array.isArray(this.state.treemenurev) &&
                this.state.treemenurev.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label || item.value || "No Label"}
                  </option>
                ))}
            </select>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <button
              style={{
                padding: "10px 20px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                marginRight: "10px",
              }}
              onClick={this.handleSelectorOk.bind(this)}
            >
              OK
            </button>
            <button
              className="primary"
              style={{
                padding: "10px 20px",
                backgroundColor: "#f44336",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              onClick={this.handleInputPopupClose.bind(this)}
            >
              Cancel
            </button>
          </div>
        </ReactModal>
        //end analyze second code //start analyze third code
        <ReactModal
          isOpen={this.state.showAnalysethree}
          style={customStylesNew}
        >
          {/* First Select Tag */}
          <div style={{ marginTop: "15px" }}>
            Third Code
            <label style={{ color: "black", padding: "5px" }}>
              Start Time:
            </label>
            <select
              style={{
                color: "black",
                padding: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                marginTop: "10px",
                width: "100%",
                fontSize: "16px",
              }}
              value={this.state.starttime}
              onChange={(e) => this.setState({ starttime: e.target.value })}
            >
              {Array.isArray(this.state.treemenu) &&
                this.state.treemenu.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.value || "No Value"}
                  </option>
                ))}
            </select>
          </div>

          {/* Second Select Tag */}
          <div style={{ marginTop: "15px" }}>
            <label style={{ color: "black", padding: "5px" }}>End Time:</label>
            <select
              style={{
                color: "black",
                padding: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                marginTop: "10px",
                width: "100%",
                fontSize: "16px",
              }}
              value={this.state.endtime}
              onChange={(e) => this.setState({ endtime: e.target.value })}
            >
              {Array.isArray(this.state.treemenurev) &&
                this.state.treemenurev.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label || item.value || "No Label"}
                  </option>
                ))}
            </select>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <button
              style={{
                padding: "10px 20px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                marginRight: "10px",
              }}
              onClick={this.handleSelectorOk.bind(this)}
            >
              OK
            </button>
            <button
              className="primary"
              style={{
                padding: "10px 20px",
                backgroundColor: "#f44336",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              onClick={this.handleInputPopupClose.bind(this)}
            >
              Cancel
            </button>
          </div>
        </ReactModal>
        //end analyze third code //start analyze fourth code
        <ReactModal isOpen={this.state.showAnalyzefour} style={customStylesNew}>
          {/* First Select Tag */}
          <div style={{ marginTop: "15px" }}>
            fourth code
            <label style={{ color: "black", padding: "5px" }}>
              Start Time:
            </label>
            <select
              style={{
                color: "black",
                padding: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                marginTop: "10px",
                width: "100%",
                fontSize: "16px",
              }}
              value={this.state.starttime}
              onChange={(e) => this.setState({ starttime: e.target.value })}
            >
              {Array.isArray(this.state.treemenu) &&
                this.state.treemenu.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.value || "No Value"}
                  </option>
                ))}
            </select>
          </div>

          {/* Second Select Tag */}
          <div style={{ marginTop: "15px" }}>
            <label style={{ color: "black", padding: "5px" }}>End Time:</label>
            <select
              style={{
                color: "black",
                padding: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                marginTop: "10px",
                width: "100%",
                fontSize: "16px",
              }}
              value={this.state.endtime}
              onChange={(e) => this.setState({ endtime: e.target.value })}
            >
              {Array.isArray(this.state.treemenurev) &&
                this.state.treemenurev.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label || item.value || "No Label"}
                  </option>
                ))}
            </select>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <button
              style={{
                padding: "10px 20px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                marginRight: "10px",
              }}
              onClick={this.handleSelectorOk.bind(this)}
            >
              OK
            </button>
            <button
              className="primary"
              style={{
                padding: "10px 20px",
                backgroundColor: "#f44336",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              onClick={this.handleInputPopupClose.bind(this)}
            >
              Cancel
            </button>
          </div>
        </ReactModal>
        //end analyze fourth code
        <ReactModal isOpen={this.state.showDeletePopup} style={customStylesNew}>
          <div style={{ marginTop: "15px" }}>
            <label style={{ color: "black", padding: "5px" }}>
              Start Time:
            </label>
            <select
              style={{
                color: "black",
                padding: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                marginTop: "10px",
                width: "100%",
                fontSize: "16px",
              }}
              value={this.state.starttime}
              onChange={(e) => this.setState({ starttime: e.target.value })}
            >
              {Array.isArray(this.state.treemenu) &&
                this.state.treemenu.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.value || "No Value"}
                  </option>
                ))}
            </select>
          </div>

          <div style={{ marginTop: "15px" }}>
            <label style={{ color: "black", padding: "5px" }}>End Time:</label>
            <select
              style={{
                color: "black",
                padding: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                marginTop: "10px",
                width: "100%",
                fontSize: "16px",
              }}
              value={this.state.endtime}
              onChange={(e) => this.setState({ endtime: e.target.value })}
            >
              {Array.isArray(this.state.treemenurev) &&
                this.state.treemenurev.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label || item.value || "No Label"}
                  </option>
                ))}
            </select>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <button
              style={{
                padding: "10px 20px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                marginRight: "10px",
              }}
              onClick={this.handleDeletePopupOk}
            >
              OK
            </button>
            <button
              className="primary"
              style={{
                padding: "10px 20px",
                backgroundColor: "#f44336",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              onClick={this.handleDeletePopupClose}
            >
              Cancel
            </button>
          </div>
        </ReactModal>
      </React.Fragment>
    );
  }
}

export default AddModal;
