import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import axios from "axios";
import apiUrl from "../../config/config";
import "./topnav.css";

class TopNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profileLink: "/dist/img/user1.png",
      notifications: [],
      notificationCount: 0,
    };
    this.markAsRead = this.markAsRead.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.user.profileImage) {
      this.setState({
        profileLink:
          "https://web.mybiocalculus.com/assets/profile_image/users/" +
          this.props.auth.user.profileImage,
      });
    }

    axios
      .get(apiUrl + "api/users/getAllNotifications")
      .then((res) => {
        this.setState({
          notifications: res.data,
        });
        if (res.data.length > 0) {
          this.setState({
            notificationCount: res.data.length,
          });
          document.getElementById("notificationCountContainer").style.display =
            "block";
        }
        console.log("notification:", this.state.notifications[0].user_id);
      })
      .catch((err) => {});
  }
  async onLogoutClick(e) {
    e.preventDefault();

    try {
      await axios.post(
        `https://web.mybiocalculus.com:3002/api/users/updateLogoutTime`,
        {
          Verifier_id: localStorage.getItem("verifierid"),
          LoginedTime: localStorage.getItem("loginedDateTime"),
        }
      );

      await axios.post(
        `https://web.mybiocalculus.com:3002/api/users/UpdateVerifierActivity`,
        {
          Verifier_id: localStorage.getItem("verifierid"),
          LoginedTime: localStorage.getItem("loginedDateTime"),
          ActivityLevel: localStorage.getItem("activityPercentage"),
          BreaksConsumed: localStorage.getItem("breakTime"),
        }
      );

      this.props.logoutUser();
      window.location.href = "/login";
    } catch (err) {
      console.error("Error during logout:", err);
    }
  }

  markAsRead(e) {
    axios
      .get(apiUrl + "api/users/markasReadAllNotifications")
      .then((res) => {
        this.setState({
          notifications: [],
          notificationCount: 0,
        });
        document.getElementById("notificationCountContainer").style.display =
          "none";
      })
      .catch((err) => {});
  }

  render() {
    const { notifications } = this.state;

    let notificationList =
      notifications.length > 0 &&
      notifications.map((notification, i) => {
        let colorClass =
          notification.type === 1
            ? "bg-green"
            : notification.type === 2
            ? "bg-yellow"
            : "";
        return (
          <React.Fragment key={i}>
            <div className="sl-item">
              <a href="#">
                <div className={"icon " + colorClass}>
                  <i className="zmdi zmdi-flag"></i>
                </div>
                <div className="sl-content">
                  <span className="inline-block capitalize-font pull-left truncate head-notifications">
                    {notification.description}
                  </span>
                  <div className="clearfix"></div>
                </div>
              </a>
            </div>
            <hr className="light-grey-hr ma-0" />
          </React.Fragment>
        );
      }, this);

    return (
      <nav className="navbar navbar-inverse navbar-fixed-top">
        <div className="mobile-only-brand pull-left">
          <div className="nav-header pull-left">
            <div className="logo-wrap">
              <a href="#">
                <img
                  className="brand-img"
                  id="login_logo"
                  src="/dist/img/logo.png"
                  alt="brand"
                />
                <span className="brand-text">Biocalculus</span>
              </a>
            </div>
          </div>
          {localStorage.getItem("verifiertype") != "7" && (
            <a
              id="toggle_nav_btn"
              className="toggle-left-nav-btn inline-block ml-20 pull-left"
              href="#"
            >
              <i className="zmdi zmdi-menu"></i>
            </a>
          )}
        </div>
        <div id="mobile_only_nav" className="mobile-only-nav pull-right">
          <ul className="nav navbar-right top-nav pull-right">
            <li className="dropdown alert-drp">
              <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                <i className="zmdi zmdi-notifications top-nav-icon"></i>
                <span
                  id="notificationCountContainer"
                  className="top-nav-icon-badge displayNone"
                >
                  {this.state.notificationCount}
                </span>
              </a>
              <ul
                className="dropdown-menu alert-dropdown"
                data-dropdown-in="bounceIn"
                data-dropdown-out="bounceOut"
              >
                <li>
                  <div className="notification-box-head-wrap">
                    <span className="notification-box-head pull-left inline-block">
                      notifications
                    </span>
                    <a
                      className="txt-danger pull-right clear-notifications inline-block- displayNone"
                      href="#"
                    >
                      {" "}
                      clear all{" "}
                    </a>
                    <div className="clearfix"></div>
                    <hr className="light-grey-hr ma-0" />
                  </div>
                </li>
                <li>
                  <div className="streamline message-nicescroll-bar">
                    {notificationList}
                  </div>
                </li>
                <li>
                  <div className="notification-box-bottom-wrap">
                    <hr className="light-grey-hr ma-0" />
                    <a
                      className="block text-center read-all"
                      onClick={this.markAsRead}
                      href="#"
                    >
                      {" "}
                      read all{" "}
                    </a>
                    <div className="clearfix"></div>
                  </div>
                </li>
              </ul>
            </li>
            <li className="dropdown auth-drp">
              <a
                href="#"
                className="dropdown-toggle pr-0"
                data-toggle="dropdown"
              >
                <img
                  src={this.state.profileLink}
                  alt="user_auth"
                  className="user-auth-img img-circle"
                />
                <span className="user-online-status"></span>
              </a>
              <ul
                className="dropdown-menu user-auth-dropdown"
                data-dropdown-in="flipInX"
                data-dropdown-out="flipOutX"
              >
                <li>
                  <a href={this.props.profileLink}>
                    <i className="zmdi zmdi-account"></i>
                    <span>Profile</span>
                  </a>
                </li>
                <li>
                  <a href="#" onClick={this.onLogoutClick.bind(this)}>
                    <i className="zmdi zmdi-power"></i>
                    <span>Log Out</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

TopNav.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(TopNav);
