import React, { useEffect, useState, version } from "react";
import axios from "axios";
import apiUrl from "../../config/config";
import { Toaster, toast } from "react-hot-toast";
import TopNav from "../common/TopNav";
import SideBar from "../common/SideBar";
import "./devicedeatils.css";
const DeviceDetails = () => {
  const [devices, setDevices] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [menus, setMenus] = useState([
    { title: "Verifier Home", url: "/verifier-home" },
    { title: "Patient list", url: "/patient-list" },
    { title: "Patient Hold", url: "/patient-hold" },
    { title: "Device Details", url: "/device-deatils" },
    { title: "Support Home", url: "support-home" },
    { title: "QC Home", url: "qc_home" },
    { title: "Support Web", url: "support-homeweb" },
  ]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [newDevice, setNewDevice] = useState({
    serial_no: "",
    mac_id: "",
    whatsapp: "",
    mail: "",
    customer_details: "",
    contactPerson: "",
    VerisonDetails: "",
  });

  useEffect(() => {
    fetchDevices();
  }, []);

  const fetchDevices = async () => {
    try {
      const response = await axios.get(`${apiUrl}api/users/GetDevicedetails`);
      setDevices(response.data.reverse()); // Reverse the data here
    } catch (error) {
      console.error("Error fetching device details", error);
    }
  };

  const filterRows = () => {
    if (!searchQuery) {
      return devices;
    }

    return devices.filter((device) => 
      Object.values(device).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(filterRows().length / rowsPerPage);
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleEditClick = (device) => {
    setSelectedDevice(device);
  };
  const handleDeleteClick = async (device) => {
    const mac_id = device.mac_id;

    if (!window.confirm("Do you want to delete this device?")) {
      return; // Stop execution if user cancels
    }

    try {
      console.log("Deleting device with MAC ID:", mac_id);

      const response = await axios.delete(
        `${apiUrl}api/users/DeleteDeviceInfo`,
        {
          params: { mac_id },
        }
      );

      if (response.status === 200) {
        toast.success("Device deleted successfully!");
        // Optionally refresh the device list after deletion
        fetchDevices();
      } else {
        toast.error("Failed to delete device!");
      }
    } catch (err) {
      console.error("Error deleting device:", err);
      toast.error("Error deleting device!");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedDevice((prevDevice) => ({
      ...prevDevice,
      [name]: value,
    }));
  };

  const handleNewDeviceChange = (e) => {
    const { name, value } = e.target;
    setNewDevice((prevDevice) => ({
      ...prevDevice,
      [name]: value,
    }));
  };

  const handleAddDevice = async () => {
    try {
      await axios.post(`${apiUrl}api/users/AddDeviceInfo`, newDevice);
      fetchDevices();

      setNewDevice({
        VerisonDetails: "",
        serial_no: "",
        mac_id: "",
        whatsapp: "",
        mail: "",
        customer_details: "",
        contactPerson: "",
      });
      console.log("Add new Device !!!!!!!!!!!!!!!", newDevice);
      toast.success("Device added successfully");
    } catch (error) {
      console.error("Error adding device", error);
      toast.error("Error adding device");
    }
  };

  const handleEditDevice = async () => {
    try {
      await axios.post(`${apiUrl}api/users/UpdateDeviceInfo`, selectedDevice);
      fetchDevices();
      toast.success("Device updated successfully");
    } catch (error) {
      console.error("Error updating device", error);
      toast.error("Error updating device");
    }
  };

  const totalPages = Math.ceil(filterRows().length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const displayedRows = filterRows().slice(
    startIndex,
    startIndex + rowsPerPage
  );

  const paginationNumbers = [];
  const startPage = Math.max(1, currentPage - 4);
  const endPage = Math.min(totalPages, startPage + 7);

  for (let i = startPage; i <= endPage; i++) {
    paginationNumbers.push(i);
  }

  return (
    <div className="wrapper theme-6-active box-layout pimary-color-green">
      <Toaster position="top-right" reverseOrder={false} />
      <TopNav profileLink="verifier-profile" /> {/* Add TopNav component */}
      <SideBar menus={menus} /> {/* Add SideBar component */}
      <div className="right-sidebar-backdrop"></div>
      <div className="page-wrapper" style={{ marginTop: "30px" }}>
        <div className="container-fluid">
          <div className="panel panel-default card-view">
            <div className="panel-heading">
              <div className="pull-left">
                <h6 className="panel-title txt-dark">Device Details</h6>
              </div>
              <div className="clearfix"></div>
            </div>
            <div className="panel-wrapper collapse in">
              <div
                className="search-bar"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  style={{ width: "10%" }}
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <button
                  className="btn btn-success"
                  data-toggle="modal"
                  data-target="#addDeviceModal"
                >
                  Add Device
                </button>
              </div>
              <div className="panel-body">
                <div className="table-responsive">
                  <table className="table table-striped table-device-deatils">
                    <thead>
                      <tr>
                        <th>Sl No</th>
                        <th>Serial No</th>
                        <th>MAC ID</th>
                        <th>Versions</th>
                        <th>WhatsApp Number</th>
                        <th style={{ width: "150px" }}>MailQ</th>
                        {/* Adjust width */}
                        <th>Customer Details</th>
                        <th>Customer Details</th>
                        <th>Contact Person</th>
                        <th>Actions</th> {/* Add Actions column */}
                      </tr>
                    </thead>
                    <tbody>
                      {displayedRows.map((device, index) => (
                        <tr key={device._id}>
                          <td>{startIndex + index + 1}</td>
                          <td>{device.serial_no || "_"}</td>
                          <td>{device.mac_id || "_"}</td>
                          <td>{device.VerisonDetails || "_"}</td>
                          <td>{device.whatsapp || "_"}</td>
                          <td style={{ width: "150px" }}>
                            {(device.mail || "_")
                              .split(",")
                              .map((email, index) => (
                                <React.Fragment key={index}>
                                  {email}
                                  <br />
                                </React.Fragment>
                              ))}
                          </td>
                          <td>{device.customer_details || "_"}</td>
                          <td>{device.customer_details || "_"}</td>
                          <td>{device.contactPerson || "_"}</td>
                          <td>
                            <button
                              className="btn btn-primary"
                              onClick={() => handleEditClick(device)}
                              data-toggle="modal"
                              data-target="#editDeviceModal"
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn-danger"
                              onClick={() => handleDeleteClick(device)}
                              style={{ marginLeft: "10px" }}
                            >
                              Delete
                            </button>
                          </td>{" "}
                          {/* Add Edit and Add buttons */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div
                  className="pagination-container"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                  }}
                >
                  <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    style={{
                      border: "1px solid #007bff",
                      margin: "0 1px",
                      padding: "5px 10px",
                      cursor: "pointer",
                      borderRadius: "5px",
                      color: "black",
                    }}
                  >
                    Prev
                  </button>
                  {paginationNumbers.map((num) => (
                    <button
                      key={num}
                      onClick={() => handlePageChange(num)}
                      style={{
                        fontWeight: num === currentPage ? "bold" : "normal",
                        backgroundColor:
                          num === currentPage ? "#007bff" : "#fff",
                        color: num === currentPage ? "#fff" : "#000",
                        border: "1px solid #007bff",
                        margin: "0 1px",
                        padding: "5px 10px",
                        cursor: "pointer",
                        borderRadius: "5px",
                      }}
                    >
                      {num}
                    </button>
                  ))}
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      color: "black",
                      border: "1px solid #007bff",
                      margin: "0 1px",
                      padding: "5px 10px",
                      cursor: "pointer",
                      borderRadius: "5px",
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Edit Device Modal */}
      {selectedDevice && (
        <div id="editDeviceModal" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
                <h4 className="modal-title">Edit Device Details</h4>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label style={{ color: "black" }}>Version Details</label>
                      <input
                        type="text"
                        className="form-control"
                        name="VerisonDetails"
                        value={selectedDevice.VerisonDetails || ""}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label style={{ color: "black" }}>Serial No</label>
                      <input
                        type="text"
                        className="form-control"
                        name="serial_no"
                        value={selectedDevice.serial_no || ""}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label style={{ color: "black" }}>WhatsApp Number</label>
                      <input
                        type="text"
                        className="form-control"
                        name="whatsapp"
                        value={selectedDevice.whatsapp || ""}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label style={{ color: "black" }}>Mail</label>
                      <input
                        type="text"
                        className="form-control"
                        name="mail"
                        value={selectedDevice.mail || ""}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label style={{ color: "black" }}>Customer Details</label>
                      <input
                        type="text"
                        className="form-control"
                        name="customer_details"
                        value={selectedDevice.customer_details || ""}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label style={{ color: "black" }}>Contact Person</label>
                      <input
                        type="text"
                        className="form-control"
                        name="contactPerson"
                        value={selectedDevice.contactPerson || ""}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={handleEditDevice}
                  data-dismiss="modal"
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Add Device Modal */}
      <div id="addDeviceModal" className="modal fade" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
              <h4 className="modal-title">Add Device</h4>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="text-dark">MAC ID</label>
                      <input
                        type="text"
                        className="form-control"
                        name="mac_id"
                        value={newDevice.mac_id}
                        onChange={handleNewDeviceChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="text-dark">Serial No</label>
                      <input
                        type="text"
                        className="form-control"
                        name="serial_no"
                        value={newDevice.serial_no}
                        onChange={handleNewDeviceChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="text-dark">WhatsApp Number</label>
                      <input
                        type="text"
                        className="form-control"
                        name="whatsapp"
                        value={newDevice.whatsapp}
                        onChange={handleNewDeviceChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="text-dark">Mail</label>
                      <input
                        type="email"
                        className="form-control"
                        name="mail"
                        value={newDevice.mail}
                        onChange={handleNewDeviceChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="text-dark">Customer Details</label>
                      <input
                        type="text"
                        className="form-control"
                        name="customer_details"
                        value={newDevice.customer_details}
                        onChange={handleNewDeviceChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="text-dark">Contact Person</label>
                      <input
                        type="text"
                        className="form-control"
                        name="contactPerson"
                        value={newDevice.contactPerson}
                        onChange={handleNewDeviceChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="text-dark">Version Details</label>
                      <input
                        type="text"
                        className="form-control"
                        name="VerisonDetails"
                        value={newDevice.VerisonDetails || ""}
                        onChange={handleNewDeviceChange}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* Align buttons to the right */}
            <div className="modal-footer d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-secondary ml-2"
                data-dismiss="modal"
                style={{ color: "black" }}
              >
                Close
              </button>{" "}
              <button
                type="button"
                className="btn btn-success"
                onClick={handleAddDevice}
                data-dismiss="modal"
              >
                Add Device
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceDetails;
