import React, { useState, useEffect } from "react";
import axios from "axios";
import apiUrl from "../../../config/config";
import moment from "moment";
import "./ReportsCompleted.css"; // Import CSS for dark theme
import Loading from "./Loading"; // Import Loading component

const ActivityLevel = () => {
  const [activityData, setActivityData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const dataPerPage = 8;
  const maxPageNumbersToShow = 8;

  const fetchActivityData = async () => {
    try {
      const response = await axios.get(`${apiUrl}api/users/getverifiersLogs`);
      console.log("Full API Response:", response.data);

      if (!response.data.verifiers) {
        console.error("Unexpected API structure. Check API response.");
        return;
      }

      const verifierType = localStorage.getItem("verifiertype");
      const verifierId = localStorage.getItem("verifierid");

      let verifierActivity = {};

      response.data.verifiers
        .filter((verifier) => {
          if (verifierType === "8") {
            return true; // Show all verifiers
          } else if (verifierType === "2") {
            return verifier._id === verifierId; // Show only the corresponding verifier
          }
          return false;
        })
        .forEach((verifier) => {
          console.log(`Processing Verifier: ${verifier.name}`);
          console.log("Verifier Logs:", verifier.logs);

          if (verifier.logs) {
            verifier.logs.forEach((log) => {
              console.log("Log Entry:", log);

              const verifierId =
                log && log.Verifier_id ? log.Verifier_id : null;
              const activityLevel =
                log && log.ActivityLevel ? parseFloat(log.ActivityLevel) : 0;
              const loginTime = log && log.DateTime ? log.DateTime : "N/A";

              if (verifierId) {
                if (!verifierActivity[verifierId]) {
                  verifierActivity[verifierId] = {
                    name: verifier.name,
                    totalActivityLevel: 0,
                    loginTimes: [],
                    todayStartTime: null,
                    todayActivityLevel: 0,
                    activityLevelCount: 0,
                  };
                }
                verifierActivity[verifierId].totalActivityLevel +=
                  activityLevel;
                verifierActivity[verifierId].loginTimes.push(loginTime);
                verifierActivity[verifierId].activityLevelCount++;

                // Check if the login time is today and update today's start time and activity level
                if (
                  moment(loginTime, "DD-MM-YYYY HH:mm:ss").isSame(
                    moment(),
                    "day"
                  )
                ) {
                  if (!verifierActivity[verifierId].todayStartTime) {
                    verifierActivity[verifierId].todayStartTime = loginTime;
                  }
                  verifierActivity[verifierId].todayActivityLevel +=
                    activityLevel;
                }
              }
            });
          }
        });

      console.log("Final Aggregated Activity Data:", verifierActivity);

      const activityLevels = Object.entries(verifierActivity).map(
        ([verifierId, data], index) => ({
          serialNo: index + 1,
          verifierName: data.name,
          totalActivityLevel: data.totalActivityLevel,
          loginTime: data.loginTimes.join(", "),
          todayStartTime: data.todayStartTime || "N/A",
          todayActivityLevel:
            (data.todayActivityLevel * 100) / (data.activityLevelCount * 100),
        })
      );

      // Sort by today's activity level
      activityLevels.sort(
        (a, b) => b.todayActivityLevel - a.todayActivityLevel
      );

      console.log("Final Processed Data for Table:", activityLevels);
      setActivityData(activityLevels);
      setFilteredData(activityLevels);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching activity data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchActivityData();
  }, []);

  // Pagination Logic
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredData.slice(indexOfFirstData, indexOfLastData);
  const totalPages = Math.ceil(filteredData.length / dataPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <div className="reports-completed">
      {loading ? (
        <Loading />
      ) : (
        <>
          <table className="reports-table">
            <thead>
              <tr>
                <th>Serial No</th>
                <th>Verifier Name</th>
                <th>Activity Level</th>
                <th>Login Time</th>
              </tr>
            </thead>
            <tbody>
              {currentData.length > 0 ? (
                currentData.map((data, index) => {
                  console.log("Rendering Row:", data); // Debug log
                  return (
                    <tr key={index}>
                      <td>{indexOfFirstData + index + 1}</td>
                      <td>{data.verifierName}</td>
                      <td>{data.todayActivityLevel.toFixed(2)}</td>
                      <td>{data.todayStartTime}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="4" style={{ textAlign: "center" }}>
                    No activity data available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="reports-pagination">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Prev
            </button>
            {Array.from({ length: totalPages }, (_, index) => index + 1).map(
              (number) => (
                <button
                  key={number}
                  onClick={() => paginate(number)}
                  className={currentPage === number ? "active" : ""}
                >
                  {number}
                </button>
              )
            )}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ActivityLevel;
