import { logoutUser } from "../actions/authActions";
import store from "../store";
import axios from "axios";

class IdleTimer {
  constructor() {
    this.timeout = 20 * 60 * 1000; // 20 minutes before logout
    this.idleThreshold = 5 * 60 * 1000; // 5 minutes of inactivity triggers logout
    this.inactivityThreshold = 2.5 * 60 * 1000; // 2.5 minutes of inactivity triggers break log
    this.events = [
      "mousedown",
      "mousemove",
      "keypress",
      "scroll",
      "touchstart",
    ];
    this.eventHandler = this.trackActivity.bind(this);
    this.timer = null;
    this.idleTimer = null;
    this.inactivityTimer = null;

    this.activityCount = 0;
    this.startTime = this.getStoredLoginTime() || Date.now();
    this.activityLevel = "Low";
    this.breakTime = this.getStoredBreakTime(); // Get previous break time

    this.startTimer();
    this.activityMonitor();
    this.setupCrossTabSync(); // Setup cross-tab synchronization
  }

  getStoredLoginTime() {
    const time = localStorage.getItem("loggedInTime");
    return time ? new Date(time).getTime() : null;
  }

  startTimer() {
    this.storeLoginTime();
    this.events.forEach((event) => {
      window.addEventListener(event, this.eventHandler);
    });

    document.addEventListener(
      "visibilitychange",
      this.handleVisibilityChange.bind(this)
    );

    this.timer = setTimeout(() => this.logout(), this.timeout);
    this.startIdleTimer();
  }

  startIdleTimer() {
    this.idleTimer = setTimeout(() => {
      this.logout();
    }, this.idleThreshold);

    this.inactivityTimer = setTimeout(() => {
      this.addBreakTime();
      console.log("Break - 2.5 min of inactivity");
    }, this.inactivityThreshold);
  }

  trackActivity() {
    this.activityCount++;
    this.resetTimers();
  }

  addBreakTime() {
    this.breakTime += 2.5;
    this.storeBreakTime();
    console.log(`Break time updated: ${this.breakTime} minutes`);
    axios
      .post(
        `https://web.mybiocalculus.com:3002/api/users/UpdateVerifierActivity`,
        {
          verifierid: localStorage.getItem("verifierid"),
          LoginedTime: localStorage.getItem("loginedDateTime"),
          BreaksConsumed: this.breakTime,
        }
      )
      .then(() => console.log("Break time updated successfully"))
      .catch((err) => console.log("Error updating break time:", err));
  }

  activityMonitor() {
    setInterval(() => {
      this.updateActivityLevel();
      this.storeActivityData();
      this.storeRemainingLogoutTime();
    }, 60 * 1000);
  }

  updateActivityLevel() {
    const activityPercentage = this.getActivityPercentage();
    console.log("User Activity Percentage:", activityPercentage);

    // Update backend with the new activity percentage
    axios
      .post(
        `https://web.mybiocalculus.com:3002/api/users/UpdateVerifierActivity`,
        {
          Verifier_id: localStorage.getItem("verifierid"),
          LoginedTime: localStorage.getItem("loginedDateTime"),
          ActivityLevel: activityPercentage,
        }
      )
      .then(() => console.log("Activity percentage updated successfully"))
      .catch((err) => console.log("Error updating activity percentage:", err));
  }

  getActivityPercentage() {
    const elapsedTime = (Date.now() - this.startTime) / 1000;
    const elapsedMinutes = elapsedTime / 60;
    const maxPossibleInteractions = elapsedMinutes * 30;
    const activityPercentage = maxPossibleInteractions
      ? Math.min((this.activityCount / maxPossibleInteractions) * 100, 100)
      : 0;
    const formattedActivityPercentage = activityPercentage.toFixed(2);
    localStorage.setItem("activityPercentage", formattedActivityPercentage);
    return formattedActivityPercentage;
  }

  storeActivityData() {
    const activityData = {
      activityPercentage: this.getActivityPercentage(),
    };
    localStorage.setItem("userActivity", JSON.stringify(activityData));
  }

  storeLoginTime() {
    if (!localStorage.getItem("loggedInTime")) {
      localStorage.setItem("loggedInTime", new Date().toISOString());
    }
  }

  storeBreakTime() {
    localStorage.setItem("breakTime", this.breakTime);
  }

  getStoredBreakTime() {
    return parseFloat(localStorage.getItem("breakTime")) || 0;
  }

  storeRemainingLogoutTime() {
    const remainingTime = Math.max(
      (this.timeout - (Date.now() - this.startTime)) / 1000 / 60,
      0
    ).toFixed(2);
    localStorage.setItem("remainingLogoutTime", remainingTime);
  }

  resetTimers() {
    clearTimeout(this.timer);
    clearTimeout(this.idleTimer);
    clearTimeout(this.inactivityTimer);
    this.timer = setTimeout(() => this.logout(), this.timeout);
    this.startIdleTimer();
  }

  handleVisibilityChange() {
    if (!document.hidden) {
      this.resetTimers();
    }
  }

  async logout() {
    const verifierType = localStorage.getItem("verifierid");
    const verifierTypeFromStorage = localStorage.getItem("verifiertype");

    if (
      verifierType === "5d6df589c3d92110fccfde7c" ||
      verifierTypeFromStorage === "7"
    ) {
      console.log("Verifier type is 7. Logout prevented.");
      return;
    }

    if (this.idleTimer && this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
      this.breakTime += 2.5; // Add the remaining 2.5 minutes to break time
      this.storeBreakTime();
    }

    await axios.post(
      `https://web.mybiocalculus.com:3002/api/users/updateLogoutTime`,
      {
        Verifier_id: localStorage.getItem("verifierid"),
        LoginedTime: localStorage.getItem("loginedDateTime"),
      }
    );

    await axios.post(
      `https://web.mybiocalculus.com:3002/api/users/UpdateVerifierActivity`,
      {
        Verifier_id: localStorage.getItem("verifierid"),
        LoginedTime: localStorage.getItem("loginedDateTime"),
        ActivityLevel: this.activityLevel,
        BreaksConsumed: this.breakTime,
      }
    );

    this.events.forEach((event) => {
      window.removeEventListener(event, this.eventHandler);
    });

    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );

    clearTimeout(this.timer);
    clearTimeout(this.idleTimer);
    clearTimeout(this.inactivityTimer);

    this.storeBreakTime();
    localStorage.clear();
    store.dispatch(logoutUser());
  }

  setupCrossTabSync() {
    window.addEventListener("storage", (event) => {
      if (event.key === "userActivity") {
        const activityData = JSON.parse(event.newValue);
        if (activityData) {
          console.log("Activity detected from another tab:", activityData);
          this.trackActivity();
        }
      }
    });
  }
}

export default IdleTimer;
