import React, { Component } from "react";

class SideBar extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div
        className="fixed-sidebar-left"
        style={{ backgroundColor: "#2a3e4c" }}
      >
        <ul
          className="nav navbar-nav side-nav nicescroll-bar"
          style={{ backgroundColor: "#2a3e4c" }}
        >
          {this.props.menus.map(function (menu, i) {
            return (
              <li key={i} style={{color:"white"}}>
                <a
                  href={menu.url}
                  data-toggle="collapse"
                  data-target="#dashboard_dr"
                  style={{color:"white"}}
                >
                  <div
                    className="pull-left"
                    style={{ backgroundColor: "#2a3e4c" }}
                  >
                    <i
                      className="zmdi zmdi-window-maximize mr-20"
                      style={{ backgroundColor: "#2a3e4c" }}
                    ></i>
                    <span
                      className="right-nav-text"
                      style={{ backgroundColor: "#2a3e4c" }}
                    >
                      {menu.title}
                    </span>
                  </div>
                  <div
                    className="pull-right"
                    style={{ backgroundColor: "#2a3e4c" }}
                  ></div>
                  <div
                    className="clearfix"
                    style={{ backgroundColor: "#2a3e4c" }}
                  ></div>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default SideBar;
