import React, { useState, useEffect } from "react";
import axios from "axios";
import apiUrl from "../../../config/config";
import moment from "moment";
import "./ReportsCompleted.css"; // Import CSS for dark theme
import Loading from "./Loading"; // Import Loading component

const Productivity = () => {
  const [productivityData, setProductivityData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const dataPerPage = 8;
  const maxPageNumbersToShow = 8;

  const fetchProductivityData = async () => {
    try {
      const response = await axios.get(`${apiUrl}api/users/getverifiersLogs`);
      console.log("Full API Response:", response.data);

      if (!response.data.verifiers) {
        console.error("Unexpected API structure. Check API response.");
        return;
      }

      const verifierType = localStorage.getItem("verifiertype");
      const verifierId = localStorage.getItem("verifierid");

      let productivityData = {};

      response.data.verifiers
        .filter((verifier) => {
          if (verifierType === "8") {
            return true; // Show all verifiers
          } else if (verifierType === "2") {
            return verifier._id === verifierId; // Show only the corresponding verifier
          }
          return false;
        })
        .forEach((verifier) => {
          if (verifier.logs) {
            verifier.logs.sort(
              (a, b) =>
                moment(b.DateTime, "DD-MM-YYYY HH:mm:ss") -
                moment(a.DateTime, "DD-MM-YYYY HH:mm:ss")
            );

            verifier.logs.forEach((log) => {
              const verifierId =
                log && log.Verifier_id ? log.Verifier_id : null;
              const breakConsumed =
                log && log.BreaksConsumed ? parseFloat(log.BreaksConsumed) : 0;
              const activityLevel =
                log && log.ActivityLevel ? parseFloat(log.ActivityLevel) : 0;
              const loginTime = log && log.DateTime ? log.DateTime : "N/A";
              const logoutTime =
                log && log.LoggedoutTime ? log.LoggedoutTime : null;

              if (verifierId) {
                if (!productivityData[verifierId]) {
                  productivityData[verifierId] = {
                    name: verifier.name,
                    totalBreakConsumed: 0,
                    totalActivityLevel: 0,
                    loginTimes: [],
                    logoutTimes: [],
                    dailyWorkedHours: 0,
                    weeklyWorkedHours: 0,
                    monthlyWorkedHours: 0,
                    totalWorkedHours: 0,
                    todayActivityLevel: 0,
                    activityLevelCount: 0,
                  };
                }

                if (
                  moment(loginTime, "DD-MM-YYYY HH:mm:ss").isSame(
                    moment(),
                    "day"
                  )
                ) {
                  productivityData[verifierId].totalBreakConsumed +=
                    breakConsumed;
                  productivityData[verifierId].todayActivityLevel +=
                    activityLevel;
                }

                productivityData[verifierId].totalActivityLevel +=
                  activityLevel;
                productivityData[verifierId].loginTimes.push(loginTime);
                if (logoutTime) {
                  productivityData[verifierId].logoutTimes.push(logoutTime);
                }
                productivityData[verifierId].activityLevelCount++;

                if (logoutTime) {
                  const loginMoment = moment(loginTime, "DD-MM-YYYY HH:mm:ss");
                  const logoutMoment = moment(
                    logoutTime,
                    "DD-MM-YYYY HH:mm:ss"
                  );
                  const duration = moment.duration(
                    logoutMoment.diff(loginMoment)
                  );
                  const hoursWorked = duration.asHours();

                  if (loginMoment.isSame(moment(), "day")) {
                    productivityData[verifierId].dailyWorkedHours +=
                      hoursWorked;
                  }
                  if (loginMoment.isSameOrAfter(moment().subtract(7, "days"))) {
                    productivityData[verifierId].weeklyWorkedHours +=
                      hoursWorked;
                  }
                  if (loginMoment.isSame(moment(), "month")) {
                    productivityData[verifierId].monthlyWorkedHours +=
                      hoursWorked;
                  }
                  productivityData[verifierId].totalWorkedHours += hoursWorked;
                }
              }
            });

            const todayLogs = verifier.logs.filter((log) =>
              moment(log.DateTime, "DD-MM-YYYY HH:mm:ss").isSame(
                moment(),
                "day"
              )
            );

            if (todayLogs.length > 0) {
              const lastLog = todayLogs[todayLogs.length - 1];
              const lastLoginTime = lastLog.DateTime;
              const lastLogoutTime =
                lastLog.LoggedoutTime || moment().format("DD-MM-YYYY HH:mm:ss");
              console.log("verifier name", verifier.name);
              console.log("Last Login Time:", lastLoginTime);
              console.log("Logout Time or Now:", lastLogoutTime);

              const loginMoment = moment(lastLoginTime, "DD-MM-YYYY HH:mm:ss");
              const logoutMoment = moment(
                lastLogoutTime,
                "DD-MM-YYYY HH:mm:ss"
              );
              const duration = moment.duration(logoutMoment.diff(loginMoment));
              const totalWorkedHoursToday = duration.asHours();

              console.log("Difference in Hours:", totalWorkedHoursToday);

              if (productivityData[verifier._id]) {
                productivityData[verifier._id].dailyWorkedHours =
                  totalWorkedHoursToday;
              }
            }
          }
        });

      console.log("Final Aggregated Productivity Data:", productivityData);

      const productivityDataArray = Object.entries(productivityData).map(
        ([verifierId, data], index) => {
          const totalPossibleHours = 8; // Assuming an 8-hour workday
          const productivityLevelPercentage =
            (data.dailyWorkedHours / totalPossibleHours) * 100;

          return {
            serialNo: index + 1,
            verifierName: data.name,
            totalBreakConsumed: data.totalBreakConsumed,
            totalActivityLevel: data.totalActivityLevel,
            dailyWorkedHours: formatHours(data.dailyWorkedHours),
            weeklyWorkedHours: data.weeklyWorkedHours.toFixed(2),
            monthlyWorkedHours: data.monthlyWorkedHours.toFixed(2),
            totalWorkedHours: data.totalWorkedHours.toFixed(2),
            todayActivityLevel:
              (data.todayActivityLevel * 100) / (data.activityLevelCount * 100),
            productivityLevelPercentage: productivityLevelPercentage.toFixed(2),
          };
        }
      );

      productivityDataArray.sort(
        (a, b) => b.monthlyWorkedHours - a.monthlyWorkedHours
      );

      console.log("Final Processed Data for Table:", productivityDataArray);
      setProductivityData(productivityDataArray);
      setFilteredData(productivityDataArray);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching productivity data:", error);
      setLoading(false);
    }
  };

  const formatHours = (hours) => {
    const totalSeconds = Math.floor(hours * 3600);
    const hrs = Math.floor(totalSeconds / 3600);
    const mins = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;
    return `${hrs.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    fetchProductivityData();
  }, []);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredData.slice(indexOfFirstData, indexOfLastData);
  const totalPages = Math.ceil(filteredData.length / dataPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <div className="reports-completed">
      {loading ? (
        <Loading />
      ) : (
        <>
          <table className="reports-table">
            <thead>
              <tr>
                <th>Serial No</th>
                <th>Verifier Name</th>
                <th>Total Break Consumed</th>
                <th>Total Activity Level</th>
                <th>Daily Worked Hours</th>
                <th>Weekly Worked Hours</th>
                <th>Monthly Worked Hours</th>
                <th>Total Worked Hours</th>
                <th>Today's Activity Level</th>
                <th>Productivity Level (%)</th>
              </tr>
            </thead>
            <tbody>
              {currentData.length > 0 ? (
                currentData.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{indexOfFirstData + index + 1}</td>
                      <td>{data.verifierName}</td>
                      <td>{data.totalBreakConsumed}</td>
                      <td>{data.totalActivityLevel}</td>
                      <td>{data.dailyWorkedHours}</td>
                      <td>{data.weeklyWorkedHours}</td>
                      <td>{data.monthlyWorkedHours}</td>
                      <td>{data.totalWorkedHours}</td>
                      <td>{data.todayActivityLevel.toFixed(2)}</td>
                      <td>{data.productivityLevelPercentage}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="10" style={{ textAlign: "center" }}>
                    No productivity data available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="reports-pagination">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Prev
            </button>
            {Array.from({ length: totalPages }, (_, index) => index + 1).map(
              (number) => (
                <button
                  key={number}
                  onClick={() => paginate(number)}
                  className={currentPage === number ? "active" : ""}
                >
                  {number}
                </button>
              )
            )}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Productivity;
